import { FC, PropsWithChildren } from 'react';

import './styles/index.scss';

export type ParagProps = Partial<{
  variant: string;
  className: string;
}>;

const Parag: FC<PropsWithChildren<ParagProps>> = ({ variant = 'primary', className = '', children }) => {
  return <p className={`parag parag--${variant} ${className}`}>{children}</p>;
};

export default Parag;
