/* eslint-disable import/no-cycle */
import { serviceLogger } from '../logger';
import { fetchLogos } from "../fetchLocalData";

export const getLogo = async (platform) => {
  try {
    const logos = await fetchLogos(platform);
    const logoNameToShow =  Object.keys(logos).find(logo => logos[logo].status);
    return `../../${logos[logoNameToShow].image}`;
  } catch (error) {
    serviceLogger.error('getLogo()', error);
  }
};
