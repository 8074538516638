import React from 'react';
import styles from '../../../pages/Game/Game.module.scss';

function SingleGameAgeRating({ rating, title }) {
  return (
    <div className={styles['game-single__rating']}>
      <h3 className={styles['game-single__titleMini']}>{title}</h3>
      <div className={styles['game-single__rating--box']}>
        <p>{rating}</p>
      </div>
    </div>
  );
}

export default SingleGameAgeRating;
