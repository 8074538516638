import { Trans, useTranslation } from 'react-i18next';
import { ParagWithIcon } from '../../../commons/ParagWithIcon';
import { Headline } from '../../../commons/Headline';
import { ReactComponent as ErrorIcon } from '../../../../assets/images/svg/error-icon.svg';

type ModalUploadImageErrorProps = {
  errorImage: string;
};

function ModalUploadImageError({ errorImage = '' }: ModalUploadImageErrorProps) {
  const { t } = useTranslation();
  return (
    <div className="modal__content-fileerror">
      <Headline headlineLevel="p" size="4" variant="primary" className="modal__headline center-inline">
        {t<string>('profile.titles.upload_error')}
      </Headline>
      <ParagWithIcon variant="primary" icon={<ErrorIcon />}>
        <div className="modal__content-fileerror-container">
          <Trans i18nKey="profile.message.upload_error" values={{ errorImage }} components={{ span: <span /> }} />
        </div>
      </ParagWithIcon>
    </div>
  );
}

export default ModalUploadImageError;
