import ACTIONS from './actions';
import { ActiveNotificationsStorage } from '../../services/Storage';
import { NOTIFICATION_STATUSES, LOCAL_STORAGE_KEYS, INSTALLATION_TYPE } from '../../constants';
import { compareVersions, getInstalledVersionByGame } from "../../helpers";

const activeNotificationsStorage = new ActiveNotificationsStorage(LOCAL_STORAGE_KEYS.NOTIFICATIONS);

const excludeNotification = (notifications, notification) => {
  const removeIndex = notifications.findIndex(storedNotification => storedNotification.uri === notification.uri);
  if (removeIndex === -1) return notifications;
  return [...notifications.slice(0, removeIndex), ...notifications.slice(removeIndex + 1)];
};

function notificationsReducer(state = [], action) {
  switch (action.type) {
    case ACTIONS.GET_INIT_NOTIFICATIONS: {
      return [...action.notifications];
    }
    case ACTIONS.ADD_NOTIFICATION: {
      return [...state, action.notification];
    }
    case ACTIONS.CHANGE_NOTIFICATION_STATUS:
      const { notification, status } = action;
      return state.map(storedNotification =>
        storedNotification.uri !== notification.uri ? storedNotification : { ...notification, status },
      );
    case ACTIONS.REMOVE_NOTIFICATION: {
      return excludeNotification(state, action.notification);
    }
    default:
      return state;
  }
}

export const setNotifications = () => {
  return (dispatch, getState) => {
    const notifications = activeNotificationsStorage.getActiveNotifications();
    const checkedNotifications = [];

    const { games, installedGames } = getState().main;
    const activeURISinProgress = getState().progress.inProgress.map(progress => progress.uri);

    const installedGamesUris = installedGames.map(game => {
      return games.find(currGame => currGame.packageId === game.packageId || currGame.xpromoId === game.xpromoId)?.storeLink || [];
    });

    if (notifications.length) {
      for (const notification of notifications) {
        const { uri, status, type } = notification;
        // eslint-disable-next-line default-case
        switch (status) {
          case NOTIFICATION_STATUSES.INSTALLED:
            if (installedGamesUris.includes(uri)) {
              checkedNotifications.push(notification);
              break;
            } else if (activeURISinProgress.includes(uri)) {
              // Contentious case, must be discussed later
              activeNotificationsStorage.changeNotificationStatus(notification, NOTIFICATION_STATUSES.PROGRESS);
              checkedNotifications.push({ ...notification, status: NOTIFICATION_STATUSES.PROGRESS });
              break;
            } else {
              removeNotification(notification);
              break;
            }
          case NOTIFICATION_STATUSES.PROGRESS:
            if (activeURISinProgress.includes(uri)) {
              checkedNotifications.push(notification);
              break;
            } else if (installedGamesUris.includes(uri)) {
              if (type === INSTALLATION_TYPE.UPDATE) {
                checkedNotifications.push(notification);
              } else {
                activeNotificationsStorage.changeNotificationStatus(notification, NOTIFICATION_STATUSES.INSTALLED);
                checkedNotifications.push({ ...notification, status: NOTIFICATION_STATUSES.INSTALLED });
              }
              break;
            } else {
              removeNotification(notification);
              break;
            }
          case NOTIFICATION_STATUSES.UPDATE:
            if (activeURISinProgress.includes(uri)) {
              activeNotificationsStorage.changeNotificationStatus(notification, NOTIFICATION_STATUSES.PROGRESS);
              checkedNotifications.push({ ...notification, status: NOTIFICATION_STATUSES.PROGRESS });
              break;
            }
            if (installedGamesUris.includes(uri)) {
              const gameByUri = games.find(game => game.storeLink === uri);
              const installedVersion = getInstalledVersionByGame(installedGames, gameByUri);

              if(compareVersions(gameByUri.updateInfoVersion, installedVersion) === 1) {
                checkedNotifications.push(notification);
                break;
              } else {
                activeNotificationsStorage.changeNotificationStatus(notification, NOTIFICATION_STATUSES.INSTALLED);
                checkedNotifications.push({ ...notification, status: NOTIFICATION_STATUSES.INSTALLED });
                break;
              }
            } else {
              removeNotification(notification);
              break;
            }
          case NOTIFICATION_STATUSES.ERROR:
            if (installedGamesUris.includes(uri)) {
              activeNotificationsStorage.changeNotificationStatus(notification, NOTIFICATION_STATUSES.INSTALLED);
              checkedNotifications.push({ ...notification, status: NOTIFICATION_STATUSES.INSTALLED });
              break;
            } else if (activeURISinProgress.includes(uri)) {
              activeNotificationsStorage.changeNotificationStatus(notification, NOTIFICATION_STATUSES.PROGRESS);
              checkedNotifications.push({ ...notification, status: NOTIFICATION_STATUSES.PROGRESS });
            } else {
              checkedNotifications.push(notification);
            }
        }
      }
    }

    {
      /*
      if (installedGames.length) {
        const deferredUpdateGames = deferredUpdateGamesNotificationsStorage.getActiveNotifications();
        for (const installedGame of installedGames) {
          const existingGame = games.find(game => game.packageId === installedGame.packageId);

          const gameUpdateIsNotDeferred =
            existingGame &&
            deferredUpdateGames.findIndex(deferredGame => deferredGame.uri === existingGame.storeLink) === -1;

          // todo: simplify the assertion to one step
          if (gameUpdateIsNotDeferred && compareVersions(existingGame.updateInfoVersion, installedGame.version) === 1) {
            const formedNotification = {
              uri: existingGame.storeLink,
              status: NOTIFICATION_STATUSES.UPDATE,
              type: INSTALLATION_TYPE.UPDATE,
              updateVersion: existingGame.updateInfoVersion
            };
            const checkedNotificationIndex = checkedNotifications.findIndex(n => n.uri === existingGame.storeLink);

            if (checkedNotificationIndex !== -1) {
              if (checkedNotifications[checkedNotificationIndex].status !== NOTIFICATION_STATUSES.PROGRESS) {
                checkedNotifications[checkedNotificationIndex] = formedNotification;
              }
            } else {
              activeNotificationsStorage.addURIData(formedNotification);
              checkedNotifications.push(formedNotification);
            }
          }
        }
      }
    */
    }

    dispatch({
      type: ACTIONS.GET_INIT_NOTIFICATIONS,
      notifications: checkedNotifications,
    });
  };
};

export const addNotification = notification => {
  return (dispatch, getState) => {
    const { settings: { autoupdate } } = getState().main;
    if(notification.type === INSTALLATION_TYPE.UPDATE && autoupdate) return;
    activeNotificationsStorage.addURIData(notification);
    dispatch({
      type: ACTIONS.ADD_NOTIFICATION,
      notification,
    });
  };
};

export const changeNotificationStatus = (uri, status) => {
  return (dispatch, getState) => {
    const notification = getState()?.notifications?.find(notification => notification.uri === uri);
    activeNotificationsStorage.changeNotificationStatus({ uri }, status);
    notification && dispatch({
      type: ACTIONS.CHANGE_NOTIFICATION_STATUS,
      notification,
      status,
    });
  };
};

export const removeNotification = notification => {
  return dispatch => {
    activeNotificationsStorage.removeURIData(notification);
    dispatch({
      type: ACTIONS.REMOVE_NOTIFICATION,
      notification,
    });
  };
};

{
  /*
  export const removeDeferredGameUpdateNotificationOnUninstall = packageId => {
    return (dispatch, getState) => {
      const { storeLink: uri } = getState().main.games.find(game => game.packageId === packageId);
      deferredUpdateGamesNotificationsStorage.removeURIData({ uri });
    };
  };
*/
}

export default notificationsReducer;
