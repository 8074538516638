import { FC } from 'react';
import { Parag } from '../../commons/Parag';

import cloudIcon from '../../../assets/images/svg/cloud.svg';
import giftIcon from '../../../assets/images/svg/gift.svg';
import friendsIcon from '../../../assets/images/svg/friends.svg';
import discountIcon from '../../../assets/images/svg/discount.svg';

import './styles/index.scss';
import { useTranslation } from "react-i18next";

const FeatureList: FC = () => {
  const { t } = useTranslation();
  return (
    <ul className="feature-list">
      <li className="feature-list__item">
        <div className="feature-list__icon">
          <img src={friendsIcon} alt="" />
        </div>
        <Parag variant="primary">
          {t<string>('profile.feature.play')}
        </Parag>
      </li>
      <li className="feature-list__item">
        <div className="feature-list__icon">
          <img src={cloudIcon} alt="" />
        </div>
        <Parag variant="primary">
          {t<string>('profile.feature.save')}
        </Parag>
      </li>
      <li className="feature-list__item">
        <div className="feature-list__icon">
          <img src={giftIcon} alt="" />
        </div>
        <Parag variant="primary">
          {t<string>('profile.feature.send')}
        </Parag>
      </li>
      <li className="feature-list__item">
        <div className="feature-list__icon">
          <img src={discountIcon} alt="" />
        </div>
        <Parag variant="primary">
          {t<string>('profile.feature.deals')}
        </Parag>
      </li>
    </ul>
  );
};

export default FeatureList;
