import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styles from './ExeInstallProgress.module.scss';
import { Progress } from '../progress';
import { selectError, selectGameProcessingStatus, selectRecentlyInstalledGame } from "../../../store/selectors";
import { ErrorTypes } from "../../../constants";
import { setError } from "../../../store/dux/main";

type ExeInstallProgressProps = {
  icon: string;
  gameName: string;
  game: any;
};

const ExeInstallProgress = ({ icon, gameName, game }: ExeInstallProgressProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // @ts-ignore
  const progressData = useSelector(selectGameProcessingStatus(game.storeLink));
  const recentlyInstalledGame = useSelector(selectRecentlyInstalledGame);
  const installError = useSelector(selectError(ErrorTypes.INSTALLATION_ERROR));
  const history = useHistory();

  const goToGame = game => {
    history.push({
      pathname: `/${game.id}`,
      state: {
        id: game.id,
      },
    });
  };

  useEffect(
    function handleGameInstallationComplete() {
      if (recentlyInstalledGame === game.storeLink) {
        goToGame(game);
      }
    },
    [recentlyInstalledGame],
  );

  useEffect(
    function handleProgressError() {
      if (progressData?.error || installError) {
        goToGame(game);
      }
      return () => {
        dispatch(setError(null));
      };
    },
    [progressData?.error, installError],
  );

  return (
    <section className={styles.ExeInstallProgressContainer}>
      <img src={icon} alt={gameName} className={styles.icon} />
      <section className={styles.ExeInstallProgressContainer__data}>
        <header>
          <h3>
            {progressData?.progress > 0
              ? (t('shared.progressbar.progresspage', { gameName }) as string)
              : (t('shared.progressbar.preparing') as string)}
          </h3>
          <h3>{`${progressData?.progress}%`}</h3>
        </header>
        <Progress progress={progressData?.progress} methodType="install" />
      </section>
    </section>
  );
};

export default ExeInstallProgress;
