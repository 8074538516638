import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import GameAction from '../../../shared/games/action/GameAction';
import styles from './SingleGameTitle.module.scss';
import { BUTTON_SIZE } from "../../../../constants";

function SingleGameTitle({ game, installedVersion, loadGame, setButtonStatus }) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const language = i18n.language;
  const divRef = useRef();
  const elementPositionParamsRef = useRef();
  const [fixTitle, setFixTitle] = useState(false);

  useEffect(function calculateDivPosition() {
    const getPanelPosition = event => {
      if (event.detail.scrollTop > elementPositionParamsRef.current.top - 84) {
        setFixTitle(true);
      } else {
        setFixTitle(false);
      }
    };

    if (divRef.current) {
      elementPositionParamsRef.current = divRef.current.getBoundingClientRect();
      window.addEventListener('scrollTop', getPanelPosition);
    }
    return () => window.removeEventListener('scrollTop', getPanelPosition);
  }, []);

  return (
    <>
      <div
        className={styles['game__state']}
        ref={divRef}
        style={{ ...(fixTitle ? { position: 'fixed', top: '90px', width: '790px' } : '') }}>
        <div className={styles['game__state--icon']}>
          <img className={styles['game__state--icon']} src={`../../${game.iconImage}`} alt="" draggable="false" />
        </div>
        <div className={styles['game__state--heating']}>
          <h2
            className={styles['game__state--name']}
            dangerouslySetInnerHTML={{ __html: game.lockit[language].name }}
          />
          <p
            className={styles['game__state--description']}
            dangerouslySetInnerHTML={{
              __html: game.lockit[language].shortDescription,
            }}
          />
        </div>
        <div className={styles['game__state--action']}>
          <GameAction
            {...{ game, installedVersion, loadGame, location, setButtonStatus }}
            key={location?.state.timestamp}
            size={BUTTON_SIZE.MEDIUM}
          />
        </div>
      </div>
      {fixTitle && <div style={{display: 'block', height: elementPositionParamsRef.current.height}}/>}
    </>
  );
}

export default SingleGameTitle;
