import { PropsWithChildren } from 'react';
import styles from './Wrappers.module.scss';

type PropsWithChildrenAndClassesType = PropsWithChildren & { classes?: string[] };

export function PageWrapper({ children, classes = [] }: PropsWithChildrenAndClassesType) {
  return <div className={`${styles.main} ${classes.join(' ')}`}>{children}</div>;
}

export function ContentWrapper({ children, classes = [] }: PropsWithChildrenAndClassesType) {
  return <div className={`${styles.content} ${classes.join(' ')}`}>{children}</div>;
}

export function ContainerWrapper({ children, classes = [] }: PropsWithChildrenAndClassesType) {
  return <article className={`${styles.container} ${classes.join(' ')}`}>{children}</article>;
}
