/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Notifications.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectGameProcessingStatus,
  selectGameByURI,
  selectCurrentLang,
  selectWebViewVersion
} from "../../store/selectors";
import { removeNotification } from '../../store/dux/notifications';
import { startGameLoading } from '../../store/dux/progress';
import ProgressBar from '../shared/progressbar/ProgressBar';
import GameActionPlay from '../shared/games/action/play/GameActionPlay';
import { BUTTON_SIZE, LOCAL_STORAGE_KEYS, NOTIFICATION_STATUSES, REFERRAL_SOURCES } from "../../constants";
import GameActionUpdate from '../shared/games/action/update/GameActionUpdate';
import { ActiveNotificationsStorage } from '../../services/Storage';
import { ReactComponent as ReloadIcon } from '../../assets/images/reload_installation.svg';
import { ReactComponent as CloseNotification } from "../../assets/images/close_notification.svg";
import { getGameLoadingMethod } from '../../helpers';

const NotificationContext = createContext();

const deferredUpdateGamesNotificationsStorage = new ActiveNotificationsStorage(
  LOCAL_STORAGE_KEYS.DEFERRED_UPDATE_GAMES,
);

const SingleNotification = ({ notification, children }) => {
  const { uri } = notification;

  const language = useSelector(selectCurrentLang);
  const game = useSelector(selectGameByURI(uri));

  const {
    iconImage,
    lockit: {
      [language]: { name },
    },
  } = game;

  const value = useMemo(
    () => ({
      uri,
      notification,
      game,
    }),
    [notification, game],
  );

  const webViewVersion = useSelector(selectWebViewVersion);

  return (
    <NotificationContext.Provider value={value}>
      <div className={`${styles.notificationsList_item} ${webViewVersion === 1 ? styles.notificationsList_wv1 : ''}`}>
        <img src={`../../${iconImage}`} />
        <div className={styles.notificationsList_item_desc}>
          <h2 dangerouslySetInnerHTML={{ __html: name }} />
          <div className={styles.notificationsList_item_desc_actions}>{children}</div>
        </div>
      </div>
    </NotificationContext.Provider>
  );
};

SingleNotification[NOTIFICATION_STATUSES.PROGRESS] = function() {
  const {
    notification: { uri },
  } = useContext(NotificationContext);
  let progressData = useSelector(selectGameProcessingStatus(uri));
  return (
    <ProgressBar
      progress={progressData?.progress}
      methodType={progressData?.methodType}
      error={progressData?.error}
      noti
    />
  );
};

SingleNotification[NOTIFICATION_STATUSES.INSTALLED] = function() {
  const dispatch = useDispatch();
  const { game, notification } = useContext(NotificationContext);

  return (
    <>
      <GameActionPlay game={game} passedSource={REFERRAL_SOURCES.LAUNCH.WIDGET} size={BUTTON_SIZE.MEDIUM} />
      <div onClick={() => dispatch(removeNotification(notification))}><CloseNotification /></div>
    </>
  );
};

SingleNotification[NOTIFICATION_STATUSES.UPDATE] = function() {
  const dispatch = useDispatch();
  const { game, notification } = useContext(NotificationContext);
  const { t } = useTranslation();

  const deferUpdateNotification = currentNotification => {
    dispatch(removeNotification(currentNotification));
    deferredUpdateGamesNotificationsStorage.addURIData(currentNotification);
  };

  return (
    <>
      <div className={styles.buttonUpdateContainer}>
        <GameActionUpdate game={game} />
      </div>
      <a onClick={() => deferUpdateNotification(notification)}>{t('notifications.later')}</a>
    </>
  );
};

SingleNotification[NOTIFICATION_STATUSES.ERROR] = function() {
  const dispatch = useDispatch();
  const { game, notification } = useContext(NotificationContext);
  const { type, launchApp, g5tid, updateVersion, source, additionalSource } = notification;
  const method = getGameLoadingMethod(type);

  return (
    <>
      <ProgressBar error />
      <div
        className={styles.errorReload}
        onClick={() =>
          dispatch(startGameLoading(method, game, launchApp, g5tid, updateVersion, source, additionalSource))
        }>
        <ReloadIcon />
      </div>
    </>
  );
};

export default SingleNotification;
