import { useSelector } from "react-redux";
import { selectSiteLocaleByCurrentLang } from "../store/selectors";
import { SITE_LINKS } from "../constants";

type SiteLinksType = {
  [KEY in keyof typeof SITE_LINKS]: string
};

export default function useSiteLinks(): SiteLinksType {
  const currentLanguage = useSelector(selectSiteLocaleByCurrentLang);

  const siteLinks = {} as SiteLinksType;

  for (const [linkType, linkValue] of Object.entries(SITE_LINKS)) {
    siteLinks[linkType] = `${linkValue.replace(':lang', currentLanguage)}`;
  }

  return siteLinks;
}