import { useEffect, useState } from 'react';
import { ProfileType } from '../../store/dux/auth/auth';
import G5FrindsIcon from '../assets/images/svg/g5friends.svg';
import AvatarDefaultIcon from '../assets/images/png/avatar-default.png';
import { useTranslation } from "react-i18next";

type UserProfileHeaderDataType = {
  noAccount?: boolean;
  defaultAccount: boolean;
  nameToDisplay: string;
  avatarToDisplay: string;
  displayCircle: boolean;
};

const useProfileHeaderData = (profileData: ProfileType): UserProfileHeaderDataType => {
  const { t } = useTranslation();
  const [profileHeaderData, setProfileHeaderData] = useState<UserProfileHeaderDataType>(
    {} as UserProfileHeaderDataType,
  );

  useEffect(
    function handleProfileData() {
      const noAccount = !profileData?.id;
      const underAge = profileData?.underageConfirmStatus === 'confirmed';
      const defaultAccount = !profileData?.email && !profileData?.passwordIsCustom;
       const nameToDisplay =
        noAccount || underAge ? 'G5 Friends®' : defaultAccount ? t('profile.titles.guest') : profileData?.nick || profileData?.name;
      const avatarToDisplay =
        noAccount || underAge
          ? G5FrindsIcon
          : defaultAccount
          ? AvatarDefaultIcon
          : profileData?.picture || AvatarDefaultIcon;
      const displayCircle = underAge ? false : !noAccount && !!(!profileData?.email || profileData?.unconfirmedEmail);

      setProfileHeaderData({
        noAccount,
        defaultAccount,
        nameToDisplay,
        avatarToDisplay,
        displayCircle,
      });
    },
    [profileData, t],
  );

  return profileHeaderData;
};

export default useProfileHeaderData;
