import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Parag } from '../../../commons/Parag';
import { Headline } from '../../../commons/Headline';
import { Button } from '../../../commons/Button';
import { Loader } from '../../../commons/Loader';
import PGPLService from '../../../../services/PGPLService';
import { launchOfflineCheckInterval, setOfflineStatus } from '../../../../../store/dux/auth/auth';
import { selectOfflineInterval } from '../../../../../store/dux/auth/selectors';

function ModalOffline() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const offlineInterval = useSelector(selectOfflineInterval);

  useEffect(function handleOfflineIntervalOnRender() {
    if (offlineInterval) {
      clearInterval(offlineInterval);
    }
  }, []);

  const checkOffline = async () => {
    setIsLoading(true);
    const onlineServerTimeStamp = await PGPLService.getServerInfo();
    if (onlineServerTimeStamp) {
      dispatch(setOfflineStatus(false));
      return;
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const goToMyGames = () => {
    dispatch(launchOfflineCheckInterval());
    history.push('/installed-games');
  };

  return (
    <div className="center-inline">
      <Headline headlineLevel="p" size="4" variant="primary" className="modal__headline">
        {t<string>('profile.titles.connection_lost')}
      </Headline>
      <Parag>{t<string>('profile.message.connection_lost')}</Parag>
      <div className="modal__btns modal__btns--column modal__btns--center">
        <Button type="button" className="modal__btn" variant="primary" sizeMode="6" onClick={goToMyGames}>
          {t<string>('profile.buttons.go', { to: t('profile.titles.my_games') })}
        </Button>
        <Button type="button" className="modal__btn" variant="secondary" sizeMode="6" onClick={checkOffline}>
          {isLoading ? <Loader /> : <span>{t<string>('profile.buttons.retry')}</span>}
        </Button>
      </div>
    </div>
  );
}

export default ModalOffline;
