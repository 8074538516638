import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './Support.module.scss';
import { getLoggingPageId, reportSubscription } from '../../../../helpers';
import Envelope from './Envelope';
import { Bell } from "../Bell";
import Settings from './Settings';
import { selectGameXPromoIdByGameId } from '../../../../store/selectors';

const Support = () => {
  const { pathname, state } = useLocation();
  const gameId = state?.id;
  const gameXPromoId = useSelector(state => selectGameXPromoIdByGameId(state, gameId));
  const pageType = getLoggingPageId(pathname, gameId);

  const report = reportSubscription(pageType, gameXPromoId);

  return (
    <nav className={styles.support}>
      <Settings report={report} />
      <Bell />
      <Envelope report={report} />
    </nav>
  );
};

export default Support;
