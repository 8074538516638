import { EventsActionTypes, IEventsActionTypes } from "./actionTypes";
import { getEvents } from "../../../services/Events";
import { logger } from "../../../logger";

const initialState = {
  events: [],
  eventIds: [],
};

type EventsState = typeof initialState;

function eventsReducer(state: EventsState = initialState, action: IEventsActionTypes) {
  switch (action.type) {
    case EventsActionTypes.SET_EVENT_IDS:
      return {
        ...state,
        eventIds: action.eventIds,
      };
    case EventsActionTypes.SET_EVENTS:
      return {
        ...state,
        events: action.events,
      };
    case EventsActionTypes.REMOVE_EVENT:
      const indexToDelete = state.events.findIndex(event => event.id === action.id);
      if (indexToDelete === -1) return state;
      return {
        ...state,
        events: [...state.events.slice(0, indexToDelete), ...state.events.slice(indexToDelete + 1)],
      }
        default:
      return state;
  }
}

export const setEventIds = (eventIds: string[]) => ({
  type: EventsActionTypes.SET_EVENT_IDS,
  eventIds,
});

export const setEvents = () => {
  return async (dispatch, getState) => {
    try {
      const { eventIds } = getState().events;
      if (!eventIds.length) return;
      const events = await getEvents(eventIds);
      dispatch({
        type: EventsActionTypes.SET_EVENTS,
        events,
      });
    } catch (error) {
      logger.error(`setEvents(), ${JSON.stringify(error)}`);
    }
  };
};

export const removeEvent = (id: string) => ({
  type: EventsActionTypes.REMOVE_EVENT,
  id
});

export default eventsReducer;
