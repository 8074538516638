import { RefObject, useEffect } from 'react';

export default function useClickOutside<T extends HTMLElement>(elRef: RefObject<T>, callback: () => void): void {
  useEffect(() => {
    const handleClickEvent = (event: Event): void => {
      if (!elRef.current) return;

      if (elRef.current && !elRef.current.contains(event.target as Node)) {
        callback();
      }
    };
    document.addEventListener('click', handleClickEvent);
    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  });
};
