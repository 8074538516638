import { FC } from 'react';

import './styles/index.scss';

type DelimiterProps = {
  className?: string;
};

const Delimiter: FC<DelimiterProps> = ({ className = '' }) => {
  return <div className={`delimiter ${className}`}></div>;
};

export default Delimiter;
