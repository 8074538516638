/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/commons/Button';
import { GridContainer } from '../../components/containers/GridContainer';
import { HeaderSignIn } from '../../components/compound/HeaderSignIn';
import { MainContainer } from '../../components/containers/MainContainer';
import { Parag } from '../../components/commons/Parag';
import { TextButton } from '../../components/commons/TextButton';
import { Checkbox } from '../../components/commons/Form/Checkbox';
import { FormWrapper } from '../../components/commons/Form/FormWrapper';
import { FormFieldWrapper } from '../../components/commons/Form/FormFieldWrapper';
import { Label } from '../../components/commons/Form/Label';
import { Input } from '../../components/commons/Form/Input';
import { InputPassword } from '../../components/commons/Form/InputPassword';
import { PolicyText } from '../../components/containers-content/PolicyText';
import { TextButtonWithIcon } from '../../components/commons/TextButtonWithIcon';
import ColoredScrollbars from '../../../components/shared/scrollbar/ColoredScrollbars';

import { ReactComponent as BackIcon } from '../../assets/images/svg/back-icon.svg';
import '../../assets/styles/sign-in.scss';
import useRegistrationForm from '../../hooks/useRegistrationForm';
import { apiPGPLMethods } from '../../utils/apiPGPL';
import { renderLogger } from '../../../logger';
import fetchPGPL from '../../utils/apiPGPL';
import { AuthActionTypes } from '../../../store/dux/auth/actionTypes';
import { setLastPlayer } from '../../../services/Keychain';
import { profileRoutes } from '../../routes';
import { ModalContainer } from '../../components/containers/ModalContainer';
import { ModalOffline } from '../../components/containers-content/ModalsContent/ModalOffline';
import { getExternalError } from '../../utils/errors';
import PGPLService from '../../services/PGPLService';
import { selectOfflineStatus } from '../../../store/dux/auth/selectors';
import usePageLogging from '../../../hooks/usePageLogging';
import { LOGGING_PAGE_IDS } from '../../../constants';
import { PageScrollbar } from '../../../components/shared/scrollbar/Scrollbars';
import { setProfileData } from "../../../store/dux/auth/auth";

export type SignUpContextType = {
  isLoading: boolean;
};

export const SignUpContext = createContext<SignUpContextType>(null!);

function SignUp() {
  usePageLogging(LOGGING_PAGE_IDS.SIGN_UP);
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setError } = useRegistrationForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const offline = useSelector(selectOfflineStatus);

  const dispatch = useDispatch();

  const createAccount = async data => {
    try {
      setIsLoading(true);
      const webToken = await PGPLService.createWebPlayer(data);
      await fetchPGPL(apiPGPLMethods.logout);
      dispatch({
        type: AuthActionTypes.CLEAN,
      });
      dispatch({
        type: AuthActionTypes.SET_WEBTOKEN,
        webToken,
      });
      const consentInfo = await PGPLService.getConsentInfo();
      await PGPLService.confirmConsent(consentInfo.version);
      if (data?.nick) {
        try {
          await PGPLService.updatePlayer({ nick: data.nick });
        } catch (error) {
          renderLogger.error(`PGPLService.updatePlayer({ nick: ${data.nick} }), ${JSON.stringify(error)}`);
        }
      }

      const profile = await PGPLService.getPlayerInfo();

      await setLastPlayer(profile.id, webToken);

      dispatch(setProfileData(profile));

      history.push({
        pathname: profileRoutes.profile,
        state: {
          newUser: true,
        },
      });
    } catch (error) {
      renderLogger.error(`createAccount(${JSON.stringify(data)})`, error);
      if (error.error === 'EmailInUse') {
        setError('email', { type: 'custom', message: getExternalError('email', error.error) });
      }
      if (error.error === 'InvalidParameter') {
        setError(error.parameterName, {
          type: 'custom',
          message: getExternalError(error?.parameterName, error.error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageScrollbar>
      <SignUpContext.Provider value={{ isLoading }}>
        <div className="container-all sign-in">
          <ColoredScrollbars
            verticalthumbstyle={{
              right: 2,
              bottom: 2,
              top: 2,
              paddingTop: 0,
              paddingBottom: 0,
            }}>
            <TextButtonWithIcon
              className="sign-in__close-btn"
              icon={<BackIcon />}
              onClick={() => {
                history.goBack();
              }}>
              {t<string>('profile.buttons.back')}
            </TextButtonWithIcon>
            <HeaderSignIn>{t<string>('profile.titles.signup')}</HeaderSignIn>
            <MainContainer mainType="sign">
              <GridContainer containerType="type-2">
                <Parag variant="primary" className="sign-in__parag center-inline">
                  {t<string>('profile.message.signup')}
                </Parag>
                <FormWrapper
                  className="sign-in__form htmlForm sign-in-form"
                  id="g5Friends_signin_form"
                  handleSubmit={() => {}}>
                  <FormFieldWrapper error={errors?.nick?.message}>
                    <Label htmlFor="SignupForm_nick">{t<string>('profile.words.nick')}</Label>
                    <Input
                      id="SignupForm_nick"
                      placeholder={t('profile.placeholders.password', { n: 3, m: 32 })}
                      register={register}
                      name="nick"
                      className={`${errors?.nick && 'error'}`}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper error={errors?.email?.message}>
                    <Label htmlFor="SignupForm_email" isRequired>
                      {t<string>('profile.titles.enter_email')}
                    </Label>
                    <Input
                      id="SignupForm_email"
                      placeholder="example@address.com"
                      register={register}
                      name="email"
                      className={`${errors?.email && 'error'}`}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper error={errors?.password?.message}>
                    <Label htmlFor="SignupForm_password" isRequired>
                      {t<string>('profile.titles.create_password')}
                    </Label>
                    <InputPassword
                      id="SignupForm_password"
                      placeholder={t('profile.placeholders.password', { n: 4, m: 32 })}
                      register={register}
                      name="password"
                      className={`${errors?.password && 'error'}`}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper error={errors?.confirmPassword?.message}>
                    <Label htmlFor="SignupForm_repeatPassword" isRequired>
                      {t<string>('profile.titles.repeat_password')}
                    </Label>
                    <InputPassword
                      id="SignupForm_repeatPassword"
                      placeholder={t<string>('profile.message.enter_same_password')}
                      register={register}
                      name="confirmPassword"
                      className={`${errors?.confirmPassword && 'error'}`}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper error={errors?.accept?.message}>
                    <Checkbox
                      isRequired
                      label={<PolicyText />}
                      inputId="SignupForm_hasConsent"
                      register={register}
                      name="accept"
                      className={`${errors?.accept && 'has-error'}`}
                    />
                  </FormFieldWrapper>
                  <Button
                    type="button"
                    sizeMode="1"
                    variant="primary"
                    className=""
                    onClick={handleSubmit(createAccount)}>
                    {t<string>('profile.buttons.create_account')}
                  </Button>
                </FormWrapper>
                <div className="center-inline">
                  <TextButton type="button" variant="primary" onClick={() => history.goBack()}>
                    {t<string>('profile.buttons.have_account')}
                  </TextButton>
                </div>
              </GridContainer>
            </MainContainer>
          </ColoredScrollbars>
          {offline && (
            <ModalContainer size="sm" closeDisabled>
              <ModalOffline />
            </ModalContainer>
          )}
        </div>
      </SignUpContext.Provider>
    </PageScrollbar>
  );
}
export default SignUp;
