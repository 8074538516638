import { getInstalledVersionByGame } from '../../../../helpers';
import GameCard from '../../../shared/games/card/GameCard';
import styles from '../InstalledGamesContent.module.scss';

function InstalledGamesList({ title, games, clientLibInstalledApps, passedSource }) {
  return (
    <div className={styles['games__installed--list']}>
      <h2 className={styles['games__installed--full-title']}>{title}</h2>
      <div className={styles['game__list']}>
        {games &&
          games.map((game, index) => {
            const installedVersion = getInstalledVersionByGame(clientLibInstalledApps, game);
            return <GameCard key={index} passedSource={passedSource} {...{ game, installedVersion }} />;
          })}
      </div>
    </div>
  );
}

export default InstalledGamesList;
