import { FC, ReactNode, useState } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import './styles/index.scss';
import { Parag } from '../../Parag';
import { RequiredSign } from '../../RequiredSign';
import { ReactComponent as CheckIcon } from '../../../../assets/images/svg/check-icon.svg';

type CheckboxProps = {
  inputId: string;
  label?: ReactNode | string;
  className?: string;
  isRequired?: boolean;
  name?: string;
  register?: UseFormRegister<FieldValues>;
};

const Checkbox: FC<CheckboxProps> = ({ inputId, label, isRequired, className = '', register, name, ...props }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className={`checkbox ${className}`}>
      <input
        type="checkbox"
        className="sr-only"
        id={inputId}
        checked={isChecked}
        {...props}
        {...(register && { ...register(name) })}
        onChange={() => setIsChecked(prev => !prev)}
      />
      <label className="checkbox__label" htmlFor={inputId}>
        <span className={`checkbox__back ${className}`}>
          <span className="checkbox__icon">
            <CheckIcon />
          </span>
        </span>
        {label ? (
          <Parag className="checkbox__txt">
            {label}
            {isRequired && <RequiredSign />}
          </Parag>
        ) : (
          ''
        )}
      </label>
    </div>
  );
};

export default Checkbox;
