import { FC } from 'react';
import { Headline } from '../../commons/Headline';
import { Parag } from '../../commons/Parag';
import { useHistory } from 'react-router-dom';
import { Button } from '../../commons/Button';

import './styles/index.scss';
import { G5LAUNCHER_ROUTES } from "../../../../constants";

type GamesNotPlayedPlaceholderProps = {
  title: string;
  text: string;
  buttonText: string;
};

const GamesNotPlayedPlaceholder: FC<GamesNotPlayedPlaceholderProps> = ({ title, text, buttonText }) => {
  const history = useHistory();
  return (
    <div className="placeholder-games">
      <Headline headlineLevel="h2" size="4" variant="tertiary" className="placeholder-games__headline">
        {title}
      </Headline>
      <Parag variant="tertiary" className="placeholder-games__parag">
        {text}
      </Parag>
      <Button
        variant="tertiary"
        sizeMode="7"
        onClick={() => {
          history.push(G5LAUNCHER_ROUTES.ALL_GAMES);
        }}>
        <span>{buttonText}</span>
      </Button>
    </div>
  );
};

export default GamesNotPlayedPlaceholder;