import { fetchPromo } from "../fetchLocalData";
import { serviceLogger } from "../logger";

export const getPromoData = async (promoName) => {
  try {
    const promoConfig = await fetchPromo(promoName);
    return {
      ...promoConfig,
      backgroundImage: `../../${promoConfig.backgroundImage}`,
      boosterImage: `../../${promoConfig.boosterImage}`
    }
  } catch (error){
    serviceLogger.error(`getPromoData(${promoName})`, error);
  }
}