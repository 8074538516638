import ClientLibConfig from '../classes/ClientLibConfig';
import clientLib from '../clientLib';
import { debugLogData } from '../helpers';
import { serviceLogger } from '../logger';
import { testPGPLServerRegex } from "../constants";
import { fetchBlockedCountries, fetchPromo } from "../fetchLocalData";

export type Config = {
  gameIds: string[];
  bannerIds: string[];
  eventIds: string[];
  platform: string;
  cdn: string;
  storeCDN: string;
  version: string;
  testDevice: boolean;
  revision: string;
  udid: string;
  pgplApiPath: string;
  xpromoId: string;
};

export type XPromoConfig = {
  adjustKey: string;
  computerName: string;
  date: Date;
  deals4key: string;
  deals4keyTest: string;
  gamesCount: string;
  id: string;
  newsBase: string;
  os: string;
  pgplDisabled: boolean;
  platform: string;
  pushwooshKey: string;
  pushwooshKeyTest: string;
  requestTrackingAuthorization: string;
  revision: string;
  site: string;
  testDevice: boolean;
  time: string;
  udid: string;
  testPGPL: boolean;
  xpromoId: string;
};

export const getConfig = async (): Promise<Config> => {
  try {
    const argId = `init_${Date.now()}`;
    const response = await clientLib.sendRequest('getConfig', null, argId);
    debugLogData('getConfig()', response, serviceLogger);
    const data = new ClientLibConfig(response);
    const gameIds = data.getGameIds();
    const bannerIds = data.getBannerIds();
    const eventIds = data.getEventsIds();
    const testDevice = data['test.device'] === 'true';
    const storeCDN = `https://${testDevice ? 'cdn.test' : 'cdn'}.store.g5e.com/`;

    const xpromoConfig = !data?.udid && await getXPromoConfig();
    const udid = data?.udid || xpromoConfig.udid;
    const computerName = data?.computername || xpromoConfig.computerName;
    const pgplApiPath = `https://api.${testPGPLServerRegex.test(computerName) ? 'test.pgpl' : 'pgpl'}.g5e.com/v2/`;

    return {
      gameIds,
      bannerIds,
      eventIds,
      platform: data.platform,
      cdn: data.site,
      version: data['package.version'],
      testDevice,
      revision: data.revision,
      storeCDN,
      udid,
      pgplApiPath,
      xpromoId: data['xpromo.id'],
    };
  } catch (error) {
    serviceLogger.error('getConfig()', error);
  }
};

export const getXPromoConfig = async (): Promise<XPromoConfig> => {
  try {
    const argId = `init_${Date.now()}`;
    const response = await clientLib.sendRequest('getXPromoConfig', null, argId);
    const {
      'adjust.key': adjustKey,
      computername: computerName,
      date,
      'deals.4.key': deals4key,
      'deals.4.key.test': deals4keyTest,
      'games.count': gamesCount,
      id,
      'news.base': newsBase,
      os,
      platform,
      "pushwoosh.key": pushwooshKey,
      "pushwoosh.key.test": pushwooshKeyTest,
      "request.tracking.authorization": requestTrackingAuthorization,
      revision,
      site,
      time,
      udid,
      "xpromo.id": xpromoId,
    } = response;

    const pgplDisabled = response['pgpl.disabled'] === 'true';
    const testDevice = response['test.device'] === 'true';
    const testPGPL = testPGPLServerRegex.test(computerName);

    return {
      adjustKey,
      computerName,
      date,
      deals4key,
      deals4keyTest,
      gamesCount,
      id,
      newsBase,
      os,
      platform,
      pushwooshKey,
      pushwooshKeyTest,
      requestTrackingAuthorization,
      revision,
      site,
      time,
      udid,
      pgplDisabled,
      testDevice,
      testPGPL,
      xpromoId,
    }
  } catch (error) {
    serviceLogger.error('getXPromoConfig()', error);
  }
};
