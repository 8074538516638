import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { selectBlockedCountriesByGame, selectUserRegion } from "../store/selectors";
import { getAndSetUserRegion, getBlockedGames } from "../services/RegionalRestrictions";
import { setBlockedGames } from "../store/dux/main";

export default function useRegionalRestrictions(): void {
  const region = useSelector(selectUserRegion);
  const restrictions = useSelector(selectBlockedCountriesByGame);
  const dispatch = useDispatch();

  useEffect(() => {
    getAndSetUserRegion();
  }, []);

  useEffect(function handleUserRegion() {
    const blockedGames = getBlockedGames(region, restrictions);
    dispatch(setBlockedGames(blockedGames));
  }, [region]);
}
