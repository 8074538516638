import { useEffect, useRef } from 'react';
import { reportMessage } from '../helpers';
import { LOGGING_PAGE_IDS } from "../constants";

const useLogEventCardInView = (
  target,
  eventId: string,
  gameId: string,
  installedVersion: string,
  gamePage: boolean,
) => {
  const inViewRef = useRef<Boolean>(false);

  useEffect(() => {
    let _observer;

    const inViewCallback = entries => {
      if (entries[0].isIntersecting && !inViewRef.current) {
        inViewRef.current = true;
        const source = gamePage ? 'game_page' : 'events_page';
        reportMessage(`g5ln_promo_show('${gameId}', 'event_card', '${eventId}', '${source}', ${!!installedVersion})`);
        _observer?.unobserve(target.current);
      }
    };

    if (target.current) {
      _observer = new IntersectionObserver(inViewCallback, { rootMargin: '0px', threshold: 0 });
      _observer.observe(target.current);
    }

    return () => {
      _observer?.disconnect();
    };
  }, [target]);
};

export default useLogEventCardInView;
