export const APP_NESTED_PATH =
  process.env.NODE_ENV === 'production' ? '/cache/xpromo' + process.env.REACT_APP_SVN_CONTENT_HTML_PATH : '';

// prettier-ignore
export const LANGUAGES = ['en','de','fr','it','es_es','es','sv','ru','zh','zh_tw','ko','ja','pt_pt','pt', 'nl'] as const;

export type LanguageTypes = typeof LANGUAGES[number];

export const SITE_LINKS = {
  TOSLink: 'https://www.g5e.com/:lang/termsofservice',
  storeTOSLink: 'https://www.g5e.com/:lang/g5storetermsofservice',
  privacyPolicyLink: 'https://www.g5e.com/:lang/privacypolicy',
  supportLink: 'https://www.g5e.com/:lang/support',
} as const;

export const EVENTS = {
  INSTALL: {
    START: 'start',
    CANCELED: 'cancelled',
    ERROR: 'error',
    COMPLETED: 'completed',
  }
};

export const INSTALLATION_TYPE = {
  INSTALL: 'install',
  UPDATE: 'update'
};

export const APP_METHODS = {
  INSTALL: 'installApp',
  UPDATE: 'updateApp',
  SET_PROGRESS: 'setProgress',
  RESUME: 'resume',
  SUSPEND: 'suspend',
  OPEN_URI: 'openURI',
  UNINSTALL: 'appUninstall',
  PUSH_NOTIFICATION: 'pushNotification',
};

export const LOGGING_PAGE_IDS = {
  MY_GAMES: 'my_games',
  ALL_GAMES: 'all_games',
  SETTINGS: 'settings',
  GAME: 'game',
  PROFILE: 'profile',
  SIGN_IN: 'sign_in',
  SIGN_UP: 'sign_up',
  PUSH_NOTIFICATIONS: 'notifications',
  EVENTS: 'events',
};

export const G5LAUNCHER_ROUTES = {
  ALL_GAMES: '/all-games',
  INSTALLED_GAMES: '/',
  SETTINGS: '/settings',
  INSTALLATION: '/installation',
  EVENTS: '/events',
};

export const LOGGING_PAGE_IDS_BY_ROUTE = {
  [G5LAUNCHER_ROUTES.ALL_GAMES]: LOGGING_PAGE_IDS.ALL_GAMES,
  [G5LAUNCHER_ROUTES.INSTALLED_GAMES]: LOGGING_PAGE_IDS.MY_GAMES,
  [G5LAUNCHER_ROUTES.SETTINGS]: LOGGING_PAGE_IDS.SETTINGS,
};

export const LOGGING_SUBSCRIBE_ACTION = {
  SUBSCRIBE_POPUP: 'subscribe_popup',
  SUBSCRIBE_POPUP_CLOSE: 'subscribe_close',
  SUBSCRIBE: 'subscribe',
  SUPPORT:  'support'
};

export const MODAL_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export enum ErrorTypes {
  SCREENSHOTS_LOADING_ERROR = 'SCREENSHOTS_LOADING_ERROR',
  INSTALLATION_ERROR = 'INSTALLATION_ERROR',
}

export const NOTIFICATION_STATUSES = {
  INSTALLED: 'Installed',
  PROGRESS: 'Progress',
  UPDATE: 'Update',
  ERROR: 'Error',
};

export const LAUNCHER_UPDATE_STATUS = {
  FORCE_UPDATE: 'forceUpdate',
  ERROR_FORCE_UPDATE: 'errorForceUpdate',
  UPDATE: 'update',
  ERROR: 'error',
  CONGRATULATIONS: 'congratulations',
  UPDATING: 'updating',
};

export const LOCAL_STORAGE_KEYS = {
  LAUNCHER_UPDATE: 'g5launcher_launcherUpdate',
  LAUNCHER_UPDATING: 'g5launcher_launcherUpdating',
  DEFERRED_LAUNCHER_UPDATE: 'g5launcher_deferredLauncherUpdate',
  NOTIFICATIONS: 'g5launcher_notifications',
  INSTALLATION_PROGRESS: 'g5launcher_installationProgress',
  LAUNCHER_REVISION: 'g5launcher_launcherRevision',
  DEFERRED_UPDATE_GAMES: 'g5launcher_deferredUpdateGames',
  LAST_LOGIN_ID: 'g5launcher_lastLoginId',
  LAST_LOGIN_NAME: 'g5launcher_lastLoginName',
  LATEST_PROFILE_GAMES: 'g5launcher_latestProfileGames',
  READ_PUSH_NOTIFICATIONS: 'g5launcher_readPushNotifications',
  TAKEOVER_BANNERS: 'g5launcher_takeoverBanners',
  TAKEOVER_BANNER_CLICKED: 'g5launcher_takeoverBanner_clicked',
  AB_TEST_PGPL_ID: 'g5launcher_ab_test_pgpl_id',
  STORED_XPROMO_CONTENT: 'g5launcher_stored_xpromo_content',
  USER_REGION: 'g5launcher_user_region',
  LAUNCHED_GAMES: 'g5launcher_launched_games',
};

export const LAUNCHER_DOWNLOAD_LINKS = {
  EXE: {
    PRODUCTION: 'https://cdn.store.g5e.com/games/com.g5e.g5launcher.winrt.g5en/G5Installer.exe',
    TEST: 'https://cdn.test.store.g5e.com/games/com.g5e.g5launcher.winrt.g5en/G5Installer.exe'
  },
  APPX: {
    PRODUCTION: 'https://cdn.store.g5e.com/games/com.g5e.g5launcher.winrt.g5en/G5Launcher.appx',
    TEST: 'https://cdn.test.store.g5e.com/games/com.g5e.g5launcher.winrt.g5en/G5Launcher.appx'
  }
};

export const GAME_ACTION_STATUS = {
  PLAY: 'play',
  INSTALL: 'install',
  UPDATE: 'update'
};

export const URI_PARAMS = {
  XPROMOID: 'appxpromoid',
  G5TID: 'g5tid',
  SESSION_TOKEN: 'sessionToken',
  ACTION: 'action',
  ACTION_TYPES: {
    FORCE_UPDATE: 'forceupdate'
  }
};

export const EMAIL_SUBSCRIPTION_STATUSES = {
  SUCCESS: 'SUCCESS',
  WRONG_EMAIL: 'WRONG_EMAIL',
  ALREADY_SUBSCRIBED: 'ALREADY_SUBSCRIBED',
  SUBMITTING: 'SUBMITTING',
  ERROR: 'ERROR'
};

export const EMAIL_SUBSCRIPTION_PARAMS = {
  URL: 'https://subscribe.g5.com/subscribe/create',
  API_KEY: 'KvQKqrBI4s9Wh5PCdcLK57tsH7S9d0ee6nRPuOMr'
};

export const EMAIL_SUBSCRIPTION_LANG_BY_LOCALE = {
  'English': ['en'],
  'German': ['de'],
  'French': ['fr'],
  'Spanish': ['es_es', 'es'],
  'Italian': ['it'],
  'Brazilian': ['pt', 'pt_pt'],
  'Korean': ['ko'],
  'Chinese_S': ['zh'],
  'Chinese_Tr': ['zh_tw'],
  'Japanese': ['ja'],
  'Swedish': ['sv']
};

type SiteLinkLocaleTypes = {
  [key in LanguageTypes]: string
};

export const SITE_LINK_LOCALES: SiteLinkLocaleTypes = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  it: 'it',
  es_es: 'es-es',
  es: 'es',
  sv: 'sv',
  ru: 'ru',
  zh: 'zh',
  zh_tw: 'zh-hk',
  ko: 'ko',
  ja: 'ja',
  pt_pt: 'pt-pt',
  pt: 'pt',
  nl: 'nl',
};

export const XPROMO_LOCALES = {
  en: 'en',
  de: 'de',
  fr: 'fr',
  it: 'it',
  es_es: 'es_ES',
  es: 'es',
  sv: 'sv',
  ru: 'ru',
  zh: 'zh',
  zh_tw: 'zh_TW',
  ko: 'ko',
  ja: 'ja',
  pt_pt: 'pt_PT',
  pt: 'pt',
  nl: 'nl',
}

export const testPGPLServerRegex = /g5-test-pgpl-server|G5_TEST_PGPL_SERVER/;


export const REFERRAL_SOURCES = {
  INSTALL: {
    GAME_PAGE: 'source=game_page',
    GAME_CARD_ALL_GAMES: 'source=game_card_all_games_page',
    TOP_BANNER: 'source=top_banner_all_games_page',
    MY_GAMES: 'source=my_games_page',
    MY_GAMES_OTHER: 'source=my_games_page_other_devices',
    MY_GAMES_ALSO_LIKE_TO_PLAY: 'source=my_games_page_also_like_play',
    PROFILE: 'source=personal_profile',
    EXE: 'source=install_exe',
    NOTIFICATION: 'source=notification',
    EVENT_CARD: 'source=event_card',
    EVENT_POPUP: 'source=event_popup',
    TAKEOVER_BANNER: 'source=takeover_banner',
  },
  LAUNCH: {
    EXE: 'autolaunch_exe',
    UPDATE: 'autolaunch_update',
    TOP_BANNER: 'autolaunch_top_banner',
    WIDGET: 'widget',
  },
};

export const PUSH_NOTIFICATIONS_PARAMS = {
  REPORT_URL_DEV: 'https://api.dev.specialdeals.g5e.com/v4/registerLauncherPushOpen',
  REPORT_URL_PROD: 'https://api.specialdeals.g5e.com/v4/registerLauncherPushOpen',
  REPORT_URL_TEST: 'https://api.test.specialdeals.g5e.com/v4/registerLauncherPushOpen',
  REPORT_TOKEN: 'ndk3ijgsPNSrnf391ndJNSb2J28nNFLAQQ',
};

export enum OpenURICommands {
  GO_TO_ALL_GAMES = 'GO_TO_ALL_GAMES',
  CLEAR = 'CLEAR',
}

export const XPROMO_SD_METHODS = {
  registerImpression: 'https://specialdeals.g5e.com/v4/registerImpression',
  registerAccept: 'https://specialdeals.g5e.com/v4/registerAccept',
};

export enum SELECTED_BANNER_TYPES {
  XPROMO = 'xpromo',
  STATIC = 'static',
}

export const BUTTON_SIZE = {
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const progressIntervalTimeMS = 1000;


