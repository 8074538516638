import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../store/dux/auth/selectors';
import { profileRoutes } from '../routes';

const useJumpToProfileOnIdChange = () => {
  const prevProfileIdRef = useRef<string>();
  const profile = useSelector(selectUserData);
  const history = useHistory();

  useEffect(
    function handleIdChange() {
      prevProfileIdRef.current = profile?.id;
      if (prevProfileIdRef.current && prevProfileIdRef.current !== profile?.id) {
        history.push(profileRoutes.profile);
      }
    },
    [profile?.id],
  );
};

export default useJumpToProfileOnIdChange;
