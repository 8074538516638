import { serviceLogger } from '../logger';
import clientLib from '../clientLib';

type LastPlayer = {
  id: string;
  token: string;
};

export const getLastPlayer = async (): Promise<LastPlayer> | never => {
  try {
    const argId = `init_${Date.now()}`;
    const response = await clientLib.sendRequest('getLastPlayer', null, argId);
    return response;
  } catch (error) {
    serviceLogger.error('getLogo()', JSON.stringify(error));
  }
};

export const setLastPlayer = async (id, token): Promise<boolean> | never => {
  try {
    const status = await clientLib.sendRequest('setLastPlayer', { id, token });
    if (status !== 'completed') {
      throw Error(status);
    }
    return true;
  } catch (error) {
    serviceLogger.error('getLogo()', JSON.stringify(error));
  }
};
