import { useRef } from "react";
import styles from '../../../pages/Game/Game.module.scss';
import useClickOnLink from "../../../../hooks/useClickOnLink";

function SingleGameDescription({ descriptionTitle, fullDescription }) {
  const containerRef = useRef();
  useClickOnLink(containerRef);

  return (
    <div className={styles['game__description']}>
      <h2 className={styles['game-single__title']}>{descriptionTitle}</h2>
      <div
        className={styles['game-single__description']}
        ref={containerRef}
        dangerouslySetInnerHTML={{ __html: fullDescription }}
      />
    </div>
  );
}

export default SingleGameDescription;
