if (process.env.NODE_ENV !== 'production') {
  require('./clientLibEmulation');
}

const eventBuffer = [];
let resourcesLoadedFlag = false;

function handleResourcesLoadingEvent() {
  resourcesLoadedFlag = true;
  for (const eventData of eventBuffer) {
    window.dispatchEvent(new CustomEvent('clientLibResponse', { detail: eventData }));
  }
  window.removeEventListener('resourcesLoaded', handleResourcesLoadingEvent);
}

function handleEventQueue(data) {
  const isInit = data.id.split('_')[0] === 'init';
  resourcesLoadedFlag || isInit
    ? window.dispatchEvent(new CustomEvent('clientLibResponse', { detail: data }))
    : eventBuffer.push(data);
}

window.invokeJsonRpc = function(data) {
  switch (data.method) {
    case 'onEvent':
      switch (data.params.type) {
        case 'load':
          window.dispatchEvent(new CustomEvent('clientLibLoad'));
          window.addEventListener('resourcesLoaded', handleResourcesLoadingEvent);
          break;
        case 'openURI':
          window.dispatchEvent(
            new CustomEvent('clientLibOpenURI', {
              detail: {
                launchArguments: data.params.uri,
              },
            }),
          );
          break;
        case 'unload':
          window.dispatchEvent(
            new Event('clientLibUnload', {
              detail: {
                params: data.params,
              },
            }),
          );
          break;
        case 'suspend':
          window.dispatchEvent(
            new Event('clientLibSuspend', {
              detail: {
                params: data.params,
              },
            }),
          );
          break;
        case 'resume':
          window.dispatchEvent(
            new Event('clientLibResume', {
              detail: {
                params: data.params,
              },
            }),
          );
          break;
        case 'notification': {
          window.dispatchEvent(
            new CustomEvent('clientLibPushNotification', {
              detail: {
                params: data.params,
              },
            }),
          );
        }
        case 'appUninstall':
          window.dispatchEvent(
            new CustomEvent('clientLibAppUninstall', {
              detail: {
                params: data.params,
              },
            }),
          );
          break;
        default:
          throw new Error('Unknown client event type!');
      }
      break;
    default:
      handleEventQueue(data);
  }
};

const clientLib = {
  _requestPrefix: 'g5launcher',
  _requestCounter: 0,
  _getRequestId: function() {
    return this._requestPrefix + '_' + this._requestCounter++;
  },
  onResponse: function(id) {
    return new Promise((resolve, reject) => {
      window.addEventListener('clientLibResponse', function responseHandler(e) {
        if (e.detail.id === id) {
          window.removeEventListener('clientLibResponse', responseHandler);
          if (e.detail.error) {
            reject(e.detail.error);
          }
          resolve(e.detail.result);
        }
      });
    });
  },
  sendRequest: function(method, params, idArg) {
    const id = idArg || this._getRequestId();

    setTimeout(() => {
      window.external.notify(
        JSON.stringify({
          jsonrpc: '2.0',
          method: method,
          params: params,
          id: id,
        }),
      );
    });

    return this.onResponse(id);
  },
  _listeners: [],
  _convertEventTypeToInternalFormat: function(type) {
    return 'clientLib' + type[0].toUpperCase() + type.slice(1);
  },
  on: function(eventType, callback) {
    const type = this._convertEventTypeToInternalFormat(eventType);
    const derivedListener = function(e) {
      e.detail ? callback({ ...e.detail, type: eventType }) : callback({ type: eventType });
    };
    this._listeners.push([callback, derivedListener]);
    window.addEventListener(type, derivedListener);
  },
  off: function(eventType, callback) {
    const type = this._convertEventTypeToInternalFormat(eventType);
    const index = this._listeners.findIndex(item => item[0] === callback);
    if (index !== -1) {
      window.removeEventListener(type, this._listeners[index][1]);
      this._listeners.splice(index, 1);
    }
  },
  one: function(eventType, callback) {
    const type = this._convertEventTypeToInternalFormat(eventType);
    window.addEventListener(type, function listener(e) {
      window.removeEventListener(type, listener);
      e.detail ? callback(e.detail) : callback();
    });
  },
};

export default clientLib;
