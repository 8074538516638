import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ToggleEditPassword } from '../ToggleEditPassword';
import PasswordInfoData from './PasswordInfoData';
import PasswordInfoForm from './PasswordInfoForm';

const PasswordInfoSection: FC = () => {
  const { t } = useTranslation();
  return (
    <ToggleEditPassword
      sectionTitle={t('profile.words.security')}
      form={PasswordInfoForm}
      dataInfo={PasswordInfoData}
    />
  );
};

export default PasswordInfoSection;
