import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Game from '../../../../classes/Game';
import { getInstalledVersionByGame } from '../../../../helpers';
import GameCard from '../card/GameCard';
import { selectAllGames, selectActiveGenre, selectInstalledGamesIDs } from '../../../../store/selectors';
import styles from './GamesCardList.module.scss';

function GamesCardList({ passedSource }) {
  const games = useSelector(selectAllGames, shallowEqual);
  const activeGenre = useSelector(selectActiveGenre);
  const clientLibInstalledApps = useSelector(selectInstalledGamesIDs, shallowEqual);
  const [sortedGameList, setSortedGameList] = useState([]);

  useEffect(
    function handleGamesSorting() {
      const installedGames = [];
      const notInstalledGames = [];
      const gamesToDisplay = activeGenre === 'all' ? games : Game.filterByGenre(games, activeGenre);
      gamesToDisplay?.forEach((game, index) => {
        if(clientLibInstalledApps && clientLibInstalledApps.find(installedGame => installedGame?.packageId === game.packageId || installedGame.xpromoId === game.xpromoId)) {
          const installedVersion = getInstalledVersionByGame(clientLibInstalledApps, game);
          installedGames.push(<GameCard {...{ game, installedVersion }} passedSource={passedSource} key={index} />);
        } else {
          notInstalledGames.push(<GameCard {...{ game } } passedSource={passedSource} key={index} />);
        }
      });
      setSortedGameList([...notInstalledGames, ...installedGames]);
    },
    [clientLibInstalledApps, activeGenre],
  );

  return <div className={styles['game__list']}>{sortedGameList}</div>;
}

export default GamesCardList;
