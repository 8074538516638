import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getEventRemainingTime, TimeoutStamps } from "../../../../services/Time";
import styles from './EventTimeRemaining.module.scss';
import { checkIsNotExpiredByMs } from "../../../../helpers";
import { removeEvent } from "../../../../store/dux/events/events";

type EventTimeRemainingPropTypes = {
  eventId?: string;
  expirationTime: number;
  closeModal: () => void;
};

const EventTimeRemaining = ({ expirationTime, eventId, closeModal }: EventTimeRemainingPropTypes) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState<string[]>([]);
  const [urgency, setUrgency] = useState<boolean>();
  const { t } = useTranslation();

  const checkRemainingTime = (timeMs: number, intervalId?: number): TimeoutStamps => {
    const {
      timeArr,
      urgency,
      timeout,
    } = getEventRemainingTime(timeMs);

    setTime(timeArr);
    setUrgency(urgency);

    if (intervalId && !checkIsNotExpiredByMs(timeMs)) {
      clearInterval(intervalId);
      closeModal();
      if (eventId) {
        dispatch(removeEvent(eventId));
      }
    }

    return timeout;
  }

  useEffect(function handleTimeInterval() {
    const timeout = checkRemainingTime(expirationTime)
    const intervalId = window.setInterval(() => {
      checkRemainingTime(expirationTime, intervalId);
     }, timeout);
     return () => clearInterval(intervalId);
  }, []);

  const [firstBlock, secondBlock, thirdBlock] = time;

  return (
    <time className={`${styles.time} ${urgency ? styles.urgency : ''}`}>
      <span>{t<string>('events.ends')} </span>
      <span className={styles.time__timeUnit}>{firstBlock}</span>
      <span>:</span>
      <span className={styles.time__timeUnit}>{secondBlock}</span>
      <span>:</span>
      <span className={styles.time__timeUnit}>{thirdBlock}</span>
    </time>
  );
};

export default EventTimeRemaining;
