import { FC } from 'react';

import './styles/index.scss';

type CircleProps = {
  variant?: 'orange' | 'red' | 'green';
  className?: string;
  size?: 'sm' | 'md';
  hasBorder?: boolean;
};

const Circle: FC<CircleProps> = ({ variant = 'red', className = '', size = 'sm', hasBorder }) => {
  return (
    <i
      className={`circle circle--${variant} circle--${size} ${
        hasBorder ? 'circle--with-border' : ''
      } ${className}`}></i>
  );
};

export default Circle;
