import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StepOneIcon from '../../../../assets/images/promo-step-1.svg';
import StepTwoIcon from '../../../../assets/images/promo-step-2.svg';
import StepThreeIcon from '../../../../assets/images/promo-step-3.svg';
import './styles/index.scss';
import { selectGameProcessingStatus } from '../../../../store/selectors';
import { GAME_ACTION_STATUS } from '../../../../constants';

function PromoPlate({ className = '', game, buttonStatus, gamePromoData }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const gameStatus = useSelector(selectGameProcessingStatus(game.storeLink));

  const simulateClick = () => {
    const buttonElem = document.getElementById('gameAction');
    if (buttonElem) {
      buttonElem.click();
    }
  };

  const buttons = {
    [GAME_ACTION_STATUS.PLAY]: (
      <span className="btn btn--default btn--green" onClick={simulateClick}>
        {t<string>('shared.buttons.play')}
      </span>
    ),
    [GAME_ACTION_STATUS.INSTALL]: (
      <span className="btn btn--default btn--blue" onClick={simulateClick}>
        {t<string>('shared.buttons.install')}
      </span>
    ),
    [GAME_ACTION_STATUS.UPDATE]: (
      <span className="btn btn--default btn--green" onClick={simulateClick}>
        {t<string>('shared.buttons.update_and_play')}
      </span>
    ),
  };

  const currentLockIt = useMemo(() => {
    const gameIsInstalled = buttonStatus !== GAME_ACTION_STATUS.INSTALL;

    const getCurrTranslation = key => {
      return gamePromoData.lockit[language][key];
    };

    const title = gameIsInstalled ? getCurrTranslation('titlePlay') : getCurrTranslation('titleDownload');
    const step1 = gameIsInstalled ? getCurrTranslation('step1_play') : getCurrTranslation('step1_download');
    return {
      title,
      subtitle: getCurrTranslation('subtitle'),
      footer: getCurrTranslation('footer'),
      step1,
      step2: getCurrTranslation('step2'),
      step3: getCurrTranslation('step3'),
    };
  }, [language, buttonStatus]);

  return (
    <div className={`promo-plate ${className}`} style={{ backgroundImage: `url(${gamePromoData.backgroundImage})` }}>
      <div className="promo-plate__content">
        <p className="promo-plate__discount" dangerouslySetInnerHTML={{ __html: currentLockIt.title }} />
        <p className="promo-plate__date uppercase">{currentLockIt.subtitle}</p>
        <div className="promo-plate__steps">
          <div className="promo-plate__step">
            <div className="promo-plate__step-head">
              <img className="promo-plate__step-icon" src={StepOneIcon} alt="" />
              <p className="promo-plate__step-title">{currentLockIt.step1.title}</p>
            </div>
            <p dangerouslySetInnerHTML={{ __html: currentLockIt.step1.description }} />
          </div>
          <div className="promo-plate__step">
            <div className="promo-plate__step-head">
              <img className="promo-plate__step-icon" src={StepTwoIcon} alt="" />
              <p className="promo-plate__step-title">{currentLockIt.step2.title}</p>
            </div>
            <p dangerouslySetInnerHTML={{ __html: currentLockIt.step2.description }} />
          </div>
          <div className="promo-plate__step">
            <div className="promo-plate__step-head">
              <img className="promo-plate__step-icon" src={StepThreeIcon} alt="" />
              <p className="promo-plate__step-title">{currentLockIt.step3.title}</p>
            </div>
            <p dangerouslySetInnerHTML={{ __html: currentLockIt.step3.description }} />
          </div>
        </div>
        <p className="promo-plate__note">{currentLockIt.step3.footer}</p>
      </div>
      {!gameStatus && buttonStatus && buttons[buttonStatus]}
    </div>
  );
}

export default PromoPlate;
