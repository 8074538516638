import { Route, Switch } from 'react-router-dom';
import { renderLogger } from '../logger';
import Game from './pages/Game/Game';
import Home from './pages/Home';
import { Installation } from "./pages/Installation";
import { Profile } from '../Profile/pages/Profile';
import InstalledGames from './pages/InstalledGames';
import Settings from './pages/Settings/Settings';
import { SignIn } from '../Profile/pages/SignIn';
import { SignUp } from '../Profile/pages/SignUp';
import { profileRoutes } from '../Profile/routes';
import ProtectedRoute from '../Profile/components/commons/ProtectedRoute/ProtectedRoute';
import Events from "./events/Events";
import { G5LAUNCHER_ROUTES } from "../constants";

function AppSwitch() {
  renderLogger.debug('AppSwitch');
  return (
    <main>
      <Switch>
        <Route exact path={[G5LAUNCHER_ROUTES.INSTALLED_GAMES, '/index.html']}>
          <InstalledGames />
        </Route>
        <Route exact path={[G5LAUNCHER_ROUTES.ALL_GAMES, '/index.html']}>
          <Home />
        </Route>
        <Route exact path={G5LAUNCHER_ROUTES.INSTALLATION}>
          <Installation />
        </Route>
        <Route exact path={G5LAUNCHER_ROUTES.SETTINGS}>
          <Settings />
        </Route>
        <Route exact path={G5LAUNCHER_ROUTES.ALL_GAMES}>
          <Home />
        </Route>
        <ProtectedRoute exact path={profileRoutes.profile}>
          <Profile />
        </ProtectedRoute>
        <Route exact path={profileRoutes.signIn}>
          <SignIn />
        </Route>
        <Route exact path={profileRoutes.signUp}>
          <SignUp />
        </Route>
        <Route exact path={G5LAUNCHER_ROUTES.EVENTS}>
          <Events />
        </Route>
        <Route
          exact
          path="/:gameId"
          render={props => <Game key={props.location?.state?.key} />}
        />
      </Switch>
    </main>
  );
}

export default AppSwitch;
