import { SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import GameAction from '../../../shared/games/action/GameAction';
import styles from '../../Events.module.scss';
import { BUTTON_SIZE } from '../../../../constants';

type eventCardButtonPropsType = {
  gameId: string;
  iconImage: string;
  installedVersion: string;
  name: string;
  game: Object;
  passedSource: string;
  closeModal?: () => void;
  additionalSource: string;
  gamePage?: boolean;
};

const EventCardButton = ({
  gameId,
  iconImage,
  installedVersion,
  name,
  game,
  passedSource,
  additionalSource,
                           gamePage,
}: eventCardButtonPropsType) => {
  const history = useHistory();

  const goToGame = (event: SyntheticEvent) => {
    event.stopPropagation();
    if (gamePage) return;
    history.push({ pathname: `/${gameId}`, state: { id: gameId } });
  };


  return (
    <footer className={styles.EventCard__footer}>
      <div onClick={goToGame} style={{ ...(gamePage && { cursor: "default" }) }}>
        <img src={`../../${iconImage}`} alt={name} />
        <p>{name}</p>
      </div>

      <div>
        {
          // @ts-ignore
          <GameAction
            game={game}
            installedVersion={installedVersion}
            location={null}
            passedSource={passedSource}
            additionalSource={additionalSource}
            size={BUTTON_SIZE.MEDIUM}
          />
        }
      </div>
    </footer>
  );
};

export default EventCardButton;
