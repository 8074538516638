import { LANGUAGES, LanguageTypes } from '../constants';
import { fetchBannerConfig } from '../fetchLocalData';
import { logger } from '../logger';

export enum BannerTypes {
  PROMO = 'promo',
  EXTERNAL = 'external',
  GAME = 'game',
  EVENT = 'event',
  GLOBAL_LAUNCH = 'globalLaunch',
}

type BannerLockitExternalType = {
  [key in LanguageTypes]: {
    buttonText: string;
  };
};

type BannerLockitGameType = {
  [key in LanguageTypes]: {
    logoImage: string;
  };
};

export type BannerLockitTakeoverType = {
  [key in LanguageTypes]: {
    backgroundImage: string
  };
};

type BannerLockitPromoType = {
  [key in LanguageTypes]: {
    title: string;
    description: string;
    footer: string;
    subtitle: string;
  };
};

type PromoBannerType = {
  backgroundBlurImage: string;
  backgroundImage: string;
  lockit: BannerLockitPromoType;
  type: BannerTypes.PROMO;
  id: string;
  dateTimeStart: string;
  dateTimeEnd: string;
};

type GameBannerType = {
  backgroundBlurImage: string;
  backgroundImage: string;
  characterImage: string;
  gameId: string;
  lockit: BannerLockitGameType;
  type: BannerTypes.GAME;
  id: string;
};

type ExternalBannerType = {
  backgroundBlurImage: string;
  backgroundImage: string;
  externalLink: string;
  lockit: BannerLockitExternalType;
  type: BannerTypes.EXTERNAL;
  id: string;
};

type TakeoverBannerType = {
  lockit: BannerLockitTakeoverType,
  dateTimeStart: string;
  dateTimeEnd: string;
  impressionsInterval: string,
  isDateTimeByUTC?: string,
  id: string,
};


type EventBannerType = TakeoverBannerType & {
  type: BannerTypes.EVENT,
};

type GlobalLaunchBannerType = TakeoverBannerType & {
  type: BannerTypes.GLOBAL_LAUNCH,
  gameId: string,
};

export type ReturnTakeoverBannerType = EventBannerType | GlobalLaunchBannerType;


export type ReturnBannerType = GameBannerType | ExternalBannerType | PromoBannerType;

export default function Banner(id, config) {
  checkArguments(id, config);

  const { type } = config;

  Object.assign(this, { id, type });

  switch (type) {
    case BannerTypes.GLOBAL_LAUNCH: 
      Object.assign(this, composeGlobalLaunchBannerData(config));
      break;
    case BannerTypes.EVENT: 
      Object.assign(this, composeEventBannerData(config));
      break;
    case BannerTypes.PROMO:
      Object.assign(this, composePromoBannerData(config));
      break;
    case BannerTypes.EXTERNAL:
      Object.assign(this, composeExternalBannerData(config));
      break;
    case BannerTypes.GAME:
      Object.assign(this, composeGameBannerData(config));
      break;
    default:
      break;
  }

  Object.freeze(this);
}

Banner.fetch = function (id): Promise<ReturnBannerType> {
  return fetchBannerConfig(id)
    .then(config => new Banner(id, config))
    .catch(reason => logger.error(`Banner.fetch('${id}')`, reason));
};

function checkArguments(id, config): void {
  if (typeof id !== 'string') {
    throw new Error('"id" is not a string');
  }

  if (typeof config !== 'object') {
    throw new Error('"config" is not an object');
  }

  if (!Object.values(BannerTypes).includes(config.type)) {
    throw new Error('unknown type of the banner');
  }
}

function composePromoBannerData(config): Omit<PromoBannerType, 'type' | 'id'> {
  const { backgroundImage = '', backgroundBlurImage = '', lockit: configLockit = {}, dateTimeStart, dateTimeEnd } = config;

  const lockit = {} as BannerLockitPromoType;

  for (const lang of LANGUAGES) {
    const { title, description, subtitle, footer } = configLockit[lang] || {};
    lockit[lang] = { title, description, subtitle, footer };
  }

  return {
    backgroundImage,
    backgroundBlurImage,
    lockit,
    dateTimeStart,
    dateTimeEnd
  };
}

function composeExternalBannerData(config): Omit<ExternalBannerType, 'type' | 'id'> {
  const { externalLink = '', backgroundImage = '', backgroundBlurImage = '', lockit: configLockit = {} } = config;

  const lockit = {} as BannerLockitExternalType;

  for (const lang of LANGUAGES) {
    const { buttonText = '' } = configLockit[lang] || {};
    lockit[lang] = { buttonText };
  }

  return {
    externalLink,
    backgroundImage,
    backgroundBlurImage,
    lockit,
  };
}

function composeGameBannerData(config): Omit<GameBannerType, 'type' | 'id'> {
  const {
    characterImage = '',
    gameId = '',
    backgroundImage = '',
    backgroundBlurImage = '',
    lockit: configLockit = {},
  } = config;

  const lockit = {} as BannerLockitGameType;

  for (const lang of LANGUAGES) {
    const { logoImage = '' } = configLockit[lang] || {};
    lockit[lang] = { logoImage };
  }

  return {
    characterImage,
    gameId,
    backgroundImage,
    backgroundBlurImage,
    lockit,
  };
}

function composeGlobalLaunchBannerData(config): Omit<GlobalLaunchBannerType, 'type' | 'id'> {
  const {
    dateTimeStart = '',
    dateTimeEnd = '',
    impressionsInterval = '',
    isDateTimeByUTC = false,
    gameId  = '',
    lockit: configLockit = {},
  } = config;

  const lockit = {} as BannerLockitTakeoverType;

  for (const lang of LANGUAGES) {
    const { backgroundImage = '' } = configLockit[lang] || {};
    lockit[lang] = { backgroundImage };
  }

  return {
    gameId,
    isDateTimeByUTC,
    impressionsInterval,
    dateTimeStart,
    dateTimeEnd,
    lockit,
  };
}

function composeEventBannerData(config): Omit<EventBannerType, 'type' | 'id'> {
  const {
    dateTimeStart = '',
    dateTimeEnd = '',
    impressionsInterval = '',
    isDateTimeByUTC = false,
    lockit: configLockit = {},
  } = config;

  const lockit = {} as BannerLockitTakeoverType;

  for (const lang of LANGUAGES) {
    const { backgroundImage = '' } = configLockit[lang] || {};
    lockit[lang] = { backgroundImage };
  }

  return {
    isDateTimeByUTC,
    impressionsInterval,
    dateTimeStart,
    dateTimeEnd,
    lockit,
  };
}



