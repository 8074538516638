import { FC, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { CloseButton } from '../CloseButton';
import ErrorIcon from '../../../assets/images/svg/error-icon.svg';
import SuccessIcon from '../../../assets/images/svg/success-icon.svg';
import './styles/index.scss';
import { NotificationTypes } from "../../../pages/Profile/Profile";

type NotificationProps = {
  message?: NotificationTypes.PROFILE_CHANGE | NotificationTypes.PASSWORD_CHANGE;
  placement?: 'top' | 'topLeft' | 'topRight' | 'bottom' | 'bottomLeft' | 'bottomRight';
  notificationType?: 'error' | 'success';
  className?: string;
  onClose?: () => void;
};

const Notification: FC<NotificationProps> = ({
  notificationType = 'success',
  placement = 'bottom',
  message,
  className = '',
  onClose,
}) => {
  const NOTIFICATION_KEYS = {
    [NotificationTypes.PROFILE_CHANGE]: 'profile.action.success',
    [NotificationTypes.PASSWORD_CHANGE]: 'profile.action.success'
  }
  const { t } = useTranslation();
  const NotificationIcon = notificationType === 'success' ? SuccessIcon : ErrorIcon;
  const [animation, setAnimation] = useState<'animateOpen' | 'animateClose'>('animateOpen');

  const hide = async () => {
    setAnimation('animateClose');
    await new Promise(r => setTimeout(r, 1000));
    onClose();
  };

  useEffect(function closeNotificationAfterMs() {
    setTimeout(async () => {
      await hide();
    }, 3500);
  }, []);



  return (
    <div
      className={`notification  notification--${notificationType} notification--${placement} ${className} notification--${animation}`}>
      <img className="notification__icon" src={NotificationIcon} alt="" />
      <p className="notification__text">{t<string>(NOTIFICATION_KEYS[message])}</p>
      <CloseButton variant="secondary" className="notification__close" onClick={hide} />
    </div>
  );
};

export default Notification;
