import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { ReactComponent as EnvelopeIcon } from '../../../../assets/images/envelope.svg';
import SubscribeModal from './modal/SubscribeModal';
import styles from './Support.module.scss';
import { transformUsageTime } from '../../../../helpers';
import { LOGGING_SUBSCRIBE_ACTION } from '../../../../constants';
import Tooltip from '../tooltip/Tooltip';

const Envelope = ({ report }) => {
  const [show, setShow] = useState(false);
  const [hovering, setHovering] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
    report('open', transformUsageTime(window.currentPageUseageStartTime), LOGGING_SUBSCRIBE_ACTION.SUBSCRIBE_POPUP);
  };

  return (
    <>
      <div
        onClick={handleOpen}
        className={styles.link}
        onMouseOver={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}>
        {/* <img src={envelope} alt="envelope to subscribe" draggable="false" /> */}
        <EnvelopeIcon alt="envelope to subscribe" draggable="false" />
        {hovering && <Tooltip text={t('subscribe.subscribe')} top={41} />}
      </div>
      <ReactModal isOpen={show} ariaHideApp={false}>
        <SubscribeModal handleClose={handleClose} report={report} />
      </ReactModal>
    </>
  );
};

export default Envelope;
