import { PushNotificationType } from '../../../services/PushNotifications';

export enum PushNotificationsActionTypes {
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_SINGLE_PUSHNOTIFICATION = 'SET_SINGLE_PUSHNOTIFICATION',
  SET_DINGDONG = 'SET_DINGDONG',
  DELETE_PUSHNOTIFICATION = 'DELETE_PUSHNOTIFICATION',
  MARK_PUSHNOTIFICATION_AS_READ = 'MARK_PUSHNOTIFICATION_AS_READ',
  TOGGLE_PUSHNOTIFICATIONS_PANEL = 'TOGGLE_PUSHNOTIFICATIONS_PANEL',
}

interface SetNotifications {
  type: PushNotificationsActionTypes.SET_NOTIFICATIONS;
  pushNotifications: PushNotificationType[];
}

interface SetSinglePushNotification {
  type: PushNotificationsActionTypes.SET_SINGLE_PUSHNOTIFICATION;
  pushNotification: PushNotificationType;
}

interface DeletePushNotification {
  type: PushNotificationsActionTypes.DELETE_PUSHNOTIFICATION;
  uid: string;
}

interface MarkPushNotificationAsRead {
  type: PushNotificationsActionTypes.MARK_PUSHNOTIFICATION_AS_READ;
  uid: string;
}

interface TogglePushNotificationsPanel {
  type: PushNotificationsActionTypes.TOGGLE_PUSHNOTIFICATIONS_PANEL;
  open: boolean;
}

interface SetDingDong {
  type: PushNotificationsActionTypes.SET_DINGDONG;
  status: boolean;
}

export type IPushNotificationsActionTypes =
  | SetSinglePushNotification
  | SetNotifications
  | DeletePushNotification
  | MarkPushNotificationAsRead
  | TogglePushNotificationsPanel
  | SetDingDong;
