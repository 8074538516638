import fetchPGPL, { apiPGPLMethods, prepareLoginType, profileFields, inGameFields } from '../utils/apiPGPL';
import { serviceLogger } from '../../logger';
import { handleDate } from '../pages/Profile/Profile';
import apiPGPL from '../utils/apiPGPL';

export type ProfileType = {
  id: string;
  name: string;
  nick?: string;
  creationTime?: number;
  picture?: string;
  frame?: Object;
  email?: string;
  birthday?: string;
  gender?: string;
  locale?: string;
  country?: string;
  subdivision?: string;
  city?: string;
  cityName?: string;
  nameIsCustom?: boolean;
  passwordIsCustom?: boolean;
  rewardIsBanned?: boolean;
  isTester?: boolean;
  isCheater?: boolean;
  isVerified?: boolean;
  viralInstallRewardIsBanned?: boolean;
  isEmailInvitationDisabled?: boolean;
  unlockedPictureIds?: string[];
  unlockedFrameIds?: string[];
  gameIds?: number[];
  migrationSourceGameId: number;
  wishlist: string[];
  friendCount: number;
  paymentSum: number;
  paymentCategory: string;
  paymentCategoryFeedbackTag: string;
  playerStats: Object;
  ingameStats: Object;
  subscription: {
    id: string;
    expireTime: 0;
  };
  subscriptions: [
    {
      id: string;
      expireTime: number;
    },
  ];
  consumedProfileRewards: string[];
  underageConfirmStatus: 'confirmed';
  underageConfirmTime: number;
  hasEmail: boolean;
  hasUnconfirmedEmail: boolean;
};

type UpdateProfileRequestType = Partial<ProfileType> & { playerId?: string };

export interface IInGameInfoType {
  gameId: number;
  friends: number;
  level: number;
}

interface IUnconfirmedEmail {
  email: string;
  expiresAfter: number;
}

interface ISetPlayerBirthday {
  confirmUnderage: Boolean;
  birthday: string;
}

export default class PGPLService {
  static loginWebWithAccessToken(accessToken: string): Promise<string> {
    return fetchPGPL(apiPGPLMethods.loginWebWithAccessToken, { accessToken })
      .then(response => {
        if (response?.error) {
          throw new Error(response.error);
        }
        return response?.accessToken;
      })
      .catch(error => {
        serviceLogger.error('loginWebWithAccessToken()', accessToken, error);
      });
  }

  static loginWithAccessToken(accessToken: string): Promise<string> {
    return fetchPGPL(apiPGPLMethods.loginWithAccessToken, { accessToken })
      .then(response => {
        if (response?.error) {
          throw new Error(response.error);
        }
        return response?.accessToken;
      })
      .catch(error => {
        serviceLogger.error('loginWebWithAccessToken()', accessToken, error);
      });
  }

  static getLastEmailUpdateRequest(): Promise<IUnconfirmedEmail> {
    return fetchPGPL(apiPGPLMethods.getLastEmailUpdateRequest)
      .then(response => {
        if (response?.error) {
          throw new Error(response.error);
        }

        if (!response?.email) return;

        return response;
      })
      .catch(error => {
        serviceLogger.error('loginWebWithAccessToken()', error);
      });
  }

  static async getPlayerInfo(): Promise<ProfileType> {
    return fetchPGPL(apiPGPLMethods.getPlayerInfo, { fields: profileFields }).then(async profileResponse => {
      if (profileResponse.error) {
        throw new Error(profileResponse.error);
      }
      const consentInfo = await fetchPGPL(apiPGPLMethods.getConsentInfo).then(consentResponse => {
        if (consentResponse.error) {
          return {};
        }
        return { status: consentResponse.status, version: consentResponse.version };
      });

      let unconfirmedEmail;
      let email;
      if (!profileResponse?.email) {
        unconfirmedEmail = await fetchPGPL(apiPGPLMethods.getLastEmailUpdateRequest);
        email = unconfirmedEmail?.email;
      }

      return {
        ...profileResponse,
        ...(unconfirmedEmail?.email && { unconfirmedEmail }),
        ...(email && { email }),
        consentInfo,
      };
    });
  }

  static updatePlayer(data: UpdateProfileRequestType) {
    return fetchPGPL(apiPGPLMethods.updatePlayer, data).then(response => {
      if (response.error) {
        throw { ...response };
      }
      return response;
    });
  }

  static loginWeb(name: string, password: string): Promise<string> {
    const loginData = prepareLoginType(name);
    return fetchPGPL(apiPGPLMethods.loginWeb, { ...loginData, password }).then(response => {
      if (response.error) {
        throw { ...response };
      }
      return response.accessToken;
    });
  }

  static logout() {
    return fetchPGPL(apiPGPLMethods.logout);
  }

  static createWebPlayer(data): Promise<string> {
    return fetchPGPL(apiPGPLMethods.createWebPlayer, {
      ...data,
      consentSource: 'pgpl',
    }).then(response => {
      if (response.error) {
        throw { ...response };
      }
      return response.accessToken;
    });
  }

  static setPlayerPassword(newPassword: string, oldPassword?: string) {
    return fetchPGPL(apiPGPLMethods.setPlayerPassword, { newPassword, ...(oldPassword && { oldPassword }) }).then(
      response => {
        if (response.error) {
          throw { ...response };
        }
      },
    );
  }

  static confirmConsent(version: string) {
    return fetchPGPL(apiPGPLMethods.confirmConsent, { confirmationSource: 'pgpl', version });
  }

  static getConsentInfo(): Promise<{ status: string; version: string }> {
    return fetchPGPL(apiPGPLMethods.getConsentInfo).then(response => {
      if (response.error) {
        throw { ...response };
      }
      return response;
    });
  }

  static recoverPlayerByEmail(email): Promise<IUnconfirmedEmail> {
    return fetchPGPL(apiPGPLMethods.recoverPlayerByEmail, { email, checkUnconfirmedEmail: true }).then(response => {
      if (response.error) {
        throw { ...response };
      }
      return response;
    });
  }

  static getServerInfo(): Promise<number> {
    return fetchPGPL(apiPGPLMethods.getServerInfo)
      .then(response => {
        if (response.timestamp) {
          return response.timestamp;
        }
        throw new Error('No timestamp passed in response');
      })
      .catch(error => {
        serviceLogger.error('getServerInfo()', error);
      });
  }

  static getIngameInfo(gameId): Promise<void | IInGameInfoType> {
    return fetchPGPL(apiPGPLMethods.getIngameInfo, { gameId, fields: inGameFields, ingameStats: 'level' })
      .then(response => {
        return {
          gameId,
          level: response?.ingameStats?.level | 0,
          friends: response?.friendCount | 0,
        };
      })
      .catch(error => {
        serviceLogger.error(`getIngameInfo(${gameId})`, error);
      });
  }

  static setPlayerBirthday(date): Promise<ISetPlayerBirthday> {
    const { birthday, confirmUnderage } = handleDate(date);
    return apiPGPL(apiPGPLMethods.setPlayerBirthday, {
      birthday,
      ...(confirmUnderage && { confirmUnderage: true }),
    }).then(response => {
      if (response.error) {
        serviceLogger.error(`setPlayerBirthday(${date})`, response.error);
        throw new Error(response.error);
      }
      return { confirmUnderage, birthday };
    });
  }
}
