import { dataLogger } from './logger';
import { debugLogData } from './helpers';

function fetchLocalJsonData(path) {
  return fetch(path, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((result) => result.json());
}

export function fetchBannerConfig(bannerId) {
  return fetchLocalJsonData(`../../banners/${bannerId}/index.json`)
    .then((result) =>
      debugLogData(`fetchBannerConfig('${bannerId}')`, result, dataLogger),
    )
    .catch((error) =>
      dataLogger.error(`Could not fetch banner config [${bannerId}].`, error),
    );
}

export function fetchBannersRotationListConfig(platform) {
  return fetchLocalJsonData(`../../platforms/${platform}/banners-rotation-list.json`)
    .then((result) => debugLogData(`fetchBannersRotationListConfig('${platform}')`, result, dataLogger))
    .catch((error) => dataLogger.error(`Could not fetch banner rotation list config [${platform}].`, error));
}

export function fetchGameConfig(gameId) {
  return fetchLocalJsonData(`../../games/${gameId}/index.json`)
    .then((result) => debugLogData(`fetchGameConfig('${gameId}')`, result, dataLogger))
    .catch((error) => dataLogger.error(`Could not fetch game config [${gameId}].`, error));
}

export function fetchGamePlatformConfig(gameId, platform) {
  return fetchLocalJsonData(`../../games/${gameId}/platforms/${platform}/index.json`)
    .then((result) => debugLogData(`fetchGamePlatformConfig('${gameId}', '${platform}')`, result, dataLogger))
    .catch((error) =>
      dataLogger.error(`Could not fetch game platform config [${gameId}][${platform}].`, error),
    );
}

export function fetchGenresConfig() {
  return fetchLocalJsonData('../../genres.json')
    .then((result) => debugLogData(`fetchGenresConfig()`, result, dataLogger))
    .catch((error) => dataLogger.error('Could not fetch genres config.', error));
}

export function fetchUpdateVersions(platform) {
  return fetchLocalJsonData(`../../platforms/${platform}/launcher.json`)
    .then(result => debugLogData(`fetchUpdateVersions('${platform}')`, result, dataLogger))
    .catch((error) => dataLogger.error(`fetchUpdateVersions [${platform}].`, error));
}

export function fetchLogos(platform) {
  return fetchLocalJsonData(`../../platforms/${platform}/logos.json`)
    .then((result) => debugLogData(`fetchLogos()`, result, dataLogger))
    .catch((error) => dataLogger.error('Could not fetch logos', error));
}

export function fetchPromo(promoName) {
  return fetchLocalJsonData(`../../promos/${promoName}/index.json`)
    .then((result) => debugLogData(`fetchPromo(${promoName})`, result, dataLogger))
    .catch((error) => dataLogger.error(`Could not fetch promo ${promoName}`, error));
}

export function fetchBlockedCountries() {
  return fetchLocalJsonData('../../game-country-restrict.json')
    .then((result) => debugLogData(`fetchBlockedCountries()`, result, dataLogger))
    .catch((error) => dataLogger.error(`Could not fetch countries restriction list`, error));
}

export function fetchEventConfig(eventId) {
  return fetchLocalJsonData(`../../events/${eventId}/index.json`)
    .then((result) =>
      debugLogData(`fetchEventConfig('${eventId}')`, result, dataLogger),
    )
    .catch((error) =>
      dataLogger.error(`Could not fetch event config [${eventId}].`, error),
    );
}

export function fetchEXPConfig() {
  return fetchLocalJsonData('../../exp_config.json')
    .then((result) => debugLogData('fetchEXPConfig()', result, dataLogger))
    .catch((error) => dataLogger.error('Could not fetch exp_config.json', error));
}
