import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { APP_METHODS, INSTALLATION_TYPE } from '../../../../../constants';
import '../../../../../i18n';
import ProgressBar from '../../../progressbar/ProgressBar';
import {
  selectGameProcessingStatus,
  selectIsAnythingInProgress,
  selectIsAnythingIsUpdating
} from "../../../../../store/selectors";
import { startGameLoading } from '../../../../../store/dux/progress';
import styles from '../../card/GameCard.module.scss';
import { getSourceToReport } from "../../../../../helpers";

function GameActionUpdate({ game, location, updateVersion, passedSource, additionalSource, size }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const gameStatus = useSelector(selectGameProcessingStatus(game.storeLink));
  const isAnythingUpdating = useSelector(selectIsAnythingIsUpdating);
  const isAnythingInstalling = useSelector(selectIsAnythingInProgress);

  const source = getSourceToReport(location, passedSource);

  const startGame = (click) => isAnythingInstalling || isAnythingUpdating ? false : click || location?.state?.startGame;

  const update = event => {
    event.preventDefault();
    dispatch(startGameLoading(APP_METHODS.UPDATE, game, startGame(true), null, updateVersion, source, additionalSource));
  };

  useEffect(
    function startAutoDownload() {
      if (!location?.state || !location.state?.startAutoDownload || location?.state?.id !== game.id || gameStatus) {
        return;
      }

      dispatch(
        startGameLoading(APP_METHODS.UPDATE, game, startGame(), location?.state?.g5tid, updateVersion, source),
      );

      history.replace({
        ...history.location,
        state: { ...history.location.state, startGame: null, startAutoDownload: null, g5tid: null, forceUpdate: null },
        search: null,
      });
    },
    [],
  );

  return (
    <div className={styles['game__card--nav-item']}>
      {gameStatus ? (
        <ProgressBar progress={gameStatus.progress} methodType={INSTALLATION_TYPE.UPDATE} />
      ) : (
        <span className={`btn btn--default btn--green ${styles['game__card--btn']} ${size}`} onClick={e => update(e)} id='gameAction'>
          {t('shared.buttons.update_and_play')}
        </span>
      )}
    </div>
  );
}

export default GameActionUpdate;
