import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { Button } from '../../commons/Button';
import { Parag } from '../../commons/Parag';
import { CloseButton } from '../../commons/CloseButton';
import './styles/index.scss';
import { profileRoutes } from '../../../routes';

type PopupProps = {
  username: string;
  avatar: string;
  className?: string;
  isDefaultUser: boolean;
  onClose: () => void;
};

const Popup: FC<PopupProps> = ({ isDefaultUser = false, username, avatar, className = '', onClose }) => {
  const [animation, setAnimation] = useState<'animateOpen' | 'animateClose'>('animateOpen');
  const { t } = useTranslation();
  const history = useHistory();

  const hide = async () => {
    if (animation === 'animateClose') return;
    setAnimation('animateClose');
    await new Promise(resolve => setTimeout(resolve, 1000));
    onClose();
  };

  const goToProfile = (): void => {
    history.push(profileRoutes.profile);
    onClose();
  };

  useEffect(function closeNotificationAfterMs() {
    setTimeout(() => {
      hide();
    }, 3500);
  }, []);

  return (
    <div className={`popup ${className} notification--${animation}`}>
      <CloseButton variant="primary" className="popup__close" onClick={hide} />
      <div className="popup__wrapper">
        <img className="popup__avatar" src={avatar} alt="" />
        <Parag variant="secondary" className="popup__parag">
          <Trans
            i18nKey="profile.message.signed_in"
            values={{ username }}
            components={{ span: <span className="popup__username" /> }}
          />
        </Parag>
      </div>
      {isDefaultUser && (
        <Button type="button" variant="primary" sizeMode="1" className="popup__btn" onClick={goToProfile}>
          {t<string>('profile.buttons.complete_registration')}
        </Button>
      )}
    </div>
  );
};

export default Popup;
