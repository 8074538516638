import { Parag } from '../../../commons/Parag';
import { Headline } from '../../../commons/Headline';
import { useTranslation } from "react-i18next";
import LetterIcon from '../../../../assets/images/png/letter-g5.png';

function ModalRecoveryPassword() {
  const { t } = useTranslation();
  return (
    <>
      <Headline headlineLevel="h3" size="4" variant="primary" className="modal__headline center-inline">
        {t<string>('profile.titles.pass_recovery')}
      </Headline>
      <div className="center-inline">
        <img className="modal__img" width="100" src={LetterIcon} alt="" />
      </div>
      <Parag variant="primary" className="center-inline">
        {t<string>('profile.message.pass_recovery')}
      </Parag>
    </>
  );
}

export default ModalRecoveryPassword;
