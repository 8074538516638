import { FC, useState, ComponentType, useContext } from 'react';
import { ProfileContext } from '../../../pages/Profile/Profile';
import { TextButton } from '../../commons/TextButton';
import { Headline } from '../../commons/Headline';
import { useTranslation } from "react-i18next";

type ToggleEditPasswordProps = {
  sectionTitle: string;
  form: ComponentType<{ toggleEdit: () => void }>;
  dataInfo: ComponentType;
};

const ToggleEditPassword: FC<ToggleEditPasswordProps> = ({
  sectionTitle,
  form: FormComponent,
  dataInfo: DataInfoComponent,
}) => {
  const { t } = useTranslation();
  const { editPassword, toggleEditPassword } = useContext(ProfileContext);

  return (
    <section className="profile__data">
      <div className="profile__head">
        <Headline headlineLevel="h2" size="3" className="profile__headline">
          {sectionTitle}
        </Headline>
        {!editPassword ? (
          <TextButton
            onClick={() => {
              toggleEditPassword(!editPassword);
            }}
            type="button"
            variant="primary"
            className="profile__edit profile-edit-btn text-button">
            {t<string>('profile.buttons.edit')}
          </TextButton>
        ) : (
          ''
        )}
      </div>
      {editPassword ? <FormComponent toggleEdit={() => toggleEditPassword(!editPassword)} /> : <DataInfoComponent />}
    </section>
  );
};

export default ToggleEditPassword;
