import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export default class ColoredScrollbars extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
    this.renderTrack = this.renderTrack.bind(this);
    this.scrollbars = React.createRef();
  }

  handleUpdate(values) {

    const { top, scrollTop } = values;
    this.setState({ top });
    window.dispatchEvent(
      new CustomEvent('scrollTop', {
        detail: {
          scrollTop,
        },
      }),
    );
  }

  renderView({ style, ...props }) {
    return <div className="box" style={{ ...style }} {...props} />;
  }

  renderTrack({ style, ...props }) {
    return <div className="track-vertical" style={{ ...style, ...this.props.verticalthumbstyle }} {...props} />;
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#7A7A7A',
      zIndex: 1100,
      borderRadius: 3,
      width: '4px',
    };
    return <div className="thumb-vertical" style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderThumbHorizontal({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#7A7A7A',
      zIndex: 1100,
      borderRadius: 3,
      height: '4px',
    };
    return <div className="thumb-horizontal" style={{ ...style, ...thumbStyle }} {...props} />;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathname !== this.props.pathname) {
      this.scrollbars.current.scrollToTop();
    }
  }

  render() {
    return (
      <Scrollbars
        ref={this.scrollbars}
        renderView={this.renderView}
        renderTrackVertical={this.renderTrack}
        renderThumbVertical={this.renderThumb}
        renderThumbHorizontal={this.renderThumbHorizontal}
        onUpdate={this.handleUpdate}
        {...this.props}
      />
    );
  }
}

ColoredScrollbars.defaultProps = {
  top: 0,
  verticalthumbstyle: {
    right: 2,
    bottom: 2,
    top: 2,
    paddingTop: 83,
    paddingBottom: 0,
  },
};
