import Event, { EventType } from '../classes/Event';
import { checkIsNotExpiredByMs } from '../helpers';
import { serviceLogger } from '../logger';

export const getEvents = async (eventIds: string[]): Promise<EventType[]> => {
  if (!eventIds.length) {
    return;
  }

  try {
    return await Promise.all(eventIds.map(eventId => Event.fetch(eventId))).then(events =>
      events.filter(event => checkIsNotExpiredByMs(event?.endTime)),
    );
  } catch (error) {
    serviceLogger.error(`getEvents('${JSON.stringify(eventIds)}')`, error);
  }
};

export const checkIsEventActive = (start, end, isUTC) => {
  const dateNow = new Date();
  const nowUTC = Date.parse(dateNow + ' GMT');
  const nowLocal = Date.now();
  const now = isUTC ? nowUTC : nowLocal;
  if (now > start && now < end) {
    return true;
  }
};
