import { EventType } from '../../../classes/Event';
import styles from '../Events.module.scss';
import { EventCard } from '../EventCard/';

type EventListTypes = {
  title?: string;
  events?: EventType[];
};

const EventList = ({ events, title }: EventListTypes) => {
  if (!events) return null;

  return (
    <section className={styles.EventList}>
      {title && <h3>{title}</h3>}
      {events
        .sort((aEvent, bEvent) => aEvent.endTime - bEvent.endTime)
        .map((event: EventType, i: number) => (
          <EventCard event={event} key={event.id} />
        ))}
    </section>
  );
};

export default EventList;
