import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import '../../../../../i18n';
import { playGame } from '../../../../../services/Games';
import styles from '../../card/GameCard.module.scss';
import ProgressBar from '../../../progressbar/ProgressBar';
import { selectGameProcessingStatus } from '../../../../../store/selectors';
import { INSTALLATION_TYPE } from "../../../../../constants";
import { removeNotification } from '../../../../../store/dux/notifications';
import { getSourceToReport } from "../../../../../helpers";

function GameActionPlay({ game, location, passedSource, additionalSource, size }) {
  const { t } = useTranslation();
  const gameStatus = useSelector(selectGameProcessingStatus(game.storeLink));

  const history = useHistory();
  const dispatch = useDispatch();

  const source = getSourceToReport(location, passedSource);

  const startTheGame = e => {
    e.preventDefault();
    playGame(game, source, additionalSource);
    dispatch(removeNotification({ uri: game.storeLink }));
  };

  useEffect(function launchOnStart() {
    if (!location?.state || !location?.state?.startGame || location?.state?.id !== game.id) {
      return;
    }

    playGame(game, source);

    history.replace({
      ...history.location,
      state: { ...history.location.state, startGame: null, startAutoDownload: null, g5tid: null, forceUpdate: null },
      search: null,
    });
  }, []);

  if (gameStatus) {
    return <ProgressBar progress={gameStatus.progress} methodType={INSTALLATION_TYPE.UPDATE} />;
  }

  return (
    <button className={`btn btn--default btn--green ${styles['game__card--btn']} ${size}`} onClick={startTheGame} id='gameAction'>
      {t('shared.buttons.play')}
    </button>
  );
}

export default GameActionPlay;
