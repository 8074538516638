import { useEffect, useMemo, createContext, useContext, useRef } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { LAUNCHER_UPDATE_STATUS } from '../../../constants';
// import { deferUpdate, startUpdate } from '../../../store/dux/main';
import { ReactComponent as HappyCat } from '../../../assets/images/happyCat.svg';
import { ReactComponent as Close } from '../../../assets/images/cross.svg';
import styles from './popup.module.scss';

const PopupContext = createContext();

function Popup({ type, children, setStatus, updateData }) {

  const popupDiv = useMemo(() => {
    const element = document.createElement('div');
    element.className = `${styles.popupContainer} ${styles[`popupContainer_${type}`]}`;
    return element;
  }, [type]);

  const value = useMemo(() => {
    return {
      setStatus,
      updateData,
    };
  }, [updateData, type]);

  useEffect(
    function handlePopupMount() {
      document.body.appendChild(popupDiv);
      return () => document.body.removeChild(popupDiv);
    }, [type, children]
  );

  return createPortal(<PopupContext.Provider value={value}>{children}</PopupContext.Provider>, popupDiv);
}

{
  /*
  Popup[LAUNCHER_UPDATE_STATUS.UPDATE] = function() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const popupBodyRef = useRef();

    const leftPosition = useSelector(state => state.main.leftPosition);

    useEffect(function locatePopupWithLang() {
      const { width } = document.getElementById("update_field").getBoundingClientRect();
      const parentDiv = popupBodyRef.current.parentNode;
      parentDiv.style.left = `${Math.floor(leftPosition) - 160 + (Math.floor(width) / 4)}px`;
    }, [leftPosition]);

    return (
      <>
        <h3 ref={popupBodyRef}>{t("update.available")}</h3>
        <p>{t("update.new_version_desc")}</p>
        <button className="btn btn--default btn--green" onClick={() => dispatch(startUpdate())}>
          {t("update.install_now")}
        </button>
        <a href="#" className={styles.later} onClick={() => dispatch(deferUpdate())}>
          {t("notifications.later")}
        </a>
        <div className={styles.arrow} />
      </>
    );
  };
*/
}

Popup[LAUNCHER_UPDATE_STATUS.CONGRATULATIONS] = function() {
  const { setStatus } = useContext(PopupContext);
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.close} onClick={() => setStatus(null)}>
        <Close />
      </div>
      <h3>{t('update.congratulations_head')}</h3>
      <p>{t('update.congratulations')}</p>
      <HappyCat />
    </>
  );
};

export default Popup;
