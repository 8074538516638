import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_METHODS, INSTALLATION_TYPE } from '../../../../../constants';
import { startGameLoading } from '../../../../../store/dux/progress';
import '../../../../../i18n';
import ProgressBar from '../../../progressbar/ProgressBar';
import { selectGameProcessingStatus, selectIsAnythingInProgress } from "../../../../../store/selectors";
import { getSourceToReport } from '../../../../../helpers';
import styles from '../../card/GameCard.module.scss';

function GameActionInstall({ game, location, passedSource, additionalSource, size }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const gameStatus = useSelector(selectGameProcessingStatus(game.storeLink));
  const isAnythingInstalling = useSelector(selectIsAnythingInProgress);

  const history = useHistory();

  const source = getSourceToReport(location, passedSource);

  const install = () => {
    dispatch(startGameLoading(APP_METHODS.INSTALL, game, null, null, null, source, additionalSource));
  };

  const startGame = isAnythingInstalling ? false : location?.state?.startGame;


  useEffect(function startAutoDownload() {
    if (!location?.state || !location?.state?.startAutoDownload || location?.state?.id !== game.id || gameStatus) {
      return;
    }
    dispatch(
      startGameLoading(APP_METHODS.INSTALL, game, startGame, location?.state?.g5tid, null, source),
    );

    history.replace({
      ...history.location,
      state: { ...history.location.state, startGame: null, startAutoDownload: null, g5tid: null, forceUpdate: null },
      search: null,
    });
  }, []);

  return (
    <div className={styles['game__card--nav-item']}>
      {gameStatus ? (
        <ProgressBar progress={gameStatus.progress} methodType={INSTALLATION_TYPE.INSTALL} />
      ) : (
        <span className={`btn btn--default btn--green ${styles['game__card--btn']} ${size}`} onClick={install} id="gameAction">
          {t('shared.buttons.getandplay')}
        </span>
      )}
    </div>
  );
}

export default GameActionInstall;
