import { useEffect, useState } from 'react';

import './styles/index.scss';
import { useTranslation } from 'react-i18next';

type SelectProps = {
  className?: string;
  name: string;
  setValue: (n: string, v: string) => void;
  defaultValue?: string;
};

function Select({ className, name, setValue, defaultValue }: SelectProps) {
  const { t } = useTranslation();
  const options = [
    { value: 'Male', translation: t('profile.words.male') },
    { value: 'Female', translation: t('profile.words.female') },
    { value: 'Other', translation: t('profile.words.other') },
  ];
  const [isDropdownShow, setDropdownShow] = useState(false);
  const [selectedOption, changeSelectedOption] = useState(() => {
    if (defaultValue) {
      return options.find(o => o.value.toLowerCase() === defaultValue)?.translation;
    }
    return t('profile.placeholders.not_specified');
  });

  const toggleDropdownShow = () => {
    setDropdownShow(prevValue => !prevValue);
  };

  const changeOption = event => {
    const optionValue = event.currentTarget.getAttribute('data-value');
    if (optionValue === selectedOption) {
      setDropdownShow(false);
      return;
    }
    setValue(name, optionValue.toLowerCase());
    changeSelectedOption(options.find(o => optionValue === o.value).translation);
    setDropdownShow(false);
  };

  useEffect(
    function closeOnClickFromOutside() {
      function onClickOutsideDropdown(event) {
        if (!event.target.closest('.select')) {
          setDropdownShow(false);
        }
      }

      window.addEventListener('click', onClickOutsideDropdown);
      return () => window.removeEventListener('click', onClickOutsideDropdown);
    },
    [setDropdownShow],
  );

  return (
    <div className={`select ${className} ${isDropdownShow ? 'open' : ''}`}>
      <button type="button" className="select__btn" onClick={toggleDropdownShow}>
        {selectedOption}
        <i className="select__icon-arrow-down icon-arrow-down" onClick={event => event.preventDefault()} />
      </button>
      {isDropdownShow && (
        <div className="select__dropdown">
          <ul className="select__list">
            {options &&
              options.map((option, index) => (
                <li className="select__item" key={index} data-value={option.value} onClick={changeOption}>
                  {option.translation}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Select;
