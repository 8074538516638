import { FC } from 'react';

import GiftIcon from '../../../../assets/images/promo-gift.svg';
import './styles/index.scss';

type PromoGiftProps = {
  className?: string;
  variant: 'sm' | 'lg';
  giftIcon?: string;
};

const PromoGift: FC<PromoGiftProps> = ({ className = '', variant = 'sm', giftIcon }) => {
  return (
    <>
      <img
        className={`promo-gift promo-gift--${variant} ${className}`}
        src={giftIcon ? giftIcon : GiftIcon}
        alt=""
        draggable="false"
      />
    </>
  );
};

export default PromoGift;
