import { useTranslation } from "react-i18next";
import { ToggleEditProfile } from '../ToggleEditProfile';
import ProfileInfoForm from './ProfileInfoForm';
import ProfileInfoData from './ProfileInfoData';

type ProfileInfoSectionProps = {
  defaultUser: boolean;
};

function ProfileInfoSection({ defaultUser }: ProfileInfoSectionProps) {
  const { t } = useTranslation();
  return (
    <ToggleEditProfile
      sectionTitle={t('profile.titles.personal_info')}
      form={ProfileInfoForm}
      dataInfo={ProfileInfoData}
      defaultUser={defaultUser}
    />
  );
}

export default ProfileInfoSection;
