import { useState, ChangeEvent } from 'react';
import { useTranslation } from "react-i18next";
import { Label } from '../../commons/Form/Label';
import AvatarDefaultIcon from '../../../assets/images/png/avatar-default.png';
import { ModalAvatarCropper } from '../../../components/containers-content/ModalsContent/ModalAvatarCropper';
import { ModalUploadImageError } from '../../containers-content/ModalsContent/ModalUploadImageError';
import ModalContainer from '../../containers/ModalContainer/ModalContainer';

const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

type AvatarCropperProps = {
  picture?: string;
};

const AvatarCropper = ({ picture }: AvatarCropperProps) => {
  const { t } = useTranslation();
  const [imageToCrop, setImageToCrop] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>();
  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);

  const handleImageCheck = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files[0]) return;

    if (!allowedTypes.includes(e.target.files[0].type)) {
      setFileError(e.target.files[0].name);
      e.target.value = '';
      return;
    }
    const image = URL.createObjectURL(e.target.files[0]);
    e.target.value = '';
    setImageToCrop(image);
  };

  const closeCropModal = () => setImageToCrop(null);

  const imageToDisplay = (croppedImage && URL.createObjectURL(croppedImage)) || picture || AvatarDefaultIcon;

  return (
    <form className="profile__form" id="g5Friends_profile_form-avatar" action="" method="post">
      <div className="profile__avatar align-center">
        <img className="profile__avatar-img avatar-img" src={imageToDisplay} height="124" width="124" alt="" />
        <Label className="profile__avatar-label sr-only" htmlFor="avatar-form">
          {t<string>('profile.titles.upload_photo')}
        </Label>
        <input
          className="profile__avatar-input"
          type="file"
          id="avatar-form"
          accept=".png, .jpg, .jpeg"
          onChange={handleImageCheck}
        />
      </div>
      {imageToCrop && (
        <ModalContainer size="lg" onClose={closeCropModal}>
          <ModalAvatarCropper
            imageToCrop={imageToCrop}
            setCroppedImage={setCroppedImage}
            closeCropModal={closeCropModal}
          />
        </ModalContainer>
      )}
      {fileError && (
        <ModalContainer size="sm" onClose={() => setFileError(null)}>
          <ModalUploadImageError errorImage={fileError} />
        </ModalContainer>
      )}
    </form>
  );
};

export default AvatarCropper;
