import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../Footer.module.scss';
import { openLink } from '../../../../helpers';
import useSiteLinks from "../../../../hooks/useSiteLinks";

function FooterLegalList() {
  const { t } = useTranslation();
  const links = useSiteLinks();

  const legal = [
    {
      title: t('footer.legal.list.terms_of_service'),
      link: links.TOSLink,
    },
    {
      title: t('footer.legal.list.privacy_policy'),
      link: links.privacyPolicyLink,
    },
    {
      title: t('footer.legal.list.store_terms_of_service'),
      link: links.storeTOSLink,
    },
    {
      title: t('footer.legal.list.support'),
      link: links.supportLink,
    },
  ];

  return (
    <ul className={styles.list}>
      {legal.map((elem, index) => (
        <li className={styles['list-item']} key={index}>
          <button className={`btn btn--link ${styles['list-link']}`} onClick={() => openLink(elem.link)}>
            {elem.title}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default FooterLegalList;
