import { useEffect } from 'react';
import { openLink } from '../helpers';

export default function useClickOnLink(elRef) {
  useEffect(() => {
    if (!elRef.current) return;

    function handleElementClick(e) {
      if (elRef.current && elRef.current.contains(e.target)) {
        if (e.target.tagName === 'A') {
          e.preventDefault();
          const link = e.target?.href;
          link && openLink(link);
        }
      }
    }

    window.addEventListener('click', handleElementClick);

    return () => window.removeEventListener('click', handleElementClick);
  }, [elRef]);
}
