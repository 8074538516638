const ACTIONS = {
  INIT_LOADING: 'INIT_LOADING',
  CHANGE_SETTINGS: 'CHANGE_SETTINGS',
  UPDATE_ACTIVE_GENRE: 'UPDATE_ACTIVE_GENRE',
  UPDATE_ON_RESUME: 'UPDATE_ON_RESUME',
  ADD_GAME: 'ADD_GAME',
  UPDATE_INSTALLATION_PROGRESS: 'UPDATE_INSTALLATION_PROGRESS',
  UPDATE_INSTALLATION_PROGRESS_WITH_ERROR: 'UPDATE_INSTALLATION_PROGRESS_WITH_ERROR',
  UPDATE_LAUNCHER_UPDATING_PROGRESS: 'UPDATE_LAUNCHER_UPDATING_PROGRESS',
  SET_APP_UPDATE_STATUS_ONLOAD: 'SET_APP_UPDATE_STATUS_ONLOAD',
  INSTALLATION_COMPLETED: 'INSTALLATION_COMPLETED',
  UPDATE_INSTALLED_APPS: 'UPDATE_INSTALLED_APPS',
  PUSH_MODAL: 'modal/PUSH_MODAL',
  SET_SCREENSHOTS_TO_GAME: 'main/SET_SCREENSHOTS_TO_GAME',
  START_SCREENSHOTS_LOADING: 'main/START_SCREENSHOTS_LOADING',
  STOP_SCREENSHOTS_LOADING: 'main/STOP_SCREENSHOTS_LOADING',
  SET_ACTIVE_BANNER_INDEX: 'SET_ACTIVE_BANNER_INDEX',
  SET_EXE_GAME_INSTALLATION_DATA: 'main/SET_EXE_GAME_INSTALLATION_DATA',
  CLEAR_GAME_DATA_TO_INSTALL_FROM_EXE: 'main/CLEAR_GAME_DATA_TO_INSTALL_FROM_EXE',
  GET_INIT_NOTIFICATIONS: 'notifications/GET_INIT_NOTIFICATIONS',
  ADD_NOTIFICATION: 'notifications/ADD_NOTIFICATION',
  REMOVE_NOTIFICATION: 'notifications/REMOVE_NOTIFICATION',
  CHANGE_NOTIFICATION_STATUS: 'notifications/CHANGE_NOTIFICATION_STATUS',
  SET_RECENTLY_INSTALLED_GAME: 'progress/ACTIONS.SET_RECENTLY_INSTALLED_GAME',
  SET_DEFER_UPDATE_STATUS: 'main/SET_DEFER_UPDATE_STATUS',
  CLEAR_DEFERRED_UPDATE: 'main/CLEAR_DEFERRED_UPDATE',
  SET_UPDATE_STARTED: 'main/SET_UPDATE_STARTED',
  COMPLETE_UPDATE: 'main/COMPLETE_UPDATE',
  COMPLETE_UPDATE_WITH_ERROR: 'main/COMPLETE_UPDATE_WITH_ERROR',
  UPDATE_ERROR: 'UPDATE_ERROR',
  SET_LEFT_POSITION: 'SET_LEFT_POSITION',
  SET_ACTIVE_STATE: 'SET_ACTIVE_STATE',
  SET_XPROMO_BANNERS: 'SET_XPROMO_BANNERS',
  SET_ACTIVE_ABTEST_GROUP: 'SET_ACTIVE_ABTEST_GROUP',
  SET_XPROMO_BANNERS_TO_FALLBACK: 'SET_XPROMO_BANNERS_TO_FALLBACK',
  SET_OPEN_URI_COMMAND: 'SET_OPEN_URI_COMMAND',
  SET_USER_REGION: 'SET_USER_REGION',
  SET_BLOCKED_GAMES: 'SET_BLOCKED_GAMES',
  SET_LAUNCHED_GAMES: 'main/SET_LAUNCHED_GAMES',
  SET_ACTIVE_XPROMO_BANNER_INDEX: 'main/SET_ACTIVE_XPROMO_BANNER_INDEX',
  ERROR: 'ERROR',

  GET_INIT_TAKEOVER_BANNERS: "GET_INIT_TAKEOVER_BANNERS",
  REMOVE_TAKEOVER_BANNER: "REMOVE_TAKEOVER_BANNER",
};

export default ACTIONS;
