import { createSelector } from 'reselect';
import { LOCAL_STORAGE_KEYS } from '../../../constants';

export const selectPushNotifications = state => {
  if (!state.pushNotifications?.pushNotifications?.length) return;
  return state.pushNotifications.pushNotifications.sort((a, b) => b.time - a.time).sort(a => (a.read ? 1 : -1));
};

export const selectPushNotificationsPanelState = state => state.pushNotifications.open;

export const selectNewPushNotificationsCount = createSelector([selectPushNotifications], pushNotifications => {
  if (!pushNotifications) return 0;
  const listOfReadPushNotificationsUIDs = window.localStorage.getItem(LOCAL_STORAGE_KEYS.READ_PUSH_NOTIFICATIONS) || [];
  const numberOfNewPushNotifications = pushNotifications.reduce((acc, n) => {
    if (!listOfReadPushNotificationsUIDs.includes(n.uid)) ++acc;
    return acc;
  }, 0);
  return numberOfNewPushNotifications > 99 ? '99+' : numberOfNewPushNotifications;
});

export const selectDingDong = state => state.pushNotifications.ding;
