import { ProfileType } from './auth';
import { IInGameInfoType } from "../../../Profile/services/PGPLService";

export enum AuthActionTypes {
  SET_KEYCHAIN = 'AUTH_SET_KEYCHAIN',
  SET_WEBTOKEN = 'AUTH_SET_WEBTOKEN',
  SET_PROFILE_DATA = 'SET_PROFILE_DATA',
  UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA',
  SET_PROFILE_EMAIL = 'SET_PROFILE_EMAIL',
  SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE',
  CLEAN = 'AUTH_CLEAN_KEYCHAIN',
  CLEAR_WEBTOKEN = 'AUTH_CLEAR_WEBTOKEN',
  SET_RESENT_CONFIRMATION = 'AUTH_SET_RESENT_CONFIRMATION',
  SET_INVALID_ACCESS_TOKEN_PLAYER_ID = 'SET_INVALID_ACCESS_TOKEN_PLAYER_ID',
  SET_SESSION_TOKEN = 'SET_SESSION_TOKEN',
  SET_OFFLINE_STATUS = 'SET_OFFLINE_STATUS',
  SET_OFFLINE_INTERVAL = 'SET_OFFLINE_INTERVAL',
  SET_PGPL_GAMES_DATA = 'SET_PGPL_GAMES_DATA',
}

interface SetKeyChain {
  type: AuthActionTypes.SET_KEYCHAIN;
  id: string;
  token: string;
}

interface SetWebToken {
  type: AuthActionTypes.SET_WEBTOKEN;
  webToken: string;
}

interface SetProfileData {
  type: AuthActionTypes.SET_PROFILE_DATA;
  profile: ProfileType;
}

interface SetProfileEmail {
  type: AuthActionTypes.SET_PROFILE_EMAIL;
  email: string;
}

interface SetProfilePicture {
  type: AuthActionTypes.SET_PROFILE_PICTURE;
  picture;
}

interface CleanKeyChain {
  type: AuthActionTypes.CLEAN;
}

interface UpdateProfileData {
  type: AuthActionTypes.UPDATE_PROFILE_DATA;
  data: Partial<ProfileType>;
}

interface ClearWebtoken {
  type: AuthActionTypes.CLEAR_WEBTOKEN;
}

interface SetSentConfirmation {
  type: AuthActionTypes.SET_RESENT_CONFIRMATION;
}

interface SetInvalidAccessTokenPlayerId {
  type: AuthActionTypes.SET_INVALID_ACCESS_TOKEN_PLAYER_ID,
  id: string | undefined,
  status?: boolean
}

interface SetSessionToken {
  type: AuthActionTypes.SET_SESSION_TOKEN,
  sessionToken: string
}

interface SetOfflineStatus {
  type: AuthActionTypes.SET_OFFLINE_STATUS,
  status: boolean
}

interface setOfflineInterval {
  type: AuthActionTypes.SET_OFFLINE_INTERVAL,
  interval: number
}

interface SetPGPLGamesData {
  type: AuthActionTypes.SET_PGPL_GAMES_DATA,
  data:IInGameInfoType | null
}

export type IAuthActionTypes =
  | SetKeyChain
  | CleanKeyChain
  | SetWebToken
  | SetProfileData
  | SetProfileEmail
  | SetProfilePicture
  | UpdateProfileData
  | ClearWebtoken
  | SetSentConfirmation
  | SetInvalidAccessTokenPlayerId
  | SetSessionToken
  | SetOfflineStatus
  | setOfflineInterval
  | SetPGPLGamesData;
