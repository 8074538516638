import { useSelector } from 'react-redux';
import TakeoverBanner from './TakeoverBanner';
import { selectTakeoverBanners } from '../../../store/selectors';

const TakeoverBannerWrapper = () => {
  const takeoverBanners = useSelector(selectTakeoverBanners);

  return <>{takeoverBanners.length ? <TakeoverBanner takeoverBanners={takeoverBanners} /> : null}</>;
};

export default TakeoverBannerWrapper;
