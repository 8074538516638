import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import BackgroundFallback from '../banners/single/BackgroundFallback';
import '../banners/index.scss';
import NavButton from './NavButton/NavButton';
import SingleXPromoBanner from './SingleXPromoBanner/SingleXPromoBanner';
import { selectActiveXPromoBanner, selectXPromoBanners } from "../../../store/selectors";
import useXPromoBannersLogging from "../../../hooks/useXPromoBannersLogging";
import { LOGGING_PAGE_IDS } from "../../../constants";
import { setActiveXPromoBannerIndex } from "../../../store/dux/main";

SwiperCore.use([Navigation, Autoplay]);

const BannerXPromo = () => {
  const [hovering, setHovering] = useState<boolean>(false);
  const [swiper, setSwiper] = useState<SwiperCore>(null!);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [muted, setMuted] = useState<boolean>(true);
  const { xPromoBanners, type } = useSelector(selectXPromoBanners);
  const showNavButtons = xPromoBanners?.length;
  const initialXPromoSlide = useSelector(selectActiveXPromoBanner);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useXPromoBannersLogging(xPromoBanners, activeIndex, LOGGING_PAGE_IDS.ALL_GAMES, type);

  function geSlideDataIndex(swipe) {
    let activeIndex = swipe.activeIndex;
    const slidesLen = swipe.slides.length;
    if (swipe.params.loop) {
      switch (swipe.activeIndex) {
        case 0:
          activeIndex = slidesLen - 3;
          break;
        case slidesLen - 1:
          activeIndex = 0;
          break;
        default:
          --activeIndex;
      }
    }
    return activeIndex;
  }

  useEffect(function handleActiveXPromoBannerChange() {
    dispatch(setActiveXPromoBannerIndex(activeIndex));
  }, [activeIndex]);

  return (
    <section className="banner--xpromo">
      <BackgroundFallback fallback="blurred-banner-empty.jpg" />
      <div className="banner--xpromo-container">
        <div
          className="banner--xpromo-container-wrapper"
          onMouseOver={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}>
          <Swiper
            observer
            observeParents
            parallax
            initialSlide={initialXPromoSlide}
            loop
            effect="fade"
            fadeEffect={{
              crossFade: true,
            }}
            direction="horizontal"
            onSwiper={(swiper: SwiperCore) => {
              setSwiper(swiper);
            }}
            onSlideChange={swiper => {
              const activeIndex = geSlideDataIndex(swiper);
              setActiveIndex(activeIndex);
            }}>
            {xPromoBanners.map((banner, key) => {
              const ChosenBanner = SingleXPromoBanner[banner.type];

              return (
                <SwiperSlide key={key}>
                  <SingleXPromoBanner
                    banner={banner}
                    swiper={swiper}
                    activeIndex={activeIndex}
                    slideIndex={key}
                    hovering={hovering}
                    muted={muted}
                    setMuted={setMuted}
                    type={type}>
                    <ChosenBanner />
                  </SingleXPromoBanner>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {showNavButtons && hovering && (
            <>
              <NavButton direction="prev" text={t('navigation.back')} fn={() => swiper.slidePrev()} />
              <NavButton direction="next" text={t('navigation.forward')} fn={() => swiper.slideNext()} />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default BannerXPromo;
