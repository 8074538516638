import store from '../../store';
import { setInvalidAccessTokenPlayerID, setOfflineStatus } from "../../store/dux/auth/auth";
import { LOCAL_STORAGE_KEYS } from "../../constants";

export interface RouteMap {
  [route: string]: string
}

export const apiPGPLMethods: RouteMap = {
  createWebPlayer: 'createWebPlayer?refSite=g5LauncherCreatePlayer',
  getPlayerInfo: 'getPlayerInfo',
  loginWebWithAccessToken: 'loginWebWithAccessToken',
  loginWithAccessToken: 'loginWithAccessToken',
  updatePlayer: 'updatePlayer',
  setPlayerPassword: 'setPlayerPassword',
  getLastEmailUpdateRequest: 'getLastEmailUpdateRequest',
  setPlayerBirthday: 'setPlayerBirthday',
  recoverPlayerByEmail: 'recoverPlayerByEmail',
  loginWeb: 'loginWeb',
  logout: 'logout',
  getConsentInfo: 'getConsentInfo',
  confirmConsent: 'confirmConsent',
  getServerInfo: 'getServerInfo',
  getIngameInfo: 'getIngameInfo',
};

const emailRegexExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const profileFields = 'id,name,nick,email,picture,gender,birthday,gameIds,passwordIsCustom,underageConfirmStatus';
export const inGameFields = 'friendCount,ingameStats';

export const prepareLoginType = (loginValue: string): { [login: string]: string } => {
  const key = emailRegexExp.test(loginValue) ? 'email' : 'name';
  return {
    [key]: loginValue,
  };
};

const prepareFormData = (data: Object = {}, udid: string, accessToken?: string): FormData => {
  const formData = new FormData();
  formData.append('udid', udid);

  if (accessToken) {
    formData.append('accessToken', accessToken);
  }

  for (const parameter in data) {
    if (!data[parameter]) continue;
    formData.append(parameter, data[parameter]);
  }
  return formData;
};

const fetchPGPL = (
  path: string,
  params?: Object,
  sessionToken?: string,
  method: string = 'POST',
): Promise<any> | never => {
  const {
    main: {
      udid,
      pgplApiPath,
    },
    auth: { webToken },
  } = store.getState();

  const url = `${pgplApiPath}${path}`;
  const token = sessionToken || webToken;

  const formData = prepareFormData(params, udid, token);

  return fetch(url, {
    method,
    headers: {
      ['PGPL-UDID']: udid,
    },
    body: formData,
  })
    .then(response => response.json())
    .then(response => {
      if(response?.error === 'InvalidAccessToken') {
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.AB_TEST_PGPL_ID);
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.LAST_LOGIN_ID);
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.LAST_LOGIN_NAME);
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.LATEST_PROFILE_GAMES);
        if(path !== apiPGPLMethods.logout) {
          const profile = store.getState()?.auth?.profile;
          store.dispatch(setInvalidAccessTokenPlayerID(profile?.name, true));
          return;
        }
      }
      return response;
    })
    .catch(error => {
      store.dispatch(setOfflineStatus(true));
      throw Error(error);
    });
};

export default fetchPGPL;
