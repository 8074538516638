// import { useSelector } from '
// react-redux';
// import { selectAvailableUpdate } from '../../../../store/selectors';
import { useHistory, useLocation } from "react-router-dom";
import styles from '../Header.module.scss';
// import UnderLineUpdate from './UnderLineUpdate';

function NavigationLink(route) {
  // const update = useSelector(selectAvailableUpdate);
  const history = useHistory();
  const location = useLocation();

  const isActive = location.pathname === route.href;

  const handleRouteClick = () => {
    !isActive && history.push(route.href);
  };

  return (
    <nav
      onClick={handleRouteClick}
      className={`${styles['header__nav--link']} ${isActive ? styles.activeLink : ''}`}
      draggable="false">
      <span>{route.label}</span>
      {/* update && route.href === '/settings' && <UnderLineUpdate /> */}
    </nav>
  );
}

export default NavigationLink;
