import ReactDOM from 'react-dom';
import { MemoryRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { getAndSetXPromoBanners, loadInitData } from "./store/dux/main";
import { checkAppUpdateStatus, continueUpdate } from "./store/dux/update";
import { setNotifications } from './store/dux/notifications';
import { setTakeoverBanners } from './store/dux/takeoverBanners';
import { initTransport } from './store/eventTransport';
import { continueProgress } from './store/dux/progress';
import './i18n';
import clientLib from './clientLib';
import { getAndSetKeychainData, getKeychainUser } from "./store/dux/auth/auth";
import { reportUserAgent } from "./helpers";
import { setPushNotifications } from "./store/dux/pushNotifications/pushNotifications";
import { setEvents } from "./store/dux/events/events";

initTransport();

clientLib.one('load', async () => {

  reportUserAgent(window.navigator.userAgent);
  await store.dispatch(loadInitData());
  await store.dispatch(checkAppUpdateStatus());
  await store.dispatch(continueProgress());
  store.dispatch(setNotifications());
  store.dispatch(setTakeoverBanners());
  store.dispatch(continueUpdate());
  await store.dispatch(getAndSetKeychainData());
  await store.dispatch(setPushNotifications());
  await store.dispatch(setEvents());

  window.dispatchEvent(new CustomEvent('resourcesLoaded'));

  await store.dispatch(getAndSetXPromoBanners());
  store.dispatch(getKeychainUser());
  ReactDOM.render(
    <Provider store={store}>
        <Router>
          <App />
        </Router>
    </Provider>,
    document.getElementById('root'),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
if (module.hot) module.hot.accept();
