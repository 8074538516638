import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GameAction from '../action/GameAction';
import { selectCurrentLang, selectGamePromoData, selectLogoByBannerId } from '../../../../store/selectors';
import styles from './GameCard.module.scss';

import PromoGift from '../../promo/promo-gift/PromoGift';

function GameCard({ game, installedVersion, passedSource }) {
  const { t } = useTranslation();
  const language = useSelector(selectCurrentLang);

  const {
    lockit: {
      [language]: { name },
    },
    id,
    cardImage,
    cardColor,
    iconImage,
    bannerId,
  } = game;

  const logo = useSelector(state => selectLogoByBannerId(state, bannerId));
  const gamePromoData = useSelector(state => selectGamePromoData(state, game.id));

  return (
    <div className={`${styles['game__card']} ${installedVersion && styles['game__card--installed']}`}>
      <Link
        className={styles['game__card--container']}
        to={{ pathname: `/${id}`, state: { id } }}
        draggable="false">
        {gamePromoData && <PromoGift variant="lg" giftIcon={gamePromoData.boosterImage} />}
        <div className={styles['game__card--wrapper']}>
          {installedVersion && <i className={`${styles['icon']} icon-check`} />}
          <img className={styles['game__card--bg']} src={`../../${cardImage}`} alt={name} draggable="false" />
          {logo && (
            <div
              className={styles['game__card--logoContainer']}
              style={{ background: `linear-gradient(0deg, ${cardColor} .1%, rgba(9,9,121,0) 40%)` }}>
              <img alt={name} src={`../../${logo}`} />
            </div>
          )}
          <div
            className={styles['game__card--info']}
            style={{
              background: `linear-gradient(0deg, ${cardColor} 20%, rgba(9,9,121,0) 80%)`,
            }}>
            <div className={styles['game__card--title']}>
              <img className={styles['game__card--icon']} src={`../../${iconImage}`} alt="" />
              <span className={styles['game__card--name']} dangerouslySetInnerHTML={{ __html: name }} />
            </div>
            <div className={styles['game__card--nav']}>
              <GameAction {...{ game, installedVersion } } passedSource={passedSource} />
              <span className={`btn--default btn--orange ${styles['game__card--btn']}`}>
                {t('shared.buttons.more')}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default GameCard;
