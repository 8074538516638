import { FC, PropsWithChildren } from 'react';

import './styles/index.scss';

type TextButtonTypeProps = 'button' | 'submit' | 'reset';

export type TextButtonProps = Partial<{
  type: TextButtonTypeProps;
  variant: 'primary' | 'secondary'; // launcher styles
  className: string;
  onClick: () => void;
}>;

const TextButton: FC<PropsWithChildren<TextButtonProps>> = ({
  type = 'button',
  variant = 'primary', // launcher styles
  className = '',
  onClick,
  children,
  ...props
}) => {
  return (
    <button type={type} className={`text-button ${className}`} onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export default TextButton;
