import { useTranslation } from 'react-i18next';
import styles from './Navigation.module.scss';

function GamesCardNavigationItem({ genre, activeGenre, updateActiveGenre }) {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <li className={`${styles['games__navigation--item']}`} key={genre.id}>
      <h4 className={styles['games__navigation--link']} onClick={() => updateActiveGenre(genre.id)}>
        <span className={`${styles['games__navigation--title']} ${styles[genre.id === activeGenre && 'active_item']}`}>
          {genre.lockit[language].name}
        </span>
      </h4>
    </li>
  );
}

export default GamesCardNavigationItem;
