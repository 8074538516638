/* eslint-disable import/no-cycle */
import clientLib from '../../clientLib';
import { logger } from '../../logger';
import {
  APP_METHODS,
  NOTIFICATION_STATUSES,
  LOCAL_STORAGE_KEYS,
  EVENTS,
  INSTALLATION_TYPE, ErrorTypes, progressIntervalTimeMS
} from "../../constants";
import { getInstalledGames } from '../../services/Games';
import { getInstallationProgress } from '../../services/Progress';
import { addNotification, changeNotificationStatus, removeNotification } from './notifications';
import { ActiveProgressStorage } from '../../services/Storage';
import ACTIONS from './actions';
import {
  getGameLoadingType,
  reportGameLoading,
  reportGameLaunch,
  compareVersions,
  getInstalledVersionByGame, handleLaunchReportSource
} from "../../helpers";
import { setError, setLaunchedGame } from "./main";

const initialState = {
  inProgress: [],
  recentlyInstalledGameURI: null,
  recentlyStartedInstallationGameURI: null,
  gameToInstallFromExe: null,
};

const activeProgressStorage = new ActiveProgressStorage(LOCAL_STORAGE_KEYS.INSTALLATION_PROGRESS);

//Common handlers
const excludeProcessingGame = (currGames, uri) => {
  const gameAlreadyExistsIndex = currGames.findIndex(game => {
    return game.uri === uri;
  });
  return gameAlreadyExistsIndex !== -1
    ? [...currGames.slice(0, gameAlreadyExistsIndex), ...currGames.slice(gameAlreadyExistsIndex + 1)]
    : currGames;
};

function progressReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.ADD_GAME:
      return {
        ...state,
        ...(action.recentlyStarted && {
          recentlyStartedInstallationGameURI: { uri: action.uri, timestamp: Date.now() },
        }),
        inProgress: [
          ...state.inProgress,
          {
            uri: action.uri,
            progress: action.progress,
            methodType: action.methodType,
            error: null,
          },
        ],
      };
    case ACTIONS.SET_EXE_GAME_INSTALLATION_DATA:
      return {
        ...state,
        gameToInstallFromExe: { ...action.data, timestamp: Date.now() },
      };
    case ACTIONS.CLEAR_GAME_DATA_TO_INSTALL_FROM_EXE:
      if (!state?.gameToInstallFromExe?.xPromoID) return state;
      if (action.xpromoId === state?.gameToInstallFromExe?.xPromoID)
        return {
          ...state,
          gameToInstallFromExe: null,
        };
      return state;
    case ACTIONS.UPDATE_INSTALLATION_PROGRESS:
      const preventDelayedProgressResponse = state.inProgress.some(progress => progress.uri === action.uri);
      if (!preventDelayedProgressResponse || !action.progress) return state;

      const filteredInProgress = excludeProcessingGame(state.inProgress, action.uri);
      return {
        ...state,
        inProgress: [
          ...filteredInProgress,
          {
            uri: action.uri,
            progress: action.progress,
            methodType: action.methodType,
          },
        ],
      };
    case ACTIONS.INSTALLATION_COMPLETED:
      const inProgress = excludeProcessingGame(state.inProgress, action.uri);
      return {
        ...state,
        inProgress: inProgress,
      };
    case ACTIONS.SET_RECENTLY_INSTALLED_GAME:
      return {
        ...state,
        recentlyInstalledGameURI: action.uri,
      };
    default:
      return state;
  }
}

const notifyAboutRecentlyInstalledGame = uri => ({ type: ACTIONS.SET_RECENTLY_INSTALLED_GAME, uri });

export const completeInstallationProcess = uri => ({
  type: ACTIONS.INSTALLATION_COMPLETED,
  uri,
});

export const handleInstallationProgress = (id, uri, methodType, progressInterval) => {
  return async dispatch => {
    try {
      const progress = await getInstallationProgress(id);
      if (!progress) return;
      dispatch({
        type: ACTIONS.UPDATE_INSTALLATION_PROGRESS,
        uri,
        progress,
        methodType,
      });
    } catch (error) {
      dispatch(launchRequiredMethodsToCompleteInstallation(uri, progressInterval));
      dispatch(removeNotification({ uri }));
    }
  };
};

export const launchRequiredMethodsToCompleteInstallation = (uri) => {
  return async dispatch => {
    activeProgressStorage.removeURIData({ uri });
    dispatch(completeInstallationProcess(uri));
    await dispatch(updateInstalledGames());
  };
};

const handleInstallationResult = (result, game, type, launchApp, source, additionalSource, intervalId) => {
  return dispatch => {
    const { xpromoId, storeLink: uri } = game;
    intervalId && clearInterval(intervalId);
    switch (result) {
      case EVENTS.INSTALL.COMPLETED:
        // !launchApp && dispatch(pushModal(MODAL_STATUS.SUCCESS, game));

        dispatch(notifyAboutRecentlyInstalledGame(uri));
        logger.debug(`${APP_METHODS.INSTALL} completed ${xpromoId}`, result);
        reportGameLoading(type, game.xpromoId, EVENTS.INSTALL.COMPLETED, source, additionalSource);
        if (launchApp) {
          const reportSource = handleLaunchReportSource(type, source);
          dispatch(removeNotification({ uri }));
          reportGameLaunch(xpromoId, reportSource, additionalSource);
          dispatch(setLaunchedGame(game.id));
        } else {
          dispatch(changeNotificationStatus(uri, NOTIFICATION_STATUSES.INSTALLED));
        }
        break;
      case EVENTS.INSTALL.CANCELED:
        // dispatch(pushModal(MODAL_STATUS.ERROR, game));
        dispatch(changeNotificationStatus(uri, NOTIFICATION_STATUSES.ERROR));
        logger.error(`${APP_METHODS.INSTALL} canceled ${xpromoId}`, result);
        reportGameLoading(type, game.xpromoId, EVENTS.INSTALL.CANCELED, source, additionalSource);
        break;
      default:
        break;
    }
  };
};

const clearGameDataToInstallFromExe = xpromoId => ({
  type: ACTIONS.CLEAR_GAME_DATA_TO_INSTALL_FROM_EXE,
  xpromoId,
});

export const setExeGameInstallationData = data => ({
  type: ACTIONS.SET_EXE_GAME_INSTALLATION_DATA,
  data,
});

export const startGameLoading = (method, game, launchApp, g5tid, updateVersion, source, additionalSource) => {
  return (dispatch, getState) => {
    const { storeLink: uri, pgplId, xpromoId } = game;

    const {
      settings: { language },
      testDevice,
    } = getState().main;
    const shortcutName = (game.lockit[language].name || game.lockit['en'].name).replace(/[/\\|:*?<>]/g, '');
    let progressInterval;

    const reqId = clientLib._getRequestId() + Date.now();

    dispatch(clearGameDataToInstallFromExe(xpromoId));

    const methodType = getGameLoadingType(method);
    activeProgressStorage.addURIData({
      uri,
      reqId,
      methodType,
      ...(updateVersion && { updateVersion }),
      ...(launchApp && { launchApp: true }),
      source,
      additionalSource,
    });
    reportGameLoading(methodType, game.xpromoId, EVENTS.INSTALL.START, source, additionalSource);

    const cdnTypeURI = testDevice ? uri.replace('cdn', 'cdn.test') : uri;

    clientLib
      .sendRequest(
        method,
        {
          packageURI: cdnTypeURI,
          xpromoId,
          ...(launchApp && { launchApp, launchURI: `pgplg5store${pgplId}://${g5tid ? `?g5tid=${g5tid}` : ''}` }),
          ...(methodType === INSTALLATION_TYPE.INSTALL && {
            shortcut: { name: shortcutName, uri: `pgplg5store${pgplId}://` },
          }),
        },
        reqId,
      )
      .then(result => {
        dispatch(handleInstallationResult(result, game, methodType, launchApp, source, additionalSource, progressInterval));
      })
      .catch(error => {
        clearInterval(progressInterval);
        dispatch(changeNotificationStatus(uri, NOTIFICATION_STATUSES.ERROR));
        dispatch(setError(ErrorTypes.INSTALLATION_ERROR));
        logger.error(`${method} ${error}`);
        reportGameLoading(methodType, game.xpromoId, EVENTS.INSTALL.ERROR, source, additionalSource);
      })
      .finally(() => {
        dispatch(launchRequiredMethodsToCompleteInstallation(uri));
      });

    dispatch({ type: ACTIONS.ADD_GAME, uri, progress: 0, methodType, recentlyStarted: true });
    const notificationAlreadyExists =
      getState().notifications.findIndex(notification => notification.uri === uri) !== -1;

    dispatch(
      notificationAlreadyExists
        ? changeNotificationStatus(uri, NOTIFICATION_STATUSES.PROGRESS)
        : addNotification({
            uri,
            status: NOTIFICATION_STATUSES.PROGRESS,
            type: methodType,
            ...(launchApp && { launchApp }),
            ...(g5tid && { g5tid }),
            ...(source && { source }),
            ...(additionalSource && { additionalSource }),
          }),
    );

    progressInterval = setInterval(() => {
      dispatch(handleInstallationProgress(reqId, uri, methodType, progressInterval));
    }, progressIntervalTimeMS);
  };
};

export const continueProgress = () => {
  return async (dispatch, getState) => {
    const { games, installedGames } = getState().main;
    const installedGamesData = games.filter(game =>
      installedGames.some(installedGame => installedGame.packageId === game.packageId || installedGame.xpromoId === game.xpromoId),
    );

    const activeUris = activeProgressStorage.getActiveURIs(installedGamesData);

    const filteredActiveUris = activeUris.filter(activeUriData => {
      const successfullyInstalledGame = installedGamesData.find(game => game.storeLink === activeUriData.uri);

      if (!successfullyInstalledGame) return true;

      if (activeUriData.methodType === INSTALLATION_TYPE.INSTALL) {
        dispatch(
          handleInstallationResult(
            EVENTS.INSTALL.COMPLETED,
            successfullyInstalledGame,
            activeUriData.methodType,
            activeUriData?.launchApp,
            activeUriData?.source,
            activeUriData?.additionalSource,
          ),
        );
        activeProgressStorage.removeURIData({ uri: activeUriData.uri });
      } else if (activeUriData.methodType === INSTALLATION_TYPE.UPDATE) {
        const gameInstalledVersion = getInstalledVersionByGame(installedGames, successfullyInstalledGame);
        if (activeUriData?.updateVersion && compareVersions(activeUriData.updateVersion, gameInstalledVersion) !== 1) {
          dispatch(
            handleInstallationResult(
              EVENTS.INSTALL.COMPLETED,
              successfullyInstalledGame,
              activeUriData.methodType,
              activeUriData?.launchApp,
              activeUriData?.source,
              activeUriData?.additionalSource,
            ),
          );
          activeProgressStorage.removeURIData({ uri: activeUriData.uri });
          return false;
        }
        return true;
      }
      return false;
    });

    if (filteredActiveUris.length) {
      for (const uriData of filteredActiveUris) {
        const { uri, reqId, methodType, launchApp, source, additionalSource } = uriData;
        dispatch({ type: ACTIONS.ADD_GAME, uri, progress: 0, methodType });
        const gameByUri = games.find(game => game.storeLink === uri);
        let progressInterval;
        clientLib
          .onResponse(reqId)
          .then(result => {
            dispatch(handleInstallationResult(result, gameByUri, methodType, launchApp, source, additionalSource, progressInterval));
          })
          .catch(error => {
            clearInterval(progressInterval);
            dispatch(changeNotificationStatus(uri, NOTIFICATION_STATUSES.ERROR));
            logger.error(`${methodType} ${error}`);
            reportGameLoading(methodType, gameByUri.xpromoId, EVENTS.INSTALL.ERROR, source, additionalSource);
          })
          .finally(() => {
            dispatch(launchRequiredMethodsToCompleteInstallation(uri));
          });

        progressInterval = setInterval(
          () => dispatch(handleInstallationProgress(reqId, uri, methodType, progressInterval)),
          progressIntervalTimeMS,
        );
      }
    }
  };
};

export const updateInstalledGames = () => {
  return async dispatch => {
    try {
      const installedGames = await getInstalledGames();
      dispatch({
        type: ACTIONS.UPDATE_INSTALLED_APPS,
        installedGames,
      });
    } catch (error) {
      dispatch({ type: ACTIONS.ERROR, error });
    }
  };
};

export default progressReducer;
