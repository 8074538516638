import usePageLogging from '../../hooks/usePageLogging';
import { renderLogger } from '../../logger';
import { LOGGING_PAGE_IDS } from '../../constants';
import { ProfileGames } from '../../Profile/pages/ProfileGames';
import { PageScrollbar } from '../shared/scrollbar/Scrollbars';

function InstalledGames() {
  renderLogger.debug('InstalledGames');

  usePageLogging(LOGGING_PAGE_IDS.MY_GAMES);

  return (
    <PageScrollbar>
      <ProfileGames />
    </PageScrollbar>
  );
}
export default InstalledGames;
