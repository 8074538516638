import { FC, PropsWithChildren, useContext } from "react";

import './styles/index.scss';
import { ProfileContext } from "../../../pages/Profile/Profile";

type ButtonType = 'button' | 'submit' | 'reset';

type ButtonProps = Partial<{
  type: ButtonType;
  variant: string;
  sizeMode: string;
  className: string;
  disabled?: boolean;
  onClick: (e?) => void;
}>;

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  type = 'button',
  variant = 'primary',
  sizeMode = '2',
  className = '',
  disabled,
  onClick,
  children,
  ...props
}) => {
  return (
    <button
      type={type}
      className={`w-btn w-btn--${variant} w-btn--mode-${sizeMode} ${className}`}
      onClick={onClick}
      disabled={disabled}
      {...props}>
      {children}
    </button>
  );
};

export default Button;
