import styles from './PromoDescription.module.scss';

type PromoDescriptionProps = {
  title: string;
  subtitle: string;
  description: string;
  footerText: string;
};

const PromoDescription = ({ title, subtitle, description, footerText }: PromoDescriptionProps) => (
  <div className={styles.promo}>
    <h2>{title}</h2>
    <h3>{subtitle}</h3>
    <div className={styles.promoDescription} dangerouslySetInnerHTML={{ __html: description }} />
    <sub>{footerText}</sub>
  </div>
);

export default PromoDescription;
