/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './Game.module.scss';
import BannerSingle from '../../shared/banners/single/BannerSingle';
import SingleGameDescription from '../../pages-content/game/description/SingleGameDescription';
import SingleGameLanguages from '../../pages-content/game/languages/SingleGameLanguages';
import SingleGameNews from '../../pages-content/game/news/SingleGameNews';
import SingleGameRequirements from '../../pages-content/game/requirements/SingleGameRequirements';
import SingleGameScreenshotsSlider from '../../pages-content/game/slider/SingleGameScreenshotsSlider';
import SingleGameTitle from '../../pages-content/game/title/SingleGameTitle';
import SingleGameAgeRating from '../../pages-content/game/rating/SingleGameAgeRating';
import SingleGameCopyright from '../../pages-content/game/copyright/SingleGameCopyright';
import SingleGameLink from '../../pages-content/game/link/SingleGameLink';
import PromoPlate from '../../shared/promo/promo-plate/PromoPlate';
import { getInstalledVersionByGame } from '../../../helpers';
import {
  selectAllBanners,
  selectInstalledGamesIDs,
  selectGamePromoData,
  selectGames
} from "../../../store/selectors";
import { renderLogger } from '../../../logger';
import usePageLogging from '../../../hooks/usePageLogging';
import { LOGGING_PAGE_IDS } from '../../../constants';
import { PageScrollbar } from '../../shared/scrollbar/Scrollbars';
import { selectEventsByGameId } from '../../../store/dux/events/selectors';
import { EventList } from '../../events/EventList';

function Game() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [buttonStatus, setButtonStatus] = useState(null);

  const location = useLocation();

  const games = useSelector(selectGames, shallowEqual);

  const game = games.find(game => game.id === location.state.id);

  usePageLogging(LOGGING_PAGE_IDS.GAME, game.xpromoId);

  const gamePromoData = useSelector(state => selectGamePromoData(state, game.id));

  const banner = useSelector(selectAllBanners, shallowEqual).filter(bannerItem => bannerItem.id === game.bannerId)[0];

  const clientLibInstalledApps = useSelector(selectInstalledGamesIDs);

  const installedVersion = getInstalledVersionByGame(clientLibInstalledApps, game);

  const gameEvents = useSelector(state => selectEventsByGameId(state, game.id));

  const {
    lockit: {
      [language]: { updateInfoDescription, fullDescription, requirements, copyright },
    },
    languages,
    updateInfoVersion,
    updateInfoDate,
    rating,
    supportLink,
    id: gameId,
  } = game;

  renderLogger.debug('Game');

  return (
    <PageScrollbar>
      <div className={styles.main}>
        <section className={styles['game-banner']}>
          <BannerSingle {...{ banner }}>
            <BannerSingle.game />
          </BannerSingle>
        </section>
        <section className={styles.content}>
          <div className={styles['game-single']}>
            <SingleGameTitle
              {...{
                game,
                installedVersion,
                setButtonStatus,
              }}
            />
            {gameEvents && (
              <>
                <h3 className={styles['game-single__title']}>{t('events.header')}</h3>
                <EventList events={gameEvents} />
              </>
            )}
            {gamePromoData && (
              <PromoPlate
                className={styles['game__promo-plate']}
                game={game}
                buttonStatus={buttonStatus}
                gamePromoData={gamePromoData}
              />
            )}
            {updateInfoDescription ? (
              <SingleGameNews {...{ updateInfoVersion, updateInfoDate, updateInfoDescription }} />
            ) : null}
            <SingleGameScreenshotsSlider gameId={gameId} />
            <SingleGameDescription
              fullDescription={fullDescription}
              descriptionTitle={t('games.single.titles.description')}
            />
            <SingleGameLanguages titleLangs={t('games.single.titles.languages')} languages={languages} />
            <SingleGameRequirements requirements={requirements} titleReqs={t('games.single.titles.requirements')} />
            <SingleGameAgeRating rating={rating} title={t('games.single.subtitles.information.rating')} />
            <SingleGameLink supportLink={supportLink} title={t('footer.legal.list.support')} />
            <SingleGameCopyright copyright={copyright} />
          </div>
        </section>
      </div>
    </PageScrollbar>
  );
}

export default Game;
