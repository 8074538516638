import { FC, PropsWithChildren } from 'react';

import './styles/index.scss';

type CircleWrapperProps = Partial<{
  variant: string;
  as: 'span' | 'p' | 'div';
  className: string;
}>;

const CircleWrapper: FC<PropsWithChildren<CircleWrapperProps>> = ({
  variant = 'orange',
  as = 'div',
  className = '',
  children,
}) => {
  const Tag = as;
  return <Tag className={`circle-wrapper circle-wrapper--${variant} ${className}`}>{children}</Tag>;
};

export default CircleWrapper;
