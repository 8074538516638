import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { selectAllNotifications, selectRecentlyInstalledGame } from '../store/selectors';

export default function useNotifications() {

  const [active, setActive] = useState(false);
  const notifications = useSelector(selectAllNotifications, shallowEqual);
  const recentlyInstalledGameURI = useSelector(selectRecentlyInstalledGame);

  useEffect(
    function handleNotificationChanges() {
      if (notifications.length === 0) {
        setActive(false);
        return;
      }
      setActive(true);
    },
    [notifications]
  );

  return { active, setActive, notifications, recentlyInstalledGameURI };
}
