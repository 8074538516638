import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import styles from './ProgressBar.module.scss';

function ProgressBar({ progress, methodType, error = null, update = null, noti }) {
  const { t } = useTranslation();
  const progressToCSS = `${progress > 7 ? progress : progress !== 0 ? 7 : 0}%`;

  return (
    <div className={`${styles.progressBar} ${noti ? styles.noti : ''}`}>
      {error ? (
        <>
          <p className={styles['progressBar--error']}>{t('notifications.error')}</p>
          <span className={styles['progressBar--wrapper']}>
            <span
              className={`${styles['progressBar--scale']} ${styles['progressBar--scale-error']}`}
              style={{ width: progressToCSS }}
            />
          </span>
        </>
      ) : (
        <>
          <p className={styles['progressBar--info']}>
            {!update && (progress > 0 ? t(`shared.progressbar.${methodType}`, { progress: progress }) : t('shared.progressbar.preparing'))}
          </p>
          <span className={styles['progressBar--wrapper']}>
            <span
              className={`${styles['progressBar--scale']} ${styles[`progressBar--scale-${methodType}`]}`}
              style={{ width: progressToCSS }}
            />
          </span>
        </>
      )}
    </div>
  );
}

export default ProgressBar;
