import { FC, useState, ComponentType, useContext } from 'react';
import { ProfileContext } from "../../../pages/Profile/Profile";
import { TextButton } from '../../commons/TextButton';
import { Headline } from '../../commons/Headline';
import { useTranslation } from "react-i18next";

type ToggleEditProfileProps = {
  sectionTitle: string;
  form: ComponentType<{ toggleEdit: () => void }>;
  dataInfo: ComponentType;
  defaultUser?: boolean;
};

const ToggleEditProfile: FC<ToggleEditProfileProps> = ({
  sectionTitle,
  form: FormComponent,
  dataInfo: DataInfoComponent,
  defaultUser,
}) => {
  const { t } = useTranslation();
  const { editProfileInfo, toggleEditProfile } = useContext(ProfileContext);

  return (
    <section className="profile__data">
      <div className="profile__head">
        <Headline headlineLevel="h2" size="3" className="profile__headline">
          {sectionTitle}
        </Headline>
        {!editProfileInfo && !defaultUser ? (
          <TextButton
            onClick={() => {
              toggleEditProfile(!editProfileInfo);
            }}
            type="button"
            variant="primary"
            className="profile__edit profile-edit-btn text-button">
            {t<string>('profile.buttons.edit')}
          </TextButton>
        ) : (
          ''
        )}
      </div>
      {editProfileInfo ? (
        <FormComponent toggleEdit={() => toggleEditProfile(!editProfileInfo)} />
      ) : (
        <DataInfoComponent />
      )}
    </section>
  );
};

export default ToggleEditProfile;
