export default function ClientLibConfig(data) {
  data = data || {};

  const gameIds = getIdsFromConfig('games', data);
  Object.freeze(gameIds);

  const bannerIds = getIdsFromConfig('banners', data);
  Object.freeze(bannerIds);

  const eventIds = getIdsFromConfig('events', data);

  Object.assign(this, {
    ...data,
    getGameIds: () => gameIds,
    getBannerIds: () => bannerIds,
    getEventsIds: () => eventIds,
  });

  Object.freeze(this);
}

function getIdsFromConfig(prefix, config) {
  if (!config) return null;

  const result = [];
  const count = +config[`${prefix}.count`];
  for (let i = 0; i < count; i++) {
    result.push(config[`${prefix}.${i}.id`]);
  }
  return result;
}
