import { FC } from 'react';

import { ReactComponent as CrossIcon } from '../../../assets/images/svg/cross-icon.svg';

import './styles/index.scss';

type CloseButtonProps = {
  className?: string;
  variant?: 'primary' | 'secondary';
  isInCircle?: boolean;
  onClick?: () => void;
};

const CloseButton: FC<CloseButtonProps> = ({ className = '', variant = 'primary', isInCircle, onClick }) => {
  return (
    <button
      type="button"
      className={`close-button close-button--${variant} ${className} ${isInCircle ? 'close-button--in-circle' : ''} `}
      onClick={onClick}>
      <CrossIcon className="close-button__icon" />
    </button>
  );
};

export default CloseButton;
