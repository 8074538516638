import { useState } from 'react';
import SubscribeModalContent from './SubscribeModalContent';
import styles from '../Support.module.scss';
import { ReactComponent as Close } from '../../../../../assets/images/cross.svg';
import { transformUsageTime } from '../../../../../helpers';
import { LOGGING_SUBSCRIBE_ACTION, EMAIL_SUBSCRIPTION_STATUSES } from '../../../../../constants';

const SubscribeModal = ({ handleClose, report }) => {
  const [status, setStatus] = useState(null);

  const closeAndLog = () => {
    handleClose();
    if (status !== EMAIL_SUBSCRIPTION_STATUSES.SUCCESS) {
      report(
        'click',
        transformUsageTime(window.currentPageUseageStartTime),
        LOGGING_SUBSCRIBE_ACTION.SUBSCRIBE_POPUP_CLOSE,
      );
    }
  };

  return (
    <div className={styles.modal}>
      <form className={styles.modalContent}>
        <div className={styles.closeModal} onClick={closeAndLog}>
          <Close />
        </div>
        <SubscribeModalContent handleClose={handleClose} report={report} status={status} setStatus={setStatus} />
      </form>
    </div>
  );
};

export default SubscribeModal;
