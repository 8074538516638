import React, { useRef, useContext, useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import { useSelector } from 'react-redux';
import { selectCurrentLang } from '../../../../store/selectors';
import useCalendarLocalization from '../../../hooks/useCalendarLocale';
import 'flatpickr/dist/flatpickr.css';
import { NotificationTypes, ProfileContext } from '../../../pages/Profile/Profile';
import { handleDate } from '../../../pages/Profile/Profile';
import { useTranslation } from "react-i18next";
import { UseFormSetValue, FieldValues } from "react-hook-form";

enum AgeModalStatus {
  CONFIRM = 'CONFIRM',
  CLOSED = 'CLOSED',
  OK = 'OK',
}

type DatePickerProps = {
  birthDate: string;
  setNotification: (v: NotificationTypes) => void;
  setValue: UseFormSetValue<FieldValues>;
  underageConfirmStatus: 'confirmed' | undefined;
};

export default function Datepicker({ birthDate, setValue }: DatePickerProps) {
  const { t } = useTranslation();
  const currentLang = useSelector(selectCurrentLang);
  const locale = useCalendarLocalization(currentLang);

  const { setAgeModal, saveAndSetBirthday, editingUnderageDate, callbackRef, setEditingUnderageDate } =
    useContext(ProfileContext);

  const calendRef = useRef(null);

  useEffect(() => {
    editingUnderageDate && calendRef.current?.flatpickr.open();
  }, [editingUnderageDate]);

  return (
    <div style={{ ...(editingUnderageDate && { pointerEvents: 'all' }) }}>
      <Flatpickr
        defaultValue={birthDate || t('profile.placeholders.not_specified')}
        options={{
          static: true,
          enableTime: false,
          dateFormat: 'Y-M-d',
          locale,
          maxDate: 'today',
          minDate: '1890-01-01',
          defaultDate: birthDate,
          ...(editingUnderageDate && { ignoredFocusElements: [window.document.body] }),
        }}
        onChange={async ([date]) => {
          callbackRef.current = () => saveAndSetBirthday(date);
          const { confirmUnderage } = handleDate(date);

          if (confirmUnderage) {
            setEditingUnderageDate(false);
            return setAgeModal(AgeModalStatus.CONFIRM);
          }
          if (editingUnderageDate) {
            await saveAndSetBirthday(date);
            return;
          }
          setValue('birthday', date);
          //
        }}
        ref={ref => {
          calendRef.current = ref;
        }}
        render={({ defaultValue }, ref) => {
          return (
            <div ref={ref} className="input input--date">
              {birthDate || defaultValue}
            </div>
          );
        }}
      />
    </div>
  );
}
