import { useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { getExternalError } from '../utils/errors';
import { emailDomainG5EcomTest } from "../utils/validators";

const useUpdateProfileForm = () => {
  const { t } = useTranslation();
  const updateProfileFormSchema = useMemo(
    () =>
      yup.object().shape({
        nick: yup
          .string()
          .nullable()
          .transform((v, o) => (o === '' ? null : v))
          .min(3, getExternalError('name', t('profile.message.wrong_length', { attribute: t('profile.words.nick'), n: 3, m: 32 })))
          .max(32, getExternalError('name', t('profile.message.wrong_length', { attribute: t('profile.words.nick'), n: 3, m: 32 })))
          .matches(
            /^(?!.*\s\s)[a-zA-Z]{2}[A-Za-z0-9\s]+$/,
            t('profile.message.not_valid', { attribute: t('profile.words.nick') }),
          ),
        name: yup
          .string()
          .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.name') }))
          .min(3, t('profile.message.not_valid', { attribute: t('profile.words.name') }))
          .max(32, t('profile.message.not_valid', { attribute: t('profile.words.name') })),
        gender: yup.string(),
        email: yup
          .string()
          .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.email') }))
          .email(t('profile.message.not_valid', { attribute: t('profile.words.email') }))
          .test(emailDomainG5EcomTest),
        birthday: yup.date(),
      }),
    [],
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    watch,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(updateProfileFormSchema),
  });

  return {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
    watch,
    reset,
  };
};

export default useUpdateProfileForm;
