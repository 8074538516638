import { FC, PropsWithChildren } from 'react';

import './styles/index.scss';

export type GridContainerProps = Partial<{
  containerType?: 'type-1' | 'type-2' | 'type-3' | 'type-4' | 'type-5';
  className?: string;
}>;

const GridContainer: FC<PropsWithChildren<GridContainerProps>> = ({
  containerType = 'type-1',
  className = '',
  children,
}) => {
  return <div className={`w-container  w-container--${containerType} ${className}`}>{children}</div>;
};

export default GridContainer;
