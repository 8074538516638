import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ColoredScrollbars from '../../scrollbar/ColoredScrollbars';
import { selectCurrentLang } from '../../../../store/selectors';
import { LANGUAGES } from '../../../../constants';
import {
  setActiveXPromoBannerIndex,
  setAndUpdateSettings,
  setXPromoBannersToFallback
} from "../../../../store/dux/main";
import styles from './FormFieldSelect.module.scss';

function FormFieldSelect() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLang = useSelector(selectCurrentLang);

  const options = useMemo(
    () =>
      LANGUAGES.map(lang => ({
        value: lang,
        label: t(`languages.${lang}`),
      })),
    [t],
  );

  const [dropdownShow, setDropdownShow] = useState(false);

  const defaultValue = (() => {
    const index = options.findIndex(({ value }) => value === currentLang);
    return options[index].label || null;
  })();

  const toggleDropdownShow = () => {
    setDropdownShow(currentValue => !currentValue);
  };

  const changeCurrentLanguage = event => {
    const language = event.currentTarget.getAttribute('data-value');
    if (language === currentLang) {
      setDropdownShow(false);
      return;
    }
    dispatch(setAndUpdateSettings({ language }));
    dispatch(setXPromoBannersToFallback());
    dispatch(setActiveXPromoBannerIndex(0));
    setDropdownShow(false);
  };

  useEffect(
    function closeOnClickFromOutside() {
      function onClickOutsideDropdown(event) {
        if (!event.target.closest(`.${styles.select}`)) {
          setDropdownShow(false);
        }
      }

      window.addEventListener('click', onClickOutsideDropdown);
      return () => window.removeEventListener('click', onClickOutsideDropdown);
    },
    [setDropdownShow],
  );

  return (
    <div className={styles.select}>
      <button className={styles['select--value']} onClick={toggleDropdownShow}>
        {defaultValue}
        <i className={`${styles.icon} icon-arrow-down`} onClick={event => event.preventDefault()} />
      </button>
      {dropdownShow && (
        <div className={styles['select--dropdown']}>
          <ColoredScrollbars
            verticalthumbstyle={{
              right: 2,
              bottom: 2,
              top: 2,
              paddingTop: 3,
              paddingBottom: 3,
            }}>
            <ul className={styles['select--list']}>
              {options &&
                options.map((option, index) => (
                  <li
                    className={styles['select--item']}
                    key={index}
                    data-value={option.value}
                    onClick={changeCurrentLanguage}>
                    {option.label}
                  </li>
                ))}
            </ul>
          </ColoredScrollbars>
        </div>
      )}
    </div>
  );
}

export default FormFieldSelect;
