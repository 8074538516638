import { LANGUAGES, LanguageTypes } from '../constants';
import { fetchEventConfig } from '../fetchLocalData';
import { logger } from '../logger';

type ConfigLockitType = {
  [key in LanguageTypes]: {
    title: string;
    shortDescription: string;
    fullDescription: string;
  };
};

type EventConfigType = {
  bannerImage: string;
  gameId: string;
  startTime: number;
  endTime: number;
  localUTC: boolean;
  lockit: ConfigLockitType;
};

export type EventType = EventConfigType & { id: string };

export default function Event(id: string, config: EventConfigType) {
  const {
    bannerImage = '',
    gameId = '',
    localUTC = false,
    lockit: configLockit = {},
  } = config;

  const endTime = new Date(`${config.endTime} ${localUTC ? '' : 'GMT'}`).getTime();
  const startTime = new Date(`${config.startTime} ${localUTC ? '' : 'GMT'}`).getTime();


  const lockit = {} as ConfigLockitType;
  for (const lang of LANGUAGES) {
    const { title, shortDescription, fullDescription } = configLockit[lang] || {};
    lockit[lang] = { title, shortDescription, fullDescription };
  }

  Object.assign(this, {
    id,
    bannerImage,
    gameId,
    startTime,
    endTime,
    localUTC,
    lockit,
  });
}

Event.fetch = function (eventId): Promise<EventType> {
  return fetchEventConfig(eventId)
    .then(config => new Event(eventId, config))
    .catch(reason => logger.error(`Event.fetch('${eventId}')`, reason));
};
