import React from 'react';
import styles from '../../../pages/Game/Game.module.scss';

function SingleGameCopyright({ copyright }) {
  return (
    <div className={styles['game__description']}>
      <p className={styles['game-single__copyright']}>{copyright}</p>
    </div>
  );
}

export default SingleGameCopyright;
