import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { HeadlineWithIcon } from '../../../commons/HeadlineWithIcon';
import { Parag } from '../../../commons/Parag';
import { Circle } from '../../../commons/Circle';
import { FormWrapper } from '../../../commons/Form/FormWrapper';
import { FormFieldWrapper } from '../../../commons/Form/FormFieldWrapper';
import { Label } from '../../../commons/Form/Label';
import { Input } from '../../../commons/Form/Input';
import { InputPassword } from '../../../commons/Form/InputPassword';
import { Checkbox } from '../../../commons/Form/Checkbox';
import { PolicyText } from '../../PolicyText';
import { Button } from '../../../commons/Button';
import useCompleteRegistrationForm from '../../../../hooks/useCompleteRegistrationForm';
import fetchPGPL, { apiPGPLMethods } from '../../../../utils/apiPGPL';
import { EmailToConfirmType, NotificationTypes, ProfileContext } from '../../../../pages/Profile/Profile';
import { renderLogger } from '../../../../../logger';
import { AuthActionTypes } from '../../../../../store/dux/auth/actionTypes';
import { setLastPlayer } from '../../../../../services/Keychain';
import { ConsentStatusType } from '../../../../../store/dux/auth/auth';
import { useTranslation } from 'react-i18next';
import { getExternalError } from '../../../../utils/errors';
import PGPLService from "../../../../services/PGPLService";

type MessageCompleteProps = {
  passwordIsCustom?: boolean;
  playerId: string;
  unconfirmedEmail?: EmailToConfirmType;
  name: string;
  consentInfo: ConsentStatusType;
};

function MessageComplete({ passwordIsCustom, playerId, unconfirmedEmail, name, consentInfo }: MessageCompleteProps) {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setError } = useCompleteRegistrationForm(unconfirmedEmail, passwordIsCustom, consentInfo);
  const { setIsLoading, setNotification } = useContext(ProfileContext);

  const dispatch = useDispatch();

  const submitRegistrationCompletion = async data => {
    const successStatus = [];
    try {
      setIsLoading(true);
      const { password, email, confirm } = data;

      if (confirm) {
        await PGPLService.confirmConsent(consentInfo?.version);
        dispatch({
          type: AuthActionTypes.UPDATE_PROFILE_DATA,
          data: {
            consentInfo: {
              status: 'confirmed',
              version: consentInfo.version,
            },
          },
        });
        successStatus.push(confirm);
      }

      if (email) {
        await PGPLService.updatePlayer({ playerId, email });
        const unconfirmedEmail = await fetchPGPL(apiPGPLMethods.getLastEmailUpdateRequest);
        dispatch({
          type: AuthActionTypes.UPDATE_PROFILE_DATA,
          data: {
            email: unconfirmedEmail?.email,
            ...(unconfirmedEmail?.email && { unconfirmedEmail }),
          },
        });
        successStatus.push(email);
      }

      if (password) {
        await PGPLService.setPlayerPassword(password);
        await PGPLService.logout();
        dispatch({
          type: AuthActionTypes.CLEAR_WEBTOKEN,
        });
        const webToken = await PGPLService.loginWeb(name, password);
        dispatch({
          type: AuthActionTypes.SET_WEBTOKEN,
          webToken,
        });
        await setLastPlayer(playerId, webToken);
        dispatch({
          type: AuthActionTypes.UPDATE_PROFILE_DATA,
          data: { passwordIsCustom: true },
        });
        successStatus.push(password);
      }
    } catch (error) {
      renderLogger.error(`submitRegistrationCompletion(${JSON.stringify(data)})`, JSON.stringify(error));
      if(error.error === 'EmailInUse') {
        return setError('email', { type: 'custom', message: getExternalError('email', error.error) });
      }
      if(error.error === 'InvalidPassword') {
        return setError('password', { type: 'custom', message: getExternalError('password', error.error) });
      }
      if(error.error === 'InvalidParameter') {
        const { parameterName } = error;
        return setError(parameterName, { type: 'custom', message: getExternalError(parameterName, error.error) });
      }
    } finally {
      if (successStatus.length) {
        setNotification(NotificationTypes.PROFILE_CHANGE);
      }
      setIsLoading(false);
    }
  };

  const getMessageKeyToCompleteRegistration = (): string => {
    if (!unconfirmedEmail && !passwordIsCustom) return 'profile.action.complete_no_both';
    if (!passwordIsCustom) return 'profile.action.no_password';
    if (!unconfirmedEmail) return 'profile.action.no_email';
  };

  return (
    <>
      <HeadlineWithIcon headlineLevel="h3" variant="primary" size="4" icon={<Circle variant="orange" size="md" />}>
        {t<string>('profile.action.complete_registration')}
      </HeadlineWithIcon>
      <Parag variant="primary" className="message__parag">
        {t<string>(getMessageKeyToCompleteRegistration())}
      </Parag>
      <FormWrapper className="message__form" id="g5Friends_full_registration_form">
        {!unconfirmedEmail && (
          <FormFieldWrapper error={errors?.email?.message}>
            <Label className="message__label" htmlFor="complete-registration" isRequired>
              {t<string>('profile.titles.enter_email')}
            </Label>
            <Input
              className={`message__input ${errors?.email && 'error'}`}
              type="text"
              id="complete-registration"
              name="email"
              placeholder="example@address.com"
              register={register}
            />
          </FormFieldWrapper>
        )}
        {!passwordIsCustom && (
          <FormFieldWrapper error={errors?.password?.message}>
            <Label htmlFor="createPassword" isRequired>
              {t<string>('profile.titles.create_password')}
            </Label>
            <InputPassword
              id="createPassword"
              placeholder={t('profile.placeholders.password', { n: 4, m: 32 })}
              register={register}
              name="password"
              className={`${errors?.password && 'error'}`}
            />
          </FormFieldWrapper>
        )}
        {consentInfo?.status !== 'confirmed' && (
          <FormFieldWrapper error={errors?.confirm?.message}>
            <Checkbox
              isRequired
              label={<PolicyText />}
              inputId="SignupForm_hasConsent"
              register={register}
              name={'confirm'}
              className={`${errors?.confirm && 'has-error'}`}
            />
          </FormFieldWrapper>
        )}
        <Button
          variant="primary"
          sizeMode="3"
          className="message__btn"
          onClick={handleSubmit(submitRegistrationCompletion)}>
          {t<string>('profile.buttons.save')}
        </Button>
      </FormWrapper>
    </>
  );
}

export default MessageComplete;
