import clientLib from './clientLib';
import { logger, serviceLogger } from './logger';
import {
  APP_METHODS,
  INSTALLATION_TYPE,
  LOGGING_PAGE_IDS,
  LOGGING_PAGE_IDS_BY_ROUTE,
  PUSH_NOTIFICATIONS_PARAMS,
  REFERRAL_SOURCES,
  XPROMO_LOCALES,
} from './constants';
import store from './store'; // eslint-disable-line

//region compareVersions(version1, version2)
const versionSplitter = {
  regExp: /^(\d+)\.(\d+)\.(\d+)(\d{2})/,
  partsLength: 4,
  split: function(version) {
    if (typeof version !== 'string') {
      return false;
    }

    const match = version.match(this.regExp);
    if (!match) {
      return false;
    }

    return match.slice(1, this.partsLength + 1).map(x => +x);
  },
};

export function compareVersions(version1, version2) {
  const parts1 = versionSplitter.split(version1);
  if (!parts1) {
    return false;
  }

  const parts2 = versionSplitter.split(version2);
  if (!parts2) {
    return false;
  }

  for (let i = 0; i < versionSplitter.partsLength; i++) {
    let diff = parts1[i] - parts2[i];
    if (diff) {
      return diff > 0 ? 1 : -1;
    }
  }

  return 0;
}

//endregion

export function transformUsageTime(usageTime) {
  const diff = Date.now() - usageTime;
  return Math.floor(diff / 1000);
}

export function getGameLoadingType(method) {
  if (APP_METHODS.INSTALL === method) return INSTALLATION_TYPE.INSTALL;
  if (APP_METHODS.UPDATE === method) return INSTALLATION_TYPE.UPDATE;
}

export function getGameLoadingMethod(type) {
  if (INSTALLATION_TYPE.INSTALL === type) return APP_METHODS.INSTALL;
  if (INSTALLATION_TYPE.UPDATE === type) return APP_METHODS.UPDATE;
}

export function reportMessage(msg) {
  clientLib.sendRequest('report', { entries: [msg] }).catch(error => logger.error(`Cannot report: ${msg}`, error));
}

export function reportABTestGroup(testName = '', groupId = 0) {
  const msg = `ab_test('${testName}', ${groupId})`;
  reportMessage(msg);
}

export function reportUserAgent(userAgent) {
  const msg = `g5ln_useragent('${userAgent}')`;
  reportMessage(msg);
}

export function reportXPromoShow(bannerType, xpromoId, bannerId, location) {
  const msg = `g5ln_promo_show('${xpromoId}, '${bannerType}', '${bannerId}', '${location}', '')`;
  reportMessage(msg);
}

export function reportSetSettings(settings) {
  const msg = `g5ln_ini('${
    settings.language
  }', 'autostart_${settings.autostart.toString()}', 'autoupdate_${settings.autoupdate.toString()}')`;
  reportMessage(msg);
}

export function reportGameLoading(type, XPromoID, status, source, additionalSource = '') {
  const msg = `g5ln_app_${type}('${XPromoID}', '${status}', '${source}', '${additionalSource}')`;
  reportMessage(msg);
}

export function reportGameLaunch(XPromoID, source, additionalSource = '') {
  const msg = `g5ln_app_launch('${XPromoID}', '${source}', '${additionalSource}')`;
  reportMessage(msg);
}

export function reportGameUninstall(XPromoID) {
  const msg = `g5ln_app_uninstall('${XPromoID}')`;
  reportMessage(msg);
}

export function reportSubscription(pageType, XPromoID = '') {
  return function(eventType, timeOnPage, action, error = '') {
    const msg = `g5ln_ui_event('${pageType}', '${XPromoID}', '${eventType}', '${timeOnPage}', '${action}', '${error}')`;
    reportMessage(msg);
  };
}

export function reportTakoverBannerShow(bannerType) {
  const msg = `g5ln_takeover_banner_show('${bannerType}')`;
  reportMessage(msg);
}

export function reportTakeoverBannerClick(gameId) {
  const msg = `g5ln_takeover_banner_click('${gameId}')`;
  reportMessage(msg);
}

export function reportPushNotificationClick(pushId, xpromoId) {
  const { udid: deviceId } = store.getState().main;
  const requestURL = new URL(PUSH_NOTIFICATIONS_PARAMS.REPORT_URL_PROD);
  requestURL.search = new URLSearchParams({
    pushId,
    xpromoId,
    deviceId,
  });
  fetch(requestURL).catch(error => {
    logger.error(`Cannot report: ${requestURL}`, error);
  });
}

export function reportXPromoSD(url, messageId) {
  const {
    udid: deviceId,
    settings: { language: locale },
    xpromoId,
  } = store.getState().main;
  const { profile } = store.getState().auth;

  const requestURL = new URL(url);
  const placement = 'appOpen';
  const playerId = profile?.id;

  if (!playerId || !messageId) return;

  requestURL.search = new URLSearchParams({
    messageId,
    xpromoId,
    deviceId,
    locale: XPROMO_LOCALES[locale],
    placement,
    assetId: messageId,
    playerId,
  });
  fetch(requestURL, { method: 'POST' }).catch(error => {
    logger.error(`Cannot report: ${requestURL}`, error);
  });
}

export function reportClick() {
  const msg = "g5ln_ui_event('', '', 'click', '', '', '')";
  reportMessage(msg);
}

export function debugLogData(name, result, customLogger) {
  (customLogger || logger).debug(name, process.env.NODE_ENV === 'production' ? JSON.stringify(result) : result);
  return result;
}

export function getInstalledVersionByGame(installedApps, game) {
  const installedApp = installedApps.find(
    item => game && (game.packageId === item['packageId'] || game.xpromoId === item['xpromoId']),
  );
  return installedApp && installedApp['version'];
}

export const openLink = link => clientLib.sendRequest('launchURL', { url: link });

export async function fetchFromCDN(path) {
  const { cdn, revision } = store.getState().main;

  const url = `${cdn}${path}?r=${revision}`;

  return fetch(url, { cache: 'force-cache' })
    .then(res => {
      if (!res.ok) throw new Error('Fetching error');
      return res;
    })
    .catch(error => {
      logger.error(`Cannot fetch: ${url}`, error);
    });
}

export const getCurrentGameUpdateVersion = xpromoId => {
  const { storeCDN } = store.getState().main;
  const path = `games/${xpromoId}/game.xml`;
  const url = `${storeCDN}${path}`;

  return fetch(url, { cache: 'no-store' })
    .then(fetchedXML => fetchedXML.text())
    .then(parsedXML => {
      const regex = /Version="(.*)"/;
      const parsedFromRegexVersion = parsedXML.match(regex)[1];
      debugLogData('getCurrentGameUpdateVersion()', parsedFromRegexVersion, serviceLogger);
      return parsedFromRegexVersion;
    })
    .catch(error => {
      serviceLogger.error(`getCurrentGameUpdateVersion()`, error);
    });
};

export const getLoggingPageId = (route, gameId) => {
  if (gameId) {
    return LOGGING_PAGE_IDS.GAME;
  }

  return LOGGING_PAGE_IDS_BY_ROUTE?.[route];
};

export const checkIsPromoActive = (start, end) => {
  const startMs = Date.parse(start + ' GMT');
  const endMs = Date.parse(end + ' GMT');
  const now = Date.now();

  if (now > startMs && now < endMs) {
    return true;
  }
};

export const checkIsActive = (start, end, isDateInUTC) => {
  const dateNow = new Date();
  const startMs = isDateInUTC ? Date.parse(start + ' GMT') : Date.parse(start);
  const endMs = isDateInUTC ? Date.parse(end + ' GMT') : Date.parse(end);
  const nowLocal = Date.parse(dateNow);
  const nowUTC = Date.parse(dateNow + ' GMT');
  const now = isDateInUTC ? nowUTC : nowLocal;
  if (now > startMs && now < endMs) {
    return true;
  }
};

export const checkIsNotExpiredByMs = expirationTimeMs => {
  const nowMs = new Date().getTime();
  return expirationTimeMs > nowMs;
};

export const handleLaunchReportSource = (type, source) => {
  if (source === 'takeover_banner') return 'takeover_banner';
  if (type === INSTALLATION_TYPE.UPDATE) return REFERRAL_SOURCES.LAUNCH.UPDATE;
  if (source === 'top_banner_all_games_page') return REFERRAL_SOURCES.LAUNCH.TOP_BANNER;
  return REFERRAL_SOURCES.LAUNCH.EXE;
};

const getValueFromURLQuery = (query, name) => {
  if (!query || !name) return;
  const queryParams = new URLSearchParams(query);
  return queryParams.get(name);
};

export const getSourceToReport = (location, passedSource) => {
  return getValueFromURLQuery(location?.search || passedSource, 'source') || passedSource || 'game_page';
};

export const convertDHMtimeInMs = time => {
  const timeRegExp = /^(?!.*([dhm]).*\1)\d*\.?\d+[dhm](?: \d*\.?\d+[dhm])*$/;
  if (timeRegExp.test(time)) {
    const ddhhmmArr = time.split(' ');

    return ddhhmmArr.reduce((timeInMs, timeItem) => {
      switch (timeItem[timeItem.length - 1]) {
        case 'd':
          timeInMs += parseInt(timeItem, 10) * 24 * 60 * 60 * 1000;
          break;
        case 'h':
          timeInMs += parseInt(timeItem, 10) * 60 * 60 * 1000;
          break;
        case 'm':
          timeInMs += parseInt(timeItem, 10) * 60 * 1000;
          break;
        case 's':
          timeInMs += parseInt(timeItem, 10) * 1000;
          break;
        default:
      }
      return timeInMs;
    }, 0);
  }
};

export const replaceStrings = (string, toReplaceArr, newString) => {
  for (const replaceString of toReplaceArr) {
    string = string.split(replaceString).join(newString);
  }
  return string.replace(/ +(?= )/g, '');
};

export const getWebViewVersion = () => {
  const regex = /Chrome\/(\d+)/i;
  const chromeVersion = Number(window.navigator.userAgent.match(regex)[1]);
  return chromeVersion > 86 ? 2 : 1;
};
