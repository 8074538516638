import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from '../Header.module.scss';
import arrow from '../../../../assets/images/arrow.svg';
import Tooltip from '../tooltip/Tooltip';

const getButtonsNavigationState = (history, currentLocationKey) => {
  if (history.entries.length === 1) return {};

  const currentLocationIndex = history.entries.findIndex(entry => entry.key === currentLocationKey);

  const canGoBack = history.entries.slice(0, currentLocationIndex).length;
  const canGoForward = history.entries.slice(currentLocationIndex + 1).length;

  return {
    canGoBack,
    canGoForward,
  };
};

export default function NavigationArrows() {
  const [hovering, setHovering] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const { key } = useLocation();

  const { canGoBack, canGoForward } = getButtonsNavigationState(history, key);

  return (
    <ul className={styles['header__nav--list']}>
      <li className={`${styles['header__nav--item']} ${styles.arrows}`}>
        <button
          className={`${styles['header__nav--btn']} ${canGoBack ? styles.activeArrow : ''}`}
          disabled={!canGoBack}
          onClick={() => {
            history.goBack();
          }}
          onMouseOver={() => canGoBack && setHovering('left')}
          onMouseOut={() => hovering && setHovering(null)}>
          <img src={arrow} alt="back" draggable="false" />
          {hovering === 'left' && <Tooltip text={t('navigation.back')} top={41} />}
        </button>
        <button
          className={`${styles['header__nav--btn']} ${canGoForward ? styles.activeArrow : ''}`}
          disabled={!canGoForward}
          onClick={() => {
            history.goForward();
          }}
          onMouseOver={() => canGoForward && setHovering('right')}
          onMouseOut={() => hovering && setHovering(null)}>
          <img src={arrow} alt="forward" draggable="false" />
          {hovering === 'right' && <Tooltip text={t('navigation.forward')} top={41} />}
        </button>
      </li>
    </ul>
  );
}
