import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectLogoByBannerId } from '../../../../store/selectors';
import GameAction from '../../../../components/shared/games/action/GameAction';
import { BUTTON_SIZE, LanguageTypes } from "../../../../constants";
import friendsIcon from '../../../assets/images/svg/friends.svg';
import starIcon from '../../../assets/images/svg/star.svg';

import './styles/index.scss';
import { selectGameStatsByPGPLID } from '../../../../store/dux/auth/selectors';

export type GameType = {
  id: string;
  platform: string;
  xpromoId: string;
  packageId: string;
  bannerId: string;
  pgplId: number;
  rating: string;
  supportLink: string;
  lockit: {
    [key in LanguageTypes]: {
      copyright: string;
    };
  };
  genreIds: string[];
  languages: LanguageTypes;
};

type GameCardProps = {
  game: GameType;
  gameType: 'installed' | 'external';
  className?: string;
  passedSource: string;
};

//@ts-ignore
const GameCard: FC<GameCardProps> = ({ game, gameType, className = '', installedVersion, passedSource }) => {
  //@ts-ignore
  const { bannerId, cardImage, id } = game;

  //@ts-ignore
  const logo = useSelector(state => selectLogoByBannerId(state, bannerId));

  const gameStats = useSelector(selectGameStatsByPGPLID(game.pgplId));

  const { t } = useTranslation();

  return (
    <article className={`game  game--${gameType} ${className}`}>
      <Link className="game__images" to={{ pathname: `/${id}`, state: { id } }} draggable="false">
        <img className="game__img" src={`../../${cardImage}`} alt="" />
        {logo && <img className="game__logo" src={`../../${logo}`} alt="" />}
      </Link>
      <div className="game__bottom">
        {gameStats && (
          <div className="game__stats">
            <div className="game__stats-item">
              <img className="game__stats-icon" src={starIcon} alt="" />
              <div className="game__stats-content">
                <p className="game__stats-name">{t<string>('profile.words.level')}</p>
                <p className="game__stats-score">{gameStats.level}</p>
              </div>
            </div>
            <div className="game__stats-item">
              <img className="game__stats-icon" src={friendsIcon} alt="" />
              <div className="game__stats-content">
                <p className="game__stats-name">{t<string>('profile.words.friends')}</p>
                <p className="game__stats-score">{gameStats.friends}</p>
              </div>
            </div>
          </div>
        )}
        {
          //@ts-ignore
          <GameAction {...{ game, installedVersion }} passedSource={passedSource} size={BUTTON_SIZE.MEDIUM}/>
        }
      </div>
    </article>
  );
};

export default GameCard;
