import { useEffect, useState, RefObject } from 'react';

export default function useInView(target: RefObject<HTMLDivElement>, options: IntersectionObserverInit = {}): boolean {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const [observer, setObserver] = useState<IntersectionObserver>(null);

  useEffect(
    function handleHTMLElement() {
      const callback = (entries: IntersectionObserverEntry[]) => {
        setIsIntersecting(entries[0].isIntersecting);
      };

      observer?.disconnect();

      if (target.current) {
        const currentElementObserver = new IntersectionObserver(callback, options);
        currentElementObserver.observe(target.current);
        setObserver(currentElementObserver);
      }

      return () => {
        observer?.disconnect();
      };
    },
    [target.current, options.root, options.rootMargin, options.threshold],
  );

  return isIntersecting;
}
