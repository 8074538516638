/* eslint-disable import/no-cycle */
import { getSettings, setSettings } from '../../services/Settings';
import { addLaunchedGame, getGames, getInstalledGames, getLaunchedGameList } from "../../services/Games";
import { getGenres } from '../../services/Genres';
import { getConfig } from '../../services/Config';
import { getBanners, getBannersRotationList, getXPromoBanners, XPromoBannerTypes } from '../../services/Banners';
import { getScreenshots } from '../../services/Screenshots';
import { getLogo } from '../../services/Logo';
import { setNotifications } from './notifications';
import { ErrorTypes, LOCAL_STORAGE_KEYS } from "../../constants";
import clientLib from '../../clientLib';
import { getWebViewVersion, reportGameUninstall } from "../../helpers";
import ACTIONS from './actions';
import { handleKeychainOnResume } from './auth/auth';
import { getPromoData } from '../../services/Promo';
import { setPushNotifications } from './pushNotifications/pushNotifications';
import { setEventIds } from './events/events';
import { fetchBlockedCountries } from "../../fetchLocalData";

const initialState = {
  games: [],
  platform: null,
  gameIds: [],
  bannerIds: [],
  genres: [],
  banners: [],
  bannersRotationList: {},
  errorData: null,
  installedGames: [],
  initialized: false,
  activeGenre: 'all',
  cdn: '',
  storeCDN: '',
  screenshots: {},
  testDevice: null,
  updateIntervalId: null,
  logo: '',
  promo: null,
  XPromoConfig: null,
  activeBanner: 0,
  activeXPromoBanner: 0,
  active: true,
  udid: null,
  blockedCountriesByGame: [],
  blockedGames: [],
  launchedGames: [],
};

function mainReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.INIT_LOADING:
      const {
        bannerIds,
        gameIds,
        platform,
        genres,
        games,
        banners,
        bannersRotationList,
        installedGames,
        launcherUpdating,
        settings,
        cdn,
        storeCDN,
        pgplApiPath,
        version,
        testDevice,
        revision,
        logo,
        promo,
        udid,
        xpromoId,
        xPromoBanners,
        blockedCountriesByGame,
        launchedGames,
        webViewVersion,
      } = action;
      return {
        ...state,
        bannerIds,
        gameIds,
        platform,
        genres,
        games,
        banners,
        bannersRotationList,
        installedGames,
        launcherUpdating,
        settings,
        initialized: true,
        cdn,
        storeCDN,
        pgplApiPath,
        version,
        testDevice,
        revision,
        logo,
        promo,
        udid,
        xpromoId,
        xPromoBanners,
        blockedCountriesByGame,
        launchedGames,
        webViewVersion,
      };
    case ACTIONS.UPDATE_INSTALLED_APPS:
      return {
        ...state,
        installedGames: action.installedGames,
      };
    case ACTIONS.UPDATE_ACTIVE_GENRE:
      return {
        ...state,
        activeGenre: action.activeGenre,
      };
    case ACTIONS.CHANGE_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    case ACTIONS.UPDATE_ON_RESUME:
      return {
        ...state,
        settings: action.settings,
        installedGames: action.installedGames,
        revision: action.revision,
      };
    case ACTIONS.START_SCREENSHOTS_LOADING:
      return {
        ...state,
        screenshots: {
          ...state.screenshots,
          [action.gameId]: {
            loading: true,
          },
        },
      };
    case ACTIONS.STOP_SCREENSHOTS_LOADING:
      return {
        ...state,
        screenshots: {
          ...state.screenshots,
          [action.gameId]: {
            loading: false,
          },
        },
      };
    case ACTIONS.SET_SCREENSHOTS_TO_GAME:
      const { gameId, screenshots } = action;
      return {
        ...state,
        screenshots: {
          ...state.screenshots,
          [gameId]: {
            loading: false,
            screenshots,
          },
        },
      };

    case ACTIONS.SET_LEFT_POSITION:
      return {
        ...state,
        leftPosition: action.left,
      };
    case ACTIONS.SET_ACTIVE_BANNER_INDEX:
      return {
        ...state,
        activeBanner: action.bannerIndex,
      };
    case ACTIONS.SET_ACTIVE_XPROMO_BANNER_INDEX:
      return {
        ...state,
        activeXPromoBanner: action.bannerIndex,
      };
    case ACTIONS.SET_ACTIVE_STATE:
      return {
        ...state,
        active: action.state,
      };
    case ACTIONS.SET_XPROMO_BANNERS:
      return {
        ...state,
        xPromoBanners: action.xPromoBanners,
      };
    case ACTIONS.SET_XPROMO_BANNERS_TO_FALLBACK:
      return {
        ...state,
        xPromoBanners: action.fallbackXPromoBanners,
      };
    case ACTIONS.SET_ACTIVE_ABTEST_GROUP:
      return {
        ...state,
        abTestGroup: action.testGroupData,
      };
    case ACTIONS.SET_OPEN_URI_COMMAND:
      return {
        ...state,
        openURICommand: action.command,
      };
    case ACTIONS.SET_USER_REGION:
      return {
        ...state,
        userRegion: action.regionCode
      }
    case ACTIONS.SET_BLOCKED_GAMES:
      const { blockedGames } = action;
      return {
        ...state,
        blockedGames,
      }
    case ACTIONS.SET_LAUNCHED_GAMES:
      const { launchedGamesList } = action;
      return {
        ...state,
        launchedGames: launchedGamesList,
      };
    case ACTIONS.ERROR:
      // todo: Later, during developing of the auth reducer, remove this action and create separate ERR reducer to handle various amount of errors
      return { ...state, errorData: action.error };

    default:
      return state;
  }
}

export const loadInitData = () => {
  return async dispatch => {
    try {
      // Config loading
      const {
        bannerIds,
        gameIds,
        eventIds,
        platform,
        cdn,
        version,
        testDevice,
        revision,
        storeCDN,
        activePromo,
        udid,
        pgplApiPath,
        xpromoId,
      } = await getConfig();

      // Settings loading
      const settings = await getSettings();

      // EXPConfig loading
      // const expConfig = await getEXPConfig();

      const blockedCountriesByGame = await fetchBlockedCountries();

      // Installed games loading
      const installedGames = await getInstalledGames();

      // Games, logo and genres loading
      const logo = await getLogo(platform);
      const genres = await getGenres();
      const games = await getGames(gameIds, platform);
      const promo = activePromo && (await getPromoData(activePromo));

      // Banners data loading
      const banners = await getBanners(bannerIds);
      const bannersRotationList = await getBannersRotationList(platform);

      const launchedGames = getLaunchedGameList();

      const webViewVersion = getWebViewVersion();

      dispatch({
        type: ACTIONS.INIT_LOADING,
        bannerIds,
        gameIds,
        platform,
        genres,
        games,
        banners,
        bannersRotationList,
        installedGames,
        settings,
        cdn,
        storeCDN,
        pgplApiPath,
        version,
        testDevice,
        revision,
        logo,
        promo,
        udid,
        xpromoId,
        blockedCountriesByGame,
        launchedGames,
        webViewVersion,
      });
      dispatch(setEventIds(eventIds));
    } catch (error) {
      dispatch({ type: ACTIONS.ERROR, error });
    }
  };
};

export const updateInstalledGames = () => {
  return async dispatch => {
    try {
      const installedGames = await getInstalledGames();
      dispatch({
        type: ACTIONS.UPDATE_INSTALLED_APPS,
        installedGames,
      });
    } catch (error) {
      dispatch({ type: ACTIONS.ERROR, error });
    }
  };
};

export const setAndUpdateSettings = settings => {
  return async dispatch => {
    try {
      const updatedSettings = await setSettings(settings);
      if (!updatedSettings) return;
      dispatch({ type: ACTIONS.CHANGE_SETTINGS, settings: updatedSettings });
    } catch (error) {
      dispatch({ type: ACTIONS.ERROR, error });
    }
  };
};

export const setActiveGenre = activeGenre => ({
  type: ACTIONS.UPDATE_ACTIVE_GENRE,
  activeGenre,
});

export const setActiveBannerIndex = bannerIndex => ({
  type: ACTIONS.SET_ACTIVE_BANNER_INDEX,
  bannerIndex,
});

export const setActiveXPromoBannerIndex = bannerIndex => ({
  type: ACTIONS.SET_ACTIVE_XPROMO_BANNER_INDEX,
  bannerIndex,
});

export const updateOnResume = () => {
  return async dispatch => {
    try {
      const { revision } = await clientLib.sendRequest('getConfig');

      const settings = await getSettings();
      const installedGames = await getInstalledGames();

      dispatch({
        type: ACTIONS.UPDATE_ON_RESUME,
        settings,
        installedGames,
        revision,
      });

      await dispatch(setNotifications());
      await dispatch(handleKeychainOnResume());
      await dispatch(setPushNotifications());
    } catch (error) {
      dispatch({ type: ACTIONS.ERROR, error });
    }
  };
};

export const setGameScreenshots = gameId => {
  return async (dispatch, getState) => {
    const { platform } = getState().main;
    try {
      dispatch({
        type: ACTIONS.START_SCREENSHOTS_LOADING,
        gameId,
      });
      const screenshots = await getScreenshots(platform, gameId);
      dispatch({
        type: ACTIONS.SET_SCREENSHOTS_TO_GAME,
        gameId,
        screenshots,
      });
    } catch (error) {
      dispatch({ type: ACTIONS.STOP_SCREENSHOTS_LOADING, gameId });
      dispatch({ type: ACTIONS.ERROR, error: ErrorTypes.SCREENSHOTS_LOADING_ERROR });
    }
  };
};

export const setLeftPosition = left => ({
  type: ACTIONS.SET_LEFT_POSITION,
  left,
});

export const reportUninstall = id => {
  return (dispatch, getState) => {
    const { xpromoId } = getState().main.games.find(game => game.packageId === id || game.xpromoId === id);
    reportGameUninstall(xpromoId);
  };
};

export const setActiveState = state => {
  return dispatch => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_STATE,
      state,
    });
  };
};

export const setError = error => ({ type: ACTIONS.ERROR, error });

export const getAndSetXPromoBanners = () => {
  return async dispatch => {
    const xPromoBanners = await getXPromoBanners();

    dispatch({
      type: ACTIONS.SET_ACTIVE_XPROMO_BANNER_INDEX,
      bannerIndex: 0,
    });

    dispatch({
      type: ACTIONS.SET_XPROMO_BANNERS,
      xPromoBanners,
    });
  };
};

export const clearXPromoBanners = () => {
  return dispatch => {
    dispatch({
      type: ACTIONS.SET_XPROMO_BANNERS,
      xPromoBanners: null,
    });
  };
};

export const setXPromoBannersToFallback = () => {
  return (dispatch, getState) => {
    const xpromoBanners = getState().main?.xPromoBanners;
    if (!xpromoBanners) return;
    const fallbackXPromoBanners = [];
    for (const xpromoBanner of xpromoBanners) {
      fallbackXPromoBanners.push({
        ...xpromoBanner,
        type: XPromoBannerTypes.FALLBACK,
      });
    }
    dispatch({
      type: ACTIONS.SET_XPROMO_BANNERS_TO_FALLBACK,
      fallbackXPromoBanners,
    });
  };
};

export const setActiveABTestGroup = testGroupData => ({
  type: ACTIONS.SET_ACTIVE_ABTEST_GROUP,
  testGroupData,
});

export const setOpenURICommand = (command) => ({
  type: ACTIONS.SET_OPEN_URI_COMMAND,
  command,
});

export const setCurrentUserRegion = regionCode => ({
  type: ACTIONS.SET_USER_REGION,
  regionCode
});

export const setBlockedGames = blockedGames => ({
  type: ACTIONS.SET_BLOCKED_GAMES,
  blockedGames
})

export const setLaunchedGame = gameId => {
  return dispatch => {
    const launchedGamesList = addLaunchedGame(gameId);
    localStorage.setItem(LOCAL_STORAGE_KEYS.LAUNCHED_GAMES, JSON.stringify(launchedGamesList));
    dispatch({
      type: ACTIONS.SET_LAUNCHED_GAMES,
      launchedGamesList,
    });
  };
};

export default mainReducer;
