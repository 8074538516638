import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { EVENT_AUTO_HEIGHT_MAX, ShadowPositionTypes } from '../../events/EventCard/EventCardModal/EventCardModal';

export default class EventCardScrollbars extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = { top: 0, data: props.children };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
    this.scrollbars = React.createRef();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ data: nextProps.children });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.recentlyStartedInstallationGameURI?.uri) return;
    if (
      prevProps.recentlyStartedInstallationGameURI.uri !== this.props.recentlyStartedInstallationGameURI.uri &&
      prevProps.recentlyStartedInstallationGameURI.timestamp !== this.props.recentlyStartedInstallationGameURI.timestamp
    ) {
      this.setState({ top: 1 }, () => this.scrollbars.current.scrollToBottom());
    }
  }

  handleUpdate(values) {
    const { top, scrollHeight } = values;

    if (EVENT_AUTO_HEIGHT_MAX < scrollHeight) {
      if (top === 0) {
        this.props.setShadow(ShadowPositionTypes.BOTTOM);
        return;
      }
      if (top >= 1) {
        this.props.setShadow(ShadowPositionTypes.TOP);
        return;
      }
      this.props.setShadow(ShadowPositionTypes.BOTH);
    }
  }

  renderView({ style, ...props }) {
    return <div className="box" style={{ ...style }} {...props} />;
  }

  renderTrack({ style, ...props }) {
    const thumbStyle = {
      right: 6,
      bottom: 2,
      top: 32,
      paddingTop: 0,
      paddingBottom: 0,
    };
    return <div className="track-vertical" style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#C2C2C2',
      zIndex: 1100,
      borderRadius: 27,
      width: '8px',
    };
    return <div className="thumb-vertical" style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderThumbHorizontal({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#7A7A7A',
      zIndex: 1100,
      borderRadius: 3,
      height: '4px',
    };
    return <div className="thumb-horizontal" style={{ ...style, ...thumbStyle }} {...props} />;
  }

  render() {
    return (
      <Scrollbars
        ref={this.scrollbars}
        renderView={this.renderView}
        renderTrackVertical={this.renderTrack}
        renderThumbVertical={this.renderThumb}
        renderThumbHorizontal={this.renderThumbHorizontal}
        onUpdate={this.handleUpdate}
        {...this.props}>
        {this.state.data}
      </Scrollbars>
    );
  }
}
