import { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  selectAvailableUpdate,
  selectDeferredUpdate,
  selectUpdatingStatus,
  selectCurrentVersion,
  selectUpdateError,
} from '../store/selectors';
import { compareLauncherVersions } from "../services/Update";
import { LAUNCHER_UPDATE_STATUS, LOCAL_STORAGE_KEYS } from '../constants';
import { completeUpdate } from '../store/dux/update';

export default function useUpdate() {
  const updateData = useSelector(selectAvailableUpdate, shallowEqual);
  const deferredUpdate = useSelector(selectDeferredUpdate);
  const currentVersion = useSelector(selectCurrentVersion);
  const updatingVersionFromStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.LAUNCHER_UPDATING);
  const updating = useSelector(selectUpdatingStatus);
  const updateError = useSelector(selectUpdateError);

  const [status, setStatus] = useState(null);

  const dispatch = useDispatch();

  // Leaving simple update ability to future iterations
  useEffect(
    function handleUpdateData() {
      if (updateError) {
        if (updateData.forceUpdate) return setStatus(LAUNCHER_UPDATE_STATUS.ERROR_FORCE_UPDATE);
        return setStatus(LAUNCHER_UPDATE_STATUS.ERROR);
      }

      //todo:  async data fetching works fine in not-yet-reviewed dev branch, remove currentVersion later
      if (updatingVersionFromStorage && currentVersion) {
        const { version: versionFromStorage } = JSON.parse(updatingVersionFromStorage);
        if (!compareLauncherVersions(versionFromStorage, currentVersion)) {
          dispatch(completeUpdate());
          return setStatus(LAUNCHER_UPDATE_STATUS.CONGRATULATIONS);
        } else {
          return setStatus(LAUNCHER_UPDATE_STATUS.UPDATING);
        }
      }

      if (status === LAUNCHER_UPDATE_STATUS.CONGRATULATIONS) {
        return setTimeout(() => {
          setStatus(null);
        }, 7000);
      }

      if (updating) {
        return setStatus(LAUNCHER_UPDATE_STATUS.UPDATING);
      }

      if (!updateData) {
        return setStatus(null);
      }

      {/*
        if (deferredUpdate && !updateData.forceUpdate) {
          if (deferredUpdate === "deferredSession") return setStatus(null);
          // can be changed to 86400000
          const DayInMs = 24 * 60 * 60 * 1000;
          const expired = new Date().getTime() - deferredUpdate > DayInMs;
          if (!expired) {
            return setStatus(null);
          }
        }
      */}

      if (updateData.forceUpdate) {
        setStatus(LAUNCHER_UPDATE_STATUS.FORCE_UPDATE);
      } else {
        // setStatus(LAUNCHER_UPDATE_STATUS.UPDATE);
      }
    },
    [updateData, deferredUpdate, updating, updatingVersionFromStorage, updateError],
  );

  return { status, setStatus, updateData };
}
