import { AuthActionTypes, IAuthActionTypes } from './actionTypes';
import { serviceLogger } from '../../../logger';
import { EmailToConfirmType } from '../../../Profile/pages/Profile/Profile';
import { getLastPlayer, setLastPlayer } from '../../../services/Keychain';
import PGPLService from '../../../Profile/services/PGPLService';
import { isUserDefault } from '../../../Profile/utils/profile';
import { LOCAL_STORAGE_KEYS } from "../../../constants";

const initialState = {
  keychain: {
    id: null,
    token: null,
  },
  profile: {},
  webToken: null,
  sessionToken: null,
  offline: false,
  offlineInterval: null,
  profileGames: null,
};

export type ConsentStatusType = {
  status: 'confirmed' | 'unconfirmed' | 'unknown';
  version: string;
};

export type ProfileType = {
  id: string;
  name: string;
  nick?: string;
  creationTime?: number;
  picture?: string;
  frame?: Object;
  email?: string;
  birthday?: string;
  gender?: string;
  locale?: string;
  country?: string;
  subdivision?: string;
  city?: string;
  cityName?: string;
  nameIsCustom?: boolean;
  passwordIsCustom?: boolean;
  rewardIsBanned?: boolean;
  isTester?: boolean;
  isCheater?: boolean;
  isVerified?: boolean;
  viralInstallRewardIsBanned?: boolean;
  isEmailInvitationDisabled?: boolean;
  unlockedPictureIds?: string[];
  unlockedFrameIds?: string[];
  gameIds?: number[];
  migrationSourceGameId: number;
  wishlist: string[];
  friendCount: number;
  paymentSum: number;
  paymentCategory: string;
  paymentCategoryFeedbackTag: string;
  playerStats: Object;
  ingameStats: Object;
  subscription: {
    id: string;
    expireTime: 0;
  };
  subscriptions: [
    {
      id: string;
      expireTime: number;
    },
  ];
  consumedProfileRewards: string[];
  underageConfirmStatus: 'confirmed';
  underageConfirmTime: number;
  hasEmail: boolean;
  hasUnconfirmedEmail: boolean;
  unconfirmedEmail?: EmailToConfirmType;
  consentInfo: ConsentStatusType;
  resentConfirmation: boolean;
};

type AuthState = typeof initialState;

function authReducer(state: AuthState = initialState, action: IAuthActionTypes) {
  switch (action.type) {
    case AuthActionTypes.SET_KEYCHAIN:
      const { id, token } = action;
      return {
        ...state,
        keychain: {
          id,
          token,
        },
      };
    case AuthActionTypes.SET_WEBTOKEN:
      const { webToken } = action;
      return {
        ...state,
        webToken,
      };
    case AuthActionTypes.SET_SESSION_TOKEN:
      const { sessionToken } = action;
      return {
        ...state,
        sessionToken,
      };
    case AuthActionTypes.CLEAR_WEBTOKEN:
      return {
        ...state,
        webToken: null,
      };
    case AuthActionTypes.SET_PROFILE_DATA:
      const { profile } = action;
      return {
        ...state,
        profile,
      };
    case AuthActionTypes.SET_PROFILE_EMAIL:
      const { email } = action;
      return {
        ...state,
        profile: {
          ...state.profile,
          email,
        },
      };
    case AuthActionTypes.SET_PROFILE_PICTURE:
      const { picture } = action;
      return {
        ...state,
        profile: {
          ...state.profile,
          picture,
        },
      };
    case AuthActionTypes.UPDATE_PROFILE_DATA:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.data,
        },
      };
    case AuthActionTypes.SET_RESENT_CONFIRMATION:
      return {
        ...state,
        profile: {
          ...state.profile,
          resentConfirmation: true,
        },
      };
    case AuthActionTypes.CLEAN:
      return initialState;
    case AuthActionTypes.SET_OFFLINE_STATUS:
      return {
        ...state,
        offline: action.status,
        ...(!action.status && { offlineInterval: null }),
      };
    case AuthActionTypes.SET_OFFLINE_INTERVAL:
      return {
        ...state,
        offlineInterval: action.interval,
      };
    case AuthActionTypes.SET_INVALID_ACCESS_TOKEN_PLAYER_ID:
      return {
        ...state,
        keychain: null,
        profile: {},
        webToken: null,
        profileGames: null,
        ...(action.id && { invalidAccessTokenPlayerId: action.id }),
        invalidAccessToken: action.status,
      };
    case AuthActionTypes.SET_PGPL_GAMES_DATA:
      return {
        ...state,
        profileGames: action.data,
      };
    default:
      return state;
  }
}

export const setProfilePicture = picture => ({
  type: AuthActionTypes.SET_PROFILE_PICTURE,
  picture,
});

export const updateProfileData = data => ({
  type: AuthActionTypes.UPDATE_PROFILE_DATA,
  data,
});

export const clearProfileData = () => ({
  type: AuthActionTypes.CLEAN,
});

export const setProfileData = (profile) => {
  return dispatch => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.AB_TEST_PGPL_ID, profile.id);
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_LOGIN_NAME, profile.name);
    dispatch({
      type: AuthActionTypes.SET_PROFILE_DATA,
      profile,
    });
  }
};

export const getKeychainUser = () => {
  return async (dispatch, getState) => {
    try {
      const keychain = getState()?.auth?.keychain;
      // const sessionToken = getState()?.auth?.sessionToken;

      const gameToken = keychain?.token;
      if (!gameToken) {
        { /*
        if (sessionToken) {
          const gameTokenFromSessionToken = await PGPLService.loginWithAccessToken(sessionToken);
          const webToken = await PGPLService.loginWebWithAccessToken(gameTokenFromSessionToken);
          if (!webToken) return;

          dispatch({
            type: AuthActionTypes.SET_WEBTOKEN,
            webToken,
          });
          const profileFromSessionToken = await PGPLService.getPlayerInfo();

          dispatch(setProfileData(profileFromSessionToken));
          await setLastPlayer(profileFromSessionToken.id, webToken);
        } */ }
        return;
      }

      const webToken = await PGPLService.loginWebWithAccessToken(gameToken);

      // if (!webToken && !sessionToken) return;
      if (!webToken) return;

      let profile;
      if (webToken) {
        dispatch({
          type: AuthActionTypes.SET_WEBTOKEN,
          webToken,
        });

        profile = await PGPLService.getPlayerInfo();
        dispatch(setProfileData(profile));
        await setLastPlayer(profile.id, webToken);
      }

      { /* if (!profile || isUserDefault(profile)) {
        const gameTokenFromSessionToken = await PGPLService.loginWithAccessToken(sessionToken);
        const webTokenFromSessionToken = await PGPLService.loginWebWithAccessToken(gameTokenFromSessionToken);
        let profileFromSessionToken;
        if (webTokenFromSessionToken) {
          dispatch({
            type: AuthActionTypes.SET_WEBTOKEN,
            webToken: webTokenFromSessionToken,
          });

          profileFromSessionToken = await PGPLService.getPlayerInfo();
        }

        if (!isUserDefault(profileFromSessionToken)) {
          dispatch(setProfileData(profileFromSessionToken));
          await setLastPlayer(profileFromSessionToken.id, webTokenFromSessionToken);
          return;
        } else {
          dispatch({
            type: AuthActionTypes.SET_WEBTOKEN,
            webToken,
          });
        }
      }

      dispatch(setProfileData(profile));
      await setLastPlayer(profile.id, webToken); */ }
    } catch (error) {
      serviceLogger.error('getKeychainUser', error);
    }
  };
};

export const setKeychainData = (id, token) => ({
  type: AuthActionTypes.SET_KEYCHAIN,
  id,
  token,
});

export const getAndSetKeychainData = () => {
  return async dispatch => {
    const data = await getLastPlayer();
    if (data) {
      const { id, token } = data;
      dispatch(setKeychainData(id, token));
    }
  };
};
export const handleKeychainOnResume = () => {
  return async (dispatch, getState) => {
    const currentKeychain = await getLastPlayer();
    const storedKeychainToken = getState()?.auth?.keychain?.token;
    const currentProfileId = getState()?.auth?.profile.id;

    if (currentKeychain.id === currentProfileId) {
      dispatch(checkEmailConfirmationStatus());
    }

    if (currentKeychain?.token && currentKeychain?.token === storedKeychainToken) return;

    dispatch(setKeychainData(currentKeychain.id, currentKeychain.token));
    dispatch(getKeychainUser());
  };
};

export const setInvalidAccessTokenPlayerID = (id, status?) => ({
  type: AuthActionTypes.SET_INVALID_ACCESS_TOKEN_PLAYER_ID,
  id,
  status,
});

export const setSessionToken = token => ({
  type: AuthActionTypes.SET_SESSION_TOKEN,
  token,
});

export const setOfflineStatus = status => ({
  type: AuthActionTypes.SET_OFFLINE_STATUS,
  status,
});

export const launchOfflineCheckInterval = () => {
  return (dispatch, getState) => {
    const interval = setInterval(async () => {
      const { offline } = getState().auth;
      if (!offline) return clearInterval(interval);
      const onlineServerTimeStamp = await PGPLService.getServerInfo();
      if (onlineServerTimeStamp) {
        clearInterval(interval);
        dispatch(setOfflineStatus(false));
      }
    }, 10000);
    dispatch({
      type: AuthActionTypes.SET_OFFLINE_INTERVAL,
      interval,
    });
  };
};

export const setPGPLGamesData = data => ({
  type: AuthActionTypes.SET_PGPL_GAMES_DATA,
  data,
});

export const getAndSetPGPLGamesData = () => {
  return async (dispatch, getState) => {
    try {
      const profilePGPLGameIDs = getState()?.auth?.profile?.gameIds;
      const offline = getState()?.auth?.offline;
      if (offline) return;
      if (!profilePGPLGameIDs.length) {
        return dispatch(setPGPLGamesData(null));
      }
      const profilePGPLGamesData = await Promise.all(
        profilePGPLGameIDs.map(async gameId => await PGPLService.getIngameInfo(gameId)),
      );
      dispatch(setPGPLGamesData(profilePGPLGamesData));
    } catch (error) {
      serviceLogger.error('getPGPLGamesData', error);
    }
  };
};

export const checkEmailConfirmationStatus = () => {
  return async (dispatch, getState) => {
    const unconfirmedEmailExists = getState()?.auth?.profile?.unconfirmedEmail?.email;
    if (unconfirmedEmailExists) {
      const emailStatus = await PGPLService.getLastEmailUpdateRequest();
      if (!emailStatus) {
        dispatch(updateProfileData({ email: unconfirmedEmailExists, unconfirmedEmail: null }));
      }
    }
  };
};

export default authReducer;
