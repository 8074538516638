import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Parag } from '../../../commons/Parag';
import { Headline } from '../../../commons/Headline';
import { Button } from '../../../commons/Button';
import { useHistory } from 'react-router-dom';
import LockIcon from '../../../../assets/images/svg/lock.svg';
import { TextButton } from '../../../commons/TextButton';
import { renderLogger } from '../../../../../logger';
import { ProfileContext, AgeModalStatus } from '../../../../pages/Profile/Profile';
import { updateProfileData } from '../../../../../store/dux/auth/auth';
import useSiteLinks from '../../../../../hooks/useSiteLinks';
import { openLink } from '../../../../../helpers';
import PGPLService from "../../../../services/PGPLService";
import { AuthActionTypes } from "../../../../../store/dux/auth/actionTypes";
import { G5LAUNCHER_ROUTES } from "../../../../../constants";

function ModalAgeAccess() {
  const { underageConfirmStatus, setIsLoading, setEditingUnderageDate, setAgeModal, toggleEditProfile, callbackRef } =
    useContext(ProfileContext);
  const { privacyPolicyLink } = useSiteLinks();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const okLetsGo = () => history.push(G5LAUNCHER_ROUTES.ALL_GAMES);

  const handleUnderAge = async () => {
    try {
      await callbackRef.current();
      const profile = await PGPLService.getPlayerInfo();
      dispatch({
        type: AuthActionTypes.SET_PROFILE_DATA,
        profile,
      });
      setAgeModal(AgeModalStatus.CLOSED);
    } catch (error) {
      renderLogger.error('handleUnderAge()', error);
    } finally {
      setIsLoading(false);
    }
  };

  const chooseBirthDate = () => {
    setEditingUnderageDate(true);
    setIsLoading(false);
    setAgeModal(null);
    toggleEditProfile(true);

  };

  const notYetClosed = underageConfirmStatus !== 'confirmed';

  return (
    <>
      <div className="center-inline">
        <img className="modal__icon" src={LockIcon} width="60" height="60" alt="" />
      </div>
      <Headline headlineLevel="p" size="4" variant="primary" className="modal__headline center-inline">
        {notYetClosed ? t<string>('profile.titles.important') : t<string>('profile.titles.account_closed')}
      </Headline>
      <Parag variant="primary" className="modal__parag">
        {notYetClosed ? t<string>('profile.message.under_1') : t<string>('profile.message.closed_under16')}
      </Parag>
      {notYetClosed && <Parag>{t<string>("profile.message.under_2")}</Parag>}
      <div className="center-inline">
        <TextButton
          type="button"
          variant="primary"
          className="modal__text-button"
          onClick={() => openLink(privacyPolicyLink)}>
          {t<string>('profile.buttons.review_pp')}
        </TextButton>
      </div>
      <div className="modal__btns modal__btns--column">
        <Button type="button" className="modal__btn" variant="primary" sizeMode="6" onClick={chooseBirthDate}>
          {t<string>('profile.buttons.set_birth_date')}
        </Button>
        {notYetClosed ? (
          <Button type="button" className="modal__btn" variant="secondary" sizeMode="6" onClick={handleUnderAge}>
            {t<string>('profile.buttons.yes_im_under')}
          </Button>
        ) : (
          <Button type="button" className="modal__btn" variant="secondary" sizeMode="6" onClick={okLetsGo}>
            {t<string>('profile.buttons.ok')}
          </Button>
        )}
      </div>
    </>
  );
}

export default ModalAgeAccess;
