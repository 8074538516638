import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ConsentStatusType } from '../../store/dux/auth/auth';
import { emailDomainG5EcomTest } from "../utils/validators";

const useCompleteRegistrationForm = (unconfirmedEmail, passwordIsCustom: boolean, consentStatus: ConsentStatusType) => {
  const { t } = useTranslation();
  const completeRegistrationSchema = useMemo(
    () =>
      yup.object().shape({
        ...(!unconfirmedEmail?.email && {
          email: yup
            .string()
            .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.email') }))
            .email(t('profile.message.not_valid', { attribute: t('profile.words.email') }))
            .test(emailDomainG5EcomTest),
        }),
        ...(consentStatus?.status !== 'confirmed' && {
          confirm: yup.boolean().oneOf([true], t('profile.message.need_to_confirm')),
        }),
        ...(!passwordIsCustom && {
          password: yup
            .string()
            .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.password') }))
            .min(4, t('profile.message.password_length_error', { n: 4, m: 32 }))
            .max(32, t('profile.message.password_length_error', { n: 4, m: 32 })),
        }),
      }),
    [passwordIsCustom, unconfirmedEmail, consentStatus],
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(completeRegistrationSchema),
  });

  return {
    register,
    handleSubmit,
    control,
    errors,
    setError,
  };
};

export default useCompleteRegistrationForm;
