import styles from './Progress.module.scss';

type ProgressTypeProps = {
  progress: number;
  methodType: string;
};

const Progress = ({ progress, methodType }: ProgressTypeProps) => {
  const progressToCSS = `${progress > 7 ? progress : progress !== 0 ? 7 : 0}%`;
  return (
    <div className={styles.Progress__wrapper}>
      <span className={`${styles.Progress__bar} ${styles[`Progress__bar-${methodType}`]}`} style={{ width: progressToCSS }} />
    </div>
  );
};

export default Progress;
