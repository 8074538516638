import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import styles from './PushCard.module.scss';
import { PushNotificationType } from '../../../services/PushNotifications';
import { selectCurrentLang, selectGameByXPromoID } from '../../../store/selectors';
import { checkIsNotExpiredByMs, reportPushNotificationClick } from '../../../helpers';
import {
  deletePushNotificationByUid,
  markPushNotificationAsRead,
} from '../../../store/dux/pushNotifications/pushNotifications';
import { getTimeAgo } from '../../../services/Time';
import { REFERRAL_SOURCES } from '../../../constants';

type PushNotificationPropsType = {
  pushNotification: PushNotificationType;
};

const PushCard = ({ pushNotification }: PushNotificationPropsType) => {
  const { t } = useTranslation();
  const readRef = useRef<boolean>(!pushNotification.read);
  const targetGame = useSelector(selectGameByXPromoID(pushNotification.targetAppXpromoId));
  const lang = useSelector(selectCurrentLang);
  const history = useHistory();
  const dispatch = useDispatch();

  const targetGameName = targetGame?.lockit[lang]?.name || targetGame?.lockit['en']?.name;

  const timeAgo = getTimeAgo(pushNotification.time);

  useEffect(function pushNotificationIntervalCheck() {
    const checkInterval = setInterval(() => {
      const available = checkIsNotExpiredByMs(pushNotification.expirationTime);
      if (readRef.current && available) {
        readRef.current = false;
        dispatch(markPushNotificationAsRead(pushNotification.uid));
      }
      if (!available) {
        dispatch(deletePushNotificationByUid(pushNotification.uid));
        clearInterval(checkInterval);
      }
    }, 7000);
    return () => clearInterval(checkInterval);
  }, []);

  const handlePushClick = () => {
    reportPushNotificationClick(pushNotification.id, pushNotification.targetAppXpromoId);
    history.push({
      pathname: `/${targetGame.id}`,
      state: { id: targetGame.id, startGame: true, key: Date.now() },
      search: REFERRAL_SOURCES.INSTALL.NOTIFICATION,
    });
  };

  return (
    <article className={styles.pushCard} onClick={handlePushClick}>
      <header>
        <img src={`../../${targetGame.iconImage}`} alt={targetGameName} />
        <h2 dangerouslySetInnerHTML={{ __html: targetGameName }} />
        <time>
          {timeAgo} {!pushNotification.read ? <span>{t<string>('pushnotifications.new')}</span> : ''}
        </time>
      </header>
      <section>
        {pushNotification.message?.image && <img src={pushNotification.message.image} alt={targetGameName} />}
        <p>
          <h3>{pushNotification.message?.header}</h3>
          {pushNotification.message?.text && <span>{pushNotification.message.text}</span>}
        </p>
      </section>
    </article>
  );
};

export default PushCard;
