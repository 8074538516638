import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';
import { setAndUpdateSettings } from '../../../../store/dux/main';
import { selectSettingsParameterStatus } from '../../../../store/selectors';
import styles from './FormFieldCheckbox.module.scss';
import { ReactComponent as QuestionIcon } from '../../../../assets/images/question.svg';
import Tooltip from '../../header/tooltip/Tooltip';

function FormFieldCheckbox({ title, parameter, tooltip }) {
  const [hovering, setHovering] = useState(false);
  const { t } = useTranslation();
  const checked = useSelector(selectSettingsParameterStatus(parameter));
  const dispatch = useDispatch();

  function handleChange(event) {
    dispatch(setAndUpdateSettings({ [parameter]: event.target.checked }));
  }

  return (
    <div className={`${styles.form__group} ${styles.checkbox}`}>
      <div className={styles['checkbox__container']}>
        <div className={styles['checkbox__heating']}>
          <span className={styles['checkbox__title']}>{title}</span>
          {tooltip && (
            <span
              className={styles['checkbox__tooltip']}
              onMouseOver={() => setHovering(true)}
              onMouseLeave={() => setHovering(false)}>
              <QuestionIcon />
              {hovering && (
                <Tooltip
                  text={tooltip}
                  top={35}
                  passedStyles={{ whiteSpace: 'pre-line', width: '300px', textAlign: 'left', padding: '8px' }}
                />
              )}
            </span>
          )}
        </div>
        <label className={`${styles['checkbox__wrapper']} ${checked && styles['active']}`} htmlFor={parameter}>
          <div className={`${styles['checkbox__state']} ${styles['checkbox__state--left']}`}>
            <span>{t('form.checkbox.active')}</span>
          </div>
          <div className={`${styles['checkbox__state']} ${styles['checkbox__state--center']}`}>
            <span className={styles['checkbox__thumb--wrapper']}>
              <span className={styles['checkbox__thumb']}>
                <input
                  className={styles['checkbox__field']}
                  type="checkbox"
                  name={parameter}
                  id={parameter}
                  checked={checked}
                  onChange={handleChange}
                />
              </span>
            </span>
          </div>
          <div className={`${styles['checkbox__state']} ${styles['checkbox__state--right']}`}>
            <span>{t('form.checkbox.inactive')}</span>
          </div>
        </label>
      </div>
    </div>
  );
}

export default FormFieldCheckbox;
