import clientLib from '../clientLib';
import { serviceLogger } from '../logger';
import { ReadPushNotificationsStorage } from './Storage';
import { LOCAL_STORAGE_KEYS } from '../constants';

type XMLParsedDataType = {
  header: string;
  text: string;
  image: string;
};

export type PushNotificationType = {
  expirationTime: number;
  id: string;
  message: XMLParsedDataType;
  targetAppXpromoId: string;
  time: number;
  uid: string;
  read: boolean;
};

const parseXMLContent = (xml): XMLParsedDataType => {
  const parser = new DOMParser();
  const parsedXML = parser.parseFromString(xml, 'text/xml');
  const textData = Array.from(parsedXML.getElementsByTagName('text')).map(node => {
    return node.childNodes[0].nodeValue;
  });
  const image = parsedXML.getElementsByTagName('image')[0]?.getAttribute('src');
  return {
    header: textData[0],
    text: textData[1],
    image: image,
  };
};

const readPushNotificationsStorage = new ReadPushNotificationsStorage(LOCAL_STORAGE_KEYS.READ_PUSH_NOTIFICATIONS);

export const preparePushNotification = (unCookedPushNotification): PushNotificationType => {
  const parsedXML = parseXMLContent(unCookedPushNotification.notification.message);
  const read = readPushNotificationsStorage.getReadStatus(unCookedPushNotification.uid);

  return {
    uid: unCookedPushNotification.uid,
    id: unCookedPushNotification.notification.id,
    message: parsedXML,
    targetAppXpromoId: unCookedPushNotification.notification.targetAppXpromoId,
    expirationTime: unCookedPushNotification.notification.expirationTime * 1000,
    time: unCookedPushNotification.notification.time * 1000,
    read,
  };
};

export const getPushNotifications = async (): Promise<PushNotificationType[] | void> => {
  try {
    const argId = `init_${Date.now()}`;
    const response = await clientLib.sendRequest('getNotifications', null, argId);
    return response.map(n => preparePushNotification(n));
  } catch (error) {
    serviceLogger.error('getPushNotifications()', JSON.stringify(error));
  }
};

export const deletePushNotification = async (uid: string) => {
  const requestResult = await clientLib.sendRequest('deleteNotification', { uid });
  if (requestResult !== 'completed') {
    throw new Error('Request has not been completed successfully');
  }
};
