import clientLib from "../clientLib";
import { serviceLogger } from "../logger";

export const getInstallationProgress = async id => {
  try {
    const progress = await clientLib.sendRequest('getInstallProgress', { id });
    return progress;
  } catch (error) {
    serviceLogger.error('getInstallationProgress()', JSON.stringify(error));
    throw new Error(error);
  }
};