import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from '../../assets/images/cross.svg';
import { ReactComponent as LonelyDing } from '../../assets/images/lonelyDing.svg';
import NotificationColoredScrollbars from '../shared/scrollbar/NotificationColoredScrollbars';
import PushCard from './PushCard/PushCard';
import { selectPushNotifications } from '../../store/dux/pushNotifications/selectors';
import styles from './pushNotifications.module.scss';
import Tooltip from '../shared/header/tooltip/Tooltip';
import { setPushNotificationsPanelOpen } from '../../store/dux/pushNotifications/pushNotifications';
import { LOGGING_PAGE_IDS } from '../../constants';
import { reportMessage, transformUsageTime } from '../../helpers';

const PushNotificationsContainer = () => {
  const pushNotifications = useSelector(selectPushNotifications);
  const [hovering, setHovering] = useState<boolean>(false);
  const dispatch = useDispatch();
  const activePushNotificationTimeRef = useRef<number>(Date.now());
  const { t } = useTranslation();

  useEffect(function logPushNotification() {
    reportMessage(`g5ln_ui_event('${LOGGING_PAGE_IDS.PUSH_NOTIFICATIONS}', '', 'open', 0, '', '')`);
    return () =>
      reportMessage(
        `g5ln_ui_event('${LOGGING_PAGE_IDS.PUSH_NOTIFICATIONS}', '', 'close', ${transformUsageTime(
          activePushNotificationTimeRef.current,
        )}), '', ''`,
      );
  }, []);

  return (
    <>
      <header>
        <h1>{t<string>('pushnotifications.title')}</h1>
        <div
          className={styles.close}
          onClick={() => dispatch(setPushNotificationsPanelOpen(false))}
          onMouseOver={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}>
          {hovering && (
            <Tooltip
              text={t<string>('pushnotifications.close')}
              passedStyles={{ top: '45px', marginRight: '-40px', left: 'unset' }}
            />
          )}
          <Close />
        </div>
      </header>
      <NotificationColoredScrollbars autoHeight autoHeightMax="100%">
      <div className={styles.wrapper}>
          {pushNotifications ? (
            pushNotifications.map(pushNotification => (
              <PushCard key={pushNotification.uid} pushNotification={pushNotification} />
            ))
          ) : (
            <div className={styles.nothing}>
              <LonelyDing />
              <h3>{t<string>('pushnotifications.nothing')}</h3>
            </div>
          )}

      </div>
      </NotificationColoredScrollbars>
    </>
  );
};

export default PushNotificationsContainer;
