import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InstalledGamesList from '../../../../components/pages-content/installed-games/list/InstalledGamesList';
import { selectNotInstalledGames } from '../../../../store/selectors';

import './styles/index.scss';
import { GameType } from "../GameCard/GameCard";

type RecommendedGamesProps = {
  passedGames?: GameType[];
  numberOfGames?: number;
  passedSource: string;
};

const RecommendedGames = ({ passedGames, numberOfGames, passedSource }: RecommendedGamesProps) => {
  const { t } = useTranslation();
  const notInstalledGames = useSelector(state => selectNotInstalledGames(state, numberOfGames));

  if(!passedGames && notInstalledGames.length === 0) return null;

  return (
    <section className='recommended-games'>
      <InstalledGamesList
        {...{
          title: t('games.installed.full.similar.title'),
          games: passedGames || notInstalledGames,
          clientLibInstalledApps: [],
        }}
        passedSource={passedSource}
      />
    </section>
  );
};

export default RecommendedGames;
