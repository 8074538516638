/* eslint-disable import/no-cycle */
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import main from './dux/main';
import modal from './dux/modal';
import progress from './dux/progress';
import notifications from './dux/notifications';
import takeoverBanners from  './dux/takeoverBanners';
import update from './dux/update';
import auth from './dux/auth/auth';
import pushNotifications from "./dux/pushNotifications/pushNotifications";
import events from './dux/events/events';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  main,
  modal,
  progress,
  notifications,
  takeoverBanners,
  update,
  auth,
  pushNotifications,
  events,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
