/* eslint-disable import/no-cycle */
import ACTIONS from './actions';
import { LAUNCHER_DOWNLOAD_LINKS, LOCAL_STORAGE_KEYS } from '../../constants';
import { getInstallationProgress } from '../../services/Progress';
import clientLib from '../../clientLib';
import { serviceLogger } from '../../logger';
import { debugLogData } from '../../helpers';
import { getUpdate } from '../../services/Update';

const initialState = {
  update: null,
  deferredUpdate: null,
  updating: false,
  launcherUpdating: 0,
  updateIntervalId: null,
};

function appUpdateReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_APP_UPDATE_STATUS_ONLOAD:
      const { update, deferredUpdate } = action;
      return {
        ...state,
        update,
        deferredUpdate,
      };
    case ACTIONS.SET_DEFER_UPDATE_STATUS:
      return {
        ...state,
        deferredUpdate: action.time,
      };
    case ACTIONS.CLEAR_DEFERRED_UPDATE:
      return {
        ...state,
        deferredUpdate: null,
      };
    case ACTIONS.SET_UPDATE_STARTED:
      return {
        ...state,
        updating: true,
        deferredUpdate: null,
        update_error: false,
      };
    case ACTIONS.UPDATE_LAUNCHER_UPDATING_PROGRESS:
      return {
        ...state,
        launcherUpdating: action.launcherUpdating,
        updateIntervalId: action.updateIntervalId,
      };
    case ACTIONS.COMPLETE_UPDATE:
      return {
        ...state,
        updating: false,
        update_error: false,
        update: null,
        deferredUpdate: null,
        launcherUpdating: 0,
      };
    case ACTIONS.COMPLETE_UPDATE_WITH_ERROR:
      const whatToDefer = state.deferredUpdate || 'deferredSession';
      return {
        ...state,
        updating: false,
        update_error: false,
        deferredUpdate: whatToDefer,
      };
    case ACTIONS.UPDATE_ERROR:
      return {
        ...state,
        updating: false,
        update_error: true,
        launcherUpdating: 0,
        update: {
          ...state.update,
          reqId: null,
        },
      };
    default:
      return state;
  }
}

export const checkAppUpdateStatus = () => {
  return async (dispatch, getState) => {
    const { platform, version } = getState().main;
    const update = await getUpdate(platform, version);
    const deferredUpdate = Number(localStorage.getItem(LOCAL_STORAGE_KEYS.DEFERRED_LAUNCHER_UPDATE));
    dispatch({
      type: ACTIONS.SET_APP_UPDATE_STATUS_ONLOAD,
      update,
      deferredUpdate,
    });
  };
};

export const deferUpdate = () => {
  return dispatch => {
    const time = new Date().getTime();
    localStorage.setItem(LOCAL_STORAGE_KEYS.DEFERRED_LAUNCHER_UPDATE, time.toString());
    dispatch({
      type: ACTIONS.SET_DEFER_UPDATE_STATUS,
      time,
    });
  };
};

export const clearDeferredUpdate = () => {
  return dispatch => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.DEFERRED_LAUNCHER_UPDATE);
    dispatch({
      type: ACTIONS.CLEAR_DEFERRED_UPDATE,
    });
  };
};

export const completeUpdate = () => {
  return dispatch => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.LAUNCHER_UPDATING);
    dispatch({
      type: ACTIONS.COMPLETE_UPDATE,
    });
  };
};

export const completeUpdateWithError = () => {
  return dispatch => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.LAUNCHER_UPDATING);
    dispatch({
      type: ACTIONS.COMPLETE_UPDATE_WITH_ERROR,
    });
  };
};

export const handleUpdatingProgress = id => {
  return async dispatch => {
    try {
      const progress = await getInstallationProgress(id);
      if (!progress) return;
      dispatch({
        type: ACTIONS.UPDATE_LAUNCHER_UPDATING_PROGRESS,
        launcherUpdating: progress,
      });
    } catch (error) {
      dispatch({
        type: ACTIONS.UPDATE_INSTALLATION_PROGRESS_WITH_ERROR,
        error: true,
      });
    }
  };
};

export const setUpdateError = updateIntervalId => {
  return dispatch => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.LAUNCHER_UPDATING);
    updateIntervalId && clearInterval(updateIntervalId);
    dispatch({
      type: ACTIONS.UPDATE_ERROR,
    });
  };
};

export const startUpdate = () => {
  return (dispatch, getState) => {
    const { update } = getState().update;
    const { testDevice } = getState().main;

    const packageURI = testDevice ? LAUNCHER_DOWNLOAD_LINKS.APPX.TEST : LAUNCHER_DOWNLOAD_LINKS.APPX.PRODUCTION;

    const reqId = clientLib._getRequestId() + Date.now();

    localStorage.setItem(LOCAL_STORAGE_KEYS.LAUNCHER_UPDATING, JSON.stringify({ ...update, reqId }));
    dispatch({
      type: ACTIONS.SET_UPDATE_STARTED,
    });

    let updateIntervalId;

    clientLib.sendRequest('launchUpdate', { packageURI }, reqId).catch(error => {
      serviceLogger.error(`updateLauncher()`, error);
      dispatch(setUpdateError(updateIntervalId));
    });

    debugLogData(`updateLauncher()`, serviceLogger);

    setTimeout(() => {
      updateIntervalId = setInterval(() => {
        dispatch(handleUpdatingProgress(reqId, updateIntervalId));
      }, 500);
    }, 200);
  };
};

export const continueUpdate = () => {
  return (dispatch, getState) => {
    const { update, update_error } = getState().update;

    if (!update || !update?.reqId || update_error) return;

    let updateIntervalId;

    clientLib.onResponse(update.reqId).catch(error => {
      serviceLogger.error(`updateLauncher()`, error);
      setTimeout(() => dispatch(setUpdateError(updateIntervalId)), 3000);
    });

    setTimeout(() => {
      updateIntervalId = setInterval(() => {
        dispatch(handleUpdatingProgress(update.reqId));
      }, 500);
    }, 200);
  };
};

export default appUpdateReducer;
