import { serviceLogger } from '../logger';
import { fetchUpdateVersions } from '../fetchLocalData';
import { LOCAL_STORAGE_KEYS } from '../constants';

export const compareLauncherVersions = (currentVersion, newVersion) => {
  return currentVersion.localeCompare(newVersion, undefined, { numeric: true, sensitivity: 'base' }) > 0;
};

export const getUpdate = async (platform, currentVersion) => {
  try {
    const localStorageUpdating = localStorage.getItem(LOCAL_STORAGE_KEYS.LAUNCHER_UPDATING);

    if(localStorageUpdating) {
      const updatingData = JSON.parse(localStorageUpdating);
      if(compareLauncherVersions(updatingData.version, currentVersion)) {
        return updatingData;
      } else {
        return;
      }
    }

    const updateConfig = await fetchUpdateVersions(platform);

    if (compareLauncherVersions(updateConfig.latestVersion, currentVersion)) {
      return {
        version: updateConfig.latestVersion,
        ...(compareLauncherVersions(updateConfig.latestForcedUpdateVersion, currentVersion) && { forceUpdate: true }),
      };
    } else return null;
  } catch (error) {
    serviceLogger.error(`getUpdateVersions('${platform}', '${currentVersion}')`, error);
  }
};