import { FC, useContext } from "react";
import { useDispatch } from "react-redux";
import { MessageContainer } from "../../containers/MessageContainer";
import { MessageCaution } from "../../containers-content/MessagesContent/MessageCaution";
import { Button } from "../../commons/Button";
import { FormWrapper } from "../../commons/Form/FormWrapper";
import { FormFieldWrapper } from "../../commons/Form/FormFieldWrapper";
import { Label } from "../../commons/Form/Label";
import { InputPassword } from "../../commons/Form/InputPassword";
import useChangePasswordForm from "../../../hooks/useChangePasswordForm";
import { NotificationTypes, ProfileContext } from "../../../pages/Profile/Profile";
import { renderLogger } from "../../../../logger";
import { AuthActionTypes } from "../../../../store/dux/auth/actionTypes";
import { setLastPlayer } from "../../../../services/Keychain";
import { useTranslation } from "react-i18next";
import { getExternalError } from "../../../utils/errors";
import PGPLService from "../../../services/PGPLService";

const PasswordInfoForm: FC = ({ toggleEdit }: { toggleEdit: () => number }) => {
  const { register, handleSubmit, errors, setError } = useChangePasswordForm();
  const { setIsLoading, setNotification, name, playerId } = useContext(ProfileContext);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const changePassword = async data => {

    try {
      const { oldPassword, newPassword } = data;
      await PGPLService.setPlayerPassword(newPassword, oldPassword);
      await PGPLService.logout();
      dispatch({
        type: AuthActionTypes.CLEAR_WEBTOKEN,
      });
      const webToken = await PGPLService.loginWeb(name, newPassword);
      dispatch({
        type: AuthActionTypes.SET_WEBTOKEN,
        webToken,
      });
      await setLastPlayer(playerId, webToken);
      setNotification(NotificationTypes.PASSWORD_CHANGE);
      toggleEdit();
    } catch (error) {
      renderLogger.error(`changePassword(${JSON.stringify(data)})`, JSON.stringify(error));
      if (error.error) {
        if (error.error === 'InvalidParameter' && error.parameterName === 'newPassword') {
          return setError('newPassword', { type: 'custom', message: getExternalError('password', 'InvalidParameter') });
        }
        if (error.error === 'InvalidPassword') {
          return setError('oldPassword', {
            type: 'custom',
            message: getExternalError('password', 'CurrentPasswordNotValid'),
          });
        }
        return;
      }
      setNotification(NotificationTypes.UNHANDLED_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormWrapper className="profile__form profile-form profile-security-form form" id="g5Friends_password_form">
      <MessageContainer variant="dark" messageType="caution" className="form__message">
        <MessageCaution />
      </MessageContainer>
      <FormFieldWrapper error={errors?.oldPassword?.message}>
        <Label variant="primary" className="profile__label--form" htmlFor="PasswordForm_currentPassword" isRequired>
          {t<string>('profile.words.current_password')}
        </Label>
        <InputPassword
          placeholder={t<string>('profile.placeholders.enter_curr_password')}
          id="PasswordForm_currentPassword"
          name="oldPassword"
          register={register}
          className={`input ${errors?.oldPassword && 'error'}`}
        />
      </FormFieldWrapper>
      <FormFieldWrapper error={errors?.newPassword?.message}>
        <Label variant="primary" htmlFor="PasswordForm_newPassword" isRequired>
          {t<string>('profile.words.new_password')}
        </Label>
        <InputPassword
          id="PasswordForm_newPassword"
          name="newPassword"
          placeholder={t('profile.placeholders.password', { n: 4, m: 32 })}
          register={register}
          className={`input ${errors?.newPassword && 'error'}`}
        />
      </FormFieldWrapper>
      <FormFieldWrapper error={errors?.confirmNewPassword?.message}>
        <Label htmlFor="PasswordForm[confirmPassword]" isRequired>
          {t<string>('profile.words.confirm_password')}
        </Label>
        <InputPassword
          id="PasswordForm[confirmPassword]"
          name="confirmNewPassword"
          placeholder={t('profile.placeholders.password', { n: 4, m: 32 })}
          register={register}
          className={`input ${errors?.confirmNewPassword && 'error'}`}
        />
      </FormFieldWrapper>
      <Button
        variant="primary"
        sizeMode="3"
        className="profile__form-btn"
        type="button"
        onClick={handleSubmit(changePassword)}>
        {t<string>('profile.buttons.save')}
      </Button>
      <Button variant="secondary" sizeMode="3" className="profile__form-btn" type="reset" onClick={toggleEdit}>
        {t<string>('profile.buttons.cancel')}
      </Button>
    </FormWrapper>
  );
};

export default PasswordInfoForm;
