// Do we need to remove modals and all associated modules?
import ACTIONS from './actions';

const initialState = {
  modalsToShow: null,
};

function modalReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.PUSH_MODAL:
      const { event, game } = action;
      return {
        ...state,
        modalToShow: [event, game],
      };
    default:
      return state;
  }
}

export const pushModal = (event, game) => ({
  type: ACTIONS.PUSH_MODAL,
  event,
  game,
});

export default modalReducer;
