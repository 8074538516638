import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentVersion } from '../../../store/selectors';
import usePageLogging from '../../../hooks/usePageLogging';
import '../../../i18n';
import { renderLogger } from '../../../logger';
import FormFieldCheckbox from '../../shared/form/checkbox/FormFieldCheckbox';
import FormFieldSelect from '../../shared/form/select/FormFieldSelect';
// import UpdateField from './UpdateField';
import styles from './Settings.module.scss';
import { LOGGING_PAGE_IDS } from '../../../constants';
import Footer from "../../shared/footer/Footer";

function Settings() {
  renderLogger.debug('Settings');

  const { t } = useTranslation();
  // const update = useSelector(selectAvailableUpdate);
  const currentVersion = useSelector(selectCurrentVersion);
  usePageLogging(LOGGING_PAGE_IDS.SETTINGS);

  return (
    <div className={styles.main}>
      <div
        className={`${styles.content} ${styles.bgLogo} ${styles['bgLogo--settings']}`}
        style={{ backgroundColor: '#E4E4E4', marginTop: '84px' }}>
        <div className={styles.container}>
          <div className={styles['settings--title']}>
            <div className={styles.container}>
              <h2>{t('settings.title')}</h2>
            </div>
          </div>
          <ul className={styles['settings__list']}>
            {/* update && <UpdateField update={update} /> */}
            <li className={styles['settings__item']}>
              <h3 className={styles['settings__item--title']}>{t('settings.list.item1.title')}</h3>
              <FormFieldSelect />
              <FormFieldCheckbox title={t('settings.list.item1.checkboxTitle')} parameter="autostart" />
            </li>
            <li className={styles['settings__item']}>
              <h3 className={styles['settings__item--title']}>{t('settings.list.item2.updatetitle')}</h3>
              <div>
                <FormFieldCheckbox
                  title={t('settings.list.item2.updatedesc')}
                  parameter="autoupdate"
                  tooltip={t('settings.list.item2.updatetooltip')}
                />
              </div>
            </li>
          </ul>
        </div>
        <div className={`${styles.container} ${styles.version}`}>
          <p>{`©${new Date().getFullYear()} G5 Entertainment AB`}</p>
          <p>{`G5 Launcher ${currentVersion}`}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Settings;
