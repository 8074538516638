import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getLauncherXPromoContent } from '../services/Banners';
import { selectPGPLId } from "../store/dux/auth/selectors";
import { LOCAL_STORAGE_KEYS } from "../constants";
import { selectCurrentLang } from "../store/selectors";
import { clearXPromoBanners } from "../store/dux/main";

export default function useXPromoContent(): void {
  const pgplId = useSelector(selectPGPLId);
  const language = useSelector(selectCurrentLang);
//  const testGroup = useSelector(selectActiveTestGroup);
  const dispatch = useDispatch();

  useEffect(function handlePGPLIdChange() {
    const storedPGPLId = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_LOGIN_ID);
    if (!pgplId || !storedPGPLId) {
      if (!storedPGPLId) {
        dispatch(clearXPromoBanners());
      }
      return;
    }
    getLauncherXPromoContent();
  }, [pgplId, language]);
}
