import i18next from 'i18next';
// todo: rewrite function into one common handler
const handleInvalidParameter = parameter =>
  i18next.t('profile.message.not_valid', { attribute: i18next.t(`profile.words.${parameter}`) });

export const ERROR_KEYS = {
  email: {
    EmailInUse: () => i18next.t('profile.message.email_in_use'),
    InvalidParameter: () => handleInvalidParameter('email'),
    PlayerNotFound: () => i18next.t('profile.message.email_not_found'),
  },
  password: {
    InvalidParameter: () => handleInvalidParameter('password'),
    CurrentPasswordNotValid: () => handleInvalidParameter('current_password'),
  },
  newPassword: {
    InvalidParameter: () => handleInvalidParameter('password'),
    CurrentPasswordNotValid: () => handleInvalidParameter('current_password'),
  },
  name: {
    InvalidParameter: () => handleInvalidParameter('name'),
  },
  nick: {
    InvalidParameter: () => handleInvalidParameter('nick')
  }
};

export const getExternalError = (field: string, type: string): string => {
  const foundMsg = ERROR_KEYS?.[field]?.[type];
  return (foundMsg && foundMsg()) || type;
};
