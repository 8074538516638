import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Parag } from '../../../commons/Parag';
import { Headline } from '../../../commons/Headline';
import { Button } from '../../../commons/Button';
import { FormWrapper } from '../../../commons/Form/FormWrapper';
import { FormFieldWrapper } from '../../../commons/Form/FormFieldWrapper';
import { Input } from '../../../commons/Form/Input';
import { Label } from '../../../commons/Form/Label';
import { SignInContext, SignInNotifications } from '../../../../pages/SignIn/SignIn';
import { getExternalError } from '../../../../utils/errors';
import PGPLService from '../../../../services/PGPLService';

function ModalForgotPassword({ recoverPasswordForm }) {
  const { setNotification, setIsLoading } = useContext(SignInContext);
  const { register, handleSubmit, errors, setError } = recoverPasswordForm;
  const { t } = useTranslation();

  const sendPasswordRecoveryRequest = async ({ email }) => {
    try {
      setIsLoading(true);
      await PGPLService.recoverPlayerByEmail(email);
      setNotification(SignInNotifications.SENT_RECOVERY_EMAIL);
    } catch (error) {
      if (error.error) {
        if (error.error === 'UnconfirmedEmail') {
          return setNotification(SignInNotifications.CONFIRM_EMAIL_FIRST);
        }
        setNotification(SignInNotifications.RECOVER_PASSWORD);
        return setError('email', { type: 'custom', message: getExternalError('email', error.error) });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Headline headlineLevel="p" size="4" variant="primary" className="modal__headline center-inline">
        <div dangerouslySetInnerHTML={{ __html: t('profile.titles.forgot_password') }} />
      </Headline>
      <Parag variant="primary" className="modal__parag">
        {t<string>('profile.message.enter_email_to_recover')}
      </Parag>
      <FormWrapper className="container">
        <FormFieldWrapper error={errors?.email?.message}>
          <Label htmlFor="RecoveredForm_email" isRequired>
            {t<string>('profile.titles.enter_email')}
          </Label>
          <Input
            id="RecoveredForm_email"
            placeholder="example@address.com"
            register={register}
            name="email"
            className={`${errors?.email && 'error'}`}
          />
        </FormFieldWrapper>
      </FormWrapper>
      <div className="modal__btns">
        <Button
          type="button"
          variant="primary"
          sizeMode="2"
          className="modal__btn flex-center"
          onClick={handleSubmit(sendPasswordRecoveryRequest)}>
          {t<string>('profile.buttons.send')}
        </Button>
      </div>
    </>
  );
}

export default ModalForgotPassword;
