import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  selectAvailableUpdate,
  selectGameByGameID,
  selectGameDataToInstall,
  selectInstalledGameVersion,
  selectIsAnythingInProgress, selectIsCurrentGameInProgress, selectOpenURICommand, selectUpdatingStatus
} from "../store/selectors";
import {
  APP_METHODS,
  G5LAUNCHER_ROUTES,
  INSTALLATION_TYPE,
  LOCAL_STORAGE_KEYS,
  OpenURICommands,
  REFERRAL_SOURCES
} from "../constants";
import { startGameLoading } from '../store/dux/progress';
import { setOpenURICommand } from "../store/dux/main";

export default function useNavigateOnOpenURI() {
  const history = useHistory();
  const dispatch = useDispatch();
  const gameDataToInstall = useSelector(selectGameDataToInstall, shallowEqual);
  const game = useSelector(selectGameByGameID(gameDataToInstall?.id));
  const installedVersion = useSelector(state => selectInstalledGameVersion(state, game?.xpromoId));
  const isAnythingInstalling = useSelector(selectIsAnythingInProgress);
  const isCurrentGameInstalling = useSelector(state => selectIsCurrentGameInProgress(state, game?.id));

  const updating = useSelector(selectUpdatingStatus);
  const updateData = useSelector(selectAvailableUpdate, shallowEqual);
  const updatingVersionFromStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.LAUNCHER_UPDATING);
  const openURICommand = useSelector(selectOpenURICommand);

  useEffect(function handleOpenURICommand() {

    if(!openURICommand) return;

    if (openURICommand === OpenURICommands.GO_TO_ALL_GAMES) {
      history.push(G5LAUNCHER_ROUTES.ALL_GAMES);
      dispatch(setOpenURICommand());
    }

  }, [openURICommand]);

  useEffect(function handleGameInstallationFromOpenURI() {
    if (updating || updateData?.forceUpdate || updatingVersionFromStorage) return;
    if (!gameDataToInstall || isCurrentGameInstalling) return;
    if (!installedVersion && !isAnythingInstalling) {
      dispatch(startGameLoading(APP_METHODS.INSTALL, game, true, gameDataToInstall.g5tid, null, 'install_exe'));
      history.push({
        pathname: '/installation',
        state: {
          id: gameDataToInstall.id,
          type: INSTALLATION_TYPE.INSTALL,
        },
        search: REFERRAL_SOURCES.INSTALL.EXE,
      });
      return;
    }
    history.push({
      pathname: `/${gameDataToInstall.id}`,
      state: {
        id: gameDataToInstall.id,
        startAutoDownload: true,
        startGame: true,
        g5tid: gameDataToInstall.g5tid,
        timestamp: gameDataToInstall.timestamp,
        forceUpdate: gameDataToInstall.forceUpdate,
      },
      search: REFERRAL_SOURCES.INSTALL.EXE,
    });
  }, [gameDataToInstall?.id, gameDataToInstall?.timestamp, updating]);
}
