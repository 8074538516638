import React from 'react';
import { useTranslation } from "react-i18next";
import styles from '../../../pages/Game/Game.module.scss';

function SingleGameLanguages({ titleLangs, languages }) {
  const { t } = useTranslation();
  const content = languages.map(lang => t('games.single.languages.' + lang)).join(', ');
  return (
    <div className={styles['game__languages']}>
      <div className={styles['game-single__wrapper']}>
        <div className={styles['game-single__heating']}>
          <h3 className={styles['game-single__titleMini']}>{titleLangs}</h3>
        </div>
        <div className={styles['game-single__body']}>
          <div
            className={styles['game-single__languages']}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </div>
  );
}

export default SingleGameLanguages;
