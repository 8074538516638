import { EMAIL_SUBSCRIPTION_PARAMS, EMAIL_SUBSCRIPTION_STATUSES, EMAIL_SUBSCRIPTION_LANG_BY_LOCALE } from '../constants';

export const subscribeEmail = (email, language) => {
  return fetch(EMAIL_SUBSCRIPTION_PARAMS.URL, {
    method: 'POST',
    headers: {
      'X-Api-Key': EMAIL_SUBSCRIPTION_PARAMS.API_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      languages: language,
      source: 'Launcher',
    }),
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      }
      throw Error();
    })
    .then(res => {
      if (res.statusCode === 200) return EMAIL_SUBSCRIPTION_STATUSES.SUCCESS;
      if (res.statusCode === 100) return EMAIL_SUBSCRIPTION_STATUSES.ALREADY_SUBSCRIBED;
      if (res.statusCode === 400) return EMAIL_SUBSCRIPTION_STATUSES.WRONG_EMAIL;
      throw Error();
    })
    .catch(() => {
      throw Error();
    });
};

export const getSubscriptionLanguageByLocale = (locale) => {
  for(const language in EMAIL_SUBSCRIPTION_LANG_BY_LOCALE) {
    if(EMAIL_SUBSCRIPTION_LANG_BY_LOCALE[language].includes(locale)) return language;
  }
  return 'English';
};
