import { FC } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextButtonWithIcon } from '../../commons/TextButtonWithIcon';
import { ReactComponent as ChangePlayerIcon } from '../../../assets/images/svg/change-player-icon.svg';
import { profileRoutes } from "../../../routes";

const ChangePlayer: FC = () => {

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <TextButtonWithIcon
        onClick={() => {
          history.push(profileRoutes.signIn)
        }}
        type="button"
        className="profile__change-btn"
        icon={<ChangePlayerIcon />}>
        {t<string>('profile.buttons.change_player')}
      </TextButtonWithIcon>
    </>
  );
};

export default ChangePlayer;
