import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import NotificationsContainer from './NotificationsContainer';

function NotificationsPortal() {
  const rootDiv = useMemo(() => document.createElement('div'), []);

  useEffect(
    function handleNotificationsMount() {
      document.body.appendChild(rootDiv);
      return () => document.body.removeChild(rootDiv);
    },
    [rootDiv],
  );

  return createPortal(<NotificationsContainer />, rootDiv);
}

export default NotificationsPortal;
