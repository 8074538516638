import { APP_METHODS, OpenURICommands, URI_PARAMS } from "../constants";
import store from '.';
import { getAndSetXPromoBanners, reportUninstall, setActiveState, setOpenURICommand, updateOnResume } from "./dux/main";
import { setExeGameInstallationData } from './dux/progress';
import clientLib from '../clientLib';
import { AuthActionTypes } from './dux/auth/actionTypes';
import { getAndSetPGPLGamesData } from "./dux/auth/auth";
import { preparePushNotification } from "../services/PushNotifications";
import { addPushNotification, setDingDong } from "./dux/pushNotifications/pushNotifications";
import { getAndSetUserRegion } from "../services/RegionalRestrictions";
// import { removeDeferredGameUpdateNotificationOnUninstall } from './dux/notifications';

const eventHandler = event => {
  switch (event.type) {
    case APP_METHODS.RESUME:
      store.dispatch(setActiveState(true));
      (async () => {
        await store.dispatch(updateOnResume());
      })();
      getAndSetUserRegion();
      store.dispatch(getAndSetXPromoBanners());
      break;
    case APP_METHODS.SUSPEND:
      store.dispatch(setActiveState(false));
      break;
    case APP_METHODS.UNINSTALL:
      store.dispatch(reportUninstall(event.params.packageId || event.params.xpromoId));
      // store.dispatch(removeDeferredGameUpdateNotificationOnUninstall(event.params.packageId));
      break;
    case APP_METHODS.OPEN_URI:
      store.dispatch(getAndSetPGPLGamesData());

      const params = new URLSearchParams(event.launchArguments.split('?')[1]);
      const xPromoID = params?.get(URI_PARAMS.XPROMOID);
      const sessionToken = params?.get(URI_PARAMS.SESSION_TOKEN);

      if(!xPromoID && !sessionToken) {
        store.dispatch(setOpenURICommand(OpenURICommands.GO_TO_ALL_GAMES));
        break;
      }

      if (sessionToken) {
        store.dispatch({
          type: AuthActionTypes.SET_SESSION_TOKEN,
          sessionToken,
        });
      }

      if (!xPromoID) {
        break;
      }
      const g5tid = params?.get(URI_PARAMS.G5TID);

      const forceUpdate = params?.get(URI_PARAMS.ACTION) === URI_PARAMS.ACTION_TYPES.FORCE_UPDATE;
      store.dispatch(setExeGameInstallationData({ xPromoID, g5tid, forceUpdate }));

      break;
    case APP_METHODS.PUSH_NOTIFICATION:
      const  pushNotification = preparePushNotification(event.params);
      store.dispatch(addPushNotification(pushNotification));
      store.dispatch(setDingDong(true));
    default:
      break;
  }
};

export const initTransport = () => {
  clientLib.on(APP_METHODS.RESUME, eventHandler);
  clientLib.on(APP_METHODS.UNINSTALL, eventHandler);
  clientLib.on(APP_METHODS.OPEN_URI, eventHandler);
  clientLib.on(APP_METHODS.PUSH_NOTIFICATION, eventHandler);
  clientLib.on(APP_METHODS.SUSPEND, eventHandler);
};
