import ACTIONS from './actions';
import { ActiveTakeoverBannersStorage } from '../../services/Storage';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { checkIsActive, convertDHMtimeInMs} from '../../helpers';

const activeTakeoverBannersStorage = new ActiveTakeoverBannersStorage(LOCAL_STORAGE_KEYS.TAKEOVER_BANNERS);

function takeoverBannersReducer(state = [], action) {
  switch(action.type) {
    case ACTIONS.GET_INIT_TAKEOVER_BANNERS: {
      return [...action.takeoverBanners]
    }

    case ACTIONS.REMOVE_TAKEOVER_BANNER: {
      return removeTakeoverBanner(state, action.takeoverBanner);
    }
    default: 
      return state;
  }
}

export const removeTakeoverBanner = (takeoverBanners, takeoverBanner) => {
  activeTakeoverBannersStorage.removeTakeoverBanner(takeoverBanner.type);
  return [...takeoverBanners.filter((takeoverBanner) => !takeoverBanner.type)]
}

export const setTakeoverBanners = () => {
  return (dispatch, getState) => {
    const { banners, games, installedGames } = getState().main;
    const takeoverBanners = banners.filter(banner => banner.type === 'event' || banner.type === 'globalLaunch');
    const activeTakeoverBanners = takeoverBanners
    .filter(banner => {
      const isBannerActive = checkIsActive(banner.dateTimeStart, banner.dateTimeEnd, banner.isDateTimeByUTC);

      if(!isBannerActive) {
        activeTakeoverBannersStorage.removeTakeoverBanner(banner.type);
      }
      return isBannerActive;
    }
    )
    .filter(banner => {
      const takeoverBannerGame = games.find(game => banner.gameId === game.id);
      return !installedGames.find(installedGame => takeoverBannerGame?.packageId === installedGame.packageId);
    });

    if(activeTakeoverBanners.length) {
      activeTakeoverBanners.forEach((takeoverBanner) => {
        const takeoverBannerStorageData =  activeTakeoverBannersStorage.getTakeoverBanner(takeoverBanner);

        if(takeoverBannerStorageData && takeoverBannerStorageData.impressionsInterval !== takeoverBanner.impressionsInterval) {
          activeTakeoverBannersStorage.removeTakeoverBanner(takeoverBanner.type);
        }
      });

      dispatch({
        type: ACTIONS.GET_INIT_TAKEOVER_BANNERS, 
        takeoverBanners: activeTakeoverBanners
      });
    } 
  }
}

export const addTakeoverBanner = banner => {
  const now = Date.now();
  const data = {
    type: banner.type,
    impressionsInterval: banner.impressionsInterval,
    timeToStart: now,
    timeToExpire: now + convertDHMtimeInMs(banner.impressionsInterval),
  };
  activeTakeoverBannersStorage.addTakeoverBanner(data);
}


export const getTakeoverBanner = (banner) => {
  return activeTakeoverBannersStorage.getTakeoverBanner(banner);
}

export default takeoverBannersReducer;