import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SwiperCore from 'swiper';
import ReactPlayer from 'react-player';
import { MuteButton } from '../MuteButton';
import BannerActionButton from '../../banners/action/BannerActionButton';
import { selectBannerByBannerId, selectGameByXPromoID } from '../../../../store/selectors';
import { REFERRAL_SOURCES, SELECTED_BANNER_TYPES, BUTTON_SIZE, XPROMO_SD_METHODS } from '../../../../constants';
import { XPromoBannerType, XPromoBannerTypes } from "../../../../services/Banners";
import { renderLogger } from '../../../../logger';
import { reportXPromoSD } from '../../../../helpers';
import useInView from "../../../../Profile/hooks/useInView";

interface ISingleXPromoContext {
  id: string;
  video: string;
  image: string;
  type: XPromoBannerTypes;
  xpromoId: string;
  game: any;
  swiper: SwiperCore;
  slideIndex: number;
  activeIndex: number;
  fallback: string;
  hovering: boolean;
  displayButton: boolean;
  muted: boolean;
  setMuted: (v: boolean) => void;
}

type SingleXPromoBannerPropTypes = {
  banner: XPromoBannerType;
  swiper: SwiperCore;
  slideIndex: number;
  hovering: boolean;
  activeIndex: number;
  muted: boolean;
  type: SELECTED_BANNER_TYPES;
  setMuted: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
};

const SingleXPromoBannerContext = createContext<ISingleXPromoContext>(null!);
const REWIND_TIMEOUT = 100;
const IMAGE_BANNER_DISPLAY_TIME = 8000;
const VIDEO_SIZE = {
  WIDTH: '976px',
  HEIGHT: '550px',
};

const SingleXPromoBanner = ({
  banner,
  swiper,
  slideIndex,
  hovering,
  activeIndex,
  muted,
  type,
  setMuted,
  children,
}: SingleXPromoBannerPropTypes) => {
  const game = useSelector(selectGameByXPromoID(banner.xpromoId));
  const displayButton = game && !banner.disableLauncherButton;

  const bannerContextValue = useMemo(
    () => ({
      video: banner.video,
      fallback: banner.fallback,
      image: banner.image,
      type: banner.type,
      xpromoId: banner.xpromoId,
      id: banner.id,
      game,
      swiper,
      slideIndex,
      activeIndex,
      hovering,
      displayButton,
      muted,
      setMuted,
    }),
    [banner, swiper, activeIndex, hovering, muted, type],
  );

  return (
    <SingleXPromoBannerContext.Provider value={bannerContextValue}>
      <Link
        to={{
          pathname: `${game?.id}`,
          state: { id: game?.id, startAutoDownload: true, startGame: true },
          search: REFERRAL_SOURCES.INSTALL.TOP_BANNER,
        }}
        onClick={() => {
          if (type === SELECTED_BANNER_TYPES.XPROMO) {
            reportXPromoSD(XPROMO_SD_METHODS.registerAccept, banner.id);
          }
        }}>
        {children}
      </Link>
    </SingleXPromoBannerContext.Provider>
  );
};

SingleXPromoBanner[XPromoBannerTypes.IMAGE] = () => {
  const { type, image, game, displayButton, swiper, hovering, slideIndex, activeIndex } =
    useContext(SingleXPromoBannerContext);
  const playingRef = useRef<boolean>();
  const timeHasComeRef = useRef<boolean>(false);
  const hoveringRef = useRef<boolean>(false);
  const fallback = useSelector(selectBannerByBannerId(game?.bannerId));

  useEffect(
    function handleActiveBannerChange() {
      playingRef.current = activeIndex === slideIndex;
    },
    [activeIndex],
  );

  useEffect(
    function handleImageBannerStart() {
      if (!playingRef.current) {
        timeHasComeRef.current = false;
        return;
      }
      const timeout = setTimeout(() => {
        timeHasComeRef.current = true;
        if (playingRef.current && !hoveringRef.current && swiper) {
          swiper.slideNext();
        }
      }, IMAGE_BANNER_DISPLAY_TIME);
      return () => clearTimeout(timeout);
    },
    [activeIndex, swiper],
  );

  useEffect(
    function handleCheck() {
      hoveringRef.current = hovering;
      if (timeHasComeRef.current && !hovering) {
        timeHasComeRef.current = false;
        swiper.slideNext();
      }
    },
    [hovering],
  );

  if (type === XPromoBannerTypes.FALLBACK) {
    return (
      <div
        className="banner--xpromo-image banner--xpromo-fallback"
        style={{
          // @ts-ignore
          backgroundImage: `url('../../${fallback?.backgroundImage}`,
        }}>
        <img src={`../../${fallback?.lockit['en']?.logoImage}`} className="banner--xpromo-fallback-logo" alt="logo" />
        <img src={`../../${fallback?.characterImage}`} className="banner--xpromo-fallback-character" alt="character" />
        {
          // @ts-ignore
          <BannerActionButton game={game} size={BUTTON_SIZE.LARGE} video />
        }
      </div>
    );
  }

  return (
    <>
      <img className="banner--xpromo-image" src={image} />
    </>
  );
};

SingleXPromoBanner[XPromoBannerTypes.FALLBACK] = SingleXPromoBanner[XPromoBannerTypes.IMAGE];

SingleXPromoBanner[XPromoBannerTypes.VIDEO] = () => {
  const { video, game, swiper, hovering, displayButton, slideIndex, activeIndex, muted, setMuted } =
    useContext(SingleXPromoBannerContext);
  const playerRef = useRef<ReactPlayer>();
  const timeHasCome = useRef<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [key, setKey] = useState<number>(slideIndex);
  const [videoBlob, setVideoBlob] = useState<string>();
  const videoWrapper = useRef<HTMLDivElement>();
  const inView = useInView(videoWrapper);
  const playing = activeIndex === slideIndex && inView;

  useEffect(function handleVideoToBlob() {
    const timeout = slideIndex ? slideIndex * 100 : slideIndex;
    setTimeout(() => {
      fetch(video)
        .then(response => response.blob())
        .then(videoBlobbed => {
          const videoObjURL = URL.createObjectURL(videoBlobbed);
          setVideoBlob(videoObjURL);
        })
        .catch(error => {
          renderLogger.error(`Blobbing video ${video} error`, JSON.stringify(error));
        });
    }, timeout);
  }, []);

  useEffect(function clearVideoMemoryReference() {
    return () => URL.revokeObjectURL(videoBlob);
  }, [videoBlob]);

  useEffect(
    function handleVideoError() {
      if (error) {
        const timeStamp = Date.now();
        playerRef.current.seekTo(0, 'seconds');
        setKey(timeStamp);
        setError(false);
      }
    },
    [error],
  );

  useEffect(
    function handleActiveBannerChange() {
      if (!playing && playerRef.current) {
        timeHasCome.current = false;
        setTimeout(() => {
          playerRef.current.seekTo(0, 'seconds');
        }, REWIND_TIMEOUT);
      }
    },
    [playing],
  );

  useEffect(
    function handleHoveringVideo() {
      if (hovering && playing) return;

      if (timeHasCome?.current) {
        swiper?.slideNext();
        timeHasCome.current = false;
      }
      if (timeHasCome.current) {
        setTimeout(() => {
          playerRef.current.seekTo(0, 'seconds');
          timeHasCome.current = false;
        }, REWIND_TIMEOUT);
      }
    },
    [hovering, playerRef.current],
  );

  return (
    <div className="banner--xpromo-video curtain" ref={videoWrapper}>
      <MuteButton show={hovering} setMuted={setMuted} muted={muted} />
      <ReactPlayer
        url={videoBlob}
        key={key}
        className="banner--xpromo-video_player"
        width={VIDEO_SIZE.WIDTH}
        height={VIDEO_SIZE.HEIGHT}
        stopOnUnmount
        pip
        ref={playerRef}
        playing={playing && ready}
        muted={playing ? muted : true}
        onReady={() => {
          setReady(true);
        }}
        onError={error => {
          if (error && playing) {
            renderLogger.error(`Video error occurred ${video}`, JSON.stringify(error));
            setError(true);
            swiper.slideNext();
          }
        }}
        onEnded={() => {
          timeHasCome.current = true;
          if (hovering || !playing) return;
          swiper.slideNext();
        }}
      />
      {
        // @ts-ignore
        displayButton ? <BannerActionButton game={game} size={BUTTON_SIZE.LARGE} video /> : null
      }
    </div>
  );
};

export default SingleXPromoBanner;
