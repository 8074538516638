import { useTranslation } from "react-i18next";
import { Parag } from '../../../commons/Parag';
import { Headline } from '../../../commons/Headline';
import { Button } from '../../../commons/Button';

type ModalThankYouProps = {
  onClose: () => void;
}

function ModalThankYou({ onClose }: ModalThankYouProps) {
  const { t } = useTranslation();
  return (
    <>
      <Headline headlineLevel="p" size="4" variant="primary" className="modal__headline">
        {t<string>('profile.titles.thank')}
      </Headline>
      <Parag variant="primary">
        {t<string>('profile.message.thank_you')}
      </Parag>
      <div className="modal__btns">
        <Button type="button" variant="primary" sizeMode="5" className="modal__btn" onClick={onClose}>
          {t<string>('profile.buttons.ok')}
        </Button>
      </div>
    </>
  );
}

export default ModalThankYou;
