import { useEffect, useRef, useState } from "react";
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import './SingleGameNews.scss';
import useClickOnLink from "../../../../hooks/useClickOnLink";

function SingleGameNews({ updateInfoVersion, updateInfoDate, updateInfoDescription }) {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [textHeight, setTextHeight] = useState(0);

  const containerRef = useRef();

  useClickOnLink(containerRef);

  const title = t('games.single.titles.news'),
    versionText = t('games.single.subtitles.news.version'),
    dropdown = t('games.single.subtitles.news.history');

  function getTimeAgo(lastUpdate) {
    if(!lastUpdate) return '';
    const day = new Date(lastUpdate);
    day.setHours(12);
    let ms = new Date().getTime() - day.getTime();
    let seconds, minutes, hours, days;

    days = Math.floor(ms / (24 * 60 * 60 * 1000));
    hours = Math.floor(ms / (60 * 60 * 1000));
    minutes = Math.floor(ms / (60 * 1000));
    seconds = Math.floor(ms / 1000);

    if (days > 0) {
      return t(`time.day${days > 1 ? 's' : ''}`, { days });
    } else {
      if (hours > 0) {
        return t(`time.hour${hours > 1 ? 's' : ''}`, { hours });
      } else {
        if (minutes > 0) {
          return t(`time.minute${minutes > 1 ? 's' : ''}`, { minutes });
        } else {
          if (seconds > 0) {
            return t(`time.second${seconds > 1 ? 's' : ''}`, { seconds });
          }
        }
      }
    }
  }

  useEffect(() => {
    const text = document.getElementsByClassName('game__news--description--text');
    setTextHeight(text[0]?.clientHeight);
  }, []);

  return (
    <div className='game__news'>
      <div className='game__news--wrapper'>
        <div className='game__news--heating'>
          <h2>{title}</h2>
        </div>
        <div className='game__news--info'>
          <span>{versionText + ' ' + updateInfoVersion}</span>
          <span>{getTimeAgo(updateInfoDate)}</span>
        </div>
        <div className='game__news--description'>
          <Collapse isOpened={isVisible}>
            <div
              className='game__news--description--text'
              ref={containerRef}
              dangerouslySetInnerHTML={{ __html: updateInfoDescription }} />
          </Collapse>
          {textHeight > 63 ? (
            <button className='btn btn--link btn--blue btn--dropdown' onClick={() => setIsVisible(currVisibility => !currVisibility)} aria-expanded={isVisible}>
              {dropdown} <div className={`icon ${isVisible ? 'icon-arrow-top' : 'icon-arrow-down'}`} />
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleGameNews;
