import { useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

const useLoginForm = () => {
  const { t } = useTranslation();
  const loginFormSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('profile.message.cant_be_blank', { attribute: t('profile.words.name') })),
        password: yup.string().required(t('profile.message.cant_be_blank', { attribute: t('profile.words.password') })),
      }),
    [],
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(loginFormSchema),
  });

  return {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue
  };
};

export default useLoginForm;
