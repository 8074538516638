import { fetchGameConfig, fetchGamePlatformConfig } from '../fetchLocalData';
import { logger } from '../logger';
import { LANGUAGES } from '../constants';

export default function Game(id = '', platform = '', config = {}, platformConfig = {}) {
  checkArguments(id, platform, config, platformConfig);

  const { pgplId = '', rating = '', supportLink = '', genreIds = [], languages = [] } = config;

  const {
    bannerId = '',
    xpromoId = '',
    packageId = '',
    enablePromotion = false,
    storeLink = '',
    iconImage = '',
    cardImage = '',
    cardColor = '',
    updateInfo: { version: updateInfoVersion = '', date: updateInfoDate = '' },
  } = platformConfig;

  const lockit = getLockit(config, platformConfig);

  Object.assign(this, {
    id,
    platform,
    xpromoId,
    packageId,
    pgplId,
    bannerId,
    enablePromotion,
    genreIds,
    languages,
    rating,
    supportLink,
    storeLink,
    iconImage,
    cardImage,
    cardColor,
    lockit,
    updateInfoVersion,
    updateInfoDate,
  });

  Object.freeze(this);
}

Game.fetch = function(id, platform) {
  return Promise.all([fetchGameConfig(id), fetchGamePlatformConfig(id, platform)])
    .then(([config, platformConfig]) => new Game(id, platform, config, platformConfig))
    .catch((reason) => logger.error(`Game.fetch('${id}', '${platform}')`, reason));
};

Game.findByXpromoID = function(games, xpromoId) {
  return games.find((game) => game.xpromoId === xpromoId);
};

Game.findByPgplId = function(games, pgplId) {
  return games.find((game) => game.pgplId === pgplId);
};

Game.filterByGenre = function(games, genre) {
  return games.filter((game) => game?.genreIds.includes(genre));
};

function checkArguments(id, platform, config, platformConfig) {
  if (typeof id !== 'string') {
    throw new Error('"id" is not a string');
  }

  if (typeof platform !== 'string') {
    throw new Error('"platform" is not a string');
  }

  if (typeof config !== 'object') {
    throw new Error('"config" is not an object');
  }

  if (typeof platformConfig !== 'object') {
    throw new Error('"platformConfig" is not an object');
  }
}

function getLockit(config, platformConfig) {
  const { lockit: configLockit = {} } = config;
  const { lockit: platformConfigLockit = {} } = platformConfig;
  const {
    updateInfo: { lockit: platformConfigUpdateInfoLockit = {} },
  } = platformConfig;

  const result = {};
  for (const lang of LANGUAGES) {
    const { copyright = '' } = configLockit[lang] || {};

    const {
      name = '',
      shortDescription = '',
      fullDescription = '',
      requirements = '',
      shortName = ''
    } = platformConfigLockit[lang] || {};

    const { description: updateInfoDescription = '' } = platformConfigUpdateInfoLockit[lang] || {};

    const langData = {
      name,
      shortDescription,
      fullDescription,
      updateInfoDescription,
      copyright,
      requirements,
      shortName
    };

    Object.freeze(langData);
    result[lang] = langData;
  }

  Object.freeze(result);
  return result;
}
