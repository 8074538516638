import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { reportXPromoSD, reportXPromoShow } from "../helpers";
import { XPromoBannerTypes } from "../services/Banners";
import { selectGameXPromoIdByGameId } from "../store/selectors";
import { SELECTED_BANNER_TYPES, XPROMO_SD_METHODS } from "../constants";

const XPROMO_LOGGING_COMMANDS = {
  [XPromoBannerTypes.VIDEO]: 'top_banner_video',
  [XPromoBannerTypes.IMAGE]: 'top_banner_image',
  [XPromoBannerTypes.FALLBACK]: 'top_banner_fallback',
};

export default function useXPromoBannersLogging(banners: any, activeIndex: number, location, type: SELECTED_BANNER_TYPES): void {
  const loggedIndicesRef = useRef<number[]>([]);
  // @ts-ignore
  const selectedXPromoId = useSelector(state => selectGameXPromoIdByGameId(state, banners?.[activeIndex]?.gameId));
  useEffect(
    function handleXPromoShow() {
      if (!loggedIndicesRef.current.includes(activeIndex) && banners) {
        const currentBanner = banners?.[activeIndex];
        loggedIndicesRef.current.push(activeIndex);
        const bannerType =
          XPROMO_LOGGING_COMMANDS[currentBanner.type] || XPROMO_LOGGING_COMMANDS[XPromoBannerTypes.IMAGE];
        const xpromoId = currentBanner.xpromoId || selectedXPromoId || 'promo';
        reportXPromoShow(bannerType, xpromoId, currentBanner.id, location);
        if (type === SELECTED_BANNER_TYPES.XPROMO) {
          reportXPromoSD(XPROMO_SD_METHODS.registerImpression, currentBanner.id);
        }
      }
    },
    [activeIndex],
  );
}
