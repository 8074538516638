import { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './MuteButton.module.scss';
import { ReactComponent as Mute } from '../../../../assets/images/mute.svg';
import { ReactComponent as Unmute } from '../../../../assets/images/unmute.svg';
import Tooltip from '../../header/tooltip/Tooltip';

type MuteButtonPropsType = {
  show: boolean;
  setMuted: (v: boolean) => void;
  muted: boolean;
};

const MuteButton = ({ show, setMuted, muted }: MuteButtonPropsType) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleMuteClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setMuted(!muted);
  };

  const actionText = muted ? t('shared.buttons.unmute') : t('shared.buttons.mute');

  if (!show) return null;

  return (
    <button
      className={styles.mute}
      onClick={handleMuteClick}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}>
      {muted ? <Unmute /> : <Mute /> }
      {hovering && <Tooltip text={actionText} top={50} />}
    </button>
  );
};

export default MuteButton;
