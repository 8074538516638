function SingleGameScreenshotSlide({ slide, handleOpen, slideIndex, yStep }) {
  return (
    <div
      style={{ backgroundImage: `url(${slide})`, backgroundPositionY: `-${yStep}px` }}
      className="screenshotSlide"
      onClick={() => handleOpen(slideIndex)}
    />
  );
}

export default SingleGameScreenshotSlide;
