import { Parag } from '../../../commons/Parag';
import { Headline } from '../../../commons/Headline';
import { Trans, useTranslation } from "react-i18next";
import LetterIcon from '../../../../assets/images/png/letter-g5.png';

function ModalConfirmEmail() {
  const { t } = useTranslation();
  return (
    <>
      <Headline headlineLevel="p" size="4" variant="primary" className="modal__headline center-inline">
        <div dangerouslySetInnerHTML={{ __html: t('profile.titles.confirm_first') }} />
      </Headline>
      <div className="center-inline">
        <img className="modal__img" width="100" src={LetterIcon} alt="" />
      </div>
      <Parag variant="primary" className="center-inline">
        {t<string>('profile.messages.confirm_first')}
      </Parag>
      <Parag variant="primary" className="center-inline">
        {t<string>('profile.messages.confirm_first_10mins', {m: 10})}
      </Parag>
    </>
  );
}

export default ModalConfirmEmail;
