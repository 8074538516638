import { useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { HeadlineWithIcon } from '../../../commons/HeadlineWithIcon';
import { Parag } from '../../../commons/Parag';
import { TextButton } from '../../../commons/TextButton';
import { Circle } from '../../../commons/Circle';
import LetterIcon from '../../../../assets/images/png/letter-g5.png';
import { EmailToConfirmType, NotificationTypes, ProfileContext } from '../../../../pages/Profile/Profile';
import { useContext } from 'react';
import fetchPGPL, { apiPGPLMethods } from '../../../../utils/apiPGPL';
import { renderLogger } from '../../../../../logger';
import { AuthActionTypes } from '../../../../../store/dux/auth/actionTypes';

type UnconfirmedEmailProps = {
  unconfirmedEmail: EmailToConfirmType;
};

function MessageNotVerified({ unconfirmedEmail }: UnconfirmedEmailProps) {
  const { callbackRef, setIsLoading, setNotification, resentConfirmation } = useContext(ProfileContext);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const resendEmailConfirmation = async email => {
    callbackRef.current = () => resendEmailConfirmation(email);
    setIsLoading(true);
    await fetchPGPL(apiPGPLMethods.updatePlayer, { email })
      .then(response => {
        if (response.error) {
          renderLogger.error('resendEmailConfirmation()', response.error);
          return;
        }
        dispatch({
          type: AuthActionTypes.SET_RESENT_CONFIRMATION,
        });
        setNotification(null);
      })
      .catch(error => {
        renderLogger.error('resendEmailConfirmation()', JSON.stringify(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <img className="message__img" src={LetterIcon} alt="" />
      <div>
        {resentConfirmation ? (
          <Parag className="message__parag left-inline" variant="secondary">
            <Trans
              i18nKey="profile.message.sent_email"
              values={{ email: unconfirmedEmail.email }}
              components={[<strong />]}
            />
          </Parag>
        ) : (
          <>
            <HeadlineWithIcon
              size="2"
              headlineLevel="h3"
              variant="primary"
              icon={<Circle variant="orange" size="md" />}>
              {t<string>('profile.titles.email_not_verified')}
            </HeadlineWithIcon>
            <Parag className="message__parag left-inline" variant="secondary">
              <Trans
                i18nKey="profile.message.verify_email"
                values={{ email: unconfirmedEmail.email }}
                components={[<strong />]}
              />
            </Parag>
            <TextButton type="button" variant="primary" onClick={() => resendEmailConfirmation(unconfirmedEmail.email)}>
              {t<string>('profile.buttons.resend_email')}
            </TextButton>
          </>
        )}
      </div>
    </>
  );
}

export default MessageNotVerified;
