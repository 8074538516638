import { serviceLogger } from "../logger";

class CommonLoadEntitiesStorage {
  constructor(key) {
    this.key = key;
    this.activeURIs = this.getKeyData();
  }

  getKeyData() {
    try {
      return JSON.parse(window.localStorage.getItem(this.key)) || [];
    } catch(error) {
      serviceLogger.error(`Storage Key: ${this.key}, getKeyData()`, error);
      return [];
    }
  }

  addURIData(data) {
    try {
      if (this.activeURIs.some(activeUriData => activeUriData.uri === data.uri)) return;
      this.activeURIs = [...this.activeURIs, data];
      window.localStorage.setItem(this.key, JSON.stringify(this.activeURIs));
    } catch(error) {
      serviceLogger.error(`Storage Key: ${this.key}, addURIData(${JSON.stringify(data)})`, error);
    }
  }

  removeURIData(data) {
    try {
      const uriIndex = this.activeURIs.findIndex(activeUriData => activeUriData.uri === data.uri);
      if (uriIndex !== -1) {
        this.activeURIs.splice(uriIndex, 1);
        window.localStorage.setItem(this.key, JSON.stringify(this.activeURIs));
      }
    } catch(error) {
      serviceLogger.error(`Storage Key: ${this.key}, removeURIData(${JSON.stringify(data)})`, error);
    }
  }
}

export class ActiveProgressStorage extends CommonLoadEntitiesStorage {
  getActiveURIs(installedGamesUris) {
    try {
      return this.activeURIs.filter(
        activeUriData => !installedGamesUris.some(installedURI => installedURI === activeUriData.uri),
      );
    } catch (error) {
      serviceLogger.error(`Storage Key: ${this.key}, getActiveURIs(${JSON.stringify(installedGamesUris)})`, error);
      return [];
    }
  }
}

export class ActiveNotificationsStorage extends CommonLoadEntitiesStorage {
  getActiveNotifications() {
    try {
      this.activeURIs = JSON.parse(window.localStorage.getItem(this.key)) || [];
      return this.activeURIs;
    } catch(error) {
      serviceLogger.error(`Storage Key: ${this.key}, getActiveNotifications()`, error);
      return [];
    }
  }

  changeNotificationStatus(notification, status) {
    try {
      const notificationIndex = this.activeURIs.findIndex(stagedNotification => stagedNotification.uri === notification.uri);
      if(notificationIndex !== -1) {
        this.activeURIs[notificationIndex].status = status;
        window.localStorage.setItem(this.key, JSON.stringify(this.activeURIs));
      }
    } catch(error) {
      serviceLogger.error(`Storage Key: ${this.key}, changeNotificationStatus('${JSON.stringify(notification)}', '${status}')`, error);
    }
  }
}

export class ReadPushNotificationsStorage extends CommonLoadEntitiesStorage {
  getReadStatus(uid) {
    return this.getKeyData().includes(uid);
  }

  markAsRead(uid) {
    const storedUids = this.getKeyData();
    if (!storedUids.includes(uid)) {
      const modifiedAndPreparedUids = JSON.stringify([...storedUids, uid]);
      window.localStorage.setItem(this.key, modifiedAndPreparedUids);
    }
  }
}

export class ActiveTakeoverBannersStorage extends CommonLoadEntitiesStorage {
  addTakeoverBanner(banner) {
    const bannerIndex = this.activeURIs.findIndex((takeoverBanner) => takeoverBanner.type === banner.type);
    if(bannerIndex !== -1) {
      this.activeURIs.splice(bannerIndex, 1);
    }
    this.activeURIs = [...this.activeURIs, banner];
    window.localStorage.setItem(this.key, JSON.stringify(this.activeURIs));
  }

  getTakeoverBanner(banner) {
    const banners = JSON.parse(window.localStorage.getItem(this.key));
    return banners?.find(bannersItem => banner.type === bannersItem.type);
  }

  removeTakeoverBanner(bannerType) {
    const banners = JSON.parse(window.localStorage.getItem(this.key)) || null;
    if(banners) {
      this.activeURIs = banners.filter(banner => bannerType !== banner.type);
      window.localStorage.setItem(this.key, JSON.stringify(this.activeURIs));
    }
  }
}