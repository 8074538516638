import { FC, PropsWithChildren } from 'react';
import { GridContainer } from '../../containers/GridContainer';
import { Headline } from '../../commons/Headline';

import './styles/index.scss';

const HeaderProfile: FC<PropsWithChildren> = ({ children }) => {
  return (
    <header className="header">
      <GridContainer containerType="type-1">
        <Headline headlineLevel="h1" size="1" variant="primary" className="center-inline">
          {children}
        </Headline>
      </GridContainer>
    </header>
  );
};

export default HeaderProfile;
