import { useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { getExternalError } from '../utils/errors';
import { emailDomainG5EcomTest } from "../utils/validators";

const useRegistrationForm = () => {
  const { t } = useTranslation();
  const registrationFormSchema = useMemo(
    () =>
      yup.object().shape({
        nick: yup
          .string()
          .nullable()
          .transform((v, o) => (o === '' ? null : v))
          .min(3, t('profile.message.wrong_length', { attribute: t('profile.words.nick'), n: 3, m: 32 }))
          .max(32, t('profile.message.wrong_length', { attribute: t('profile.words.nick'), n: 3, m: 32 }))
          .matches(/^(?!.*\s\s)[a-zA-Z]{2}[A-Za-z0-9\s]+$/, getExternalError('nick', 'InvalidParameter')),
        email: yup
          .string()
          .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.email') }))
          .email(t('profile.message.not_valid', { attribute: t('profile.words.email') }))
          .test(emailDomainG5EcomTest),
        password: yup
          .string()
          .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.password') }))
          .min(4, t('profile.message.password_length_error', { n: 4, m: 32 }))
          .max(32, t('profile.message.password_length_error', { n: 4, m: 32 })),
        confirmPassword: yup
          .string()
          .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.confirm_password') }))
          .oneOf([yup.ref('password'), null], t('profile.message.passwords_not_match'))
          .min(4, t('profile.message.password_length_error', { n: 4, m: 32 }))
          .max(32, t('profile.message.password_length_error', { n: 4, m: 32 })),
        accept: yup.bool().oneOf([true], t('profile.message.need_to_confirm')),
      }),
    [],
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(registrationFormSchema),
  });

  return {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
    reset,
  };
};

export default useRegistrationForm;
