import { useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCurrentLang } from "../../../../../store/selectors";
import { EMAIL_SUBSCRIPTION_STATUSES, LOGGING_SUBSCRIBE_ACTION } from '../../../../../constants';
import calvin_success from '../../../../../assets/images/calvin_success.svg';
import calvin_hmmm from '../../../../../assets/images/calvin_hmmm.svg';
import { openLink, transformUsageTime } from '../../../../../helpers';
import styles from '../Support.module.scss';
import Input from '../../../form/input/Input';
import { getSubscriptionLanguageByLocale, subscribeEmail } from "../../../../../services/Subscribe";
import useSiteLinks from "../../../../../hooks/useSiteLinks";

const SubscribeModalContent = ({ report, status, setStatus }) => {
  const { t } = useTranslation();
  const { TOSLink, privacyPolicyLink } = useSiteLinks();
  const currentLocaleLang = useSelector(selectCurrentLang);

  const inputRef = useRef(null);

  const validateAndPrepareEmail = email => {
    const isValid = email.match(
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
    );
    if (isValid) {
      return email;
    }};


  const handleInputChange = e => {
    status && setStatus(null);
    inputRef.current = e.target.value;
  };

  const handleSubmit = async event => {
    event && event.preventDefault();
    report('click', transformUsageTime(window.currentPageUseageStartTime), LOGGING_SUBSCRIBE_ACTION.SUBSCRIBE);
    if (!inputRef.current) return setStatus(EMAIL_SUBSCRIPTION_STATUSES.WRONG_EMAIL);

    try {
      const email = inputRef.current.toLowerCase();
      const validatedEmail = validateAndPrepareEmail(email);

      if (!validatedEmail) {
        return setStatus(EMAIL_SUBSCRIPTION_STATUSES.WRONG_EMAIL);
      }

      setStatus(EMAIL_SUBSCRIPTION_STATUSES.SUBMITTING);
      const language = getSubscriptionLanguageByLocale(currentLocaleLang);
      const subscribeStatus = await subscribeEmail(email, language);
      setStatus(subscribeStatus);
    } catch (error) {
      setStatus(EMAIL_SUBSCRIPTION_STATUSES.ERROR);
      report(
        'click',
        transformUsageTime(window.currentPageUseageStartTime),
        LOGGING_SUBSCRIBE_ACTION.SUBSCRIBE,
        'error',
      );
    }
  };

  const MODAL_STATUS_DATA = {
    [EMAIL_SUBSCRIPTION_STATUSES.SUCCESS]: {
      HEADER: t('subscribe.success.header'),
      DESCRIPTION: (
        <>
          <span>{t('subscribe.success.description.text')}</span>
          <span className={styles.email}>{inputRef.current}</span>{t('subscribe.success.description.textend')}
        </>
      ),
      CALVIN: calvin_success,
    },
    [EMAIL_SUBSCRIPTION_STATUSES.ALREADY_SUBSCRIBED]: {
      HEADER: t('subscribe.already.header'),
      DESCRIPTION: (
        <>
          <span>{t('subscribe.already.description.text')}</span>
          <span className={styles.email}>{inputRef.current}</span>{t('subscribe.already.description.textend')}
        </>
      ),
      CALVIN: calvin_success,
    },
    [EMAIL_SUBSCRIPTION_STATUSES.ERROR]: {
      HEADER: t('subscribe.error.header'),
      DESCRIPTION: t('subscribe.error.description'),
      CALVIN: calvin_hmmm,
    },
  };

  const showResultedContent =
    status &&
    [
      EMAIL_SUBSCRIPTION_STATUSES.SUCCESS,
      EMAIL_SUBSCRIPTION_STATUSES.ALREADY_SUBSCRIBED,
      EMAIL_SUBSCRIPTION_STATUSES.ERROR,
    ].includes(status);

  if (showResultedContent) {
    return (
      <>
        <img className={styles[status]} src={MODAL_STATUS_DATA[status].CALVIN} alt="Calvin the cat" />
        <h2>{MODAL_STATUS_DATA[status].HEADER}</h2>
        <span className={styles.modalDescription} style={{ textAlign: 'center' }}>
          {MODAL_STATUS_DATA[status].DESCRIPTION}
        </span>
      </>
    );
  }

  return (
    <>
      <h2>{t('subscribe.join')}</h2>
      <span className={styles.modalDescription}>
        {t('subscribe.description')}
      </span>
      <Input
        onChange={handleInputChange}
        error={EMAIL_SUBSCRIPTION_STATUSES.WRONG_EMAIL === status && t('subscribe.error.input')}
        maxLength={50}
      />
      <span className={styles.modalAgreement}>
        <span>
          {t('subscribe.agreement.text')}
        </span>
        <a onClick={() => openLink(TOSLink)}>{t('subscribe.agreement.terms')}</a>
        <span>{t('subscribe.agreement.and')}</span>
        <a onClick={() => openLink(privacyPolicyLink)}>{t('subscribe.agreement.policy')}</a>
      </span>
      <button
        className={`${styles.subscribeButton}`}
        onClick={handleSubmit}
        disabled={EMAIL_SUBSCRIPTION_STATUSES.SUBMITTING === status}
        type="submit">
        {t('subscribe.subscribe')}
      </button>
    </>
  );
};

export default SubscribeModalContent;
