import { FC, PropsWithChildren } from 'react';

import './styles/index.scss';

type MessageContainerProps = {
  messageType: 'complete' | 'not-verified' | 'password-changed' | 'caution';
  variant?: 'dark' | 'light';
  className?: string;
};

const MessageContainer: FC<PropsWithChildren<MessageContainerProps>> = ({
  messageType,
  variant = 'dark',
  className = '',
  children,
}) => {
  return <div className={`message message--${variant} message--${messageType} ${className}`}>{children}</div>;
};

export default MessageContainer;
