import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import { fetchFromCDN } from '../../../../helpers';
import SwiperCore, { A11y, Autoplay, Controller, Navigation, Pagination, Scrollbar, Keyboard } from 'swiper';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import './index.scss';
import SingleGameScreenshotSlide from './SingleGameScreenshotSlide';
import { selectScreenshotsByGameId } from '../../../../store/selectors';
import { setGameScreenshots } from '../../../../store/dux/main';
import SingleGameScreenshotsNotLoaded from './SingleGameScreenshotsNotLoaded';
import styles from "../../../events/Events.module.scss";
import { ReactComponent as CrossIcon } from "../../../../assets/images/cross.svg";

const PREVIEW_IMAGE_STEP = 193;
const OPENED_IMAGE_STEP = 525;
const SINGLE_SCREENSHOT_ORIGINAL_HEIGHT = 768;

function SingleGameScreenshotsSlider({ gameId }) {
  SwiperCore.use([Scrollbar, Navigation, Controller, Pagination, Autoplay, A11y, Keyboard]);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);
  const [index, setIndex] = useState(0);
  const [sprite, setSprite] = useState(null);
  const [blobbing, setBlobbing] = useState(false);
  const [numberOfScreenshots, setNumberOfScreenshots] = useState(null);

  const screenshots = useSelector(state => selectScreenshotsByGameId(state, gameId));

  useEffect(function fetchScreenshots() {
    if (!screenshots?.image) {
      dispatch(setGameScreenshots(gameId));
    }
  }, []);

  useEffect(
    function handleImageToBlob() {
      (async () => {
        if (!screenshots?.image) return;
        setBlobbing(true);
        // todo: handle request with cdn thunk based on revision from store
        await fetchFromCDN(screenshots.image)
          .then(response => response.blob())
          .then(img => {
            const imgObj = URL.createObjectURL(img);
            const image = new Image();

            image.src = imgObj;

            image.onload = function () {
              const numberOfScreenshots = Math.ceil(image.height / SINGLE_SCREENSHOT_ORIGINAL_HEIGHT);
              setNumberOfScreenshots(numberOfScreenshots);
            };
            setSprite(imgObj);
            setBlobbing(false);
          })
          .catch(() => {
            setBlobbing(false);
          });
      })();
    },
    [screenshots.image],
  );

  const handleOpen = index => {
    setIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    index > 2 && firstSwiper.slideTo(index);
    setTimeout(() => secondSwiper.destroy(), 400);
  };

  return (
    <div className="game__slider">
      {!sprite || !numberOfScreenshots ? (
        <SingleGameScreenshotsNotLoaded gameId={gameId} blobbing={blobbing} />
      ) : (
        <>
          <Swiper
            className={`slider__list ${numberOfScreenshots > 3 ? null : 'hideButtons'}`}
            onSwiper={setFirstSwiper}
            spaceBetween={0}
            scrollbar={{
              hide: false,
              dragSize: 30,
              draggable: true,
              snapOnRelease: true,
            }}
            pagination={false}
            allowTouchMove={true}
            navigation
            slidesPerView={3}
            autoHeight={true}>
            {[...Array(numberOfScreenshots)].map((_, slideIndex) => {
              const yStep = slideIndex * PREVIEW_IMAGE_STEP;
              return (
                <SwiperSlide className="slider__item" key={slideIndex}>
                  <SingleGameScreenshotSlide
                    slide={sprite}
                    handleOpen={handleOpen}
                    slideIndex={slideIndex}
                    yStep={yStep}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <ReactModal isOpen={open} ariaHideApp={false} onAfterClose={handleClose} onRequestClose={handleClose}>
            <div className="game__slider--modal">
              <Swiper
                className="slider__list"
                slidesPerView={1}
                initialSlide={index}
                onSwiper={setSecondSwiper}
                controller={{
                  control: firstSwiper,
                  by: 'slide',
                }}
                navigation
                spaceBetween={50}
                onSlideChange={swiper => {
                  const { realIndex } = swiper;
                  setIndex(realIndex);
                }}
                effect="fade"
                keyboard={{ enabled: true }}>
                {[...Array(numberOfScreenshots)].map((_, slideIndex) => {
                  const yStep = !slideIndex || slideIndex * OPENED_IMAGE_STEP;
                  return (
                    <SwiperSlide className="bigSlide" key={slideIndex}>
                      <SingleGameScreenshotSlide
                        slide={sprite}
                        handleOpen={handleOpen}
                        slideIndex={slideIndex}
                        yStep={yStep}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <button type="button" className={`${styles.modal__close} close-button`} onClick={handleClose}>
                <CrossIcon className={styles.modal__closeIcon} />
              </button>
            </div>
          </ReactModal>
        </>
      )}
    </div>
  );
}

export default SingleGameScreenshotsSlider;
