import { createContext, useContext, useMemo } from "react";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import BannerActionButton from '../action/BannerActionButton';
import BackgroundFallback from './BackgroundFallback';
import { PromoDescription } from './PromoDescription';
import { selectCurrentLang } from '../../../../store/selectors';

const BannerContext = createContext();

function BannerSingle({ banner, game, children }) {
  const { gameId } = useParams();

  const [animationParams, setAnimationParams] = useSpring(() => ({
    xy: [0, 0],
    config: {
      mass: 10,
      tension: 1500,
      friction: 200,
    },
  }));
  const { i18n } = useTranslation();
  const language = i18n.language;

  function onMouseMoveHandler(e) {
    const rect = e.currentTarget.getBoundingClientRect();

    setAnimationParams({
      xy: calcAnimationParamsXY(e.clientX, e.clientY, rect.height),
      config: {
        tension: 1500,
      },
    });
  }

  function onMouseLeaveHandler() {
    setAnimationParams({
      xy: [0, 0],
      config: {
        tension: 750,
      },
    });
  }

  const value = useMemo(
    () => ({
      banner,
      game,
      onMouseMoveHandler,
      onMouseLeaveHandler,
      animationParams,
      language,
      gameId,
    }),
    [banner, game, animationParams, language, gameId, onMouseMoveHandler],
  );

  return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>;
}

BannerSingle.promo = function PromoBanner() {
  const { onMouseMoveHandler, onMouseLeaveHandler, banner } = useContext(BannerContext);
  const currentLanguage = useSelector(selectCurrentLang);
  const { title, description, subtitle, footer } = banner.lockit[currentLanguage];

  return (
    <div className="banner--slide" onMouseMove={onMouseMoveHandler} onMouseLeave={onMouseLeaveHandler}>
      <BackgroundFallback fallback={banner?.backgroundBlurImage} />
      <PromoDescription title={title} description={description} subtitle={subtitle} footerText={footer} />
      <div
        className="banner--slide-background"
        style={{
          backgroundImage: `url('../../${banner?.backgroundImage}`,
        }}
      />
    </div>
  );
};

BannerSingle.external = function ExternalBanner() {
  const { onMouseMoveHandler, onMouseLeaveHandler, banner, animationParams, gameId, game } = useContext(BannerContext);

  return (
    <div className="banner--slide" onMouseMove={onMouseMoveHandler} onMouseLeave={onMouseLeaveHandler}>
      <BackgroundFallback fallback={banner?.backgroundBlurImage} />
      <div
        className="banner--slide-background"
        style={{
          backgroundImage: `url('../../${banner?.backgroundImage}`,
          backgroundPosition: animationParams.xy.to(changeBackgroundPosition),
        }}
      />
      {!gameId && <BannerActionButton {...{ banner, game }} />}
    </div>
  );
};

BannerSingle.game = function GameBanner() {
  const { onMouseMoveHandler, onMouseLeaveHandler, banner, game, animationParams, language, gameId } =
    useContext(BannerContext);

  const logoImage = banner?.lockit[language]?.logoImage || banner?.lockit['en']?.logoImage;

  return (
    <div className="banner--slide" onMouseMove={onMouseMoveHandler} onMouseLeave={onMouseLeaveHandler}>
      <BackgroundFallback fallback={banner?.backgroundBlurImage} />
      <div
        className="banner--slide-background"
        style={{
          backgroundImage: `url('../../${banner?.backgroundImage}`,
          backgroundPosition: animationParams.xy.to(changeBackgroundPosition),
        }}
      />
      <figure className="banner--slide-logobody">
        <animated.img
          src={`../../${logoImage}`}
          className="banner--slide-logotype"
          style={{ transform: animationParams.xy.to(changeImagePosition) }}
          alt="Game Logotype"
          width="auto"
          height="auto"
        />
      </figure>
      <figure className="banner--slide-charbody">
        <animated.img
          src={`../../${banner?.characterImage}`}
          className="banner--slide-char"
          style={{ transform: animationParams.xy.to(changeImagePosition) }}
          alt="Game Character"
          width="auto"
          height="100%"
        />
      </figure>
      {!gameId && <BannerActionButton {...{ banner, game }} />}
    </div>
  );
};

function calcAnimationParamsXY(x, y, rect) {
  return [-(x - window.innerWidth / 2), -(y - rect / 2)];
}

function changeImagePosition(x, y) {
  return `translate3d(${x / 50}%, ${y / 55}%, 0)`;
}

function changeBackgroundPosition(x, y) {
  return `calc(${x / 50}px + 50%) calc(${y / 50}px + 50%)`;
}

export default BannerSingle;
