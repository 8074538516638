import { FC } from 'react';

import './styles/index.scss';

type LoaderProps = {
  className?: string
}

const Loader: FC<LoaderProps> = ({className = ''}) => {
  return <span className={`loader ${className}`}></span>;
};

export default Loader;
