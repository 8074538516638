/* eslint-disable */
import { createSelector } from 'reselect';
import { selectInstalledGames, selectAllGames, selectLaunchedGamesList } from '../../selectors';
import { checkIsNotExpiredByMs } from '../../../helpers';
import { checkIsEventActive } from '../../../services/Events';

const selectAllEvents = state => state.events.events.filter(event => checkIsNotExpiredByMs(event?.endTime));

export const selectAllActiveEvents = createSelector(
  [selectAllEvents, selectInstalledGames, selectAllGames, selectLaunchedGamesList],
  (allEvents, installedGames, allGames, launchedGames) => {
    if (!allEvents.length) return;
    const eventsInstalledGame = [];
    const eventsOther = [];

    const activeEvents = allEvents.filter(event =>
      checkIsEventActive(event.startTime, event.endTime, event.localUTC === 'true'),
    );

    activeEvents.forEach(event => {
      const game = allGames.find(game => game.id === event.gameId);
      if (!game) return;
      const foundInstalledGames = installedGames.find(installedGame => installedGame.xpromoId === game.xpromoId);
      if (foundInstalledGames) return eventsInstalledGame.push(event);
      eventsOther.push(event);
    });

    if (!eventsInstalledGame.length && !eventsOther.length) return;

    const shuffleLaunchedEvents = events => {
      const launchedEvents = [];
      for (const gameId of launchedGames) {
        for (const event of events) {
          if (event.gameId === gameId) {
            launchedEvents.unshift(event);
          }
        }
      }
      return launchedEvents;
    };
    const shuffleLaunchedGameEvents = shuffleLaunchedEvents(eventsInstalledGame);
    return {
      ...(shuffleLaunchedGameEvents.length && {
        shuffleLaunchedGameEvents,
      }),
      ...(eventsInstalledGame.length && {
        eventsInstalledGame: eventsInstalledGame.filter(event => !launchedGames.includes(event.gameId)),
      }),
      ...(eventsOther.length && { eventsOther }),
    };
  },
);

export const selectEventsByGameId = createSelector([selectAllEvents, (state, gameId) => gameId], (events, gameId) => {
  const gameEvents = events.filter(
    event => event.gameId === gameId && checkIsEventActive(event.startTime, event.endTime, event.localUTC === 'true'),
  );
  return gameEvents.length ? gameEvents : null;
});
