import { FC, PropsWithChildren } from 'react';
import './styles/index.scss';

type SectionContainerProps = {
  colorTheme?: 'primary' | 'secondary' | 'tertiary';
  sectionType?: 'type-1' | 'type-2' | 'type-3' | 'type-4';
};

const SectionContainer: FC<PropsWithChildren<SectionContainerProps>> = ({
  colorTheme = 'primary',
  sectionType = 'type-1',
  children,
}) => {
  return (
    <section className={`section-container section-container--${colorTheme} section-container--${sectionType}`}>
      {children}
    </section>
  );
};

export default SectionContainer;
