import ColoredScrollbars from "./ColoredScrollbars";
import Footer from "../footer/Footer";

export function PageScrollbar({ children, profile = false }) {
  return (
    <ColoredScrollbars autoHeight autoHeightMin="100vh">
      {children}
      <Footer profile={profile}/>
    </ColoredScrollbars>
  );
}