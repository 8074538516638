import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProfileDataList } from '../../commons/ProfileDataList';
import { selectUserData } from '../../../../store/dux/auth/selectors';

function ProfileInfoData() {
  const { t } = useTranslation();
  const { birthday, gender, email, nick, name } = useSelector(selectUserData);
  return (
    <ProfileDataList
      data={[
        { label: t('profile.words.nick'), value: nick },
        { label: t('profile.words.name'), value: name },
        { label: t('profile.words.birthday'), value: birthday },
        {
          label: t('profile.words.gender'),
          value: gender ? t(`profile.words.${gender}`) : undefined,
        },
        ...(email ? [{ label: t('profile.words.email'), value: email }] : []),
      ]}
    />
  );
}

export default ProfileInfoData;
