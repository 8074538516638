import { format, intervalToDuration, differenceInHours, Locale, formatDuration, differenceInDays } from "date-fns";
import i18 from 'i18next';
import englishLocale from 'date-fns/locale/en-US';
import koreanLocale from 'date-fns/locale/ko';
import germanLocale from 'date-fns/locale/de';
import frenchLocale from 'date-fns/locale/fr';
import italianLocale from 'date-fns/locale/it';
import spanishLocale from 'date-fns/locale/es';
import swedishLocale from 'date-fns/locale/sv';
import russianLocale from 'date-fns/locale/ru';
import chineseLocale from 'date-fns/locale/zh-CN';
import taiwaneseLocale from 'date-fns/locale/zh-TW';
import japaneseLocale from 'date-fns/locale/ja';
import portugueseLocale from 'date-fns/locale/pt';
import dutchLocale from 'date-fns/locale/nl';
import store from '../store';
import { LanguageTypes } from '../constants';

type TimeLocalizationsByKeysType = {
  [key in LanguageTypes]: Locale;
};

enum EventRemainingTimeThresholds {
  LESS_72 = 72,
  LESS_24 = 24,
}

export enum TimeoutStamps {
  MINUTE = 60000,
  SECOND = 1000,
}

const DELIMITER = '|';

const timeLocalizationsByKeys: TimeLocalizationsByKeysType = {
  en: englishLocale,
  de: germanLocale,
  fr: frenchLocale,
  it: italianLocale,
  es_es: spanishLocale,
  es: spanishLocale,
  sv: swedishLocale,
  ru: russianLocale,
  zh: chineseLocale,
  zh_tw: taiwaneseLocale,
  ko: koreanLocale,
  ja: japaneseLocale,
  pt_pt: portugueseLocale,
  pt: portugueseLocale,
  nl: dutchLocale,
};

enum TimeMarkTypes {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  DATE = 'DATE',
}

type RemainingTimeReturnType = {
  timeArr: string[];
  urgency?: boolean;
  timeout: TimeoutStamps,
};

type TimeMarkPatternsType = {
  [key in TimeMarkTypes]: (n: number, l?: string) => string;
};

const timeMarkPatterns: TimeMarkPatternsType = {
  [TimeMarkTypes.SECONDS]: (timeStamp: number): string => {
    const seconds = Math.floor((new Date().getTime() - timeStamp) / 1000);
    return i18.t(`time.second${seconds > 1 ? 's' : ''}`, { seconds });
  },
  [TimeMarkTypes.MINUTES]: (timeStamp: number): string => {
    const minutes = Math.floor((new Date().getTime() - timeStamp) / (60 * 1000));
    return i18.t(`time.minute${minutes > 1 ? 's' : ''}`, { minutes });
  },
  [TimeMarkTypes.TODAY]: (timeStamp: number, lang: string): string => {
    const h24 = lang !== 'en';
    return format(new Date(timeStamp), `${h24 ? 'HH' : 'hh'}:mm${h24 ? '' : 'aaa'}`, {
      locale: timeLocalizationsByKeys[lang],
    });
  },
  [TimeMarkTypes.YESTERDAY]: (timeStamp: number, lang: string): string => {
    const h24 = lang !== 'en';
    return `${i18.t('time.yesterday')}, ${format(
      new Date(timeStamp),
      `${h24 ? 'HH' : 'hh'}:mm${lang === 'en' ? 'aaa' : ''}`,
      {
        locale: timeLocalizationsByKeys[lang],
      },
    )}`;
  },
  [TimeMarkTypes.DATE]: (timeStamp: number, lang: string): string => {
    const h24 = lang !== 'en';
    return format(new Date(timeStamp), `MMM dd, yy ${h24 ? 'HH' : 'hh'}:mm${lang === 'en' ? 'aaa' : ''}`, {
      locale: timeLocalizationsByKeys[lang],
    });
  },
};

const isYesterday = (dateN: number): boolean => {
  if (!dateN) return;
  const date = new Date(dateN);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  );
};

export const getTimeAgo = (time: number): string => {
  const intervalMs = intervalToDuration({ start: Date.now(), end: time });
  const {
    settings: { language },
  } = store.getState().main;

  let timeMark;

  if (intervalMs.seconds) {
    timeMark = TimeMarkTypes.SECONDS;
  }

  if (intervalMs.minutes) {
    timeMark = TimeMarkTypes.MINUTES;
  }

  if (intervalMs.hours) {
    timeMark = TimeMarkTypes.TODAY;
  }

  if (intervalMs.hours || intervalMs.days < 2) {
    const yesterday = isYesterday(time);
    if (yesterday) {
      timeMark = TimeMarkTypes.YESTERDAY;
    }
  }

  if (intervalMs.days >= 2 || intervalMs.months || intervalMs.weeks || intervalMs.years) {
    timeMark = TimeMarkTypes.DATE;
  }

  return timeMarkPatterns[timeMark](time, language);
};

const formatRemainingTimeString = (timeString: string): string[] => {
  return timeString.split(DELIMITER).map(timeUnit => {
    const timeDigits = timeUnit.match(/\d+/g)?.[0];
    if (!timeDigits) return timeUnit;
    let formattedTimeUnit = timeUnit.match(/\D+/g)[0];
    if (!['ko', 'zh', 'zh_tw', 'ja'].includes(i18.language)) {
      formattedTimeUnit = formattedTimeUnit.trim().charAt(0).toLowerCase();
    }
    return timeDigits + formattedTimeUnit;
  });
};

export const getEventRemainingTime = (expirationTime: number): RemainingTimeReturnType => {
  const now = Date.now();
  // temporary patch
  const locale =  ['sv', 'nl'].includes(i18.language) ? 'en' : i18.language;
  const remainingHours = differenceInHours(expirationTime, now);
  const { hours, minutes, seconds } = intervalToDuration({ start: Date.now(), end: expirationTime });
  if (remainingHours < EventRemainingTimeThresholds.LESS_72) {
    const remainingTimeString = formatDuration(
      { hours: remainingHours, minutes, seconds },
      {
        format: ['hours', 'minutes', 'seconds'],
        locale: timeLocalizationsByKeys[locale],
        delimiter: DELIMITER,
        zero: true,
      },
    );

    return {
      timeArr: formatRemainingTimeString(remainingTimeString),
      urgency: remainingHours < EventRemainingTimeThresholds.LESS_24,
      timeout: TimeoutStamps.SECOND,
    };
  }

  const remainingDays = differenceInDays(expirationTime, now);
  const remainingTimeString = formatDuration(
    { days: remainingDays, hours, minutes },
    {
      format: ['days', 'hours', 'minutes'],
      locale: timeLocalizationsByKeys[locale],
      delimiter: DELIMITER,
      zero: true,
    },
  );
  return {
    timeArr: formatRemainingTimeString(remainingTimeString),
    timeout: TimeoutStamps.MINUTE,
  };
};
