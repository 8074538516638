import { useHistory } from 'react-router-dom';
import styles from './NoEvents.module.scss';
import InstalledGamesList from '../../pages-content/installed-games/list/InstalledGamesList';
import { useSelector } from 'react-redux';
import { selectAllGames } from '../../../store/selectors';
import { G5LAUNCHER_ROUTES, REFERRAL_SOURCES } from "../../../constants";
import { useTranslation } from 'react-i18next';
import { ContentWrapper } from '../../shared/layout/Wrappers/Wrappers';
import { ReactComponent as LookingFor } from '../../../assets/images/lookingfor.svg';
import { Button } from '../../../Profile/components/commons/Button';

const NoEvents = () => {
  const games = useSelector(selectAllGames);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ContentWrapper classes={[styles.noEvents]}>
      <div className={styles.noEvents__data}>
        <LookingFor />
        <h2>{t<string>('events.noevents')}</h2>
        <p>
          <span>{t<string>('events.noevents_desc')}</span>
          <span> {t<string>('events.noevents_desc1')}</span>
        </p>

        <Button
          variant="tertiary"
          sizeMode="7"
          onClick={() => {
            history.push(G5LAUNCHER_ROUTES.ALL_GAMES);
          }}>
          <span>{t<string>('events.explore')}</span>
        </Button>
      </div>
      <div className={styles.otherGamesContainer}>
        <div className={styles.otherGamesContainer__list}>
          <InstalledGamesList
            {...{
              title: t('games.installed.full.similar.title'),
              games: games.slice(0, 4),
              clientLibInstalledApps: [],
            }}
            passedSource={REFERRAL_SOURCES.INSTALL.MY_GAMES}
          />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default NoEvents;
