import { forwardRef, useState } from 'react';
import { ReactComponent as NavIcon } from '../../../../assets/images/navxpromo.svg';
import styles from './NavButton.module.scss';
import Tooltip from '../../header/tooltip/Tooltip';

type RefType = HTMLButtonElement;
type NavButtonPropsType = {
  direction: 'next' | 'prev';
  text: string;
  fn: () => void;
};

export const directionParams = {
  next: { top: '55px', right: '5px', left: 'unset', transform: 'unset' },
  prev: { top: '55px', left: '5px', transform: 'unset' },
};

const NavButton = forwardRef<RefType, NavButtonPropsType>(({ direction, text, fn }, ref) => {
  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <button
      ref={ref}
      className={styles[direction]}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={fn}>
      <NavIcon />
      {hovering && (
          <Tooltip text={text} passedStyles={directionParams[direction]} />
      )}
    </button>
  );
});

export default NavButton;
