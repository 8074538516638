import store from '../store';
import { serviceLogger } from '../logger';
import { LOCAL_STORAGE_KEYS } from '../constants';
import { setCurrentUserRegion } from "../store/dux/main";

export const getAndSetUserRegion = (): void => {
  const {
    udid: deviceId,
    xpromoId,
    settings: { language },
  } = store.getState().main;
  fetch(
    'https://specialdeals.g5e.com/v4/getClientData?' + new URLSearchParams({ deviceId, xpromoId, locale: language }),
  )
    .then(response => response.json())
    .then(response => {
      const { countryCode } = response;
      if (!countryCode) return;
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.USER_REGION, countryCode);
      store.dispatch(setCurrentUserRegion(countryCode));
    })
    .catch(error => {
      serviceLogger.error('getAndSetUserRegion()', error);
    });
};

type CountryRestrictionsType = {
  [id: string]: string[];
};

export const getBlockedGames = (region: string, restrictions: CountryRestrictionsType) => {
  const blockedGames: string[] = [];

  for (const gameId in restrictions) {
    if (restrictions[gameId].includes(region)) {
      blockedGames.push(gameId);
    }
  }

  return blockedGames;
}
