import { FC, useContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '../../commons/Button';
import { FormWrapper } from '../../commons/Form/FormWrapper';
import { FormFieldWrapper } from '../../commons/Form/FormFieldWrapper';
import { Label } from '../../commons/Form/Label';
import { Input } from '../../commons/Form/Input';
import { DatePicker } from '../DatePicker';
import { Select } from '../../commons/Form/Select';
import { selectUserData } from '../../../../store/dux/auth/selectors';
import { NotificationTypes, ProfileContext } from '../../../pages/Profile/Profile';
import useUpdateProfileForm from '../../../hooks/useUpdateProfileForm';
import { AuthActionTypes } from '../../../../store/dux/auth/actionTypes';
import { renderLogger } from '../../../../logger';
import { getExternalError } from '../../../utils/errors';
import PGPLService from '../../../services/PGPLService';
import { formatDateToPGPL } from "../../../pages/Profile/Profile";

const ProfileInfoForm: FC = ({ toggleEdit }: { toggleEdit: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { birthday, underageConfirmStatus, gender, email, nick, name } = useSelector(selectUserData);
  const { register, handleSubmit, errors, setError, watch, setValue } = useUpdateProfileForm();
  const { callbackRef, setNotification, editingUnderageDate, setIsLoading } = useContext(ProfileContext);
  const bdayForm = watch('birthday');

  useEffect(() => {
    email && setValue('email', email);
  }, []);

  const saveAndSubmit = async data => {
    callbackRef.current = () => saveAndSubmit(data);
    const dataCopy = { ...data };
    if (data?.email === email) {
      delete dataCopy['email'];
    }
    setIsLoading(true);
    try {
      if (dataCopy?.birthday) {
        await PGPLService.setPlayerBirthday(dataCopy.birthday);
      }
      await PGPLService.updatePlayer(dataCopy);
      const profileData = await PGPLService.getPlayerInfo();
      dispatch({
        type: AuthActionTypes.UPDATE_PROFILE_DATA,
        data: profileData,
      });
      setNotification(NotificationTypes.PROFILE_CHANGE);
      toggleEdit();
    } catch (error) {
      renderLogger.error(`saveAndSubmit(${JSON.stringify(data)})`, JSON.stringify(error));
      if (error.error === 'NameInUse') {
        setError('name', { type: 'custom', message: getExternalError('name', 'InvalidParameter') });
      }
      if (error.error === 'EmailInUse') {
        setError('email', { type: 'custom', message: getExternalError('email', error.error) });
      }
      if (error.error === 'InvalidParameter') {
        setError(error.parameterName, {
          type: 'custom',
          message: getExternalError(error?.parameterName, error.error),
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const birthdayToShow = formatDateToPGPL(bdayForm) || birthday;

  return (
    <>
      <FormWrapper className="profile__form profile-form" id="g5Friends_profile_form">
        <FormFieldWrapper error={errors?.nick?.message}>
          <Label variant="primary" htmlFor="username">
            {t<string>('profile.words.nick')}
          </Label>
          <Input
            type="text"
            id="username"
            name="nick"
            placeholder={t<string>('profile.placeholders.nick')}
            defaultValue={nick}
            register={register}
            className={`${errors?.nick && 'error'}`}
          />
        </FormFieldWrapper>
        <FormFieldWrapper error={errors?.name?.message}>
          <Label variant="primary" htmlFor="player-login">
            {t<string>('profile.words.name')}
          </Label>
          <Input
            type="text"
            placeholder="Player158696459"
            id="player-login"
            name="name"
            defaultValue={name}
            register={register}
            className={`${errors?.name && 'error'}`}
          />
        </FormFieldWrapper>
        <FormFieldWrapper size="half">
          <Label variant="primary" htmlFor="ProfileForm_birthday">
            {t<string>('profile.words.birthday')}
          </Label>
          <DatePicker
            birthDate={birthdayToShow}
            setNotification={setNotification}
            setValue={setValue}
            underageConfirmStatus={underageConfirmStatus}
          />
        </FormFieldWrapper>
        <FormFieldWrapper size="half">
          <Label variant="primary" htmlFor="ProfileForm_gender">
            {t<string>('profile.words.gender')}
          </Label>
          <Select name="gender" setValue={setValue} defaultValue={gender} />
        </FormFieldWrapper>
        <FormFieldWrapper error={errors?.email?.message}>
          <Label variant="primary" htmlFor="profile-email" isRequired>
            {t<string>('profile.words.email')}
          </Label>
          <Input
            type="text"
            placeholder="example@address.com"
            id="profile-email"
            name="email"
            register={register}
            defaultValue={email}
            className={`${errors?.email && 'error'}`}
          />
        </FormFieldWrapper>
        <Button
          variant="primary"
          sizeMode="3"
          className="profile__form-btn"
          onClick={handleSubmit(saveAndSubmit)}
          disabled={editingUnderageDate}>
          {t<string>('profile.buttons.save')}
        </Button>
        <Button variant="secondary" sizeMode="3" className="profile__form-btn" type="reset" onClick={toggleEdit}>
          {t<string>('profile.buttons.cancel')}
        </Button>
      </FormWrapper>
    </>
  );
};

export default ProfileInfoForm;
