import { useTranslation } from 'react-i18next';
import FooterLegalList from './legal/FooterLegalList';
import styles from './Footer.module.scss';

function Footer({ profile }) {
  const { t } = useTranslation();
  return (
    <footer className={`${styles.footer} ${profile ? styles.profile : ''}`}>
      <p className={styles.copyright}>
        <span>{t('footer.copyright.name')}</span>
        <span>{`©${new Date().getFullYear()} ${t('footer.copyright.company')}`}</span>
      </p>
      <div className={styles['footer-bottom']}>
        <FooterLegalList />
      </div>
    </footer>
  );
}

export default Footer;
