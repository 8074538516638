import { I18nextProvider } from 'react-i18next';
import { renderLogger } from '../logger';
import AppSwitch from './AppSwitch';
import '../assets/styles/shared/_base.scss';
import { Notifications } from './notifications';
import { PushNotifications } from './pushNotifications';
import Update from './system/update/Update';
import useNavigateOnOpenURI from '../hooks/useNavigateOnOpenURI';
import Layout from './shared/layout/Layout';
import { TakeoverBannerWrapper } from './shared/takeoverBanner';
import { usePGPLGameStats } from '../Profile/hooks/usePGPLGameStats';
import { useClickLogging } from '../hooks/useClickLogging';
import i18n from '../i18n';
import useRegionalRestrictions from "../hooks/useRegionalRestrictions";
import useXPromoContent from "../hooks/useXPromoContent";

function App() {
  renderLogger.debug('App');
  useNavigateOnOpenURI();
  usePGPLGameStats();
  useClickLogging();
//  useABTestingLogging();
  useXPromoContent();
  useRegionalRestrictions();

  return (
    <I18nextProvider i18n={i18n}>
      <Layout>
        <AppSwitch />
        <Notifications />
        <PushNotifications />
        <Update />
      </Layout>
      <TakeoverBannerWrapper />
    </I18nextProvider>
  );
}

export default App;
