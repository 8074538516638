import { createSelector } from 'reselect';
import { selectAllGames, selectInstalledGamesIDs } from '../../selectors';

export const selectUserData = state => state.auth.profile;

export const selectIsAuth = state => Object.keys(state.auth.profile).length;

export const selectInvalidAccessTokenPlayerId = state => state?.auth?.invalidAccessTokenPlayerId;

export const selectInvalidAccessTokenStatus = state => state?.auth?.invalidAccessToken;

export const selectOfflineStatus = state => state?.auth.offline;

export const selectOfflineInterval = state => state?.auth.offlineInterval;

export const selectPGPLId = state => state.auth.profile.id;

export const selectProfilePGPLGameIDs = state => state.auth?.profile?.gameIds;

export const selectProfilePGPLData = state => state.auth?.profileGames;

export const selectProfileGames = createSelector(
  [selectAllGames, selectInstalledGamesIDs, selectProfilePGPLData],
  (allGames, installedGameIDs, userPGPLGames) => {
    const filterByClientLibInstalledApps = game => {
      return installedGameIDs.some(item => game.packageId === item['packageId'] || game.xpromoId === item['xpromoId']);
    };
    const allInstalledGames = allGames.filter(game => filterByClientLibInstalledApps(game));
    const allNotInstalledGames = allGames.filter(game => !filterByClientLibInstalledApps(game));
    if (!userPGPLGames || !userPGPLGames.length) return;

    const installedOnCurrentDevice = allInstalledGames.filter(installedGame => {
      return userPGPLGames.some(game => game.gameId === installedGame.pgplId);
    });
    const installedOnOtherDevices = allNotInstalledGames.filter(notInstalledGame => {
      return userPGPLGames.some(game => game.gameId === notInstalledGame.pgplId);
    });
    const otherInstalledOnCurrentDevice = allInstalledGames.filter(installedGame => {
      return !installedOnCurrentDevice.some(game => game.pgplId === installedGame.pgplId);
    });
    const allKnownGames = [...installedOnCurrentDevice, ...installedOnOtherDevices, ...otherInstalledOnCurrentDevice];
    const otherGames = allGames.filter(game => {
      return !allKnownGames.some(knownGame => knownGame.pgplId === game.pgplId);
    });

    return {
      installedOnCurrentDevice,
      installedOnOtherDevices,
      otherInstalledOnCurrentDevice,
      otherGames,
    };
  },
);

export const selectGameStatsByPGPLID = pgplId => state => {
  const userPGPLGames = state.auth?.profileGames;
  if (!userPGPLGames) return;
  return userPGPLGames.find(stats => stats.gameId === pgplId);
};
