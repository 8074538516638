import { IPushNotificationsActionTypes, PushNotificationsActionTypes } from './actionTypes';
import {
  deletePushNotification,
  getPushNotifications,
  PushNotificationType,
} from '../../../services/PushNotifications';
import { checkIsNotExpiredByMs } from '../../../helpers';
import { logger } from '../../../logger';
import { ReadPushNotificationsStorage } from '../../../services/Storage';
import { LOCAL_STORAGE_KEYS } from '../../../constants';

const initialState = {
  pushNotifications: [],
  newPushNotifications: 0,
  open: false,
  ding: false,
};

type PushNotificationsState = typeof initialState;

const readPushNotificationsStorage = new ReadPushNotificationsStorage(LOCAL_STORAGE_KEYS.READ_PUSH_NOTIFICATIONS);

function pushNotificationsReducer(state: PushNotificationsState = initialState, action: IPushNotificationsActionTypes) {
  switch (action.type) {
    case PushNotificationsActionTypes.TOGGLE_PUSHNOTIFICATIONS_PANEL:
      return {
        ...state,
        open: action.open,
      };
    case PushNotificationsActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        pushNotifications: action.pushNotifications,
      };
    case PushNotificationsActionTypes.SET_DINGDONG:
      return {
        ...state,
        ding: action.status,
      };
    case PushNotificationsActionTypes.SET_SINGLE_PUSHNOTIFICATION:
      if (state.pushNotifications.find(n => n.id === action.pushNotification.id)) return state;
      return { ...state, pushNotifications: [action.pushNotification, ...state.pushNotifications] };
    case PushNotificationsActionTypes.DELETE_PUSHNOTIFICATION:
      const indexToDelete = state.pushNotifications.findIndex(n => n.uid === action.uid);
      if (indexToDelete === -1) return state;
      return [...state.pushNotifications.slice(0, indexToDelete), ...state.pushNotifications.slice(indexToDelete + 1)];
    case PushNotificationsActionTypes.MARK_PUSHNOTIFICATION_AS_READ:
      const indexToChange = state.pushNotifications.findIndex(n => n.uid === action.uid);
      if (indexToChange === -1) return state;
      const pushNotificationToChange = { ...state.pushNotifications[indexToChange], read: true };
      return {
        ...state,
        pushNotifications: [
          ...state.pushNotifications.slice(0, indexToChange),
          ...state.pushNotifications.slice(indexToChange + 1),
          pushNotificationToChange,
        ],
      };
    default:
      return state;
  }
}

export const setPushNotifications = () => {
  return async dispatch => {
    const fetchedPushNotifications = await getPushNotifications();

    if (!fetchedPushNotifications) return;

    const pushNotifications = fetchedPushNotifications.filter(n => checkIsNotExpiredByMs(n.expirationTime));

    dispatch({
      type: PushNotificationsActionTypes.SET_NOTIFICATIONS,
      pushNotifications,
    });
  };
};

export const addPushNotification = (pushNotification: PushNotificationType) => ({
  type: PushNotificationsActionTypes.SET_SINGLE_PUSHNOTIFICATION,
  pushNotification,
});

export const setDingDong = (status: boolean) => ({
  type: PushNotificationsActionTypes.SET_DINGDONG,
  status,
});

export const deletePushNotificationByUid = (uid: string) => {
  return async dispatch => {
    try {
      await deletePushNotification(uid);
      dispatch({
        type: PushNotificationsActionTypes.DELETE_PUSHNOTIFICATION,
        uid,
      });
    } catch (error) {
      logger.error(`deletePushNotificationByUid('${uid}'), ${JSON.stringify(error)}`);
    }
  };
};

export const markPushNotificationAsRead = (uid: string) => {
  return async dispatch => {
    try {
      readPushNotificationsStorage.markAsRead(uid);
      dispatch({
        type: PushNotificationsActionTypes.MARK_PUSHNOTIFICATION_AS_READ,
        uid,
      });
    } catch (error) {
      logger.error(`markPushNotificationAsRead('${uid}'), ${JSON.stringify(error)}`);
    }
  };
};

export const setPushNotificationsPanelOpen = (open) => {
  return (dispatch) => {
    dispatch({
      type: PushNotificationsActionTypes.TOGGLE_PUSHNOTIFICATIONS_PANEL,
      open,
    });
  };
};

export default pushNotificationsReducer;
