import { FC, useState } from 'react';
import { Input, InputProps } from '../Input';

import { ReactComponent as EyeIcon } from '../../../../assets/images/svg/eye.svg';
import { ReactComponent as EyeCrossIcon } from '../../../../assets/images/svg/eye-cross.svg';

import './styles/index.scss';

const InputPassword: FC<InputProps> = ({ id, name, placeholder, register, ...props }) => {
  const [isPasswordVisibile, togglePasswordVisibility] = useState(false);
  return (
    <div className="input-password">
      <Input
        id={id}
        type={isPasswordVisibile ? 'text' : 'password'}
        placeholder={placeholder}
        register={register}
        name={name}
        maxlength={32}
        {...props}
      />
      <button
        className="input-password__btn"
        type="button"
        onClick={() => togglePasswordVisibility(!isPasswordVisibile)}>
        {isPasswordVisibile ? (
          <EyeIcon className="input-password__visible-icon" />
        ) : (
          <EyeCrossIcon className="input-password__hidden-icon" />
        )}
      </button>
    </div>
  );
};

export default InputPassword;
