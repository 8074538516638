import { FC, PropsWithChildren } from 'react';
import { FeatureList } from '../FeatureList';
import { GridContainer } from '../../containers/GridContainer';
import { Headline } from '../../commons/Headline';

import maskot from '../../../assets/images/png/maskot.png';
import styles from './styles/index.module.scss';

const HeaderSignIn: FC<PropsWithChildren> = ({ children }) => {
  return (
    <header className={styles['header']}>
      <GridContainer containerType="type-3">
        <div className={styles['header__content']}>
          <Headline headlineLevel="h1" variant="primary" size="1" className={styles['header__headline']}>
            {children}
          </Headline>
          <img className={styles['header__maskot']} src={maskot} alt="" width="83" height="130" />
          <div className={styles['header__right']}>
            <FeatureList />
          </div>
        </div>
      </GridContainer>
    </header>
  );
};

export default HeaderSignIn;
