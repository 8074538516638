import { FC, PropsWithChildren } from 'react';
import { Overlay } from '../../commons/Overlay';
import { CloseButton } from '../../commons/CloseButton';

import './styles/index.scss';

type ModalContainerProps = Partial<{
  id: string;
  className: string;
  closeDisabled: boolean; // using only in components-content/ModalAgeAccess
  size: 'sm' | 'lg';
  onClose: () => void;
}>;

const ModalContainer: FC<PropsWithChildren<ModalContainerProps>> = ({
  id,
  className = '',
  closeDisabled = false,
  size,
  children,
  onClose,
}) => {

  return (
    <div id={id} className={`modal ${className}`}>
      <Overlay />
      <div className={`modal__wrapper modal__wrapper--${size}`}>
        {!closeDisabled && <CloseButton variant="primary" className="modal__close" onClick={onClose} />}
        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
};

export default ModalContainer;
