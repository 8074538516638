import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { createPortal } from "react-dom";
import PushNotificationsContainer from "./PushNotificationsContainer";
import styles from './pushNotifications.module.scss';
import { selectPushNotificationsPanelState } from "../../store/dux/pushNotifications/selectors";
import useClickOutside from "../../hooks/useClickOutside";
import { setPushNotificationsPanelOpen } from "../../store/dux/pushNotifications/pushNotifications";

export default function PushNotificationsPortal() {

  const open = useSelector(selectPushNotificationsPanelState);
  const dispatch = useDispatch();

  const rootAside: HTMLElement = useMemo(() => {
    if (!open) return null;
    const asideElem = document.createElement('aside');
    asideElem.classList.add(styles.pushNotificationsContainer);
    return asideElem;
  }, [open]);

  useClickOutside({ current: rootAside }, () => dispatch(setPushNotificationsPanelOpen(false)));

  useEffect(
    function handlePushNotificationsMount() {
      if (rootAside) {
        document.body.appendChild(rootAside);
      }
      return () => {
        rootAside && document.body.removeChild(rootAside);
      };
    },
    [rootAside],
  );

  if(open) {
    return createPortal(<PushNotificationsContainer />, rootAside);
  }

  return null;
}