import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './Support.module.scss';
import { ReactComponent as SettingsIcon } from '../../../../assets/images/settings.svg';
import Tooltip from '../tooltip/Tooltip';

const Settings = ({ report }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [hovering, setHovering] = useState(false);
  const route = {
    href: '/settings',
    exact: true,
  };

  const isActive = location.pathname === route.href;

  const handleRouteClick = () => {
    !isActive && history.push(route.href);
  };

  return (
    <div
      className={`${styles['link']} ${isActive ? styles['link--active'] : ''}`}
      onClick={handleRouteClick}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}>
      <SettingsIcon />
      {hovering && <Tooltip text={t('header.navigation.list.settings')} top={41} />}
    </div>
  );
};

export default Settings;
