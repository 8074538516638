import { Parag } from '../../../commons/Parag';
import { CircleWrapper } from '../../../commons/CircleWrapepr';
import { useTranslation } from "react-i18next";
import cautionIcon from '../../../../assets/images/svg/caution-sign.svg';

function MessageCaution() {
  const { t } = useTranslation();
  return (
    <>
      <CircleWrapper as="div" variant="orange" className="message__circle-wrapper">
        <img className="message__img" src={cautionIcon} height="20" width="20" alt="" />
      </CircleWrapper>
      <Parag variant="primary">
        {t<string>('profile.message.caution_password')}
      </Parag>
    </>
  );
}

export default MessageCaution;
