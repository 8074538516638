import React, { PropsWithChildren } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuth, selectInvalidAccessTokenPlayerId } from '../../../../store/dux/auth/selectors';
import Home from '../../../../components/pages/Home';
import { useHistory } from 'react-router-dom';
import { profileRoutes } from '../../../routes';

const ProtectedRoute = ({ children, ...rest }: PropsWithChildren<RouteProps>) => {
  const isAuth = useSelector(selectIsAuth);
  const invalidTokenId = useSelector(selectInvalidAccessTokenPlayerId);
  const history = useHistory();

  const renderProtectedComponent = props => {
    if (invalidTokenId) {
      return history.push(profileRoutes.signIn);
    }
    if (isAuth) {
      return React.Children.map(children, child => {
        if (React.isValidElement(child)) return React.cloneElement(child, { key: props.location.key });
      });
    }
    return <Home />;
  };
  return <Route {...rest} render={props => renderProtectedComponent(props)} />;
};

export default ProtectedRoute;
