import { FC, PropsWithChildren } from 'react';

import './styles/index.scss';

type FormWrapperProps = Partial<{
  id: string;
  className: string;
  handleSubmit: () => void;
}>;

const FormWrapper: FC<PropsWithChildren<FormWrapperProps>> = ({ id, className = '', handleSubmit, children }) => {
  return (
    <form id={id} className={`form ${className}`} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

export default FormWrapper;
