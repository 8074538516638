import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { openLink } from '../../../../helpers';
import { REFERRAL_SOURCES } from '../../../../constants';

function BannerActionButton({ banner, game, video, size }) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const button = (function () {
    if (!game) {
      const link = banner?.externalLink;
      return (
        <span onClick={() => openLink(link)} className="btn btn--default btn--green">
          {banner?.lockit[language].buttonText}
        </span>
      );
    }

    return (
      <Link
        to={{
          pathname: `${game.id}`,
          state: { id: game.id, startAutoDownload: true, startGame: true },
          search: REFERRAL_SOURCES.INSTALL.TOP_BANNER,
        }}
        className={`btn btn--default btn--green ${video ? 'btn_border' : ''} ${size}`}>
        {t('shared.buttons.play')}
      </Link>
    );
  })();

  return <div className="banner--slide-link">{button}</div>;
}

export default BannerActionButton;
