import i18n from 'i18next';
import clientLib from '../clientLib';
import { debugLogData, reportSetSettings } from "../helpers";
import { serviceLogger } from '../logger';
import { LANGUAGES } from '../constants';

const getAppropriateLanguage = (locale) => {
  try {
    const localeToCheck = locale.toLowerCase();
    if(LANGUAGES.includes(localeToCheck)) return localeToCheck;
    const generalLangToCheck = localeToCheck.substring(0, 2);
    if(LANGUAGES.includes(generalLangToCheck)) return generalLangToCheck;
    return 'en';
  } catch (error) {
    serviceLogger.error(`getAppropriateLanguage('${locale}')`, error);
    return 'en';
  }
};

export const getSettings = async () => {
  try {
    const argId = `init_${Date.now()}`;
    const settings = await clientLib.sendRequest('getSettings', null, argId);
    debugLogData(`getSettings()`, settings, serviceLogger);

    if (settings.language !== i18n.language) {
      settings.language = getAppropriateLanguage(settings.language);

      i18n
        .changeLanguage(settings.language)
        .catch((error) =>
          serviceLogger.error(`Could not change language [${settings.language}] in i18next.`, error),
        );
    }

    // fix for rendering invalid brackets in japanese (https://jira.g5e.com/browse/MRKT-105661)
    document.documentElement.lang = settings.language;

    return settings;
  } catch (error) {
    serviceLogger.error('getSettings()', error);
  }
};

export const setSettings = async (settings) => {
  try {
    const status = await clientLib.sendRequest('setSettings', settings);
    if (status !== 'completed') return;
    const newSettings = await getSettings();
    reportSetSettings(newSettings);
    return newSettings;
  } catch (error) {
    serviceLogger.error(`setSettings('${settings}')`, JSON.stringify(error));
  }
};
