import GamesCardList from '../../shared/games/list/GamesCardList';
import GamesCardNavigation from './navigation/GamesCardNavigation';
import { REFERRAL_SOURCES } from "../../../constants";
import './index.scss';

function HomeContent() {
  return (
    <section className="content">
      <div className="container">
        <GamesCardNavigation />
      </div>
      <div className="container">
        <GamesCardList passedSource={REFERRAL_SOURCES.INSTALL.GAME_CARD_ALL_GAMES} />
      </div>
    </section>
  );
}

export default HomeContent;
