import { FC, PropsWithChildren } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage';

import './styles/index.scss';

type FormFieldWrapperProps = Partial<{
  error?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
  size?: 'full' | 'half';
  className?: string;
}>;

const FormFieldWrapper: FC<PropsWithChildren<FormFieldWrapperProps>> = ({
  className = '',
  error,
  size = 'full',
  children,
}) => {
  return (
    <div className={`form__field  form__field--${size} ${className} ${error ? 'hasError' : ''}`}>
      {children}
      {error && (
        <ErrorMessage>
          <span>{error.toString()}</span>
        </ErrorMessage>
      )}
    </div>
  );
};

export default FormFieldWrapper;
