import clientLib from '../clientLib';
import { serviceLogger } from '../logger';
import store from '../store';
import { LOCAL_STORAGE_KEYS } from '../constants';

type FileToDownloadType = {
  id: string;
  name: string;
};

export const getStoredXPromoBanners = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.STORED_XPROMO_CONTENT)) || [];

export const setStorageFilesList = (filesToDownload: FileToDownloadType[]): void => {
  try {
    const playerId = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_LOGIN_ID);
    if (!playerId) return;
    const {
      udid: deviceId,
      xpromoId,
      settings: { language },
    } = store.getState().main;
    const files = filesToDownload.map(file => {
      const uri = new URL('https://specialdeals.g5e.com/v4/getLauncherFile');
      uri.search = new URLSearchParams({
        xpromoId,
        deviceId,
        playerId,
        locale: language,
        id: file.id,
      }).toString();
      return {
        uri: uri.href,
        name: file.name,
      };
    });
    clientLib.sendRequest('setStorageFilesList', { files });
  } catch (error) {
    serviceLogger.error(`setStorageFilesList(${JSON.stringify(filesToDownload)})`, JSON.stringify(error));
  }
};

export const getStorageFilePath = async (name: string): Promise<string> => {
  try {
    const argId = `g5lnCheck_${Date.now()}`;
    return await clientLib
      .sendRequest('getStorageFilePath', { name }, argId)
      .then(path => path);
  } catch (error) {
    serviceLogger.error(`getStorageFilePath(${name})`, JSON.stringify(error));
  }
};

export const getFilesListFromXPromoContent = (xpromoContent): FileToDownloadType[] => {
  if (!xpromoContent) return;
  const resultedArray = [];
  for (const bannerXPromo of xpromoContent) {
    if (bannerXPromo?.files['background.png']) {
      resultedArray.push({
        name: bannerXPromo.files['background.png'].name,
        id: bannerXPromo.files['background.png'].id,
      });
    }

    if (bannerXPromo?.files['video.mp4']) {
      resultedArray.push({ name: bannerXPromo.files['video.mp4'].name, id: bannerXPromo.files['video.mp4'].id });
    }
  }
  return resultedArray;
};
