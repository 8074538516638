import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import clientLib from '../clientLib';
import { reportMessage } from '../helpers';

function report(pageId, xPromoId, action, usageTime, elementId, additionalElementId = '') {
  usageTime = Math.floor(usageTime / 1000);
  elementId = elementId || '';

  const msg = `g5ln_ui_event('${pageId}', '${xPromoId}', '${action}', ${usageTime}, '${elementId}', '${additionalElementId}')`;
  reportMessage(msg);
}

export default function usePageLogging(pageId, xPromoId = '') {
  const history = useHistory();
  const [usageStartTime, updateUsageStartTime] = useState(Date.now());

  useEffect(
    function updateUsageTimeOnSuspend() {
      let suspendStartTime = usageStartTime;

      function updateSuspendStartTime() {
        suspendStartTime = Date.now();
      }

      function updateUsageStartTimeOnResume() {
        const diff = Date.now() - suspendStartTime;
        updateUsageStartTime(usageStartTime + diff);
      }

      clientLib.on('suspend', updateSuspendStartTime);
      clientLib.on('resume', updateUsageStartTimeOnResume);
      return () => {
        clientLib.off('suspend', updateSuspendStartTime);
        clientLib.off('resume', updateUsageStartTimeOnResume);
      };
    },
    [usageStartTime],
  );

  useEffect(function setTimeOnPageGlobally() {
    window.currentPageUseageStartTime = usageStartTime;
  }, [usageStartTime]);

  useEffect(
    function reportOnOpen() {
      report(pageId, xPromoId, 'open', 0);
    },
    [pageId],
  );

  useEffect(
    function reportOnClose() {
      const pageLocation = history.location;
      const unListen = history.listen((location) => {
        if (location.pathname !== pageLocation.pathname) {
          report(pageId, xPromoId, 'close', Date.now() - usageStartTime);
          unListen();
        }
      });

      return unListen;
    },
    [pageId, history, usageStartTime],
  );

  return useCallback(
    function reportOnClick(elementId) {
      report(pageId, 'click', Date.now() - usageStartTime, elementId);
    },
    [pageId, usageStartTime],
  );
}
