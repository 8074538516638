import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import InstalledGamesBannerImage from '../../../components/shared/banners/installedGamesBannerImage/InstalledGamesBannerImage';
import { selectInstalledGames, selectInstalledGamesIDs, selectNotInstalledGames } from "../../../store/selectors";
import { RecommendedGames } from '../../components/compound/RecommendedGames';
import { GridContainer } from '../../components/containers/GridContainer';
import { SectionContainer } from '../../components/containers/SectionContainer';
import { GamesList } from '../../components/compound/GamesList';
import { GamesNotPlayedPlaceholder } from '../../components/compound/GamesNotPlayedPlaceholder';
import { DecorLine } from '../../components/commons/DecorLine';
import '../../assets/styles/profile-games.scss';
import { selectIsAuth, selectProfileGames, selectUserData } from '../../../store/dux/auth/selectors';
import { LOCAL_STORAGE_KEYS, REFERRAL_SOURCES } from '../../../constants';

function ProfileGames() {
  const { t } = useTranslation();

  const profileData = useSelector(selectUserData);
  const isAuth = useSelector(selectIsAuth);
  const nickname = profileData?.name || localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_LOGIN_NAME) || undefined;
  const clientLibInstalledApps = useSelector(selectInstalledGamesIDs, shallowEqual);
  const installedGames = useSelector(selectInstalledGames);
  const notInstalledGames = useSelector(selectNotInstalledGames);
  const profileGames = useSelector(selectProfileGames);
  const storedProfileGames = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LATEST_PROFILE_GAMES)) || [];
  const {
    installedOnCurrentDevice = [],
    installedOnOtherDevices = [],
    otherInstalledOnCurrentDevice = installedGames,
  } = profileGames || storedProfileGames;

  const noGamesInstalled =
    [...installedOnCurrentDevice, ...installedOnOtherDevices, ...otherInstalledOnCurrentDevice].length === 0;

  const noGamesInstalledOnCurrentDevice = [...installedOnCurrentDevice, ...otherInstalledOnCurrentDevice].length === 0;
  const noProgressAsCurrentPlayer =
    [...installedOnCurrentDevice, ...installedOnOtherDevices].length === 0 &&
    otherInstalledOnCurrentDevice.length !== 0;

  useEffect(
    function saveProfileGamesToStorage() {
      if (profileGames) {
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.LATEST_PROFILE_GAMES, JSON.stringify(profileGames));
      }
    },
    [profileGames],
  );

  const showNoAuth = !isAuth && !nickname;

  if (showNoAuth) {
    return (
      <div className="profile-games">
        <InstalledGamesBannerImage />
        {installedGames?.length !== 0 ? (
          <SectionContainer colorTheme="secondary" sectionType="type-1">
            <GridContainer containerType="type-4">
              <GamesList
                {...{
                  games: installedGames,
                  title: t('profile.titles.we_found_other'),
                  clientLibInstalledApps,
                }}
              />
            </GridContainer>
          </SectionContainer>
        ) : (
          <SectionContainer colorTheme="primary" sectionType="type-1">
            <GridContainer containerType="type-4">
              <GamesNotPlayedPlaceholder
                title={t('games.installed.empty.title')}
                text={t('profile.titles.visit_all_games')}
                buttonText={t('profile.buttons.discover_games')}
              />
            </GridContainer>
          </SectionContainer>
        )}
        {notInstalledGames.length > 0 && (
          <SectionContainer colorTheme="tertiary" sectionType="type-3">
            <GridContainer containerType="type-4">
              <RecommendedGames
                passedGames={notInstalledGames.slice(0, 4)}
                passedSource={REFERRAL_SOURCES.INSTALL.MY_GAMES_ALSO_LIKE_TO_PLAY}
              />
            </GridContainer>
          </SectionContainer>
        )}
      </div>
    );
  }

  return (
    <div className="profile-games">
      <InstalledGamesBannerImage />
      {noGamesInstalled && (
        <SectionContainer colorTheme="primary" sectionType="type-1">
          <GridContainer containerType="type-4">
            <GamesNotPlayedPlaceholder
              title={t('games.installed.empty.title')}
              text={t('profile.titles.visit_all_games')}
              buttonText={t('profile.buttons.discover_games')}
            />
          </GridContainer>
        </SectionContainer>
      )}
      {noProgressAsCurrentPlayer && (
        <SectionContainer colorTheme="secondary" sectionType="type-3">
          <GridContainer containerType="type-4">
            <GamesNotPlayedPlaceholder
              title={t('profile.titles.you_dont_play', { nickname })}
              text={t('profile.titles.visit_all_games')}
              buttonText={t('profile.buttons.discover_games')}
            />
            <DecorLine />
          </GridContainer>
        </SectionContainer>
      )}

      {(!noGamesInstalledOnCurrentDevice || installedOnOtherDevices.length) && (
        <SectionContainer colorTheme="secondary" sectionType="type-1">
          <GridContainer containerType="type-4">
            {installedOnCurrentDevice.length > 0 && (
              <GamesList
                {...{
                  games: installedOnCurrentDevice,
                  title: t('profile.titles.you_play_on_current', { nickname }),
                  clientLibInstalledApps,
                }}
              />
            )}
            {/* Rendered for testing card with dashed border */}
            {otherInstalledOnCurrentDevice.length > 0 && (
              <GamesList
                {...{
                  games: otherInstalledOnCurrentDevice,
                  title: t('profile.titles.we_found_other'),
                  ...(nickname && { text: t('profile.titles.we_found_other_sub', { nickname }) }),
                  clientLibInstalledApps,
                }}
              />
            )}
            {installedOnOtherDevices.length > 0 && (
              <GamesList
                {...{
                  games: installedOnOtherDevices,
                  title: t('profile.titles.you_play_as', { nickname }),
                  text: t('profile.titles.install_now'),
                  clientLibInstalledApps,
                }}
                passedSource={REFERRAL_SOURCES.INSTALL.MY_GAMES_OTHER}
              />
            )}
          </GridContainer>
        </SectionContainer>
      )}
      {notInstalledGames.length > 0 && (
        <SectionContainer colorTheme="tertiary" sectionType="type-3">
          <GridContainer containerType="type-4">
            <RecommendedGames
              passedGames={notInstalledGames.slice(0, 4)}
              passedSource={REFERRAL_SOURCES.INSTALL.MY_GAMES_ALSO_LIKE_TO_PLAY}
            />
          </GridContainer>
        </SectionContainer>
      )}
    </div>
  );
}

export default ProfileGames;
