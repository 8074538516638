import { FC, PropsWithChildren } from 'react';

import './styles/index.scss';

type HeadlineProps = Partial<{
  headlineLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  size: string;
  className: string;
  variant: string;
}>;

const Headline: FC<PropsWithChildren<HeadlineProps>> = ({
  headlineLevel = 'p',
  className = '',
  size = '13',
  variant = 'primary',
  children,
}) => {
  const Tag = headlineLevel;

  return <Tag className={`headline headline--${variant} headline--size-${size} ${className}`}>{children}</Tag>;
};

export default Headline;
export type { HeadlineProps };
