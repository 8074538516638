import React from 'react';
import styles from '../../../pages/Game/Game.module.scss';

function SingleGameRequirements({ requirements, titleReqs }) {
  return (
    <div className={styles['game-single__requirements']}>
      <div className={styles['game-single__wrapper']}>
        <div className={styles['game-single__heating']}>
          <h3 className={styles['game-single__titleMini']}>{titleReqs}</h3>
        </div>
        <div className={styles['game-single__list']}>
          <div
            className={styles['game-single__description']}
            dangerouslySetInnerHTML={{ __html: requirements }}
          />
        </div>
      </div>
    </div>
  );
}

export default SingleGameRequirements;
