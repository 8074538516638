import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../../../store/dux/auth/selectors';
import { useHistory, useLocation } from 'react-router-dom';
// import { Circle } from '../Circle';
import './styles/index.scss';
import styles from '../../../../components/shared/header/Header.module.scss';
import { Popup } from '../../compound/Popup';
import useProfileHeaderData from '../../../hooks/useProfileHeaderData';
import { LOCAL_STORAGE_KEYS } from '../../../../constants';
import { profileRoutes } from "../../../routes";

type AccountButtonProps = {
  className?: string;
};

const route = {
  label: 'Profile',
  href: '/profile',
  exact: true,
};

const AccountButton: FC<AccountButtonProps> = ({ className = '' }) => {
  const [notify, setNotify] = useState<boolean>(false);
  const profileData = useSelector(selectUserData);
  const { noAccount, nameToDisplay, avatarToDisplay, defaultAccount } = useProfileHeaderData(profileData);

  const history = useHistory();
  const location = useLocation();
  const isActive = location.pathname === route.href;

  useEffect(
    function handleIdChange() {
      if (!profileData?.id) return;
      if (profileData?.underageConfirmStatus === 'confirmed') return;
      const lastLoginIdFromStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_LOGIN_ID);
      const accountIsDefault = !profileData?.email && !profileData?.passwordIsCustom;

      if (lastLoginIdFromStorage !== profileData?.id || accountIsDefault) {
        setNotify(true);
        localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_LOGIN_ID, profileData.id);
      }
    },
    [profileData?.id],
  );

  const handleRouteClick = () => {
    if (!isActive) {
      const routeToGo = noAccount ? profileRoutes.signIn : profileRoutes.profile;
      history.push(routeToGo);
    }
  };
  const closeNotification = () => setNotify(false);

  return (
    <>
      <button
        type="button"
        className={`account-button ${isActive ? 'account-button--active' : ''} ${className}`}
        onClick={handleRouteClick}>
        <span className="account-button__content">
          <span className="account-button__name truncate-text">{nameToDisplay}</span>
          <span className="account-button__avatar-wrapper">
            <img className="account-button__avatar" src={avatarToDisplay} alt="" />
            {/* displayCircle && <Circle variant="orange" size="sm" hasBorder className="account-button__circle" /> */}
          </span>
        </span>
      </button>
      {notify && (
        <Popup
          isDefaultUser={defaultAccount}
          username={nameToDisplay}
          avatar={avatarToDisplay}
          className={styles['header__popup']}
          onClose={closeNotification}
        />
      )}
    </>
  );
};

export default AccountButton;
