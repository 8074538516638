import Popup from '../../shared/popup/Popup';
import UpdateModal from '../../shared/updateModal/UpdateModal';
import useUpdate from '../../../hooks/useUpdate';
import { LAUNCHER_UPDATE_STATUS } from '../../../constants';

const Update = () => {
  const { status, setStatus, updateData } = useUpdate();
  if(!status) return null;

  if ([LAUNCHER_UPDATE_STATUS.UPDATE, LAUNCHER_UPDATE_STATUS.CONGRATULATIONS].includes(status)) {
    const ChosenPopup = Popup[status];
    return (
      <Popup type={status} setStatus={setStatus} updateData={updateData}>
        <ChosenPopup />
      </Popup>
    );
  } else {
    const ChosenModal = UpdateModal[status];
    return (
      <UpdateModal type={status}>
        <ChosenModal />
      </UpdateModal>
    )
  }
};

export default Update;
