import { FC } from 'react';

import './styles/index.scss';

type OverlayProps = Partial<{
  className: string;
  onClick: () => void;
}>;

const Overlay: FC<OverlayProps> = ({ className = '', onClick }) => {
  return <div className={`overlay ${className}`} onClick={onClick} />;
};

export default Overlay;
