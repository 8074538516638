import { useTranslation } from 'react-i18next';
import { ReactComponent as ReloadIcon } from '../../../../assets/images/reload.svg';

function ReloadButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <div className="slider__reload">
      <button className="slider__reload-button" onClick={onClick}>
        <ReloadIcon />
        {t('games.single.screenshots.reload')}
      </button>
    </div>
  );
}

export default ReloadButton;
