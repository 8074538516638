import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GamesCardNavigationItem from './GamesCardNavigationItem';
import { selectActiveGenre, selectGenresWithGames } from '../../../../store/selectors';
import { setActiveGenre } from '../../../../store/dux/main';
import styles from './Navigation.module.scss';

function GamesCardNavigation() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const updateActiveGenre = genre => dispatch(setActiveGenre(genre));

  const activeGenre = useSelector(selectActiveGenre);

  const genres = useSelector(selectGenresWithGames);

  return (
    <ul className={styles['games__navigation']}>
      <li className={`${styles['games__navigation--item']}`}>
        <h4 className={styles['games__navigation--link']} onClick={() => updateActiveGenre('all')}>
          <span className={`${styles['games__navigation--title']} ${styles[activeGenre === 'all' && 'active_item']}`}>{t('shared.buttons.most_popular')}</span>
        </h4>
      </li>
      {genres &&
        genres.map((genre, index) => (
          <GamesCardNavigationItem {...{ genre, activeGenre, updateActiveGenre }} key={index} />
        ))}
    </ul>
  );
}

export default GamesCardNavigation;
