import { FC, ReactElement, PropsWithChildren } from 'react';
import { Headline, HeadlineProps } from '../Headline';

import './styles/index.scss';

export type HeadlineWithIconProps = HeadlineProps & {
  icon: ReactElement;
};

const HeadlineWithIcon: FC<PropsWithChildren<HeadlineWithIconProps>> = ({
  headlineLevel,
  size,
  variant,
  className = '',
  icon,
  children,
}) => {
  return (
    <Headline
      headlineLevel={headlineLevel}
      size={size}
      variant={variant}
      className={`headline--with-icon ${className}`}>
      {icon}
      {children}
    </Headline>
  );
};

export default HeadlineWithIcon;
