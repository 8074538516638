import Loader from './loader/Loader';
import ReloadButton from './ReloadButton';
import { setGameScreenshots } from '../../../../store/dux/main';
import { useSelector, useDispatch } from 'react-redux';
import { selectScreenshotsAreLoadingByGameId } from '../../../../store/selectors';

function SingleGameScreenshotsNotLoaded({ gameId, blobbing }) {
  const dispatch = useDispatch();
  const screenshotsAreLoading = useSelector(state => selectScreenshotsAreLoadingByGameId(state, gameId));

  return (
    <div className="slider__noLoaded">
      {screenshotsAreLoading || blobbing ? (
        <Loader />
      ) : (
        <ReloadButton onClick={() => dispatch(setGameScreenshots(gameId))} />
      )}
    </div>
  );
}

export default SingleGameScreenshotsNotLoaded;
