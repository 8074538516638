import { Trans } from 'react-i18next';
import useSiteLinks from '../../../../hooks/useSiteLinks';
import { openLink } from '../../../../helpers';

function PolicyText() {
  const links = useSiteLinks();

  const clickLink = (e, link) => {
    e.preventDefault();
    openLink(link);
  };

  return (
    <span>
      <Trans
        i18nKey="profile.title.agreement"
        components={{
          tosLink: <a onClick={e => clickLink(e, links.TOSLink)} />,
          policyLink: <a onClick={e => clickLink(e, links.privacyPolicyLink)} />,
          storeTOSLink: <a onClick={e => clickLink(e, links.storeTOSLink)} />,
        }}
      />
    </span>
  );
}

export default PolicyText;
