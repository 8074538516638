import { serviceLogger } from '../logger';
import { debugLogData, fetchFromCDN } from "../helpers";

export const getScreenshots = async (platform, gameId) => {
  try {
    const path = `games/${gameId}/platforms/${platform.toLowerCase()}/screenshots.json`;

    const response = await fetchFromCDN(path)
      .then(res => res.json())
      .then(screenshots => screenshots.lockit);

    const formedScreenshots = Object.keys(response).reduce((acc, lng) => {
      if (response[lng]['sprite']?.image) {
        acc[lng] = {
          image: `${response[lng]['sprite'].image}`,
        };
      }
      return acc;
    }, {});

    debugLogData(`getScreenshots('${platform}', ${gameId})`, response, serviceLogger);
    return formedScreenshots;
  } catch (error) {
    serviceLogger.error(`getScreenshots('${platform}', ${gameId})`, error);
  }
};
