import { useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const useChangePasswordForm = () => {
  const { t } = useTranslation();
  const changePasswordForm = useMemo(
    () =>
      yup.object().shape({
        oldPassword: yup
          .string()
          .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.password') }))
          .min(4, t('profile.message.password_length_error', { n: 4, m: 32 }))
          .max(32, t('profile.message.password_length_error', { n: 4, m: 32 })),
        newPassword: yup
          .string()
          .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.new_password') }))
          .min(4, t('profile.message.password_length_error', { n: 4, m: 32 }))
          .max(32, t('profile.message.password_length_error', { n: 4, m: 32 })),
        confirmNewPassword: yup
          .string()
          .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.confirm_password') }))
          .min(4, t('profile.message.password_length_error', { n: 4, m: 32 }))
          .max(32, t('profile.message.password_length_error', { n: 4, m: 32 }))
          .oneOf([yup.ref('newPassword'), null], t('profile.message.passwords_not_match')),
      }),

    [],
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(changePasswordForm),
  });

  return {
    register,
    handleSubmit,
    control,
    errors,
    setError,
  };
};

export default useChangePasswordForm;
