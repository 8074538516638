import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactModal from 'react-modal';
import { ReactComponent as CrossIcon } from '../../../../assets/images/cross.svg';
import { EventTimeRemaining } from '../EventTimeRemaining';
import { EventCardButton } from '../EventCardButton';
import { LOGGING_PAGE_IDS, REFERRAL_SOURCES } from '../../../../constants';
import { EventType } from '../../../../classes/Event';
import styles from '../../Events.module.scss';
import { reportMessage, transformUsageTime } from '../../../../helpers';
import { selectAsianLocaleIsActive, selectWebViewVersion } from '../../../../store/selectors';
import EventCardScrollbars from '../../../shared/scrollbar/EventCardScrollbars';

type EventCardModalPropsType = {
  event: EventType;
  game: any;
  eventTitle: string;
  iconImage: string;
  installedVersion: string;
  name: string;
  fullDescription: string;
  gamePage: boolean;
  closeModal: () => void;
};

export enum ShadowPositionTypes {
  TOP = 'top',
  BOTTOM = 'bottom',
  BOTH = 'both',
}

export const EVENT_AUTO_HEIGHT_MAX = 260;

const EventCardModal = ({
  event,
  game,
  eventTitle,
  iconImage,
  installedVersion,
  name,
  fullDescription,
  gamePage,
  closeModal,
}: EventCardModalPropsType) => {
  const [shadow, setShadow] = useState<ShadowPositionTypes>();
  const popupTimeRef = useRef<number>(Date.now());
  const asianLocalActive = useSelector(selectAsianLocaleIsActive);
  const webViewVersion = useSelector(selectWebViewVersion);

  useEffect(function handleEventCardLogging() {
    const source = gamePage ? LOGGING_PAGE_IDS.GAME : LOGGING_PAGE_IDS.EVENTS;

    reportMessage(`g5ln_popup_show('${game.xpromoId}', '${event.id}', 'open', '${source}', 0, ${!!installedVersion})`);

    return () => {
      reportMessage(
        `g5ln_popup_show('${game.xpromoId}', '${event.id}', 'close', '${source}', ${transformUsageTime(
          popupTimeRef.current,
        )}, ${!!installedVersion})`,
      );
    };
  }, []);

  return (
    <ReactModal
      isOpen
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={{
        content: {
          width: '630px',
          minHeight: '714px',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}>
      <div className={styles.modal}>
        <img src={'../../' + event.bannerImage} className={styles.modal__image} alt={eventTitle} />
        <button type="button" className={styles.modal__close} onClick={closeModal}>
          <CrossIcon className={styles.modal__closeIcon} />
        </button>
        <div className={`${shadow ? styles[`shadow_${shadow}`] : ''}`}>
          <EventCardScrollbars autoHeight autoHeightMax={`${EVENT_AUTO_HEIGHT_MAX}px`} setShadow={setShadow}>
            <div className={styles.modal__content}>
              <EventTimeRemaining expirationTime={event.endTime} closeModal={closeModal} />
              <h3 className={styles.EventCard_header}>{eventTitle}</h3>
              <div
                className={styles.modal__content__description}
                dangerouslySetInnerHTML={{ __html: fullDescription }}
                style={{
                  ...(asianLocalActive ? { wordBreak: 'break-all' } : ''),
                  ...(webViewVersion === 1 ? { marginBottom: '15px' } : ''),
                }}
              />
            </div>
          </EventCardScrollbars>
        </div>
        <div className={styles.modal__footer}>
          <EventCardButton
            game={game}
            gameId={event.gameId}
            iconImage={iconImage}
            installedVersion={installedVersion}
            name={name}
            passedSource={REFERRAL_SOURCES.INSTALL.EVENT_POPUP}
            closeModal={closeModal}
            additionalSource={event.id}
            gamePage={gamePage}
          />
        </div>
      </div>
    </ReactModal>
  );
};

export default EventCardModal;
