import { FC, PropsWithChildren } from 'react';

import './styles/index.scss';

type MainContainerProps = {
  mainType?: 'sign' | 'profile';
};

const MainContainer: FC<PropsWithChildren<MainContainerProps>> = ({ mainType, children }) => {
  return <div className={`w-main w-main--${mainType}`}>{children}</div>;
};

export default MainContainer;
