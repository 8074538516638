import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { AUTO_HEIGHT_MAX } from "../../notifications/NotificationsContainer";

export default class NotificationColoredScrollbars extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = { top: 0, notifications: props.children, scrollBackground: 'transparent' };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumb = this.renderThumb.bind(this);
    this.scrollbars = React.createRef();
  }

  componentDidMount() {
      this.scrollbars.current.scrollToBottom();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ notifications: nextProps.children });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!prevProps.recentlyStartedInstallationGameURI?.uri) return;
    if (
      prevProps.recentlyStartedInstallationGameURI.uri !== this.props.recentlyStartedInstallationGameURI.uri &&
      prevProps.recentlyStartedInstallationGameURI.timestamp !== this.props.recentlyStartedInstallationGameURI.timestamp
    ) {
      this.setState({ top: 1 }, () => this.scrollbars.current.scrollToBottom());
    }
  }

  handleUpdate(values) {
    const { top, clientHeight } = values;
    this.props.updatefadeawaypositiononscroll && this.props.updatefadeawaypositiononscroll(top);

    if (!this.props?.widget) return;

    if (clientHeight >= AUTO_HEIGHT_MAX) {
      this.setState({scrollBackground: '#EFEFEF'});
    } else {
      this.setState({scrollBackground: 'transparent'});
    }
  }

  renderView({ style, ...props }) {
    return <div className="box" style={{ ...style }} {...props} />;
  }

  renderTrack = ({ style, ...props }) => {
    const thumbStyle = {
      width: 8,
      right: 0,
      bottom: 16,
      top: 16,
      backgroundColor: this.state.scrollBackground,
      borderRadius: '8px',
    };
    return <div className="track-vertical" style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#C2C2C2',
      zIndex: 1100,
      borderRadius: 8,
      width: '8px',
    };
    return <div className="thumb-vertical" style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderThumbHorizontal({ style, ...props }) {
    const thumbStyle = {
      backgroundColor: '#7A7A7A',
      zIndex: 1100,
      borderRadius: 3,
      height: '4px',
    };
    return <div className="thumb-horizontal" style={{ ...style, ...thumbStyle }} {...props} />;
  }

  render() {
    return (
      <Scrollbars
        ref={this.scrollbars}
        renderView={this.renderView}
        renderTrackVertical={this.renderTrack}
        renderThumbVertical={this.renderThumb}
        renderThumbHorizontal={this.renderThumbHorizontal}
        onUpdate={this.handleUpdate}
        {...this.props}>
        {this.state.notifications}
      </Scrollbars>
    );
  }
}
