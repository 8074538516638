import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import NavigationArrows from './arrow/NavigationArrows';
import NavigationLink from './link/NavigationLink';
// import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
// import logo from '../../../assets/images/logo.png';
import styles from './Header.module.scss';
import { Support } from './support';
import { selectLogo } from '../../../store/selectors';
import { AccountButton } from '../../../Profile/components/commons/AccountButton';
import { G5LAUNCHER_ROUTES } from '../../../constants';

function Header() {
  const { t } = useTranslation();
  const logo = useSelector(selectLogo);

  const routes = [
    {
      label: t('header.navigation.list.home'),
      href: G5LAUNCHER_ROUTES.ALL_GAMES,
      exact: true,
    },
    {
      label: t('header.navigation.list.your_games'),
      href: G5LAUNCHER_ROUTES.INSTALLED_GAMES,
      exact: true,
    },
    {
      label: t('events.header'),
      href: G5LAUNCHER_ROUTES.EVENTS,
      exact: true,
    },
  ];

  return (
    <header className={styles.header}>
      <div className={styles.panel} />
      <div className={styles['header--wrapper']}>
        <div className={styles['header--logo']} onClick={scroll}>
          <img src={logo} alt="" draggable="false" />
        </div>
        <div className={styles['header__nav--arrows']}>
          <NavigationArrows />
        </div>
        <div className={styles['header__nav']}>
          <ul className={styles['header__nav--list']}>
            {routes.map((route, index) => {
              return (
                <li className={styles['header__nav--item']} key={index}>
                  <NavigationLink {...route} />
                </li>
              );
            })}
          </ul>
        </div>
        <Support />
        <AccountButton className={styles['header__account-button']} />
      </div>
    </header>
  );
}

export default Header;
