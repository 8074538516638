const logger = require('loglevel');
const prefix = require('loglevel-plugin-prefix');

logger.setDefaultLevel(logger.levels.WARN);

prefix.reg(logger);
prefix.apply(logger, {
  template: '[%t] %l (%n):',
});

export const renderLogger = logger.getLogger('render');
export const dataLogger = logger.getLogger('data');
export const hookLogger = logger.getLogger('hook');
export const serviceLogger = logger.getLogger('service');
export { logger };
