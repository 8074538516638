import { FC, PropsWithChildren, ReactElement } from 'react';
import { TextButton, TextButtonProps } from '../TextButton';

type ButtonWithIconProps = TextButtonProps & {
  icon: ReactElement;
};

const TextButtonWithIcon: FC<PropsWithChildren<ButtonWithIconProps>> = ({
  type,
  className = '',
  onClick,
  icon,
  children,
  ...props
}) => {
  return (
    <TextButton type={type} className={`text-button--with-icon ${className}`} onClick={onClick} {...props}>
      {icon}
      {children}
    </TextButton>
  );
};

export default TextButtonWithIcon;
