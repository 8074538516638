import { serviceLogger } from '../logger';
import Game from '../classes/Game';
import { debugLogData, reportGameLaunch } from '../helpers';
import clientLib from '../clientLib';
import { LOCAL_STORAGE_KEYS } from "../constants";
import store from "../store";
import { setLaunchedGame } from "../store/dux/main";

export const getGames = async (gameIds, platform) => {
  try {
    const games = await Promise.all(gameIds.map(id => Game.fetch(id, platform)));

    const filteredGames = games.filter(Boolean);

    debugLogData(`getGames('${platform}', '${JSON.stringify(gameIds)}')`, filteredGames, serviceLogger);
    return filteredGames;
  } catch (error) {
    serviceLogger.error(`getGames('${JSON.stringify(gameIds)}', '${platform}')`, error);
  }
};

export const getInstalledGames = async () => {
  try {
    const argId = `init_${Date.now()}`;
    const installedGames = await clientLib.sendRequest('getInstalledApps', null, argId);
    debugLogData(`getInstalledGames()`, installedGames, serviceLogger);
    return installedGames;
  } catch (error) {
    serviceLogger.error('getInstalledGames()', error);
  }
};

export const playGame = (game, source, additionalSource) => {
  try {
    reportGameLaunch(game.xpromoId, source, additionalSource);
    store.dispatch(setLaunchedGame(game.id));
    clientLib.sendRequest('launchURL', {
      url: `pgplg5store${game.pgplId}://`,
    });
  } catch (error) {
    serviceLogger.error(`playGame('${JSON.stringify(game)}')`, error);
  }
};

export const getLaunchedGameList = () => JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LAUNCHED_GAMES)) || [];

export const addLaunchedGame = (gameId) => {
  const launchedGames = getLaunchedGameList();
  const gameIndex = launchedGames.indexOf(gameId);
  if (gameIndex !== -1) {
    return [gameId, ...launchedGames.slice(0, gameIndex), ...launchedGames.slice(gameIndex + 1)];
  }
  launchedGames.unshift(gameId);
  return launchedGames;
};
