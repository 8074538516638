import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ReactModal from 'react-modal';
import {
  selectActiveState,
  selectCurrentLang,
  selectGameIsInstalledByGameId, selectIsAnythingInProgress,
  selectIsAnythingIsUpdating,
} from "../../../store/selectors";
import { reportTakeoverBannerClick, reportTakoverBannerShow } from '../../../helpers';
import {
  getTakeoverBanner,
  addTakeoverBanner,
} from "../../../store/dux/takeoverBanners";

import { ReactComponent as CrossIcon } from '../../../assets/images/cross.svg';
import styles from './TakeoverBanner.module.scss';
import { G5LAUNCHER_ROUTES, LOCAL_STORAGE_KEYS, REFERRAL_SOURCES } from "../../../constants";

const TakeoverBanner = ({ takeoverBanners }) => {
  const [showTakeoverBanner, setTakeoverBannerShow] = useState(false);
  // @ts-ignore
  const forceUpdating = useSelector(state => selectIsAnythingIsUpdating(state, true));
  // @ts-ignore
  const location = useLocation();
  const language = useSelector(selectCurrentLang);
  const activeWindow = useSelector(selectActiveState);
  const globalLaunchTakeoverBanner = takeoverBanners.find(banner => banner.type === 'globalLaunch');
  // @ts-ignore
  const gameIsInstalled = useSelector(state => selectGameIsInstalledByGameId(state, globalLaunchTakeoverBanner?.gameId));
  // @ts-ignore
  const activeProgress = useSelector(selectIsAnythingInProgress);
  const globalLaunchTakeoverBannerStorage = getTakeoverBanner(globalLaunchTakeoverBanner);

  const checkTakeoverBannerActive = () => {
    if (gameIsInstalled) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.TAKEOVER_BANNER_CLICKED, JSON.stringify(false));
      return;
    }
    if (forceUpdating || activeProgress) {
      setTakeoverBannerShow(false);
      return;
    }

    const now = new Date().getTime();
    const bannerClicked = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.TAKEOVER_BANNER_CLICKED));
    if (!globalLaunchTakeoverBannerStorage && !bannerClicked) {
      addTakeoverBanner(globalLaunchTakeoverBanner);
      setTakeoverBannerShow(true);
      return;
    }

    if (globalLaunchTakeoverBannerStorage?.timeToExpire > now && bannerClicked) {
      setTakeoverBannerShow(false);
    }

    if (globalLaunchTakeoverBannerStorage?.timeToExpire < now) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.TAKEOVER_BANNER_CLICKED, JSON.stringify(false));
      addTakeoverBanner(globalLaunchTakeoverBannerStorage);
      setTakeoverBannerShow(true);
    }
  };

  const onClose = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TAKEOVER_BANNER_CLICKED, JSON.stringify(true));
    setTakeoverBannerShow(false);
  };

  useEffect(() => {
    checkTakeoverBannerActive();
  }, [gameIsInstalled, forceUpdating, location, activeProgress]);

  useEffect(() => {
    if (activeWindow) {
      checkTakeoverBannerActive();
    }
  }, [activeWindow]);

  useEffect(() => {
    if (showTakeoverBanner && !activeProgress) {
      reportTakoverBannerShow(globalLaunchTakeoverBanner.type);
    }
  }, [showTakeoverBanner]);

  return (
    <ReactModal
      style={{
        content: {
          width: '690px',
          height: '490px',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
      isOpen={showTakeoverBanner}
      ariaHideApp={false}
      onRequestClose={onClose}>
      <div className={styles['takeover-banner']}>
        <Link
          className={styles['takeover-banner__link']}
          to={
            !globalLaunchTakeoverBanner?.gameId
              ? G5LAUNCHER_ROUTES.ALL_GAMES
              : {
                  pathname: `/${globalLaunchTakeoverBanner?.gameId}`,
                  state: {
                    id: globalLaunchTakeoverBanner?.gameId,
                    startAutoDownload: true,
                    startGame: true,
                  },
                  search: REFERRAL_SOURCES.INSTALL.TAKEOVER_BANNER,
                }
          }
          onClick={() => {
            // reportTakeoverBannerClick(globalLaunchTakeoverBanner.gameId);
            // removeTakeoverBanner(takeoverBanners, globalLaunchTakeoverBanner);
            reportTakeoverBannerClick(globalLaunchTakeoverBanner.gameId);
            onClose();
          }}
          draggable="false">
          <img
            height="490"
            width="690"
            className={styles['takeover-banner__img']}
            src={`../../${
              globalLaunchTakeoverBanner?.lockit[language]?.backgroundImage ||
              globalLaunchTakeoverBanner?.lockit?.en?.backgroundImage
            }`}
            alt=""
          />
        </Link>
        <button
          type="button"
          className={styles['takeover-banner__close']}
          onClick={onClose}>
          <CrossIcon className={styles['takeover-banner__close-icon']} />
        </button>
      </div>
    </ReactModal>
  );
};

export default TakeoverBanner;
