import { useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

const useRecoverPasswordForm = () => {
  const { t } = useTranslation();
  const recoverPasswordSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required(t('profile.message.cant_be_blank', { attribute: t('profile.words.email') }))
          .email(t('profile.message.not_valid', { attribute: t('profile.words.email') })),
      }),
    [],
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(recoverPasswordSchema),
  });

  return {
    register,
    handleSubmit,
    control,
    errors,
    setError,
  };
};

export default useRecoverPasswordForm;
