import { useEffect, useState } from 'react';

const convertCalendLocaleName = (lang): string => {
  const rules = {
    German: ['de'],
    Spanish: ['es_es', 'es'],
    Portuguese: ['pt', 'pt_pt'],
    Korean: ['ko'],
    Italian: ['it'],
    Mandarin: ['zh'],
    MandarinTraditional: ['zh_tw'],
    Japanese: ['ja'],
    Swedish: ['sv'],
    French: ['fr'],
    Russian: ['ru'],
    Dutch: ['nl'],
  };

  for (let l in rules) {
    if (rules[l].includes(lang)) {
      return l;
    }
  }
};

const localeRequestLangTitle = (lang) => {
  if(lang === 'pt_pt') return 'pt';
  if(lang === 'es_es') return 'es';
  return lang.replace('_', '-');
}

export default function useCalendarLocalization(lang: string) {
  const [locale, setLocale] = useState(null);
  useEffect(
    function dynamicallyImportLocale() {
      (async () => {
        try {
          const calendLocalization = convertCalendLocaleName(lang);
          const language = await import(`flatpickr/dist/l10n/${localeRequestLangTitle(lang)}`);
          setLocale(language[calendLocalization]);
        } catch {
          const language = await import('flatpickr/dist/l10n/default');
          setLocale(language.default);
        }
      })();
    },
    [lang],
  );
  return locale;
}
