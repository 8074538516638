import { useEffect } from 'react';
import { reportClick } from '../helpers';

export const useClickLogging = (): void => {
  useEffect(() => {
    window.addEventListener('click', () => {
      reportClick();
    });
  }, []);
};
