import { FC, ReactElement, PropsWithChildren } from 'react';
import { Parag, ParagProps } from '../Parag';

import './styles/index.scss';

export type ParagWithIconProps = ParagProps & {
  icon: ReactElement;
};

const ParagWithIcon: FC<PropsWithChildren<ParagWithIconProps>> = ({
  variant = 'primary',
  icon,
  className = '',
  children,
}) => {
  return (
    <Parag className={`parag parag--${variant} parag--with-icon ${className}`}>
      {icon}
      {children}
    </Parag>
  );
};

export default ParagWithIcon;
