import { fetchGenresConfig } from '../fetchLocalData';
import { logger } from '../logger';
import { LANGUAGES } from '../constants';

export default function Genre(id = '', data = {}) {
  checkArguments(id, data);

  const { sortOrder = 999 } = data;

  const lockit = getLockit(data);

  Object.assign(this, { id, sortOrder, lockit });
  Object.freeze(this);
}

Genre.fetchAll = function() {
  return fetchGenresConfig()
    .then((config) => Object.entries(config).map(([id, data]) => new Genre(id, data)))
    .catch((reason) => logger.error('Genre.fetchAll()', reason));
};

function checkArguments(id, data) {
  if (typeof id !== 'string') {
    throw new Error('"id" is not a string');
  }

  if (typeof data !== 'object') {
    throw new Error('"platformConfig" is not an object');
  }
}

function getLockit(data) {
  const { lockit = {} } = data;

  const result = {};
  for (const lang of LANGUAGES) {
    const { name = '' } = lockit[lang] || {};
    const langData = { name };

    Object.freeze(langData);
    result[lang] = langData;
  }

  Object.freeze(result);
  return result;
}
