/* eslint-disable */
import Resizer from "react-image-file-resizer";
import "blueimp-canvas-to-blob";

export const createImage = (url): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.addEventListener('error', error => reject(error));
    image.src = url;
  });

export async function getCroppedImg(imageSrc, pixelCrop): Promise<Blob> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    <HTMLImageElement>image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  return new Promise(resolve => {
    canvas.toBlob( blob => {
      Resizer.imageFileResizer(
        blob,
        212,
        212,
        "JPEG",
        70,
        0,
        (uri: Blob) => {
          resolve(uri);
        },
        "blob",
        212,
        212
      );
    }, 'image/jpeg');
  });
}