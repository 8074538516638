import { FC, PropsWithChildren } from 'react';
import { RequiredSign } from '../../RequiredSign';

import './styles/index.scss';

export type LabelProps = {
  className?: string;
  variant?: string;
  isRequired?: boolean;
  htmlFor: string;
};

const Label: FC<PropsWithChildren<LabelProps>> = ({
  className = '',
  variant = 'primary',
  isRequired,
  htmlFor,
  children,
}) => {
  return (
    <label className={`label label--${variant} ${className}`} htmlFor={htmlFor}>
      {children}
      {isRequired && <RequiredSign />}
    </label>
  );
};

export default Label;
