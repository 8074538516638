import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import LockIcon from '../../../../assets/images/svg/lock.svg';
import { Headline } from '../../../commons/Headline';
import { Parag } from '../../../commons/Parag';
import { Button } from '../../../commons/Button';
import { useContext } from 'react';
import { ProfileContext } from '../../../../pages/Profile/Profile';
import { G5LAUNCHER_ROUTES } from "../../../../../constants";

function ModalAccountClosed() {
  const { setAgeModal } = useContext(ProfileContext);

  const history = useHistory();
  const { t } = useTranslation();

  const okLetsGo = (): void => {
    setAgeModal(null);
    history.push(G5LAUNCHER_ROUTES.ALL_GAMES);
  };

  return (
    <>
      <div className="center-inline">
        <img className="modal__icon" src={LockIcon} width="60" height="60" alt="" />
      </div>
      <Headline headlineLevel="p" size="4" variant="primary" className="modal__headline center-inline">
        {t<string>('profile.titles.account_closed')}
      </Headline>
      <Parag variant="primary" className="modal__parag">
        {t<string>('profile.message.account_closed')}
      </Parag>
      <div className="modal__btns modal__btns--column">
        <Button type="button" variant="primary" sizeMode="5" className="modal__btn" onClick={okLetsGo}>
          {t<string>('profile.buttons.ok')}
        </Button>
      </div>
    </>
  );
}

export default ModalAccountClosed;
