import { useState, useRef, useEffect, useContext } from "react";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { EventType } from '../../../classes/Event';
import styles from '../Events.module.scss';
import {
  selectAsianLocaleIsActive,
  selectCurrentLang,
  selectGameByGameID,
  selectInstalledGamesIDs,
} from '../../../store/selectors';
import { EventCardButton } from './EventCardButton';
import { EventTimeRemaining } from './EventTimeRemaining';
import { getInstalledVersionByGame } from '../../../helpers';
import { REFERRAL_SOURCES } from '../../../constants';
import { EventCardModal } from './EventCardModal';
import useLogEventCardInView from '../../../hooks/useLogEventCardInView';
import { EventsContext } from "../Events";

type EventCardPropsType = {
  event: EventType;
};

const EventCard = ({ event }: EventCardPropsType) => {
  const language = useSelector(selectCurrentLang);
  const asianLocalActive = useSelector(selectAsianLocaleIsActive);
  const location = useLocation();
  const eventTitle = event.lockit[language]?.title || event.lockit['en'].title;
  const game = useSelector(selectGameByGameID(event.gameId));
  const installedGames = useSelector(selectInstalledGamesIDs);
  const installedVersion = getInstalledVersionByGame(installedGames, game);
  const shortDescription = event.lockit[language]?.shortDescription || event.lockit['en'].shortDescription;
  const fullDescription = event.lockit[language]?.fullDescription || event.lockit['en'].fullDescription;
  const [shortenDescription, setShortenDescription] = useState<boolean>(false);
  const [gamePageModalShow, setGamePageModalShow] = useState<boolean>(false);
  const eventContainerRef = useRef<HTMLElement>();
  const headerRef = useRef<HTMLHeadingElement>();
  const gamePage = location?.state?.id === event.gameId;
  const { activeEventId, setActiveEventId } = useContext(EventsContext);

  useLogEventCardInView(eventContainerRef, event.id, event.gameId, installedVersion, gamePage);

  const { iconImage } = game;

  const gameTitle = game.lockit[language].shortName || game.lockit['en'].shortName;

  const closeModal = () => {
    gamePage && setGamePageModalShow(false);
    setActiveEventId && setActiveEventId(undefined);
  };

  const openModal = () => {
    gamePage && setGamePageModalShow(true);
    setActiveEventId && setActiveEventId(event.id);
  }

  useEffect(() => {
    if (headerRef.current) {
      const numberHeaderLines = headerRef.current.getClientRects().length;
      if (numberHeaderLines > 1) {
        setShortenDescription(true);
      }
    }
  }, [headerRef.current]);

  return (
    <>
      <article className={styles.EventCard} onClick={openModal} ref={eventContainerRef}>
        <div
          className={`${styles.EventCard__image} ${gamePage ? styles.EventCard__image_game : ''}`}
          style={{ backgroundImage: `url(${'../../' + event.bannerImage})` }}
        />
        <div className={`${styles.EventCard__data} ${gamePage ? styles.EventCard__data_game : ''}`}>
          <header>
            <EventTimeRemaining expirationTime={event.endTime} closeModal={closeModal} eventId={event.id} />
            <h3
              ref={headerRef}
              style={{ display: 'inline' }}
              className={`${styles.EventCard_header} ${gamePage ? styles.EventCard_header_game : ''}`}>
              {eventTitle}
            </h3>
          </header>
          <div
            dangerouslySetInnerHTML={{ __html: shortDescription }}
            className={styles.EventCard__data_shortDescription}
            style={{
              ...(asianLocalActive ? { wordBreak: 'break-all' } : ''),
              ...(shortenDescription ? { WebkitLineClamp: 1 } : ''),
            }}
          />
          {!gamePage && (
            <EventCardButton
              game={game}
              gameId={event.gameId}
              iconImage={iconImage}
              installedVersion={installedVersion}
              name={gameTitle}
              passedSource={REFERRAL_SOURCES.INSTALL.EVENT_CARD}
              additionalSource={event.id}
            />
          )}
        </div>
      </article>
      {(activeEventId === event.id || gamePageModalShow) && (
        <EventCardModal
          event={event}
          game={game}
          eventTitle={eventTitle}
          iconImage={iconImage}
          installedVersion={installedVersion}
          name={gameTitle}
          fullDescription={fullDescription}
          gamePage={gamePage}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

export default EventCard;
