import { FC } from 'react';
import { GameCard } from '../GameCard';
import { Headline } from '../../commons/Headline';
import { Parag } from '../../commons/Parag';
import { getInstalledVersionByGame } from '../../../../helpers';
import { GameType } from "../GameCard/GameCard";

import './styles/index.scss';
import { REFERRAL_SOURCES } from "../../../../constants";

type GamesListProps = {
  title?: string;
  text?: string;
  className?: string;
  //TODO should be more precise types for properties below
  games: GameType[];
  clientLibInstalledApps?: [];
  passedSource?: string;
};

const GamesList: FC<GamesListProps> = ({ title, text, games, clientLibInstalledApps, className = '', passedSource }) => {
  return (
    <div className={`games-list ${className}`}>
      {title && (
        <Headline headlineLevel="h2" size="4" variant="tertiary" className="games-list__headline">
          {title}
        </Headline>
      )}
      {text && (
        <Parag variant="tertiary" className="games-list__parag">
          {text}
        </Parag>
      )}
      <div className="games-list__wrapper">
        {games &&
          games.map((game, index) => {
            const installedVersion = getInstalledVersionByGame(clientLibInstalledApps, game);
            const gameType = installedVersion ? 'installed' : 'external';
            //@ts-ignore
            return (
              <GameCard
                key={index}
                passedSource={passedSource || REFERRAL_SOURCES.INSTALL.MY_GAMES}
                {...{ game: game, gameType: gameType, className: 'games-list__item', installedVersion }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default GamesList;
