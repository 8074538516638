import Banner from '../classes/Banner';
import { debugLogData } from '../helpers';
import { serviceLogger } from '../logger';
import { fetchBannersRotationListConfig } from '../fetchLocalData';
import { ReturnBannerType } from '../classes/Banner';
import { LOCAL_STORAGE_KEYS, XPROMO_LOCALES } from '../constants';
import {
  getStorageFilePath,
  getFilesListFromXPromoContent,
  setStorageFilesList,
  getStoredXPromoBanners,
} from "./Files";
import store from '../store';

export enum XPromoBannerTypes {
  VIDEO = 'video',
  IMAGE = 'image',
  FALLBACK = 'fallback',
}

const MAX_XPROMO_BANNERS_LENGTH = 6;

export const getBanners = async (bannerIds: string[]): Promise<ReturnBannerType[]> => {
  if (!bannerIds.length) {
    return;
  }

  try {
    //todo: renew babel and rewrite in correct way with allSettled with promise check
    const bannersPromise: (ReturnBannerType | undefined)[] = await Promise.all(bannerIds.map(id => Banner.fetch(id)));
    const resultedBanners: ReturnBannerType[] = bannersPromise.filter(Boolean);
    debugLogData(`getBanners('${JSON.stringify(bannerIds)})`, resultedBanners, serviceLogger);
    return resultedBanners;
  } catch (error) {
    serviceLogger.error(`getBanners('${JSON.stringify(bannerIds)}')`, error);
  }
};

export const getBannersRotationList = async platform => {
  if (!platform) return;
  try {
    const list = await fetchBannersRotationListConfig(platform);
    debugLogData(`getBannersRotationList('${platform}')`, list, serviceLogger);
    return list;
  } catch (error) {
    serviceLogger.error(`getBannersRotationList('${platform}')`, error);
  }
};

export const getLauncherXPromoContent = ()  => {
  const playerId = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_LOGIN_ID);
  if (!playerId) return;

  const {
    udid: deviceId,
    xpromoId,
    settings: { language },
  } = store.getState().main;

  try {
    const formData = new FormData();
    formData.append('xpromoId', xpromoId);
    formData.append('deviceId', deviceId);
    formData.append('playerId', playerId);
    formData.append('locale', XPROMO_LOCALES[language]);
    fetch('https://specialdeals.g5e.com/v4/getLauncherContent', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(xpromoContent => {
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.STORED_XPROMO_CONTENT,
          JSON.stringify(xpromoContent.assets.filter(banner => banner.type === 'cross-promo')),
        );
        const filesToDownload = getFilesListFromXPromoContent(xpromoContent.assets);
        setStorageFilesList(filesToDownload);
      });

  } catch (error) {
    serviceLogger.error(`getXPromoBanners('${xpromoId}', '${deviceId}', '${playerId}', '${language}')`, error);
  }
};

export type XPromoBannerType = {
  id: string;
  image: string;
  priority: number;
  type: XPromoBannerTypes;
  xpromoId: string;
  video: string;
  disableLauncherButton: boolean;
  fallback: string;
};

export const getXPromoBanners = async (): Promise<XPromoBannerType[]> => {
  try {
    let banners = {};
    const storedBanners = getStoredXPromoBanners();
    if (!storedBanners.length) return;
    for (const banner of storedBanners) {
      banners[banner.id] = {
        xpromoId: banner.targetXpromoId,
        priority: banner.priority,
        id: banner.id,
        disableLauncherButton: banner?.settings?.disableLauncherButton,
      };
      for (const bannerItem in banner.files) {
        const fileType = banner?.files[bannerItem]?.type;
        if (!fileType) {
          continue;
        }
        // eslint-disable-next-line no-await-in-loop
        const fileExistsOnClient = await getStorageFilePath(banner.files[bannerItem].name);

        if (fileExistsOnClient) {
          banners[banner.id][fileType] = fileExistsOnClient;
        }

      }
      {
        /*
        banners[banner.id].type = banners[banner.id][XPromoBannerTypes.VIDEO]
          ? XPromoBannerTypes.VIDEO
          : banners[banner.id][XPromoBannerTypes.IMAGE]
            ? XPromoBannerTypes.IMAGE
            : XPromoBannerTypes.FALLBACK;
      */
      }
      banners[banner.id].type = banners[banner.id][XPromoBannerTypes.VIDEO]
        ? XPromoBannerTypes.VIDEO
        : XPromoBannerTypes.FALLBACK;
    }
    return Object.keys(banners).map(bannerKey => banners[bannerKey]);
  } catch (error) {
    serviceLogger.error('getXPromoBanners()', error);
  }
};
