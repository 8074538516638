import { EventType } from "../../../classes/Event";

export enum EventsActionTypes {
  SET_EVENTS = 'SET_EVENTS',
  SET_EVENT_IDS = 'SET_EVENT_IDS',
  REMOVE_EVENT = 'REMOVE_EVENT',
}

interface SetEvents {
  type: EventsActionTypes.SET_EVENTS,
  events: EventType[]
}

interface SetEventIds {
  type: EventsActionTypes.SET_EVENT_IDS,
  eventIds: string[]
}

interface RemoveEvent {
  type: EventsActionTypes.REMOVE_EVENT,
  id: string,
}

export type IEventsActionTypes = SetEvents | SetEventIds | RemoveEvent;