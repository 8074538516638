import Cropper from "react-easy-crop";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Headline } from "../../../commons/Headline";
import { Button } from "../../../commons/Button";
import { useDispatch } from "react-redux";
// import { HintMessage } from '../../../commons/HintMessage';
import { getCroppedImg } from "../../../../utils/image";
// import { ReactComponent as HintIcon } from '../../../../assets/images/svg/hint-icon.svg';
import { ReactComponent as PlusIcon } from "../../../../assets/images/svg/plus.svg";
import { ReactComponent as MinusIcon } from "../../../../assets/images/svg/minus.svg";
import fetchPGPL, { apiPGPLMethods } from "../../../../utils/apiPGPL";
import { renderLogger } from "../../../../../logger";
import { setProfilePicture } from "../../../../../store/dux/auth/auth";
import { ProfileContext } from "../../../../pages/Profile/Profile";

interface IModalAvatarCropper {
  imageToCrop: string;
  setCroppedImage: (img: Blob) => void;
  closeCropModal: () => void;
}

interface ICropCoords {
  x: number;
  y: number;
}

const MIN_ZOOM = 1;
const MAX_ZOOM = 5;

function ModalAvatarCropper({ imageToCrop, setCroppedImage, closeCropModal }: IModalAvatarCropper) {
  const { t } = useTranslation();
  const { setNotification, callbackRef, setIsLoading } = useContext(ProfileContext);
  const [crop, setCrop] = useState<ICropCoords>({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [zoom, setZoom] = useState<number>(2);
  const dispatch = useDispatch();

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const saveAndDisplayImage = async (): Promise<void> => {
    callbackRef.current = () => saveAndDisplayImage();
    setIsLoading(true);
    const image = await getCroppedImg(imageToCrop, croppedAreaPixels);
    await fetchPGPL(apiPGPLMethods.updatePlayer, { customPicture: image })
      .then(response => {
        if (response.error) {
          throw Error('default');
        }
        dispatch(setProfilePicture(URL.createObjectURL(image)));
        setCroppedImage(image);
        setNotification(null);
        closeCropModal();
      })
      .catch(error => {
        renderLogger.error('saveAndDisplayImage()', error);
        if (error.message === 'default') {
          return setNotification(null);
        }
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const decrementZoom = () => {
    setZoom(v => {
      if (v <= MIN_ZOOM) return v;
      return --v;
    });
  };

  const incrementZoom = () => {
    setZoom(v => {
      if (v >= MAX_ZOOM) return v;
      return ++v;
    });
  };

  return (
    <>
      {/* <HintMessage label="Drag to change the position" icon={<HintIcon />} className="modal__hint center" /> */}
      <Headline headlineLevel="p" size="4" variant="primary" className="modal__headline center-inline">
        {t<string>('profile.titles.upload_photo')}
      </Headline>
      <div className="modal__crop-container">
        <Cropper
          image={imageToCrop}
          crop={crop}
          onCropChange={setCrop}
          onZoomChange={v => setZoom(v)}
          cropSize={{ width: 260, height: 260 }}
          objectFit="vertical-cover"
          aspect={1}
          showGrid={false}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
          zoom={zoom}
          zoomSpeed={1}
          zoomWithScroll
          onCropComplete={onCropComplete}
          style={{
            cropAreaStyle: {
              borderRadius: '40px',
              color: '#a5a5a9',
              border: 'none',
            },
          }}
        />
        <div className="modal__crop-zoom">
          <button type="button" className="modal__crop-button" onClick={decrementZoom}>
            <MinusIcon />
          </button>
          <button type="button" className="modal__crop-button" onClick={incrementZoom}>
            <PlusIcon />
          </button>
        </div>
      </div>
      <div className="modal__btns">
        <Button
          type="button"
          variant="primary"
          sizeMode="2"
          className="modal__btn flex-center"
          onClick={saveAndDisplayImage}>
          {t<string>('profile.buttons.save')}
        </Button>
      </div>
    </>
  );
}

export default ModalAvatarCropper;
