import { useTranslation } from "react-i18next";
import alarm from '../../../../assets/images/alarm.svg';
import styles from './Input.module.scss';

const Input = ({ onChange, error, maxLength }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.inputField}>
      <input
        type="email"
        onChange={onChange}
        placeholder={t('subscribe.placeholder')}
        className={`${styles['inputField__input']} ${error ? styles['inputField__error--input'] : ''}`}
        maxLength={maxLength}
      />
      {error && (
        <span className={styles['inputField__error']}>
          <img src={alarm} alt="Invalid email address" />
          {error}
        </span>
      )}
    </div>
  );
};

export default Input;
