import usePageLogging from '../../hooks/usePageLogging';
import { renderLogger } from '../../logger';
import HomeContent from '../pages-content/home/HomeContent';
import { LOGGING_PAGE_IDS } from '../../constants';
import { PageScrollbar } from "../shared/scrollbar/Scrollbars";
import { BannerXPromo } from "../shared/BannerXPromo";

function Home() {
  renderLogger.debug('Home');
  usePageLogging(LOGGING_PAGE_IDS.ALL_GAMES);
  return (
    <PageScrollbar>
      <div className="main">
        <BannerXPromo />
        <HomeContent />
      </div>
    </PageScrollbar>
  );
}

export default Home;
