import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ContainerWrapper, PageWrapper } from '../../shared/layout/Wrappers';
import BackgroundFallback from '../../shared/banners/single/BackgroundFallback';
import styles from './Installation.module.scss';
import logo from '../../../assets/images/logo-white.svg';
import { ExeInstallProgress } from '../../shared/exeInstallProgress';
import { selectAllBanners, selectCurrentLang, selectGameByGameID } from '../../../store/selectors';

const Installation = () => {
  const {
    state: { id },
  } = useLocation();
  const game = useSelector(selectGameByGameID(id));
  const language = useSelector(selectCurrentLang);
  const banner = useSelector(selectAllBanners, shallowEqual).filter(bannerItem => bannerItem.id === game.bannerId)[0];
  const logoImage = banner?.lockit[language]?.logoImage || banner?.lockit['en']?.logoImage;
  const gameName = game.lockit[language].shortName || game.lockit['en'].shortName;

  return (
    <PageWrapper classes={[styles.InstallationWrapper]}>
      <div className={styles.panel} />
      <BackgroundFallback
        fallback={banner?.backgroundBlurImage}
        styles={{ top: 0, right: 0, backgroundPosition: 'center' }}
      />
      <ContainerWrapper classes={[styles.InstallationContainer]}>
        <div className={styles.background} style={{ backgroundImage: `url('../../${banner?.backgroundImage}')` }}>
          <img src={logo} className={styles.g5Logo} alt="" />
          <img src={`../../${logoImage}`} className={styles.gameLogo} alt="" />
          <img src={`../../${banner?.characterImage}`} className={styles.character} alt="" />
          <ExeInstallProgress icon={`../../${game.iconImage}`} gameName={gameName} game={game} />
        </div>
      </ContainerWrapper>
    </PageWrapper>
  );
};

export default Installation;
