import React from 'react';
import styles from '../../../pages/Game/Game.module.scss';
import { openLink } from '../../../../helpers';

function SingleGameLink({ supportLink, title }) {
  return (
    <div className={styles['game-single__link']}>
      <button className="btn btn--link btn--blue" onClick={() => openLink(supportLink)}>
        {title}
      </button>
    </div>
  );
}

export default SingleGameLink;
