import { serviceLogger } from '../logger';
import Genre from '../classes/Genre';

export const getGenres = async () => {
  try {
    const genres = await Genre.fetchAll();
    const sortedGenres = genres.sort((a, b) => a['sortOrder'] - b['sortOrder']);
    return sortedGenres;
  } catch (error) {
    serviceLogger.error('getGenres()', error);
  }
};
