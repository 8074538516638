import {useEffect, useMemo} from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { startUpdate } from '../../../store/dux/update';
import styles from './modal.module.scss';
import ProgressBar from '../progressbar/ProgressBar';
import { useTranslation } from 'react-i18next';
import { openLink } from '../../../helpers';
// import { ReactComponent as Close } from '../../../assets/images/cross.svg';
import { LAUNCHER_UPDATE_STATUS, INSTALLATION_TYPE } from '../../../constants';
import {selectLauncherUpdatingProgress, selectUpdateLink} from "../../../store/selectors";

function UpdateModal({ type, children }) {
  const modalDiv = useMemo(() => {
    const element = document.createElement('div');
    element.className = styles.modalContainer;
    return element;
  }, []);

  useEffect(
    function handlePopupMount() {
      document.body.appendChild(modalDiv);
      return () => document.body.removeChild(modalDiv);
    },
    [type],
  );

  return createPortal(<div className={styles.modal}>{children}</div>, modalDiv);
}

UpdateModal[LAUNCHER_UPDATE_STATUS.UPDATING] = function() {
  /* eslint-disable react-hooks/rules-of-hooks */
  const { t } = useTranslation();
  const progressData = useSelector(selectLauncherUpdatingProgress);
  return (
    <>
      <h3>{t('update.available')}</h3>
      <p>{t('update.ready_to_install')}</p>
      <ProgressBar progress={progressData}
                   methodType={INSTALLATION_TYPE.INSTALL}
                   update />
      <strong>{t(`shared.progressbar.update`, { progress: progressData })}</strong>
    </>
  );
};

UpdateModal[LAUNCHER_UPDATE_STATUS.FORCE_UPDATE] = function() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  useEffect(function handleStartUpdate() {
    dispatch(startUpdate())
  }, []);
  return null;
};

{/*
  UpdateModal[LAUNCHER_UPDATE_STATUS.ERROR] = function() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
      <>
        <div className={styles.close} onClick={() => dispatch(completeUpdateWithError())}>
          <Close />
        </div>
        <h3>{t("update.error_head")}</h3>
        <p>{t("update.force_update_error_body")}</p>
        <div className={styles.buttonContainer}>
          <button className="btn btn--default btn--round btn--blue" onClick={() => dispatch(startUpdate())}>
            {t("update.retry")}
          </button>
          <button className="btn btn--default btn--round btn--grey" onClick={() => dispatch(completeUpdateWithError())}>
            {t("shared.buttons.cancel")}
          </button>
        </div>
      </>
    );
  };
*/}

UpdateModal[LAUNCHER_UPDATE_STATUS.ERROR_FORCE_UPDATE] = function() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const downloadLink = useSelector(selectUpdateLink);
  return (
    <>
      <h3>{t('update.error_head')}</h3>
      <p>
        {t('update.force_update_error_body')}
      </p>
      <div className={styles.buttonContainer}>
        <button className="btn btn--default btn--round btn--blue" onClick={() => dispatch(startUpdate())}>
          {t('update.retry')}
        </button>
        <button
          className="btn btn--default btn--round btn--grey"
          onClick={() => openLink(downloadLink)}>
          {t('update.download')}
        </button>
      </div>
    </>
  );
};

export default UpdateModal;
