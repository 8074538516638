import { FC, InputHTMLAttributes, forwardRef, KeyboardEvent } from 'react';
import { UseFormRegister, FieldValues } from 'react-hook-form';
import './styles/index.scss';

type InputType = 'text' | 'email' | 'tel' | 'password';

export type InputProps =
  | InputHTMLAttributes<HTMLInputElement> & {
      id: string;
      type?: InputType;
      name?: string;
      register?: UseFormRegister<FieldValues>;
      className?: string;
      placeholder?: string;
      defaultValue?: string;
      maxlength?: number;
    };

const preventDefaultOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
  if (event.keyCode === 13) {
    event.preventDefault();
  }
};

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({ id, type = 'text', name = '', className = '', placeholder, register, maxlength, defaultValue, ...props }, ref) => {
    return (
      <input
        id={id}
        type={type}
        name={name}
        className={`input ${className}`}
        placeholder={placeholder}
        ref={ref}
        defaultValue={defaultValue}
        {...(register && { ...register(name) })}
        {...(maxlength && { maxlength })}
        {...props}
        onKeyDown={preventDefaultOnEnter}
      />
    );
  },
);

export default Input;
