import { createSelector } from 'reselect';
import { checkIsPromoActive } from '../helpers';
import {
  LAUNCHER_DOWNLOAD_LINKS,
  SITE_LINK_LOCALES,
  INSTALLATION_TYPE,
  LOCAL_STORAGE_KEYS,
  SELECTED_BANNER_TYPES,
} from '../constants';
import { XPromoBannerTypes } from '../services/Banners';

// Simple selectors
export const selectCurrentLang = state => state.main.settings.language;

export const selectSiteLocaleByCurrentLang = createSelector(
  [selectCurrentLang],
  currentLang => SITE_LINK_LOCALES[currentLang],
);

export const selectAllGenres = state => state.main.genres;

export const selectLogo = state => state.main.logo;

const selectBlockedGames = state => state.main.blockedGames;

export const selectGames = state => state.main.games;

export const selectAllGames = createSelector([selectGames, selectBlockedGames], (games, blockedGames) =>
  games.filter(game => !blockedGames.includes(game.id)),
);

export const selectActiveGenre = state => state.main.activeGenre;

export const selectAllBanners = state => state.main.banners;

export const selectAllBannersRotationList = state => state.main.bannersRotationList;

export const selectEXPConfig = state => state.main?.expConfig;

export const selectActiveABTestGroup = state => state.main?.abTestGroup;

export const selectLaunchedGamesList = state => state.main.launchedGames;

export const selectInstalledGamesIDs = state => state.main.installedGames;

export const selectInstalledGames = createSelector(
  [selectInstalledGamesIDs, selectAllGames],
  (installedIDs, allGames) => {
    const filterByClientLibInstalledApps = game => {
      return installedIDs.some(item => game.packageId === item['packageId'] || game.xpromoId === item['xpromoId']);
    };
    return allGames.filter(game => filterByClientLibInstalledApps(game));
  },
);

export const selectNotInstalledGames = (state, numberOfGames) =>
  createSelector(
    [selectInstalledGamesIDs, selectAllGames, (state, numberOfGames) => numberOfGames],
    (installedGames, games, numberOfGames) => {
      const filterByClientLibInstalledApps = game => {
        return installedGames.some(item => game.packageId === item['packageId'] || game.xpromoId === item['xpromoId']);
      };
      return games.filter(game => !filterByClientLibInstalledApps(game)).slice(0, numberOfGames);
    },
  )(state, numberOfGames);

export const selectModalToShow = state => state.modal.modalToShow;

export const selectTakeoverBanners = state => state.takeoverBanners;

export const selectGameProcessingStatus = uri => state => {
  return state.progress.inProgress.find(game => game.uri === uri) || undefined;
};

export const selectLauncherUpdatingProgress = state => state.update.launcherUpdating ?? 0;

export const selectGameByURI = uri => state => {
  return state.main.games.find(game => game.storeLink === uri);
};

export const selectGameByXPromoID = xpromoId => state => {
  return state.main.games.find(game => game.xpromoId === xpromoId);
};

export const selectGameByGameID = gameId => state => state.main.games.find(game => game.id === gameId);

export const selectAutostartStatus = state => state.main.settings.autostart;

export const selectSettingsParameterStatus = parameter => state => {
  return state.main.settings?.[parameter];
};

export const selectCDN = state => state.main.cdn;

export const selectAllNotifications = state => state.notifications;

export const selectGameIsInstalledByGameId = createSelector(
  [selectInstalledGamesIDs, selectAllGames, (state, id) => id],
  (installedGames, games, id) => {
    const xpromoId = games.find(game => game.id === id)?.xpromoId;
    if (!xpromoId) return;
    return installedGames.findIndex(game => game.xpromoId === xpromoId) !== -1;
  },
);

const selectPromo = state => state.main.promo;

export const selectGamePromoData = createSelector([selectPromo, (state, gameId) => gameId], (promo, gameId) => {
  if (!promo || !promo?.games.includes(gameId)) return;
  if (checkIsPromoActive(promo.dateTimeStart, promo.dateTimeEnd)) {
    return promo;
  }
});

export const selectInstalledGameVersion = createSelector(
  [selectInstalledGames, (state, xpromoId) => xpromoId],
  (installedGamesData, xpromoId) => {
    const installedApp = installedGamesData.find(item => item.xpromoId === xpromoId);
    return installedApp && installedApp['version'];
  },
);

export const selectAllScreenshots = state => state.main.screenshots;

export const selectCurrentVersion = state => state.main.version;

export const selectAvailableUpdate = state => state.update.update;

export const selectDeferredUpdate = state => state.update.deferredUpdate;

export const selectUpdatingStatus = state => state.update.updating;

export const selectUpdateError = state => state.update.update_error;

export const selectGameDataToInstallFromExe = state => state.progress.gameToInstallFromExe;

export const selectScreenshotsAreLoadingByGameId = createSelector(
  [selectAllScreenshots, (state, gameId) => gameId],
  (screenshots, gameId) => {
    return screenshots?.[gameId]?.loading;
  },
);

export const selectScreenshotsByGameId = createSelector(
  [selectCurrentLang, selectAllScreenshots, (state, gameId) => gameId],
  (language, screenshots, gameId) => {
    return screenshots?.[gameId]?.screenshots?.[language] || {};
  },
);

export const selectLogoByBannerId = createSelector(
  [selectAllBanners, selectCurrentLang, (state, bannerId) => bannerId],
  (banners, language, bannerId) => {
    const banner = banners.find(banner => banner.id === bannerId);
    return banner?.lockit[language]?.logoImage || banner?.lockit['en']?.logoImage;
  },
);

const selectActiveBannerIndex = state => state.main.activeBanner;

// Complex selectors
// Memoization depends on simple selectors
export const selectBannerSliderData = createSelector(
  [
    selectAllGames,
    selectAllBanners,
    selectAllBannersRotationList,
    selectActiveBannerIndex,
    selectInstalledGamesIDs,
    selectBlockedGames,
  ],
  (games, banners, bannersRotationList, activeBannerIndex, clientLibInstalledApps, blockedGames) => {
    const rotatedBanners = banners
      .filter(banner => {
        if (blockedGames.includes(banner.gameId)) return;
        if (banner.type === 'promo') {
          return checkIsPromoActive(banner.dateTimeStart, banner.dateTimeEnd);
        }
        return true;
      })
      .filter(banner => bannersRotationList[banner.id])
      .sort((a, b) => bannersRotationList[a.id]['sortOrder'] - bannersRotationList[b.id]['sortOrder']);

    const checkedBannerIndex = rotatedBanners.length < activeBannerIndex + 1 ? 0 : activeBannerIndex;

    return {
      games,
      rotatedBanners,
      activeBannerIndex: checkedBannerIndex,
      clientLibInstalledApps,
    };
  },
);

export const selectActiveXPromoBanner = state => state.main.activeXPromoBanner;

export const selectGameDataToInstall = createSelector(
  [selectAllGames, selectInstalledGamesIDs, selectGameDataToInstallFromExe, selectAvailableUpdate],
  (games, installedGames, dataToInstall, appUpdate) => {
    if (!dataToInstall?.xPromoID || appUpdate?.forceUpdate) return;

    const gameByXPromoID = games.find(game => game.xpromoId === dataToInstall.xPromoID);

    if (!gameByXPromoID) {
      return;
    }

    return {
      id: gameByXPromoID.id,
      g5tid: dataToInstall.g5tid,
      timestamp: dataToInstall.timestamp,
      forceUpdate: dataToInstall.forceUpdate,
    };
  },
);

export const selectGenresWithGames = createSelector([selectAllGames, selectAllGenres], (games, genres) => {
  const allGenresInGames = games.reduce((acc, curr) => {
    return acc.concat(curr.genreIds);
  }, []);
  return genres.filter(genre => {
    return allGenresInGames.includes(genre.id);
  });
});

export const selectRecentlyInstalledGame = state => state.progress.recentlyInstalledGameURI;

export const selectRecentlyStartedInstallationGameURI = state => state.progress.recentlyStartedInstallationGameURI;

export const selectGamesAutoupdateStatus = state => state.main.settings.autoupdate;

export const selectUpdateLink = state => {
  if (state.main.testDevice) {
    return LAUNCHER_DOWNLOAD_LINKS.EXE.TEST;
  }
  return LAUNCHER_DOWNLOAD_LINKS.EXE.PRODUCTION;
};

export const selectGameXPromoIdByGameId = createSelector(
  [selectAllGames, (state, gameId) => gameId],
  (games, gameId) => {
    if (!gameId) return;
    return games.find(game => game.id === gameId)?.xpromoId;
  },
);

export const selectActiveState = state => state.main.active;

const selectInProgress = state => state?.progress?.inProgress;

export const selectIsAnythingIsUpdating = createSelector(
  [selectInProgress, (state, forceUpdate) => forceUpdate],
  (inProgress, forceUpdate) => {
    if (!inProgress || inProgress.length === 0) return;
    return inProgress.filter(gameData => {
      const progressTypeIsUpdate = !gameData.error && gameData.methodType === INSTALLATION_TYPE.UPDATE;
      if (progressTypeIsUpdate && forceUpdate) return true;
      return progressTypeIsUpdate;
    }).length;
  },
);

export const selectIsCurrentGameInProgress = createSelector(
  [selectInProgress, selectAllGames, (state, gameId) => gameId],
  (inProgress, games, gameId) => {
    const uri = games.find(game => game.id === gameId)?.storeLink;
    return inProgress.findIndex(progressGame => progressGame.uri === uri) !== -1;
  },
);

export const selectIsAnythingInProgress = state => {
  return !!state.progress.inProgress.filter(gameData => !gameData.error).length;
};

export const selectError = error => state => {
  return state.main.errorData === error;
};

export const selectXPromoBanners = state => {
  if (state.main?.xPromoBanners?.length) {
    return {
      xPromoBanners: state.main.xPromoBanners.sort((a, b) => a.priority - b.priority),
      type: SELECTED_BANNER_TYPES.XPROMO,
    };
  } else {
    const defaultBanners = [];
    const rotationList = state.main.bannersRotationList;
    for (const bId in rotationList) {
      const xpromoId = state.main.games.find(game => game.bannerId === bId)?.xpromoId;
      if (!xpromoId) continue;
      defaultBanners.push({
        priority: rotationList[bId].sortOrder,
        type: XPromoBannerTypes.FALLBACK,
        id: bId,
        xpromoId,
      });
    }

    return {
      xPromoBanners: defaultBanners.sort((a, b) => a.priority - b.priority),
      type: SELECTED_BANNER_TYPES.STATIC,
    };
  }
};

export const selectBannerByBannerId = gameBannerId => state =>
  state.main.banners.filter(bannerItem => bannerItem.id === gameBannerId)[0];

export const selectOpenURICommand = state => state.main.openURICommand;

export const selectActiveTestGroup = state => state.main?.abTestGroup?.exp_log_id[0];

export const selectUserRegion = state =>
  state.main?.userRegion || window.localStorage.getItem(LOCAL_STORAGE_KEYS.USER_REGION);

export const selectBlockedCountriesByGame = state => state.main?.blockedCountriesByGame;

export const selectAsianLocaleIsActive = createSelector([selectCurrentLang], currentLanguage =>
  ['ja', 'ko', 'zh', 'zh_tw'].includes(currentLanguage),
);

export const selectWebViewVersion = state => state.main.webViewVersion;
