/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/commons/Button';
import { GridContainer } from '../../components/containers/GridContainer';
import { HeaderSignIn } from '../../components/compound/HeaderSignIn';
import { MainContainer } from '../../components/containers/MainContainer';
import { TextButton } from '../../components/commons/TextButton';
import { Input } from '../../components/commons/Form/Input';
import { Label } from '../../components/commons/Form/Label';
import { InputPassword } from '../../components/commons/Form/InputPassword';
import { FormWrapper } from '../../components/commons/Form/FormWrapper';
import { FormFieldWrapper } from '../../components/commons/Form/FormFieldWrapper';
import { Delimeter } from '../../components/commons/Delimiter';
import { TextButtonWithIcon } from '../../components/commons/TextButtonWithIcon';
import ColoredScrollbars from '../../../components/shared/scrollbar/ColoredScrollbars';
import { profileRoutes } from '../../routes';
import { ReactComponent as BackIcon } from '../../assets/images/svg/back-icon.svg';
import '../../assets/styles/sign-in.scss';
import { ModalContainer } from '../../components/containers/ModalContainer';
import { ModalForgotPassword } from '../../components/containers-content/ModalsContent/ModalForgotPassword';
import ModalRecoveryPassword from '../../components/containers-content/ModalsContent/ModalRecoveryPassword/ModalRecoveryPassword';
import { ModalConfirmEmail } from '../../components/containers-content/ModalsContent/ModalConfirmEmail';
import { ErrorMessage } from '../../components/commons/Form/ErrorMessage';
import useLoginForm from '../../hooks/useLoginForm';
import {
  selectInvalidAccessTokenPlayerId,
  selectOfflineStatus,
  selectUserData,
} from '../../../store/dux/auth/selectors';
import { AuthActionTypes } from '../../../store/dux/auth/actionTypes';
import { renderLogger } from '../../../logger';
import { setLastPlayer } from '../../../services/Keychain';
import { ModalOffline } from '../../components/containers-content/ModalsContent/ModalOffline';
import useRecoverPasswordForm from '../../hooks/useRecoverPasswordForm';
import PGPLService from '../../services/PGPLService';
import { setInvalidAccessTokenPlayerID, setProfileData } from "../../../store/dux/auth/auth";
import usePageLogging from '../../../hooks/usePageLogging';
import { G5LAUNCHER_ROUTES, LOGGING_PAGE_IDS } from "../../../constants";
import { PageScrollbar } from '../../../components/shared/scrollbar/Scrollbars';

export enum SignInNotifications {
  RECOVER_PASSWORD = 'RECOVER_PASSWORD',
  SENT_RECOVERY_EMAIL = 'SENT_RECOVERY_EMAIL',
  CONFIRM_EMAIL_FIRST = 'CONFIRM_EMAIL_FIRST',
}

export type SignInContextType = {
  notification: SignInNotifications | null;
  setNotification: (type: SignInNotifications) => void;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
};

export const SignInContext = createContext<SignInContextType>(null!);

enum LoginErrorMessages {
  DEFAULT = 'profile.message.wrong_login',
  ALREADY_LOGGED_IN = 'profile.message.login_in_use',
}

function SignIn() {
  usePageLogging(LOGGING_PAGE_IDS.SIGN_IN);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profileData = useSelector(selectUserData);
  const offline = useSelector(selectOfflineStatus);
  const { register, handleSubmit, errors, setValue } = useLoginForm();
  const recoverPasswordForm = useRecoverPasswordForm();
  const [notification, setNotification] = useState<SignInNotifications | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<LoginErrorMessages | null>(null);
  const invalidAccessTokenID = useSelector(selectInvalidAccessTokenPlayerId);

  const invalidAccessTokenIDRef = useRef<string | undefined>(undefined);

  useEffect(
    function handleInvalidAccessTokenID() {
      if (invalidAccessTokenID) {
        setValue('name', invalidAccessTokenID);
        invalidAccessTokenIDRef.current = invalidAccessTokenID;
        dispatch(setInvalidAccessTokenPlayerID(undefined));
      }
    },
    [invalidAccessTokenID],
  );

  const closeNotification = () => setNotification(null);
  const setRecoverPasswordModal = () => setNotification(SignInNotifications.RECOVER_PASSWORD);

  const goBack = () => {
    if (invalidAccessTokenIDRef.current) {
      return history.push(G5LAUNCHER_ROUTES.ALL_GAMES);
    }
    history.goBack();
  };

  const login = async data => {
    try {
      setIsLoading(true);
      const { name, password } = data;
      if (!invalidAccessTokenIDRef.current && [profileData?.email, profileData?.name].includes(name)) {
        return setLoginError(LoginErrorMessages.ALREADY_LOGGED_IN);
      }
      const webToken = await PGPLService.loginWeb(name, password);
      // logout previous user and set recently logged in user credentials
      if (process.env.NODE_ENV === 'production') {
        await PGPLService.logout();
      }

      dispatch({
        type: AuthActionTypes.CLEAN,
      });
      dispatch({
        type: AuthActionTypes.SET_WEBTOKEN,
        webToken,
      });
      const profile = await PGPLService.getPlayerInfo();
      await setLastPlayer(profile.id, webToken);
      dispatch(setProfileData(profile));
      history.push(profileRoutes.profile);
    } catch (error) {
      renderLogger.error(`login(${JSON.stringify(data)})`, error);
      if (error.error) {
        setNotification(null);
        return setLoginError(LoginErrorMessages.DEFAULT);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageScrollbar>
      <SignInContext.Provider value={{ notification, setNotification, isLoading, setIsLoading }}>
        <div className="container-all sign-in">
          <ColoredScrollbars
            verticalthumbstyle={{
              right: 2,
              bottom: 2,
              top: 2,
              paddingTop: 0,
              paddingBottom: 0,
            }}>
            <TextButtonWithIcon className="sign-in__close-btn" icon={<BackIcon />} onClick={goBack}>
              {invalidAccessTokenIDRef.current
                ? t<string>('profile.buttons.go', { to: t('header.navigation.list.home') })
                : t<string>('profile.buttons.back')}
            </TextButtonWithIcon>
            <HeaderSignIn>{t<string>('profile.titles.sign_in_to_friends')}</HeaderSignIn>
            <MainContainer mainType="sign">
              <GridContainer containerType="type-2">
                <FormWrapper className="sign-in__form htmlForm sign-in-form" id="g5Friends_signin_form">
                  <FormFieldWrapper error={errors?.name?.message}>
                    <Label htmlFor="sign-in-email-or-login">{t<string>('profile.titles.enter_login')}</Label>
                    <Input
                      id="sign-in-email-or-login"
                      placeholder="example@address.com"
                      register={register}
                      name="name"
                      className={`input ${errors?.name && 'error'}`}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper error={errors?.password?.message}>
                    <Label htmlFor="SigninForm_password">{t<string>('profile.titles.enter_password')}</Label>
                    <InputPassword
                      id="SigninForm_password"
                      register={register}
                      placeholder={t<string>('profile.titles.enter_password')}
                      name="password"
                      className={`input ${errors?.password && 'error'}`}
                    />
                  </FormFieldWrapper>
                  {loginError && <ErrorMessage>{t<string>(loginError)}</ErrorMessage>}
                  {/* <Checkbox label="Keep me Signed In" inputId='"personal-checkbox' /> */}
                  <Button type="button" sizeMode="1" variant="tertiary" onClick={handleSubmit(login)}>
                    {t<string>('profile.buttons.sign_in')}
                  </Button>
                </FormWrapper>
                <div className="center-inline">
                  <TextButton type="button" variant="primary" onClick={setRecoverPasswordModal}>
                    {t<string>('profile.buttons.forgot_password')}
                  </TextButton>
                  <Delimeter className="sign-in__delimiter" />
                  <Button type="button" sizeMode="1" onClick={() => history.push(profileRoutes.signUp)}>
                    {t<string>('profile.buttons.create_account')}
                  </Button>
                </div>
              </GridContainer>
            </MainContainer>
          </ColoredScrollbars>
        </div>
        {offline && (
          <ModalContainer size="sm" className="modal-higher" closeDisabled>
            <ModalOffline />
          </ModalContainer>
        )}
        {notification === SignInNotifications.RECOVER_PASSWORD && (
          <ModalContainer size="sm" onClose={closeNotification}>
            <ModalForgotPassword recoverPasswordForm={recoverPasswordForm} />
          </ModalContainer>
        )}
        {notification === SignInNotifications.SENT_RECOVERY_EMAIL && (
          <ModalContainer size="sm" onClose={closeNotification}>
            <ModalRecoveryPassword />
          </ModalContainer>
        )}
        {notification === SignInNotifications.CONFIRM_EMAIL_FIRST && (
          <ModalContainer size="sm" onClose={closeNotification}>
            <ModalConfirmEmail />
          </ModalContainer>
        )}
      </SignInContext.Provider>
    </PageScrollbar>
  );
}
export default SignIn;
