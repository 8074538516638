/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useRef, MutableRefObject, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { intervalToDuration, format } from 'date-fns';
import { GridContainer } from '../../components/containers/GridContainer';
import { MainContainer } from '../../components/containers/MainContainer';
import Delimiter from '../../components/commons/Delimiter/Delimeter';
import { ChangePlayer } from '../../components/compound/ChangePlayer';
import { HeaderProfile } from '../../components/compound/HeaderProfile';
import { ProfileInfoSection } from '../../components/compound/ProfileInfoSection';
import { RecommendedGames } from '../../components/compound/RecommendedGames';
import { PasswordInfoSection } from '../../components/compound/PasswordInfoSection';
import { AvatarCropper } from '../../components/compound/AvatarCropper';
import { Notification } from '../../components/commons/Notification';
import { MessageContainer } from '../../components/containers/MessageContainer';
import { MessageComplete } from '../../components/containers-content/MessagesContent/MessageComplete';
import { MessageNotVerified } from '../../components/containers-content/MessagesContent/MessageNotVerified';
import '../../assets/styles/profile.scss';
import { selectOfflineStatus, selectUserData } from '../../../store/dux/auth/selectors';
import { ModalContainer } from '../../components/containers/ModalContainer';
import { ModalThankYou } from '../../components/containers-content/ModalsContent/ModalThankYou';
import { ModalOffline } from '../../components/containers-content/ModalsContent/ModalOffline';
import { ModalAgeAccess } from '../../components/containers-content/ModalsContent/ModalAgeAccess';
import { updateProfileData } from '../../../store/dux/auth/auth';
import { renderLogger } from '../../../logger';
import { ModalAccountClosed } from '../../components/containers-content/ModalsContent/ModalAccountClosed';
import useJumpToProfileOnIdChange from '../../hooks/useJumpToProfileOnIdChange';
import usePageLogging from '../../../hooks/usePageLogging';
import { REFERRAL_SOURCES, LOGGING_PAGE_IDS } from '../../../constants';
import { PageScrollbar } from '../../../components/shared/scrollbar/Scrollbars';
import PGPLService from '../../services/PGPLService';

const MIN_AGE = 16;

export type EmailToConfirmType = {
  email: string;
  expiresAfter: number;
};

export enum AgeModalStatus {
  CONFIRM = 'CONFIRM',
  CLOSED = 'CLOSED',
  OK = 'OK',
}

export enum NotificationTypes {
  THANK_YOU = 'THANK_YOU',
  PROFILE_CHANGE = 'PROFILE_CHANGE',
  PASSWORD_CHANGE = 'PASSWORD_CHANGE',
  UNHANDLED_ERROR = 'UNHANDLED_ERROR',
}

export type ProfileContextType = {
  notification: NotificationTypes | null;
  setNotification: (type: NotificationTypes) => void;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  editingUnderageDate: boolean;
  setEditingUnderageDate: (v: boolean) => void;
  editProfileInfo: boolean;
  toggleEditProfile: (v: boolean) => void;
  ageModal: AgeModalStatus | null;
  setAgeModal: (v: AgeModalStatus | null) => void;
  saveAndSetBirthday: (date: Date) => void;
  callbackRef: MutableRefObject<(e?) => void> | null;
  underageConfirmStatus: 'confirmed' | null;
  unconfirmedEmail?: EmailToConfirmType;
  toggleEditPassword: (v: boolean) => void;
  editPassword: boolean;
  name: string;
  playerId: string;
  resentConfirmation: boolean;
};

const checkSelectedAge = (date): boolean => {
  const currentDate = new Date();
  const selectedBirthDate = new Date(date);
  const difference = intervalToDuration({ start: selectedBirthDate, end: currentDate });
  return MIN_AGE <= difference.years;
};

export const formatDateToPGPL = (date): string => {
  if (!date) return;
  return format(date, 'yyyy-MM-dd');
};

export const handleDate = (
  date,
): {
  birthday: string;
  confirmUnderage: boolean;
} => {
  const convertDate = formatDateToPGPL(date);
  const alrightAge = checkSelectedAge(convertDate);
  return {
    birthday: convertDate,
    confirmUnderage: !alrightAge,
  };
};

export const ProfileContext = createContext<ProfileContextType>(null!);

function Profile() {
  usePageLogging(LOGGING_PAGE_IDS.PROFILE);
  const {
    picture,
    passwordIsCustom,
    email,
    id: playerId,
    underageConfirmStatus,
    unconfirmedEmail,
    name,
    consentInfo,
    resentConfirmation,
  } = useSelector(selectUserData);
  const offline = useSelector(selectOfflineStatus);
  const location = useLocation();

  const [ageModal, setAgeModal] = useState<AgeModalStatus | null>(null);
  const [notification, setNotification] = useState<NotificationTypes | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editingUnderageDate, setEditingUnderageDate] = useState<boolean>(false);
  const [editProfileInfo, toggleEditProfile] = useState<boolean>(false);
  const [editPassword, toggleEditPassword] = useState<boolean>(false);

  const callbackRef = useRef<(e?) => void>(null!);

  const dispatch = useDispatch();

  useJumpToProfileOnIdChange();

  useEffect(
    function setInitialStatesOnUserChange() {
      toggleEditProfile(false);
      setEditingUnderageDate(false);
      setNotification(null);
      setAgeModal(null);
    },
    [playerId],
  );

  useEffect(function checkIfNewUser() {
    if (location?.state?.newUser) {
      setNotification(NotificationTypes.PROFILE_CHANGE);
    }
  }, []);

  const defaultUser = !email || !passwordIsCustom;

  const closeNotification = () => setNotification(null);

  const saveAndSetBirthday = async (date: Date) => {
    try {
      setIsLoading(true);
      await PGPLService.setPlayerBirthday(date).then(response => {
        if (!response.confirmUnderage) {
          dispatch(updateProfileData({ birthday: response.birthday, underageConfirmStatus: null }));
          setNotification(NotificationTypes.THANK_YOU);
          setEditingUnderageDate(null);
        }

        if (response.confirmUnderage) {
          dispatch(updateProfileData({ birthday: response.birthday }));
        }

        if (!response.confirmUnderage) {
          setNotification(NotificationTypes.THANK_YOU);
        }
        setIsLoading(false);
        toggleEditProfile(false);
      });
    } catch (error) {
      setIsLoading(false);
      renderLogger.error('saveAndSetBirthday()', JSON.stringify(date), error);
      throw error;
    }
  };

  const displayCompleteMessage = (!email || !passwordIsCustom) && underageConfirmStatus !== 'confirmed';

  return (
    <PageScrollbar profile>
      <ProfileContext.Provider
        value={{
          editingUnderageDate,
          setEditingUnderageDate,
          notification,
          setNotification,
          isLoading,
          setIsLoading,
          editProfileInfo,
          toggleEditProfile,
          ageModal,
          setAgeModal,
          saveAndSetBirthday,
          callbackRef,
          underageConfirmStatus,
          unconfirmedEmail,
          toggleEditPassword,
          editPassword,
          name,
          playerId,
          resentConfirmation,
        }}>
        <div className="container-all">
          <HeaderProfile>G5 Friends®</HeaderProfile>
          <MainContainer mainType="profile">
            <GridContainer containerType="type-1">
              <div className="profile" style={{ ...(editingUnderageDate && { pointerEvents: 'none' }) }}>
                <div className="profile__left center-inline">
                  <AvatarCropper picture={picture} />
                  <ChangePlayer />
                </div>
                <div className="profile__right">
                  {displayCompleteMessage && (
                    <MessageContainer messageType="complete" variant="dark">
                      <MessageComplete
                        passwordIsCustom={passwordIsCustom}
                        playerId={playerId}
                        unconfirmedEmail={unconfirmedEmail}
                        name={name}
                        consentInfo={consentInfo}
                      />
                    </MessageContainer>
                  )}
                  <ProfileInfoSection defaultUser={defaultUser} />
                  {unconfirmedEmail && (
                    <MessageContainer messageType="not-verified" variant="dark">
                      <MessageNotVerified unconfirmedEmail={unconfirmedEmail} />
                    </MessageContainer>
                  )}

                  {passwordIsCustom && (
                    <>
                      <Delimiter className="profile__delimiter" />
                      <PasswordInfoSection />
                    </>
                  )}
                </div>
              </div>
              <RecommendedGames numberOfGames={4} passedSource={REFERRAL_SOURCES.INSTALL.PROFILE} />
            </GridContainer>
          </MainContainer>

          {(notification === NotificationTypes.PROFILE_CHANGE ||
            notification === NotificationTypes.PASSWORD_CHANGE) && (
            <Notification
              notificationType="success"
              message={notification}
              placement="bottom"
              onClose={closeNotification}
            />
          )}
          {notification === NotificationTypes.THANK_YOU && (
            <ModalContainer size="lg" onClose={closeNotification}>
              <ModalThankYou onClose={closeNotification} />
            </ModalContainer>
          )}
          {offline && (
            <ModalContainer size="sm" className="modal-higher" closeDisabled>
              <ModalOffline />
            </ModalContainer>
          )}
          {ageModal === AgeModalStatus.CLOSED && (
            <ModalContainer size="lg" onClose={() => setAgeModal(null)} closeDisabled>
              <ModalAccountClosed />
            </ModalContainer>
          )}
          {((!editingUnderageDate && underageConfirmStatus === 'confirmed' && ageModal !== AgeModalStatus.CLOSED) ||
            ageModal === AgeModalStatus.CONFIRM) && (
            <ModalContainer
              size="lg"
              onClose={() => setAgeModal(null)}
              closeDisabled={underageConfirmStatus === 'confirmed'}>
              <ModalAgeAccess />
            </ModalContainer>
          )}
        </div>
      </ProfileContext.Provider>
    </PageScrollbar>
  );
}

export default Profile;
