import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import styles from './Bell.module.scss';
import { ReactComponent as BellIcon } from '../../../../assets/images/bell.svg';
import {
  selectDingDong,
  selectPushNotificationsPanelState,
  selectNewPushNotificationsCount,
} from "../../../../store/dux/pushNotifications/selectors";
import { setDingDong, setPushNotificationsPanelOpen } from "../../../../store/dux/pushNotifications/pushNotifications";
import Tooltip from "../tooltip/Tooltip";
import { selectActiveState } from "../../../../store/selectors";

const Bell = () => {
  const isOpenPushNotificationsPanel = useSelector(selectPushNotificationsPanelState);
  const dingDong = useSelector(selectDingDong);
  const activeWindow = useSelector(selectActiveState);
  const newPushNotificationsCount = useSelector(selectNewPushNotificationsCount);
  const [hovering, setHovering] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(function handleDingDong() {
    if (dingDong && activeWindow) {
      setTimeout(() => {
        dispatch(setDingDong(false));
      }, 1000);
    }
  }, [dingDong, activeWindow]);

  return (
    <div
      className={`${styles.bell} ${isOpenPushNotificationsPanel ? styles.activePanel : ''} ${(activeWindow && dingDong) ? styles.notify : ''}`}
      onClick={() => dispatch(setPushNotificationsPanelOpen(true))}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}>
      {newPushNotificationsCount !== 0 && <span className={styles.bell__new}>{newPushNotificationsCount}</span>}
      <BellIcon />
      {hovering && <Tooltip text={t<string>('pushnotifications.title')} top={41} />}
    </div>
  );
};

export default Bell;
