import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectProfilePGPLGameIDs, selectUserData } from "../../store/dux/auth/selectors";
import { getAndSetPGPLGamesData, setPGPLGamesData } from "../../store/dux/auth/auth";

export const usePGPLGameStats = (): void => {
  const profilePGPLGameIDs = useSelector(selectProfilePGPLGameIDs);
  const profileData = useSelector(selectUserData);
  const dispatch = useDispatch();

  useEffect(
    function handlePGPLUserGames() {
      if (!profileData.id || !profilePGPLGameIDs?.length) {
        setPGPLGamesData(null);
        return;
      }
      dispatch(getAndSetPGPLGamesData());
    },
    [profilePGPLGameIDs, profileData.id],
  );
};
