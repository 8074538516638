import { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styles from './Notifications.module.scss';
import useNotifications from '../../hooks/useNotifications';
import SingleNotification from './SingleNotification';
import NotificationColoredScrollbars from '../shared/scrollbar/NotificationColoredScrollbars';
import { useTranslation } from 'react-i18next';
import { selectRecentlyStartedInstallationGameURI } from '../../store/selectors';

export const AUTO_HEIGHT_MAX = 470;

const NotificationsContainer = () => {
  const { active, setActive, notifications } = useNotifications();
  const [fadeAwayPosition, setFadeAwayPosition] = useState(null);
  const recentlyStartedInstallationGameURI = useSelector(selectRecentlyStartedInstallationGameURI, shallowEqual);
  const { t } = useTranslation();

  const updateFadeAwayPositionOnScroll = top => {
    if (notifications.length <= 4) return fadeAwayPosition && setFadeAwayPosition(null);
    if (top === 0) {
      setFadeAwayPosition('bottom');
    } else if (top >= 1) {
      setFadeAwayPosition('top');
    } else {
      setFadeAwayPosition('both');
    }
  };

  if (!notifications.length) return null;

  return (
    <>
      <button
        className={styles.notificationsButton}
        onClick={() => notifications.length && setActive(active => !active)}>
        {t('notifications.downloads')}
        <i className={`${styles.notificationsButton__arrow} ${active ? styles.notificationsButton__arrow_down : ''}`} />
      </button>
      {active && (
        <div className={styles.notificationsList}>
          <div className={`${fadeAwayPosition ? styles[`fadingAway_${fadeAwayPosition}`] : null}`}>
            <NotificationColoredScrollbars
              autoHeight
              autoHeightMin="100px"
              autoHeightMax={`${AUTO_HEIGHT_MAX}px`}
              recentlyStartedInstallationGameURI={recentlyStartedInstallationGameURI}
              updatefadeawaypositiononscroll={updateFadeAwayPositionOnScroll}
              widget>
              {notifications.map((notification, index) => {
                const ChosenNotification = SingleNotification[notification.status];
                return (
                  <SingleNotification notification={notification} key={index}>
                    <ChosenNotification />
                  </SingleNotification>
                );
              })}
            </NotificationColoredScrollbars>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationsContainer;
