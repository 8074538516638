import { FC } from 'react';
import { useTranslation } from "react-i18next";

type ProfileDataProps = { data: { label: string; value: string }[] };

const ProfileDataList: FC<ProfileDataProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <ul className="profile__list">
      {data.map((dataItem, index) => (
        <li key={index} className="profile__list-item">
          <span className="profile__caption">{dataItem.label}</span>
          <span className={`profile__user-text ${dataItem.value ? '' : 'profile__user-text--missed'}`}>
            {dataItem.value || t<string>('profile.placeholders.not_specified')}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default ProfileDataList;
