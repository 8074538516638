/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState } from "react";
import { shallowEqual, useSelector } from 'react-redux';
import { PageScrollbar } from '../shared/scrollbar/Scrollbars';
import { selectAllActiveEvents } from '../../store/dux/events/selectors';
import { ContainerWrapper, PageWrapper } from '../shared/layout/Wrappers';
import { EventList } from './EventList';
import { NoEvents } from './NoEvents';
import { ContentWrapper } from '../shared/layout/Wrappers/Wrappers';
import styles from './Events.module.scss';
import usePageLogging from '../../hooks/usePageLogging';
import { LOGGING_PAGE_IDS } from '../../constants';
import { selectIsAuth } from '../../store/dux/auth/selectors';

type EventContextType = {
  activeEventId: string;
  setActiveEventId: (id: string | undefined) => void;
};

export const EventsContext = createContext<EventContextType>({activeEventId: null, setActiveEventId: null});

export default function Events() {
  const [activeEventId, setActiveEventId] = useState<string>();
  const events = useSelector(selectAllActiveEvents, shallowEqual);
  const isAuth = useSelector(selectIsAuth);

  usePageLogging(LOGGING_PAGE_IDS.EVENTS);

  return (
    <PageScrollbar>
      <PageWrapper>
        {isAuth && events ? (
          <ContentWrapper classes={[styles.EventsContainer]}>
            <ContainerWrapper>
              <EventsContext.Provider value={{ activeEventId, setActiveEventId }}>
                {events?.shuffleLaunchedGameEvents && <EventList events={events.shuffleLaunchedGameEvents} />}
                <EventList events={events?.eventsInstalledGame} />
                <EventList events={events?.eventsOther} />
              </EventsContext.Provider>
            </ContainerWrapper>
          </ContentWrapper>
        ) : (
          <NoEvents />
        )}
      </PageWrapper>
    </PageScrollbar>
  );
}
