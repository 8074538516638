import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compareVersions, getCurrentGameUpdateVersion } from '../../../../helpers';
import GameActionInstall from './install/GameActionInstall';
import GameActionPlay from './play/GameActionPlay';
import GameActionUpdate from './update/GameActionUpdate';
import Loader from '../../../pages-content/game/slider/loader/Loader';
import { APP_METHODS, GAME_ACTION_STATUS } from '../../../../constants';
import { selectGameProcessingStatus, selectGamesAutoupdateStatus } from "../../../../store/selectors";
import { startGameLoading } from '../../../../store/dux/progress';

function GameAction({ game, installedVersion, location, setButtonStatus, passedSource, additionalSource, size }) {
  const [gameActionStatus, setGameActionStatus] = useState(null);
  const gameUpdateVersionRef = useRef();
  const gameAutoUpdate = useSelector(selectGamesAutoupdateStatus);
  const gameStatus = useSelector(selectGameProcessingStatus(game.storeLink));
  const dispatch = useDispatch();

  const Buttons = {
    [GAME_ACTION_STATUS.PLAY]: <GameActionPlay {...{ game }} location={location} passedSource={passedSource} additionalSource={additionalSource} size={size} />,
    [GAME_ACTION_STATUS.INSTALL]: <GameActionInstall {...{ game }} location={location} passedSource={passedSource} additionalSource={additionalSource} size={size} />,
    [GAME_ACTION_STATUS.UPDATE]: (
      <GameActionUpdate
        {...{ game }}
        location={location}
        updateVersion={gameUpdateVersionRef.current}
        passedSource={passedSource}
        additionalSource={additionalSource}
        size={size}
      />
    ),
  };

  useEffect(
    function getGameActionStatus() {
      if (!installedVersion) {
        return setGameActionStatus(GAME_ACTION_STATUS.INSTALL);
      }

      if (location?.state?.forceUpdate) {
        return setGameActionStatus(GAME_ACTION_STATUS.UPDATE);
      }

      if(gameStatus) {
        return setGameActionStatus(GAME_ACTION_STATUS.PLAY);
      }

      (async () => {
        const actualUpdateVersion = await getCurrentGameUpdateVersion(game.xpromoId);

        if (actualUpdateVersion && compareVersions(actualUpdateVersion, installedVersion) === 1) {
          if (gameAutoUpdate) {
            dispatch(startGameLoading(APP_METHODS.UPDATE, game, null, null, actualUpdateVersion, 'game_autoupdate'));
            return setGameActionStatus(GAME_ACTION_STATUS.PLAY);
          }
          gameUpdateVersionRef.current = actualUpdateVersion;
          setGameActionStatus(GAME_ACTION_STATUS.UPDATE);
          return;
        }

        return setGameActionStatus(GAME_ACTION_STATUS.PLAY);
      })();
    },
    [installedVersion, location?.state?.timestamp, location?.state?.forceUpdate],
  );

  useEffect(() => {
    if (gameActionStatus && setButtonStatus) {
      setButtonStatus(gameActionStatus);
    }
  }, [gameActionStatus]);

  if (!gameActionStatus) return <Loader />;

  return <div>{Buttons[gameActionStatus]}</div>;
}
export default GameAction;
